import { useSearchParams } from 'react-router-dom';

import { DashboardSection } from '@spinach-shared/constants';
import { WebUrlQuery } from '@spinach-shared/types';

export function useNoteViewerDetection(): boolean {
    const [searchParams] = useSearchParams();
    const isViewingNotes = !!searchParams.get(WebUrlQuery.BotId) && !!searchParams.get(WebUrlQuery.SeriesId);

    const isDraftLink = searchParams.get(WebUrlQuery.MarkedAsDraft) === 'true';
    const isAccountDeepLink = searchParams.get(WebUrlQuery.Section) === DashboardSection.Actions;
    const isTicketSourceLink = !!searchParams.get(WebUrlQuery.TicketSource);

    const isExceptionToNoteViewingDeepLinkBehaviors = isDraftLink || isTicketSourceLink || isAccountDeepLink;

    return isViewingNotes && !isExceptionToNoteViewingDeepLinkBehaviors;
}
