import { Box } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { useMachine } from '@xstate/react';
import { useCallback, useState } from 'react';

import { VideoAgentSession } from '@spinach-shared/models';
import {
    ClientEventType,
    ClientSocketEvent,
    VideoAgentConnectingPayload,
    VideoAgentControlAction,
    VideoAgentControlCommand,
} from '@spinach-shared/types';
import { isLocalStage } from '@spinach-shared/utils';

import {
    ConnectionEventMetadata,
    patchVideoAgentSession,
    postVideoAgentChatMessage,
    postVideoAgentLLM,
    postVideoAgentSpeechSrc,
    useActivityTracking,
    useAddTopic,
    useFetchAgentAssetMap,
    useFetchVideoAgentConfig,
    useGlobalModal,
    useGlobalNullableVideoAgent,
    useJumpToTopicNumber,
    useLiveRecallTranscription,
    useNextAgendaTopic,
    usePreviousTopic,
    useStartAgenda,
    useTestAgentSession,
    useTimer,
    useVideoAgentSocketSyncing,
    useVideoAgentTextCommandProcessing,
    useWebsocket,
} from '../../../..';
import { postVideoAgentAskSpinach } from '../../../apis/video-agent/postVideoAgentAskSpinach';
import { GlobalModal, atomVideoAgentSocket } from '../../../atoms';
import { useSyncPastTriggers } from '../../../hooks/agent/useSyncPastTriggers';
import { AgentCommandType, SayFunction } from '../../../types/agent';
import { createWebsocketPayload } from '../../../utils';
import { useCallbackAt } from '../../../utils/useCallbackAt';
import { useFlagWithTimeout } from '../../../utils/useFlagWithTimeout';
import { VideoAgentTestControlsModal } from '../modals/VideoAgentTestControlsModal';
import { AboutToMatchIndicator } from './AboutToMatchIndicator';
import { AgentAgendaBreadcrumbs } from './AgentAgendaBreadcrumbs';
import { AgentAgendaContent } from './AgentAgendaContent';
import { AgentBackgrounds } from './AgentBackgrounds';
import { AgentLobbyContent } from './AgentLobbyContent';
import { AgentNotificationBanner } from './AgentNotificationBanner';
import { IntroComponent } from './IntroComponent';
import { VideoPlayerComponent } from './VideoPlayerComponent';
import { playAudioWithMonitoring, stopAllAudio } from './audioMonitor';
import { bufferToStringWithUnderscoreDelay } from './textProccessing';
import { consolidateRawWords, createConsolidatedBuffer, regexGuard, videoAgentMachine } from './videoAgentMachine';

const cachedAudio = new Map<string, string>();

// TODO - move to a new file
export function VideoAgentContainer({
    seriesId,
    botId,
    token,
}: {
    seriesId: string;
    botId: string;
    token: string;
}): JSX.Element {
    const {
        state: { session },
        setSession,
    } = useGlobalNullableVideoAgent();
    const [lastSyncedFeedback, setLastSyncedFeedback] = useState(session?.userFeedback);
    const eventMeta: ConnectionEventMetadata<VideoAgentConnectingPayload> = {
        event: ClientSocketEvent.VideoAgentConnecting,
        payload: createWebsocketPayload<VideoAgentConnectingPayload>({
            seriesSlug: seriesId,
            botId,
            token,
        }),
        botId,
    };
    const [globalModal, setGlobalModal] = useGlobalModal();
    useWebsocket(atomVideoAgentSocket, eventMeta);

    useVideoAgentSocketSyncing((msg: VideoAgentControlCommand) => {
        if (!session) return;

        let updatedSession: VideoAgentSession | null = null;

        switch (msg.action) {
            case VideoAgentControlAction.Next:
                handleNextTopic(AgentCommandType.RemoteControl);
                break;
            case VideoAgentControlAction.Previous:
                handlePreviousTopic(AgentCommandType.RemoteControl);
                break;
            case VideoAgentControlAction.JumpToTopic:
                if (msg.topicIndex !== undefined) {
                    jumpToTopicNumber(msg.topicIndex + 1, AgentCommandType.RemoteControl);
                }
                break;
            case VideoAgentControlAction.AddTopic:
                handleAddTopic(msg.topicName, AgentCommandType.RemoteControl);
                break;
            case VideoAgentControlAction.EditTopic:
                updatedSession = session.withRenamedTopicById(msg.topicId, msg.topicName);
                break;
            case VideoAgentControlAction.DeleteTopic:
                updatedSession = session.withRemovedTopicById(msg.topicId);
                break;
            case VideoAgentControlAction.ToggleRecording:
                updatedSession = session.withToggledPaused();
                break;
            case VideoAgentControlAction.ToggleAudioAcknowledgementDisabled:
                updatedSession = session.withToggledAudioAcknowledgementDisabled();
                break;
            case VideoAgentControlAction.ToggleAudioTimeCheckDisabled:
                updatedSession = session.withToggledAudioTimeCheckDisabled();
                break;
            case VideoAgentControlAction.ToggleRoundtable:
                updatedSession = session.withToggledRoundTable();
                break;
            case VideoAgentControlAction.ReorderTopics:
                updatedSession = session.withReorderedTopics(msg.topics);
                break;
            case VideoAgentControlAction.PlayVideo:
                send('PLAY_VIDEO', { videoUrl: msg.videoUrl });
                break;
            case VideoAgentControlAction.Stop:
                stopAllAudio();
                send('STOP_VIDEO');
                break;
        }

        if (updatedSession) {
            setSession(updatedSession);
            patchVideoAgentSession(updatedSession.toJSON());
        }
    });
    const config = useFetchVideoAgentConfig(session?.hostId);

    const trackActivity = useActivityTracking();

    useFetchAgentAssetMap();
    useTestAgentSession({ botId, seriesId });

    const precacheAudio = async (toSay: string, options: { cacheRemotely: boolean } = { cacheRemotely: false }) => {
        say(toSay, true, options);
    };

    // TODO figure out how to move this into its own function while handling the circular dependency on `say` and `send`
    const say: SayFunction = async (
        toSay: string,
        downloadOnly: boolean = false,
        options: { cacheRemotely: boolean; aboutToPlay?: () => void } = { cacheRemotely: false }
    ) => {
        if (isLocalStage()) {
            // browser tts
            if (!downloadOnly) {
                const utterance = new SpeechSynthesisUtterance(toSay);
                options.aboutToPlay?.();
                (window.speechSynthesis as any).speak(utterance);
            }
            return;
        }
        try {
            if (cachedAudio.has(toSay)) {
                if (downloadOnly) {
                    return;
                }
                options.aboutToPlay?.();
                await playAudioWithMonitoring(cachedAudio.get(toSay)!, toSay, () => {
                    send({
                        type: 'AUDIO_END',
                        toSay,
                    });
                });
                return;
            }
            const audioUrl = await postVideoAgentSpeechSrc({
                speech: toSay,
                cacheRemotely: options.cacheRemotely,
            });
            cachedAudio.set(toSay, audioUrl);

            if (downloadOnly) {
                return;
            }

            options.aboutToPlay?.();
            await playAudioWithMonitoring(audioUrl, toSay, () => {
                send({
                    type: 'AUDIO_END',
                    toSay,
                });
            });
        } catch (error) {
            Sentry.captureException(error, {
                tags: {
                    path: location.pathname,
                    ...session?.flatMetadata,
                },
            });
        }
    };

    const handleStartAgenda = useStartAgenda(say);
    const handleNextTopic = useNextAgendaTopic(say);
    const handlePreviousTopic = usePreviousTopic(say);
    const handleAddTopic = useAddTopic(say);
    const jumpToTopicNumber = useJumpToTopicNumber(say);

    const [state, send] = useMachine(videoAgentMachine, {
        context: {
            config,
        },
        guards: {
            isIntroCommand: (ctx) =>
                regexGuard((ctx) => ctx.config.introCommandRegex)(ctx) && !!session?.isPendingIntro,
        },
        actions: {
            init: () => {
                // precacheAudio(AGENT_SAYINGS.StartAgenda, { cacheRemotely: true });
                // precacheAudio(AGENT_SAYINGS.GoToLastTopicAsFeedback, { cacheRemotely: true });
                // precacheAudio(AGENT_SAYINGS.AFFIRMATIVE_1, { cacheRemotely: true });
            },
            proccessAddTopicToAgendaExact: async (context, event) => {
                const textBuf = event.data.bufferSincePreviousTrigger as string;
                let matchedTopic = null;
                for (const regex of config.addTopicToTheAgendaMatchersRegex) {
                    // a final test might adjust the delay by abit, if it made it here we don't care about it so we'll just pad it
                    const correctedTextBuff = textBuf.concat('_'.repeat(50));
                    const match = correctedTextBuff.match(regex);
                    if (match && match[1]) {
                        matchedTopic = match[1];
                        break;
                    }
                }
                if (!matchedTopic) {
                    throw new Error('couldnt match topic text:' + textBuf);
                }
                const format = (sentence: string): string =>
                    sentence.length ? sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase() : sentence;
                handleAddTopic(format(matchedTopic.replaceAll(/_/g, ' ')));
            },
            startAgenda: () => {
                handleStartAgenda();
            },
            nextTopic: () => {
                handleNextTopic();
            },
            previousTopic: () => {
                handlePreviousTopic();
            },
            onIntroEnded: () => {
                if (session) {
                    const updatedSession = session.withStartedIntro();
                    setSession(updatedSession);
                    patchVideoAgentSession(updatedSession.toJSON());
                } else {
                    throw new Error('session is null');
                }
            },
            syncFeedback: () => {
                if (
                    session?.userFeedback &&
                    session.settings.isFeedbackCollectionEnabled &&
                    session.userFeedback !== lastSyncedFeedback
                ) {
                    patchVideoAgentSession(session.toJSON());
                    setLastSyncedFeedback(session.userFeedback);
                }
            },
            debugWhyDidItTrigger: (ctx) => {
                if (!session) {
                    return;
                }
                const history = ctx.pastTriggers.slice(-5).map((trigger) => {
                    const triggerTime = new Date(trigger.timestamp);
                    const secondsAgo = (Date.now() - triggerTime.getTime()) / 1000;
                    const regexesThatMatchedIt = [
                        ...config.startAgendaMatchersRegex,
                        ...config.nextTopicMatchersRegex,
                        ...config.previousTopicRegex,
                        ...config.addTopicToTheAgendaMatchersRegex,
                    ];
                    const matchedRegexes = regexesThatMatchedIt
                        .filter((regex) => regex.test(trigger.text))
                        .map((r) => r.source)
                        .join('\n');
                    return `${trigger.text} - ${triggerTime.toLocaleTimeString()} - ${secondsAgo.toFixed(
                        1
                    )}s ago by:\n ${matchedRegexes}`;
                });
                postVideoAgentChatMessage(
                    {
                        message: history.join('\n\n'),
                        botId: session.botId,
                    },
                    true
                );
            },
            debugWhyDidItNotTrigger: async (context) => {
                if (!session) {
                    return;
                }
                const words = consolidateRawWords(createConsolidatedBuffer(context.textBuffer));
                const beforeLastTrigger = words.filter((w) => w.endTimestamp <= context.triggerWordTimestamp);
                const bufferBefore = bufferToStringWithUnderscoreDelay(
                    beforeLastTrigger,
                    Date.now() - context.lastNewTextTimestamp
                );
                const afterLastTrigger = words.filter((w) => w.endTimestamp > context.triggerWordTimestamp);
                const bufferAfter = bufferToStringWithUnderscoreDelay(
                    afterLastTrigger,
                    Date.now() - context.lastNewTextTimestamp
                );
                postVideoAgentChatMessage(
                    {
                        message: `bufferBeforeLastTrigger: ${bufferBefore}\n\nbufferAfterLastTrigger: ${bufferAfter}`,
                        botId: session.botId,
                    },
                    true
                );
            },
            onQuestionReady: async (_, event) => {
                try {
                    const textBuf = event.data.bufferSincePreviousTrigger as string;
                    let matchedQuestion = null;
                    for (const regex of config.questionMatchersRegex) {
                        // a final test might adjust the delay by abit, if it made it here we don't care about it so we'll just pad it
                        const correctedTextBuff = textBuf.concat('_'.repeat(50));
                        const match = correctedTextBuff.match(regex);
                        if (match && match[1]) {
                            matchedQuestion = match[1];
                            break;
                        }
                    }
                    if (!matchedQuestion) {
                        throw new Error('couldnt match topic text:' + textBuf);
                    }
                    const formattedQuestion = matchedQuestion.replaceAll(/_+/g, ' ').toLocaleLowerCase().trim();

                    trackActivity(ClientEventType.VideoAgentActivity, 'Ask Spinach', {
                        TriggeredBy: AgentCommandType.Voice,
                        Question: formattedQuestion,
                        ...session?.analyticsPayload,
                    });
                    const { output: isQuestionClass } = await postVideoAgentLLM({
                        input: `non perfect transcript: "${formattedQuestion}" is this a question? respond with 'YES' or 'NO' When in doubt, say 'YES'`,
                        temperature: 0,
                    });
                    if (isQuestionClass.trim().toLocaleLowerCase() !== 'no') {
                        // if the llm returns something unexpected we should ask the question which is why we check if it is not 'no' instead of a 'yes'
                        trackActivity(ClientEventType.VideoAgentActivity, 'Ask Spinach Confirmed', {
                            TriggeredBy: AgentCommandType.Voice,
                            Question: formattedQuestion,
                            ...session?.analyticsPayload,
                        });
                        const response = await postVideoAgentAskSpinach(formattedQuestion);
                        trackActivity(ClientEventType.VideoAgentActivity, 'Ask Spinach Response', {
                            TriggeredBy: AgentCommandType.Voice,
                            Question: formattedQuestion,
                            ...session?.analyticsPayload,
                        });
                        say(response.output, false, {
                            cacheRemotely: false,
                            aboutToPlay: () => {
                                send({ type: 'ANSWER_READY', payload: { response: response.output } });
                            },
                        });
                    } else {
                        trackActivity(ClientEventType.VideoAgentActivity, 'Ask Spinach False Positive', {
                            TriggeredBy: AgentCommandType.Voice,
                            Question: formattedQuestion,
                            ...session?.analyticsPayload,
                        });
                        // false positive, ignore
                        send('ASK_SPINACH_FAILED');
                    }
                } catch (error) {
                    trackActivity(ClientEventType.VideoAgentActivity, 'Ask Spinach Error', {
                        TriggeredBy: AgentCommandType.Voice,
                        ...session?.analyticsPayload,
                    });
                    send('ASK_SPINACH_FAILED');
                    say('Sorry, Can you please repeat the question?', false, { cacheRemotely: true });
                }
            },
        },
    });

    useSyncPastTriggers(state.context.pastTriggers);
    useVideoAgentTextCommandProcessing(say, send);
    useLiveRecallTranscription(send);

    const fullState = state
        .toStrings()
        .reverse()
        .find((s) => s.startsWith('dialogue'));

    const speaking = fullState?.startsWith('dialogue.processing') ?? false;
    const proccessingAskSpinach = fullState?.startsWith('dialogue.proccessing_ask_spinach') ?? false;
    const answeringQuestion = fullState?.startsWith('dialogue.answering_question') ?? false;
    const playingIntro = fullState?.startsWith('dialogue.intro') ?? false;
    const playingVideo = fullState?.startsWith('dialogue.video_playing') ?? false;
    const aboutToMatch =
        (fullState?.startsWith('dialogue.idle.pendingSilence') || fullState?.startsWith('dialogue.question')) ?? false;
    const wakeWordDetected = fullState?.startsWith('dialogue.idle.wakeWordDetected') ?? false;
    const unknownCommand = fullState?.startsWith('dialogue.idle.unknown') ?? false;

    const timer = useTimer(session?.currentTopicStartedAt);

    const [meetingIsAboutToBeOverIndicator, raiseMeetingIsAboutToBeOverIndicator] = useFlagWithTimeout(
        config.bannerDurationInMs
    );
    useCallbackAt(
        session
            ? new Date(session.scheduledEndTime.getTime() - config.meetingEndReminderInMinutes * 60 * 1000)
            : undefined,
        useCallback(() => {
            raiseMeetingIsAboutToBeOverIndicator();
            if (
                !session?.settings.isAudioAcknowledgementDisabled &&
                !!session?.agenda.topics.length &&
                !session?.settings.isAudioOutputForcedOff
            ) {
                new Audio(config.chimeSoundUrl).play();
            }
        }, [
            raiseMeetingIsAboutToBeOverIndicator,
            config.chimeSoundUrl,
            session?.settings.isAudioAcknowledgementDisabled,
            session?.agenda.topics.length,
            session?.settings.isAudioOutputForcedOff,
        ])
    );

    const shouldShowTimecheckBanner =
        timer > config.bannerDurationInMs / 1000 &&
        timer % (config.longTopicReminderThresholdInMinutes * 60) < config.bannerDurationInMs / 1000;

    if (!session) {
        return (
            <Box
                height="100vh"
                width="100vw"
                display="flex"
                justifyContent="center"
                style={{
                    backgroundColor: 'rgb(30, 30, 30)',
                }}
            ></Box>
        );
    }

    return (
        <Box
            height="100vh"
            width="100vw"
            display="flex"
            justifyContent="center"
            style={{
                backgroundColor: 'rgb(30, 30, 30)',
            }}
            onClick={() => {
                if (isLocalStage()) {
                    if (globalModal === GlobalModal.VideoAgentTestControls) {
                        setGlobalModal(null);
                    } else {
                        setGlobalModal(GlobalModal.VideoAgentTestControls);
                    }
                }
            }}
        >
            {session?.isPendingIntro || playingIntro ? (
                <IntroComponent
                    playingIntro={playingIntro}
                    speaking={speaking}
                    send={send}
                    aboutToMatch={aboutToMatch}
                    wakeWordDetected={wakeWordDetected}
                    unknown={unknownCommand}
                />
            ) : playingVideo && !!state.context.videoUrl ? (
                <VideoPlayerComponent send={send} videoUrl={state.context.videoUrl} />
            ) : (
                <>
                    <AgentNotificationBanner
                        highlightColor={'rgb(194,150,52)'}
                        visible={meetingIsAboutToBeOverIndicator}
                        text={`${config.meetingEndReminderInMinutes} minutes left in the meeting`}
                    />
                    <AgentNotificationBanner
                        highlightColor={'rgb(177 210 10)'}
                        visible={shouldShowTimecheckBanner}
                        text={`${config.longTopicReminderThresholdInMinutes} minutes elapsed`}
                    />
                    {session?.isLobbyPhase ? <AgentLobbyContent /> : <AgentAgendaContent />}
                </>
            )}
            {isLocalStage() ? <VideoAgentTestControlsModal send={send} /> : null}
        </Box>
    );
}
