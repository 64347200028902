import { ListAlt, PeopleOutline, VolumeOff, VolumeUp } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';

import { VideoAgentControlAction, VideoAgentControlCommand } from '@spinach-shared/types';
import { ClientEventType } from '@spinach-shared/types';

import { useActivityTracking, useGlobalAuthedUser, useGlobalVideoAgent } from '../../..';
import { AgentCommandType } from '../../types/agent';
import { ControlButton } from './common';

const TopicListHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 -4px 0px -4px;

    @media (max-width: 600px) {
        margin: 0 -2px 0px -2px;
    }
`;

const HeaderSection = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 4px;

    &:first-child {
        justify-content: flex-start;
    }

    &:nth-child(2) {
        justify-content: center;
    }

    &:last-child {
        justify-content: flex-end;
    }

    @media (max-width: 600px) {
        padding: 0 2px;
    }
`;

const RecordingControl = styled(ControlButton).attrs({ variant: 'primary' })``;

interface MeetingWideControlsProps {
    onCommand: (command: VideoAgentControlCommand) => void;
}

export function MeetingWideControls({ onCommand }: MeetingWideControlsProps) {
    const { session } = useGlobalVideoAgent();
    const trackActivity = useActivityTracking();

    const handleToggleRecording = () => {
        trackActivity(ClientEventType.VideoAgentActivity, 'Toggle Recording', {
            TriggeredBy: AgentCommandType.RemoteControl,
            When: session.currentTopic?.id ? 'After Agenda Started' : 'Before Agenda Started',
            ...session?.analyticsPayload,
        });
        onCommand({ action: VideoAgentControlAction.ToggleRecording });
    };

    const handleToggleRoundtable = () => {
        trackActivity(ClientEventType.VideoAgentActivity, 'Toggle Roundtable', {
            TriggeredBy: AgentCommandType.RemoteControl,
            When: session.currentTopic?.id ? 'After Agenda Started' : 'Before Agenda Started',
            ...session?.analyticsPayload,
        });
        onCommand({ action: VideoAgentControlAction.ToggleRoundtable });
    };

    const handleToggleAudioAcknowledgementDisabled = () => {
        trackActivity(ClientEventType.VideoAgentActivity, 'Toggle Audio Acknowledgement Disabled', {
            TriggeredBy: AgentCommandType.RemoteControl,
            When: session.currentTopic?.id ? 'After Agenda Started' : 'Before Agenda Started',
            ...session?.analyticsPayload,
        });
        onCommand({ action: VideoAgentControlAction.ToggleAudioAcknowledgementDisabled });
    };

    return (
        <TopicListHeader>
            <HeaderSection>
                <ControlButton
                    isActive={session.isRoundtableEnabled}
                    onClick={handleToggleRoundtable}
                    variant="secondary"
                >
                    {session.isRoundtableEnabled ? (
                        <>
                            <PeopleOutline style={{ fontSize: 20 }} />
                            Roundtable On
                        </>
                    ) : (
                        <>
                            <ListAlt style={{ fontSize: 20 }} />
                            Roundtable Off
                        </>
                    )}
                </ControlButton>
            </HeaderSection>

            <HeaderSection>
                <RecordingControl isActive={!(session.isPaused ?? false)} onClick={handleToggleRecording}>
                    {session.isPaused ? (
                        <>
                            <span>⏵</span>
                            Resume Recording
                        </>
                    ) : (
                        <>
                            <span>⏸</span>
                            Pause Recording
                        </>
                    )}
                </RecordingControl>
            </HeaderSection>

            {session.settings.isAudioOutputForcedOff ? null : (
                <HeaderSection>
                    <ControlButton
                        isActive={session.isAudioAcknowledgementDisabled}
                        onClick={handleToggleAudioAcknowledgementDisabled}
                        variant="tertiary"
                    >
                        {session.isAudioAcknowledgementDisabled ? (
                            <>
                                <VolumeOff />
                                Sound Effects Off
                            </>
                        ) : (
                            <>
                                <VolumeUp />
                                Sound Effects On
                            </>
                        )}
                    </ControlButton>
                </HeaderSection>
            )}
        </TopicListHeader>
    );
}
