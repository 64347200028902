import { CallMade } from '@material-ui/icons';
import { useState } from 'react';

import { ClientEventType, SpinachAPIPath, SpinachIntegration, WebUrlQuery } from '@spinach-shared/types';

import { postMicrosoftRecallV2Setup } from '../../../../apis/postMicrosoftRecallV2Setup';
import GoogleCalendarSrc from '../../../../assets/google-cal.png';
import { ReactComponent as OutlookIcon } from '../../../../assets/integrations/microsoft.svg';
import { useExperienceTracking, useGlobalAuthedUser, useIntegrationDetection } from '../../../../hooks';
import { BodyRegularOnboard, ButtonSize, HeaderThree } from '../../../../styles';
import { URLUtil } from '../../../../utils';
import { getClientConfigValue } from '../../../../utils/getClientConfigValue';
import { Row, Spacing } from '../../../common';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import { IntegrationSettingsModalKind, UseHoverRowProps } from '../types';
import { ConnectedRowContents } from './ConnectedRowContents';
import { Label } from './common';

export function MicrosoftCalendarIntegrationSection({
    hoverRowProps,
    setModalKind,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
    hoverRowProps: UseHoverRowProps;
}) {
    const [user] = useGlobalAuthedUser();
    const startDetection = useIntegrationDetection();
    const track = useExperienceTracking();
    const [isUpgradeLoading, setIsUpgradeLoading] = useState(false);
    const { getHoverableRowProps, hoveredRow } = hoverRowProps;
    const shouldUseRecallAuth = user.isEnabledForRecallV2;
    return (
        <Row vCenter {...getHoverableRowProps(SpinachIntegration.Microsoft)}>
            <OutlookIcon style={{ width: '24px', height: '24px', marginRight: '15px' }} />
            <Label>Microsoft</Label>
            {!user.isAuthedForMicrosoftCalendar ? (
                <OutlinedButton
                    title={'Connect'}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Microsoft Calendar Connect`,
                        });
                        startDetection();
                        URLUtil.openURL(
                            `${getClientConfigValue('REACT_APP_AUTH_URL')}${SpinachAPIPath.MicrosoftAuth}/?${
                                WebUrlQuery.Suid
                            }=${user.spinachUserId}`
                        );
                    }}
                    size={ButtonSize.Mini}
                    endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                    labelStyles={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                />
            ) : shouldUseRecallAuth && !user.isUsingRecallV2 ? (
                <OutlinedButton
                    title={'Update'}
                    isLoading={isUpgradeLoading}
                    onClick={async () => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Upgrade Microsoft Calendar Connect`,
                        });
                        setIsUpgradeLoading(true);
                        startDetection();
                        await postMicrosoftRecallV2Setup();
                    }}
                    size={ButtonSize.Mini}
                    endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                    labelStyles={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                />
            ) : (
                <ConnectedRowContents
                    label={'Connected'}
                    isSettingsVisible={hoveredRow === SpinachIntegration.Microsoft}
                    onSettingsClick={async () => {
                        setModalKind(IntegrationSettingsModalKind.SettingsMicrosoftCalendar);
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Open Remove Microsoft Calendar Modal`,
                        });
                    }}
                />
            )}
        </Row>
    );
}

export function GoogleCalendarIntegrationSection({
    hoverRowProps,
    setModalKind,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
    hoverRowProps: UseHoverRowProps;
}) {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const startDetection = useIntegrationDetection();
    const { getHoverableRowProps, hoveredRow } = hoverRowProps;
    const shouldUseRecallAuth = user.isEnabledForRecallV2;

    return (
        <Row vCenter {...getHoverableRowProps(SpinachIntegration.GoogleCalendar)}>
            <img src={GoogleCalendarSrc} style={{ width: '24px', height: '24px', marginRight: '15px' }} />
            <Label>Google</Label>
            {!user.isAuthedForGoogleCalendar ? (
                <OutlinedButton
                    title={'Connect'}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Google Calendar Connect`,
                        });
                        startDetection();
                        URLUtil.openURL(
                            `${getClientConfigValue('REACT_APP_AUTH_URL')}${SpinachAPIPath.GoogleCalendarAuth}/?${
                                WebUrlQuery.Suid
                            }=${user.spinachUserId}`
                        );
                    }}
                    size={ButtonSize.Mini}
                    endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                    labelStyles={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                />
            ) : shouldUseRecallAuth && !user.metadata.isUsingRecallCalendarV2 ? (
                <OutlinedButton
                    title={'Update'}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Upgrade Google Calendar Connect`,
                        });
                        startDetection();
                        URLUtil.openURL(
                            `${getClientConfigValue('REACT_APP_AUTH_URL')}${
                                SpinachAPIPath.GoogleRecallV2CalendarAuth
                            }/?${WebUrlQuery.Suid}=${user.spinachUserId}&spinachSource=loginPage`
                        );
                    }}
                    size={ButtonSize.Mini}
                    endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                    labelStyles={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                />
            ) : (
                <ConnectedRowContents
                    label={'Connected'}
                    isSettingsVisible={hoveredRow === SpinachIntegration.GoogleCalendar}
                    onSettingsClick={async () => {
                        setModalKind(IntegrationSettingsModalKind.SettingsGoogleCalendar);
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Open Remove Google Calendar Modal`,
                        });
                    }}
                />
            )}
        </Row>
    );
}

export function CalendarIntegrationSection({
    setModalKind,
    hoverRowProps,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
    hoverRowProps: UseHoverRowProps;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const startDetection = useIntegrationDetection();
    const { getHoverableRowProps, hoveredRow } = hoverRowProps;

    return (
        <>
            <Row>
                <HeaderThree>Calendar</HeaderThree>
            </Row>
            <Spacing factor={1 / 2} />
            <Row>
                <BodyRegularOnboard>
                    {user.isAuthedForAnyCalendar
                        ? 'This is the account you have linked to your calendar'
                        : 'Connect your Calendar to Spinach'}
                </BodyRegularOnboard>
            </Row>
            {!user.isAuthedForMicrosoft ? (
                <>
                    <Spacing factor={1 / 2} />
                    <GoogleCalendarIntegrationSection hoverRowProps={hoverRowProps} setModalKind={setModalKind} />
                </>
            ) : null}
            {!user.isAuthedForGoogle ? (
                <>
                    <Spacing factor={1 / 2} />
                    <MicrosoftCalendarIntegrationSection hoverRowProps={hoverRowProps} setModalKind={setModalKind} />
                </>
            ) : null}
        </>
    );
}
