import { useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { useWindowSize } from '../../../../hooks';
import { Column } from '../../../common';

const GlobalStyle = createGlobalStyle<{ isMobile: boolean }>`
    ${(props) =>
        props.isMobile &&
        `
        html, body {
            overflow: hidden !important;
            position: fixed !important;
            width: 100% !important;
            height: 100% !important;
            margin: 0 !important;
            padding: 0 !important;
        }
        
        #root {
            overflow: hidden !important;
            position: fixed !important;
            width: 100% !important;
            height: 100% !important;
        }

        /* Hide scrollbars for Chrome, Safari and Opera */
        ::-webkit-scrollbar {
            display: none !important;
            width: 0 !important;
            height: 0 !important;
        }

        /* Hide scrollbars for IE, Edge and Firefox */
        * {
            -ms-overflow-style: none !important;  /* IE and Edge */
            scrollbar-width: none !important;  /* Firefox */
        }
    `}
`;

const MobileResponsiveColumn = styled(Column)`
    height: 100%;
    width: 100%;
    position: relative;
    margin: 0;
    padding: 0;
    overflow: hidden;

    @media (max-width: 768px) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        height: 100vh;
        width: 100vw;
        -webkit-overflow-scrolling: touch;
    }
`;

const StyledIframe = styled.iframe`
    border: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    display: block;
    overflow: hidden;

    @media (max-width: 768px) {
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-overflow-scrolling: touch;
    }
`;

export function EmbeddedWebflowSection(): JSX.Element {
    const { width } = useWindowSize();
    const isMobile = width <= 768;

    useEffect(() => {
        if (isMobile) {
            // Prevent scrolling on the main container
            const mainContainer = document.querySelector('#root') as HTMLElement;
            if (mainContainer) {
                mainContainer.style.overflow = 'hidden';
            }
        }

        return () => {
            const mainContainer = document.querySelector('#root') as HTMLElement;
            if (mainContainer) {
                mainContainer.style.overflow = '';
            }
        };
    }, [isMobile]);

    return (
        <>
            <GlobalStyle isMobile={isMobile} />
            <MobileResponsiveColumn>
                <StyledIframe
                    src="https://www.spinach.ai/video-academy"
                    title="Spinach Video Academy"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    sandbox="allow-scripts allow-same-origin allow-forms allow-popups"
                />
            </MobileResponsiveColumn>
        </>
    );
}
