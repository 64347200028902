import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    IconButton,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from '@material-ui/core';
import {
    ChevronLeft,
    Delete,
    FiberManualRecord,
    InfoOutlined,
    Mic,
    Pause,
    PlayArrow,
    Replay,
    Stop,
} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ClientEventType } from '@spinach-shared/types';

import { GlobalModal, useExperienceTracking, useGlobalModal } from '../../../../..';
import { BodyRegularOnboard, lightTheme } from '../../../../styles';
import { Anchor, Column, Row, Spacing } from '../../../common';
import { QUICK_RECORD_SYSTEM_AUDIO_DISCLAIMER_DONT_SHOW_AGAIN_KEY } from '../../../common/modals/QuickRecordSystemAudioDisclaimer';
import { PrimaryButton } from '../../../stand-up';
import { AudioDevice, RecordingSettings } from './types';
import { useAudioRecording } from './useAudioRecording';

const SIDEBAR_RECORDER_SELECTED_DEVICE_ID_KEY = 'sidebarRecorderSelectedDeviceId';

const RecorderContainer = styled(Box)<{ state: RecordingState }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 8px;
    border-radius: 8px;
    border-color: ${(props) => (props.state === 'inactive' ? lightTheme.secondary.midnight : '#F24747')};
    border-width: 1px;
    border-style: solid;
    cursor: ${(props) => (props.state === 'inactive' ? 'pointer' : 'default')};
    min-height: 46px;
`;

const StyledIconButton = styled(IconButton)`
    border-radius: 50%;
    padding: 13px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background: #e3edec;
    &:hover {
        background: #e4eeed;
    }
`;

export const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    '& .MuiTooltip-tooltip': {
        backgroundColor: 'transparent',
    },
}));

const DeviceSelect = styled(Select)`
    width: 100%;
    margin-bottom: 8px;
`;

const TimerDisplay = styled.span`
    font-family: monospace;
    font-size: 15px;
    font-weight: 500;
    color: ${lightTheme.primary.midnight};
    margin-left: 13px;
`;

const StyledAccordion = styled(Accordion)`
    box-shadow: none;
    background: transparent;
    width: 100%;
    elevation: 0;

    &.MuiAccordion-root {
        background: transparent;
    }

    &.MuiAccordion-root:before {
        display: none;
    }

    & .MuiAccordionSummary-root {
        padding: 0;
        min-height: unset;
        height: 40px;
        &.Mui-expanded {
            min-height: unset;
        }
    }

    & .MuiAccordionSummary-content {
        margin: 0;
        &.Mui-expanded {
            margin: 0;
        }
    }

    & .MuiAccordionDetails-root {
        padding: 8px 8px 16px 8px;
    }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
    flex-direction: row-reverse;
    elevation: 0;

    &.Mui-expanded {
        min-height: unset;
    }
`;

function formatTime(seconds: number): string {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
}

export function InactiveView({
    onExpand,
    onStartRecording,
    availableDevices,
    selectedDeviceId,
    setSelectedDeviceId,
    permissionDenied,
}: {
    onExpand: () => void;
    onStartRecording: () => void;
    availableDevices: AudioDevice[];
    selectedDeviceId: string | null;
    setSelectedDeviceId: (deviceId: string) => void;
    permissionDenied: boolean;
}): JSX.Element {
    const [isExpanded, setIsExpanded] = useState(false);
    const track = useExperienceTracking();
    const handleAccordionChange = (event: React.ChangeEvent<{}>, expanded: boolean) => {
        event.stopPropagation();
        setIsExpanded(expanded);
        if (expanded) {
            onExpand();
        }
    };

    return (
        <StyledAccordion expanded={isExpanded} onChange={handleAccordionChange} elevation={0}>
            <StyledAccordionSummary>
                <Row vCenter>
                    <div
                        style={{
                            backgroundColor: lightTheme.secondary.midnight,
                            borderRadius: '50%',
                            height: '24px',
                            width: '24px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            boxSizing: 'border-box',
                            padding: '14px',
                        }}
                    >
                        <Mic style={{ color: lightTheme.neutrals.grayLight, fontSize: '20px' }} />
                    </div>
                    <BodyRegularOnboard style={{ fontSize: '16px', fontWeight: '700', marginLeft: '10px' }}>
                        Quick Record
                    </BodyRegularOnboard>
                    <CustomTooltip
                        interactive={true}
                        enterTouchDelay={0}
                        leaveTouchDelay={15000}
                        title={
                            <div
                                style={{
                                    flexDirection: 'column',
                                    backgroundColor: 'black',
                                    padding: '7px 24px',
                                }}
                            >
                                <BodyRegularOnboard style={{ color: 'white' }}>
                                    <span style={{ fontWeight: 'bold' }}>Try free</span>
                                    <span>
                                        {
                                            " - An easy way to record meetings or audio on the fly. We'll do the work and "
                                        }
                                        {'summarize it for you. '}
                                        <Anchor
                                            onClick={(e) => {
                                                track(ClientEventType.AIDashboardActivity, {
                                                    Activity: 'Quick Record Learn More Clicked',
                                                    Section: 'Sidebar Recorder',
                                                });
                                                e.stopPropagation();
                                                window.Intercom?.('showArticle', '10751130');
                                            }}
                                            style={{
                                                color: 'white',
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            Learn more
                                        </Anchor>
                                    </span>
                                </BodyRegularOnboard>
                            </div>
                        }
                        placement="top"
                    >
                        <InfoOutlined
                            style={{ color: lightTheme.primary.greenLight, marginLeft: '10px', fontSize: '24px' }}
                        />
                    </CustomTooltip>
                    <div style={{ flex: 1 }}></div>
                    <ChevronLeft
                        style={{
                            color: lightTheme.secondary.midnight,
                            fontSize: '24px',
                            transform: isExpanded ? 'rotate(90deg)' : 'rotate(-90deg)',
                        }}
                    />
                </Row>
            </StyledAccordionSummary>
            <AccordionDetails>
                <Column centered>
                    {permissionDenied ? (
                        <div style={{ fontSize: '15px' }}>
                            <Anchor
                                onClick={(e) => {
                                    track(ClientEventType.AIDashboardActivity, {
                                        Activity: 'Sidebar Recorder Microphone Access Denied',
                                        Section: 'Sidebar Recorder',
                                    });
                                    e.stopPropagation();
                                    window.Intercom?.('showArticle', '10526234');
                                }}
                                style={{
                                    textDecoration: 'none',
                                    marginRight: '4px',
                                }}
                            >
                                Enable microphone access
                            </Anchor>
                            in your browser settings to use this feature
                        </div>
                    ) : (
                        <>
                            {availableDevices.length > 0 ? (
                                <DeviceSelect
                                    disableUnderline
                                    value={selectedDeviceId || ''}
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        setSelectedDeviceId(e.target.value as string);
                                        localStorage.setItem(
                                            SIDEBAR_RECORDER_SELECTED_DEVICE_ID_KEY,
                                            e.target.value as string
                                        );
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: 'Sidebar Recorder Device Selected',
                                            Section: 'Sidebar Recorder',
                                            Device: e.target.value as string,
                                        });
                                    }}
                                >
                                    {availableDevices.map((device) => (
                                        <MenuItem key={device.deviceId} value={device.deviceId}>
                                            {device.label}
                                        </MenuItem>
                                    ))}
                                </DeviceSelect>
                            ) : null}
                            <Spacing factor={1 / 4} />
                            <PrimaryButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsExpanded(false);
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Start Recording',
                                        Section: 'Sidebar Recorder',
                                    });
                                    onStartRecording();
                                }}
                                title={'Start Recording'}
                                style={{ borderRadius: '6px', width: '100%' }}
                            />
                        </>
                    )}
                </Column>
            </AccordionDetails>
        </StyledAccordion>
    );
}

const VerticalDivider = styled.div`
    min-width: 1px;
    height: 100%;
    min-height: 24px;
    background-color: #d3d3d3;
    margin-left: 8px;
    margin-right: 8px;
`;

const AmplitudeIndicator = styled.div<{ scale: number }>`
    background-color: #f24747;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 14px;
    transition: transform 0.1s ease-out;
    transform: scale(${(props) => props.scale});
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -10;
`;

export function RecordingView({
    recordingTime,
    onStopRecording,
    onPauseRecording,
    onResumeRecording,
    onRestartRecording,
    onDeleteRecording,
    recordingState,
    amplitude,
}: {
    recordingTime: number;
    onStopRecording: () => void;
    onPauseRecording: () => void;
    onResumeRecording: () => void;
    onRestartRecording: () => void;
    onDeleteRecording: () => void;
    recordingState: RecordingState;
    amplitude: number;
}): JSX.Element {
    // max scale is 0.4
    const scale = Math.min(amplitude * 100, 0.4);
    const track = useExperienceTracking();

    return (
        <Row vCenter style={{ padding: '8px 0 ' }}>
            <div
                style={{
                    height: '24px',
                    width: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxSizing: 'border-box',
                    padding: '14px',
                    position: 'relative',
                }}
            >
                <AmplitudeIndicator scale={1 + scale} />
                <div
                    style={{
                        backgroundColor: '#e68481',
                        margin: 'auto',
                        height: 24,
                        width: 24,
                        borderRadius: '50%',
                        boxSizing: 'border-box',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: -5,
                    }}
                />
                <Mic style={{ color: 'white', fontSize: '20px' }} />
            </div>
            <TimerDisplay>{formatTime(recordingTime)}</TimerDisplay>
            <StyledIconButton
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Stop Recording',
                        Section: 'Sidebar Recorder',
                    });
                    onStopRecording();
                }}
                style={{ marginLeft: '12px' }}
            >
                <Stop style={{ fontSize: '21px' }} />
            </StyledIconButton>
            {recordingState === 'recording' ? (
                <>
                    <StyledIconButton
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Pause Recording',
                                Section: 'Sidebar Recorder',
                            });
                            onPauseRecording();
                        }}
                    >
                        <Pause style={{ fontSize: '21px' }} />
                    </StyledIconButton>
                </>
            ) : null}

            {recordingState === 'paused' ? (
                <>
                    <StyledIconButton
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Resume Recording',
                                Section: 'Sidebar Recorder',
                            });
                            onResumeRecording();
                        }}
                    >
                        <PlayArrow style={{ fontSize: '21px' }} />
                    </StyledIconButton>
                </>
            ) : null}
            <VerticalDivider />

            <StyledIconButton
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Restart Recording',
                        Section: 'Sidebar Recorder',
                    });
                    onRestartRecording();
                }}
            >
                <Replay style={{ fontSize: '21px' }} />
            </StyledIconButton>
            <StyledIconButton
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Delete Recording',
                        Section: 'Sidebar Recorder',
                    });
                    onDeleteRecording();
                }}
            >
                <Delete />
            </StyledIconButton>
        </Row>
    );
}

export function SidebarRecorder(): JSX.Element {
    const {
        recordingState,
        recordingTime,
        availableDevices,
        permissionDenied,
        refreshDevices,
        startRecording,
        stopRecording,
        pauseRecording,
        resumeRecording,
        restartRecording,
        deleteRecording,
        amplitude,
    } = useAudioRecording();

    const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null);
    const [_, setGlobalModal] = useGlobalModal();

    // Auto-select first device or default device
    useEffect(() => {
        let chosenDevice = null;
        if (availableDevices.length > 0) {
            if (localStorage.getItem(SIDEBAR_RECORDER_SELECTED_DEVICE_ID_KEY)) {
                chosenDevice = availableDevices.find(
                    (device) => device.deviceId === localStorage.getItem(SIDEBAR_RECORDER_SELECTED_DEVICE_ID_KEY)
                );
            }
            if (!chosenDevice) {
                chosenDevice = availableDevices[0];
            }

            setSelectedDeviceId(chosenDevice.deviceId);
        }
    }, [availableDevices]);

    const handleStartRecording = async () => {
        // No need to check for MIME type support anymore, it's handled in useAudioRecording
        const settings: Omit<RecordingSettings, 'mimeType'> = {
            selectedDeviceId,
        };

        const disclaimerHidden =
            localStorage.getItem(QUICK_RECORD_SYSTEM_AUDIO_DISCLAIMER_DONT_SHOW_AGAIN_KEY) === 'true';
        if (!disclaimerHidden) {
            setGlobalModal(GlobalModal.QuickRecordSystemAudioDisclaimer);
        }

        await startRecording(settings);
    };

    return (
        <RecorderContainer state={recordingState}>
            {recordingState === 'inactive' ? (
                <InactiveView
                    onExpand={() => {
                        if (!selectedDeviceId || availableDevices.length === 0) {
                            refreshDevices();
                        }
                    }}
                    onStartRecording={() => {
                        if (selectedDeviceId) {
                            handleStartRecording();
                        }
                    }}
                    availableDevices={availableDevices}
                    selectedDeviceId={selectedDeviceId}
                    setSelectedDeviceId={setSelectedDeviceId}
                    permissionDenied={permissionDenied}
                />
            ) : (
                <RecordingView
                    recordingTime={recordingTime}
                    onStopRecording={stopRecording}
                    onPauseRecording={pauseRecording}
                    onResumeRecording={resumeRecording}
                    onRestartRecording={restartRecording}
                    onDeleteRecording={deleteRecording}
                    recordingState={recordingState}
                    amplitude={amplitude}
                />
            )}
        </RecorderContainer>
    );
}
