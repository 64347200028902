import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ClientUser } from '@spinach-shared/models';
import { getStage, isLocalStage, validateEnv } from '@spinach-shared/utils';

import { ClientPath, initFullstory, useAnonymousUserIdentification, usePrevious } from '../..';
import { AnonymousUserTracker, initIntercomWithUser } from '../utils';
import { ClientEnvVarKey, getClientConfigValue } from '../utils/getClientConfigValue';
import { useApolloLogoUrlFetcher } from './useApolloLogoUrlFetcher';
import { useClarity } from './useClarity';
import { useFetchUser } from './useFetchUser';
import { useDetailedJiraIssues } from './useJiraIssues';
import { useTagManager } from './useTagManager';
import { useZoomCollaborate } from './useZoomCollaborate';
import { useSpinachWorkflows } from './workflows/useSpinachWorkflows';

export function useStartupProcesses(): { user: ClientUser | null | undefined; isLoading: boolean } {
    useEnvironmentValidation();
    useSentry();
    useDetailedJiraIssues({ maxResults: 20 });
    useSpinachWorkflows();
    useAnonymousUserTracking();
    useZoomCollaborate();
    useTagManager();
    useClarity();
    useAnonymousUserIdentification();
    const [user] = useFetchUser();
    useIntercom(user);
    useApolloLogoUrlFetcher();

    const isLoading = user === undefined;

    return { user, isLoading };
}

function validateConfig(keys: ClientEnvVarKey[]) {
    keys.forEach((key) => {
        if (getClientConfigValue(key) === undefined) {
            throw new Error(`Missing env var ${key}. Please populate in respective .env file.`);
        }
    });
}
export function useEnvironmentValidation() {
    useEffect(() => {
        validateConfig([
            'REACT_APP_WEBSOCKET_URL',
            'REACT_APP_AUTH_URL',
            'REACT_APP_SLACK_SERVER_URL',
            'REACT_APP_COGNITO_BASE_PATH',
            'REACT_APP_COGNITO_CLIENT_ID',
            'REACT_APP_MICROSOFT_APP_ID',
            'REACT_APP_STRIPE_PUBLIC_API_KEY',
        ]);

        validateEnv(['REACT_APP_STAGE', 'REACT_APP_PLATFORM']);
    }, []);
}

export function useSentry() {
    useEffect(() => {
        Sentry.init({
            dsn: 'https://8e63123c073e452eb6f46457750b447f@o527999.ingest.sentry.io/5645082',
            environment: getStage(),
            ignoreErrors: [
                /AbortError: The play/,
                /NotAllowedError: The play method is not allowed/,
                /NotSupportedError: Failed to load because no supported source was found/,
                /can only be initiated by a user gesture/,
                /The request is not allowed by the user agent or the platform in the current context/,
                /The media resource indicated by the src attribute/,
                /Illegal invocation/,
                /Insufficient Permission/,
            ],
        });
    }, []);
}

export function useFullStory() {
    useEffect(() => {
        initFullstory();
    }, []);
}

export function useAnonymousUserTracking() {
    useEffect(() => {
        AnonymousUserTracker.init();
    }, []);
}

export function useIntercom(user: ClientUser | null | undefined) {
    const [isIntercomSetup, setIsIntercomSetup] = useState(false);
    const location = useLocation();
    const previousPath = usePrevious(location.pathname);
    const currentPath = location.pathname;

    useEffect(() => {
        if (!isIntercomSetup && user && user.metadata.intercomHash) {
            if (
                currentPath.includes(ClientPath.Meeting) ||
                currentPath.includes(ClientPath.Control) ||
                user.isEnabledForAutoOpenAgentControlOnEmbeddedApps
            ) {
                initIntercomWithUser(user, {
                    hideDefaultLauncher: true,
                });
            } else {
                initIntercomWithUser(user, {
                    hideDefaultLauncher: false,
                });
            }
            setIsIntercomSetup(true);
        }
    }, [user, isIntercomSetup, currentPath]);

    useEffect(() => {
        const openedAgendaModule =
            !previousPath.includes(ClientPath.Meeting) && currentPath.includes(ClientPath.Meeting);
        const openedControlModule =
            !previousPath.includes(ClientPath.Control) && currentPath.includes(ClientPath.Control);

        const leftAgendaModule = previousPath.includes(ClientPath.Meeting) && !currentPath.includes(ClientPath.Meeting);
        const leftControlModule =
            previousPath.includes(ClientPath.Control) && !currentPath.includes(ClientPath.Control);

        if ((leftAgendaModule || openedAgendaModule) && isIntercomSetup) {
            setIsIntercomSetup(false);
        }
        if ((leftControlModule || openedControlModule) && isIntercomSetup) {
            setIsIntercomSetup(false);
        }
    }, [currentPath, isIntercomSetup, previousPath]);
}
