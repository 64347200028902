import { z } from 'zod';

import { SlackTeamType } from './Slack';
import { ISOString } from './Time';

export enum OutputTargetKind {
    Email = 'email',
    Slack = 'slack',
}

export enum SpinachWorkflowActionKind {
    Prompt = 'prompt',
    MorningBriefing = 'morning-briefing',
}

export const spinachWorkflowPromptActionSchema = z.object({
    kind: z.literal(SpinachWorkflowActionKind.Prompt),
    promptId: z.string(),
});

// Type derived from Zod schema
export type SpinachWorkflowPromptAction = z.infer<typeof spinachWorkflowPromptActionSchema>;

export type SpinachWorkflowMorningBriefingAction = {
    kind: SpinachWorkflowActionKind.MorningBriefing;
    timezone: string;
};

export type SpinachWorkflowAction = SpinachWorkflowPromptAction | SpinachWorkflowMorningBriefingAction;

// Zod schemas for output targets
export const spinachWorkflowSlackOutputTargetSchema = z.object({
    kind: z.literal(OutputTargetKind.Slack),
    config: z.object({
        channel: z.object({
            id: z.string(),
            name: z.string().optional(),
            isPrivate: z.boolean().optional(),
        }),
        teamId: z.string(),
        teamType: z.nativeEnum(SlackTeamType),
    }),
});

export const spinachWorkflowEmailOutputTargetSchema = z.object({
    kind: z.literal(OutputTargetKind.Email),
    config: z.object({
        to: z.array(z.string()),
    }),
});

// Types derived from Zod schemas
export type SpinachWorkflowSlackOutputTarget = z.infer<typeof spinachWorkflowSlackOutputTargetSchema>;
export type SpinachWorkflowEmailOutputTarget = z.infer<typeof spinachWorkflowEmailOutputTargetSchema>;

export type SpinachWorkflowOutputTarget = SpinachWorkflowEmailOutputTarget | SpinachWorkflowSlackOutputTarget; // | other output targets

export type SpinachWorkflowInputJSON = {
    userId: string;
    outputTargets: SpinachWorkflowOutputTarget[];
    actions: SpinachWorkflowAction[];
    isActive: boolean;
};

export type SpinachWorkflowClientJSON = Pick<
    SpinachWorkflowInputJSON,
    'userId' | 'isActive' | 'actions' | 'outputTargets'
> & {
    id: string;
};

export enum SpinachWorkflowTriggerKind {
    MeetingComplete = 'meeting-complete',
    Scheduled = 'scheduled',
}

export type SpinachWorkflowMeetingCompleteTrigger = {
    kind: SpinachWorkflowTriggerKind.MeetingComplete;
    conditions?: {
        isExternalMeeting?: boolean;
        isRecurringMeeting?: boolean;
        keywords?: string[];
    };
};

export enum SpinachWorkflowScheduledTriggerTimeType {
    Cron = 'cron',
    DateTime = 'datetime',
}

export type CronTriggerTime = {
    type: SpinachWorkflowScheduledTriggerTimeType.Cron;
    expression: string;
};

export type DateTimeTriggerTime = {
    type: SpinachWorkflowScheduledTriggerTimeType.DateTime;
    value: ISOString;
};

export type SpinachWorkflowScheduledTriggerTime = CronTriggerTime | DateTimeTriggerTime;

export type SpinachWorkflowScheduledTrigger = {
    kind: SpinachWorkflowTriggerKind.Scheduled;
    timezone: string;
    conditions: {
        triggerTime: SpinachWorkflowScheduledTriggerTime;
    };
};

export type SpinachWorkflowTriggerInputJSON = (
    | SpinachWorkflowMeetingCompleteTrigger
    | SpinachWorkflowScheduledTrigger
) & {
    userId: string;
    workflowId: string;
};

export type SpinachWorkflowTriggerClientJSON = Pick<
    SpinachWorkflowTriggerInputJSON,
    'workflowId' | 'kind' | 'conditions'
> & {
    id: string;
};
