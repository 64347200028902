import { Modal } from '@material-ui/core';
import { CalendarToday, Check as CheckIcon } from '@material-ui/icons';
import { useState } from 'react';

import { DashboardSection } from '@spinach-shared/constants';
import { ClientEventType } from '@spinach-shared/types';

import { GlobalModal } from '../../../atoms';
import { useExperienceTracking, useGlobalAiDashboard, useGlobalAuthedUser, useGlobalModal } from '../../../hooks';
import { BodyBig, BodyRegular, BodyRegularOnboard, HeaderThreeOnboard, lightTheme } from '../../../styles';
import { PrimaryButton } from '../../stand-up';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { BootstrapTooltip } from '../BootstrapTooltip';
import { SpinachModalContent } from '../SpinachModalContent';
import { Column, Row, Spacing } from '../framing';

function StyledMeetingRow({ label }: { label: string }): JSX.Element {
    return (
        <Row
            style={{
                width: 'calc(100% - 32px)',
                padding: '12px 16px',
                backgroundColor: '#ffffff',
                borderRadius: '8px',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '8px',
                boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                border: '1px solid rgba(0, 0, 0, 0.08)',
            }}
        >
            <BodyRegular style={{ fontWeight: 700 }}>{label}</BodyRegular>
            <BootstrapTooltip
                tooltipStyle={{
                    maxWidth: '220px',
                }}
                title={
                    <BodyRegular style={{ fontSize: '14px', color: 'white' }}>
                        Customize auto-record settings in the <b>Future Meetings</b> page
                    </BodyRegular>
                }
                placement="top"
            >
                <div
                    style={{
                        width: '40px',
                        height: '20px',
                        backgroundColor: '#35A289',
                        borderRadius: '10px',
                        position: 'relative',
                        cursor: 'default',
                    }}
                >
                    <div
                        style={{
                            width: '16px',
                            height: '16px',
                            backgroundColor: 'white',
                            borderRadius: '50%',
                            position: 'absolute',
                            right: '2px',
                            top: '2px',
                        }}
                    />
                </div>
            </BootstrapTooltip>
        </Row>
    );
}

function ReviewFeaturesContent({
    setShowReviewFeatures,
}: {
    setShowReviewFeatures: (show: boolean) => void;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();

    return (
        <Column style={{ alignItems: 'center' }}>
            <HeaderThreeOnboard style={{ textAlign: 'center', marginBottom: '8px' }}>
                You're all set 🥳
            </HeaderThreeOnboard>
            <Spacing factor={0.5} />
            <BodyBig style={{ textAlign: 'center', marginBottom: '15px' }}>
                Spinach Pro features are enabled for the next <b>{user.reverseTrialDaysLeft} days!</b>
            </BodyBig>

            <Column style={{ gap: '10px', marginBottom: '10px', maxWidth: '400px' }}>
                <Row>
                    <BodyRegular style={{ fontWeight: 700 }}>What you get:</BodyRegular>
                </Row>
                <Row style={{ alignItems: 'center', gap: '12px' }}>
                    <CheckIcon style={{ color: '#4CAF50', fontSize: '20px' }} />
                    <BodyRegularOnboard>Unlimited meeting hours</BodyRegularOnboard>
                </Row>

                <Row style={{ alignItems: 'center', gap: '12px' }}>
                    <CheckIcon style={{ color: '#4CAF50', fontSize: '20px' }} />
                    <BodyRegularOnboard>Advanced AI Summaries</BodyRegularOnboard>
                </Row>

                <Row style={{ alignItems: 'center', gap: '12px' }}>
                    <CheckIcon style={{ color: '#4CAF50', fontSize: '20px' }} />
                    <BodyRegularOnboard>Ask Spinach - AI assistant</BodyRegularOnboard>
                </Row>

                <Row style={{ alignItems: 'center', gap: '12px' }}>
                    <CheckIcon style={{ color: '#4CAF50', fontSize: '20px' }} />
                    <BodyRegularOnboard>All integrations</BodyRegularOnboard>
                </Row>
            </Column>

            <PrimaryButton
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Continue Button Finished Activating Trial Modal',
                    });
                    setShowReviewFeatures(false);
                }}
                title="Continue"
            />
        </Column>
    );
}

function ReviewMeetings(): JSX.Element {
    const { setActiveSection } = useGlobalAiDashboard();
    const [, setGlobalModal] = useGlobalModal();
    const track = useExperienceTracking();
    const closeModal = () => {
        setGlobalModal(null);
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Close Finished Activating Trial Modal',
        });
    };
    return (
        <Column style={{ alignItems: 'center' }}>
            <HeaderThreeOnboard style={{ textAlign: 'center', marginBottom: '8px' }}>
                You're all set 🥳
            </HeaderThreeOnboard>

            <Spacing factor={0.5} />

            <Column style={{ gap: '5px', marginBottom: '5px', maxWidth: '400px', width: '100%' }}>
                <Row>
                    <BodyRegular style={{ fontWeight: 600 }}>Spinach will join:</BodyRegular>
                </Row>

                <Row
                    style={{
                        alignItems: 'center',
                        gap: '12px',
                        backgroundColor: '#35A28926',
                        padding: '5px 10px',
                        borderRadius: '8px',
                        width: 'calc(100% - 20px)',
                    }}
                >
                    <CalendarToday style={{ color: lightTheme.primary.greenLight, fontSize: '20px' }} />
                    <Column style={{ alignItems: 'flex-start' }}>
                        <BodyRegular>Spinach is set up to join all your meetings.</BodyRegular>
                        <BodyRegular>
                            You can customize this in <b>Future Meetings</b>.
                        </BodyRegular>
                    </Column>
                </Row>
                <Column style={{ width: '100%', marginTop: '8px' }}>
                    <StyledMeetingRow label="Internal meetings" />
                    <StyledMeetingRow label="External meetings" />
                </Column>
            </Column>

            <SecondaryButton
                buttonStyles={{ textDecoration: 'none', color: '#35A289' }}
                labelStyles={{ fontWeight: 'normal' }}
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'See more Finished Activating Trial Modal',
                    });
                    setActiveSection(DashboardSection.Meetings);
                    closeModal();
                }}
                title="See all your meetings"
            />

            <Spacing />

            <PrimaryButton
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Explore Dashboard Button Finished Activating Trial Modal',
                    });
                    closeModal();
                }}
                title="Explore dashboard"
            />
        </Column>
    );
}

export function FinishedActivatingTrialModal(): JSX.Element {
    const [globalModal] = useGlobalModal();

    const [showReviewFeatures, setShowReviewFeatures] = useState(true);

    if (globalModal !== GlobalModal.FinishedActivatingTrial) {
        return <></>;
    }

    return (
        <Modal open={globalModal === GlobalModal.FinishedActivatingTrial}>
            <SpinachModalContent style={{ overflow: 'hidden', minHeight: 'unset' }}>
                {showReviewFeatures ? (
                    <ReviewFeaturesContent setShowReviewFeatures={setShowReviewFeatures} />
                ) : (
                    <ReviewMeetings />
                )}
            </SpinachModalContent>
        </Modal>
    );
}
