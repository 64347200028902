import React from 'react';
import { atom } from 'recoil';

import {
    CheckoutBillingScope,
    ClientUsersWithAiHistoryAccess,
    KnowledgeBaseIntegration,
    PlanType,
    ProPlanType,
    SummaryJson,
    TicketSource,
} from '@spinach-shared/types';

import { CreateTicketIntegrationRedirectCause } from '../types';

/**
 * The thought here is that we can control modals from anywhere with global state
 * we can house them in a single place so as to not bloat up other components
 * There should only be a single modal up at a time, so global shouldn't produce
 * too many conflicts. Open to alternatives though.
 *
 * Initial usecase - need to finish demo mode -> route to real lobby -> pop modal there
 * Could use a query param, but ultimately i'd want to remove it. Global state makes this much easier.
 */
export enum GlobalModal {
    ConfigureSlackHuddles = 'configure-slack-huddles',
    ShareSeries = 'share-series',
    SubscribeSeriesOutput = 'subscribe-series-output',
    Schedule = 'schedule',
    ConfirmLeaveDemo = 'confirm-leave-demo',
    VerifyEmail = 'verify-email',
    UserAccount = 'user-account',
    SeriesSettings = 'series-settings',
    ArchivedUserGoals = 'archived-user-goals',
    MeditationPrompt = 'meditation-prompt',
    SpinachReferral = 'spinach-referral',
    IntegrationsSettings = 'integrations-settings',
    InviteViaSlack = 'invite-via-slack',
    ConnectTicketSource = 'connect-ticket-source',
    SelectTicketProject = 'select-ticket-project',
    KnowledgeBaseSetupComplete = 'knowledge-base-setup-complete',
    RenameSeries = 'rename-series',
    RemoveSeries = 'remove-series',
    CreateSeries = 'create-series',
    ImageUpload = 'image-upload',
    QuickRecordSystemAudioDisclaimer = 'quick-record-system-audio-disclaimer',
    AddPrivateChannelInstructions = 'add-private-channel-instructions',
    SSOProvisioningInstructions = 'sso-provisioning-instructions',
    AddTeamMember = 'add-team-member',
    FinishedActivatingTrial = 'finished-activating-trial',
    ActivateTrial = 'activate-trial',
    CalendarAccessNeededForTrial = 'calendar-access-needed-for-trial',

    // admin modals
    ConfirmDeleteMeetingAsAdmin = 'confirm-delete-meeting-as-admin',
    ConfirmAccessMeetingNotesAsAdmin = 'confirm-access-meeting-notes-as-admin',
    ConfirmDeactivateUserAsAdmin = 'confirm-deactivate-user-as-admin',

    ConfirmDeleteMeeting = 'confirm-delete-meeting',

    AgentAgenda = 'agent-agenda',

    UpgradeToRecallCalendarV2 = 'upgrade-to-recall-calendar-v2',
    LoadingBackdrop = 'loading-backdrop',

    ShareAiHistory = 'share-ai-history',

    UserAccountManagement = 'user-account-management',

    VideoAgentTestControls = 'video-agent-test-controls',
    ClaimHost = 'claim-host',
    Zapier = 'zapier',
    SelectTicketProjectFromDropDown = 'select-ticket-project-from-drop-down',
    ManageDraftSummaryRecipients = 'manage-draft-summary-recipients',
    ManageSeriesAdditionalEditors = 'manage-series-additional-editors',

    CreateAccount = 'create-account',
    Checkout = 'checkout',
}
export enum GlobalModalFrom {
    StartStandup = 'start-standup',
    InviteSecondUserCallout = 'invite-second-user-callout',
    ActionItemSummaryComponent = 'action-item-summary-component',
    RestartTrial = 'restart-trial',
}

export enum GlobalModalMetadataType {
    SeriesInformation = 'series-information',
    KnowledgeBaseSetup = 'knowledge-base-setup',
    TicketIntegration = 'ticket-integration',
    SSOProvisioningInstructions = 'sso-provisioning-instructions',
    ShareAiHistory = 'share-ai-history',
    ClaimHost = 'claim-host',
    ManageDraftSummaryRecipients = 'manage-draft-summary-recipients',
    Checkout = 'checkout',
}

export type SeriesInformationGlobalModalMetadata = {
    botId?: string | null;
    seriesId?: string | null;
};

export type KnowledgeBaseSetupModalMetadata = SeriesInformationGlobalModalMetadata & {
    metadataType: GlobalModalMetadataType.KnowledgeBaseSetup;
    setupFailed: boolean;
    knowledgeBaseIntegration: KnowledgeBaseIntegration;
};

export type TicketIntegrationModalMetadata = SeriesInformationGlobalModalMetadata & {
    metadataType: GlobalModalMetadataType.TicketIntegration;
    teamTicketIntegration?: TicketSource;
    integrationRedirectCause?: CreateTicketIntegrationRedirectCause;
    ticketSource?: TicketSource;
};

export type SSOProvisioningInstructionsModalMetadata = {
    metadataType: GlobalModalMetadataType.SSOProvisioningInstructions;
    slackChannelId: string;
    slackChannelName: string;
    userProvisioningLink: string;
};

export type ShareAiHistoryModalMetadata = {
    metadataType: GlobalModalMetadataType.ShareAiHistory;
    usersWithHistoryAccess: ClientUsersWithAiHistoryAccess[];
    botId: string;
    meetingTitle?: string;
    seriesId: string;
};

export type ClaimHostModalMetadata = {
    metadataType: GlobalModalMetadataType.ClaimHost;
    seriesId: string;
};

export type ManageDraftSummaryRecipientsModalMetadata = {
    metadataType: GlobalModalMetadataType.ManageDraftSummaryRecipients;
    summaryJSON: SummaryJson;
    defaultList: string[];
    attendees: {
        email: string;
        rootDomain: string;
    }[];
    botId: string;
    onSaved: (emails: string[]) => void;
    hostUserEmail: string;
};

export type CheckoutModalMetadata = {
    metadataType: GlobalModalMetadataType.Checkout;
} & (
    | {
          skipPlanSelectionStep: true;
          selectedPlanType: PlanType;
          selectedProPlan: ProPlanType;
          selectedBundle: string;
          proPlanScope: CheckoutBillingScope.Team | CheckoutBillingScope.Company;
      }
    | {
          skipPlanSelectionStep?: false;
      }
);

export type GlobalModalMetadata =
    | KnowledgeBaseSetupModalMetadata
    | TicketIntegrationModalMetadata
    | SSOProvisioningInstructionsModalMetadata
    | ShareAiHistoryModalMetadata
    | ClaimHostModalMetadata
    | ManageDraftSummaryRecipientsModalMetadata
    | CheckoutModalMetadata;

export type GlobalModalState = {
    modal: GlobalModal | null;
    location?: string;
    from?: GlobalModalFrom;
    metadata?: GlobalModalMetadata;
};

export const atomModalState = atom<GlobalModalState>({
    key: 'modalState',
    default: {
        modal: null,
    },
});
