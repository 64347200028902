import { SpinachAPIPath } from '@spinach-shared/types';

import { getSpinachAPI } from './getSpinachAPI';

export async function getAutoBrandingUrl(): Promise<string | null> {
    try {
        const response = await getSpinachAPI<{ logoUrl: string }>(SpinachAPIPath.AutoBrandingUrl);
        return response?.logoUrl ?? null;
    } catch {
        return null;
    }
}
