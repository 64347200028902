import {
    EmailWithRootDomain,
    ScribeMeetingType,
    SpinachAPIPath,
    SummaryFeatureIntents,
    SummaryJson,
    UserNameWithEmailConfidenceMap,
} from '@spinach-shared/types';

import { getSpinachAPI } from './getSpinachAPI';

export type GetAiSummaryJsonResponse = {
    summaryJson: SummaryJson;
    meetingTime: string;
    meetingTitle: string;
    usernameEmailMapping?: UserNameWithEmailConfidenceMap;
    meetingType: ScribeMeetingType;
    seriesId: string;
    enabledSummarySections?: SummaryFeatureIntents[];
    emailsWithAccess: string[];
    anyoneWithLinkCanAccess?: boolean;
    attendees?: EmailWithRootDomain[];
    hostUserEmail: string;
};

export async function getAiSummaryJson(
    botId: string,
    isEditSummary: boolean
): Promise<GetAiSummaryJsonResponse | { code: string }> {
    const response = await getSpinachAPI<GetAiSummaryJsonResponse | { code: string }>(SpinachAPIPath.AiSummary, {
        params: {
            botId,
            isEditSummary,
        },
    });
    if (!response) {
        return { code: 'failure' };
    }

    if (!!response && 'code' in response) {
        return { code: response.code };
    }

    return {
        summaryJson: response.summaryJson,
        meetingTime: response.meetingTime,
        meetingTitle: response.meetingTitle,
        usernameEmailMapping: response.usernameEmailMapping,
        meetingType: response.meetingType,
        seriesId: response.seriesId,
        enabledSummarySections: response.enabledSummarySections,
        attendees: response.attendees,
        emailsWithAccess: response.emailsWithAccess,
        hostUserEmail: response.hostUserEmail,
    };
}
