import { useQuery } from 'react-query';

import { AgentConfig, SpinachAPIPath } from '@spinach-shared/types';

import { getSpinachAPI } from '../../../../apis/getSpinachAPI';
import { useGlobalAuthedUser } from '../../../../hooks/useGlobalUser';

interface AgentConfigResponse {
    success: boolean;
    enabledAgents: Record<string, AgentConfig>;
}

/**
 * Hook to fetch enabled agent configurations
 * @returns Record of agent IDs to their configurations
 */
export function useEnabledAgents(): Record<string, AgentConfig> {
    const [user] = useGlobalAuthedUser();

    const { data } = useQuery<AgentConfigResponse, Error, Record<string, AgentConfig>>({
        queryKey: ['agentConfig'],
        queryFn: async () => {
            const response = await getSpinachAPI<AgentConfigResponse>(SpinachAPIPath.AgentConfig);
            if (!response) {
                throw new Error('Failed to fetch agent configurations');
            }
            return response;
        },
        select: (data) => data.enabledAgents || {},
        enabled: !!user,
        staleTime: 5 * 60 * 1000, // 5 minutes
        cacheTime: 10 * 60 * 1000, // 10 minutes
    });

    return data || {};
}
