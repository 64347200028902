import { Box, Button, Tooltip } from '@material-ui/core';
import { ButtonGroup } from '@material-ui/core';
import { Check, ChevronRight, InfoOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import styled from 'styled-components';

import { CheckoutBillingScope, ClientEventType, PlanType, ProPlanType } from '@spinach-shared/types';

import IntegrationsTooltipSrc from '../../../../assets/checkout-integrations-tooltip.png';
import SummaryTooltipSrc from '../../../../assets/checkout-summary-tooltip.png';
import { useExperienceTracking, useGlobalAuthedUser, useStripePlansV2, useWindowSize } from '../../../../hooks';
import { BodyRegularOnboard, HeaderThreeOnboard, lightTheme } from '../../../../styles';
import { URLUtil } from '../../../../utils';
import { Anchor, Row, Spacing } from '../../../common';
import { PrimaryButton } from '../../../stand-up';

interface Feature {
    text: string;
    tooltip?: React.ReactNode;
}

interface PlanCardProps {
    name: string;
    pricePerUnit?: string;
    subtitle?: string;
    unit?: string;
    features: Feature[];
    ctaText?: string;
    ctaDisabled?: boolean;
    onCtaClicked?: () => void;
    isExpanded: boolean;
    style?: React.CSSProperties;
    children?: React.ReactNode;
    priceUnitTooltip?: React.ReactNode;
    id?: string;
}

export const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    '& .MuiTooltip-tooltip': {
        backgroundColor: 'transparent',
        maxWidth: '450px',
    },
}));

const SubText = styled(BodyRegularOnboard)`
    font-size: 14px;
    color: black;
    font-size: 16px;
    font-weight: 400;
`;

const BundleOption = styled.div<{ selected: boolean }>`
    margin-bottom: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const BundleOptionText = styled(BodyRegularOnboard)`
    font-size: 16px;
    font-weight: 700;
    margin-right: 6px;
`;

const RadioCircle = styled.div<{ selected: boolean }>`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #b4b4b4;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    background: #f6f6f6;
    &::after {
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: ${(props) => (props.selected ? lightTheme.primary.greenLight : 'transparent')};
    }
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    background: #f9f9f9;
    border-radius: 15px;
    max-width: 352px;
    min-width: 305px;
    height: auto;
    transition: height 0.3s ease-in-out;
    box-sizing: border-box;
    width: 100%;
    padding: 24px;

    @media (max-width: 860px) {
        max-width: unset;
        margin-right: 0;
    }
`;

const CardTopSection = styled.div`
    display: flex;
    flex-direction: column;
    height: 110px;
`;

const CardBottomSection = styled.div`
    width: 100%;
`;

const CardMiddleSection = styled.div`
    display: flex;
    flex-direction: column;
    height: 190px;
`;

const CardExpandSection = styled.div<{ isExpanded: boolean }>`
    display: flex;
    flex-direction: column;
    height: ${(props) => (props.isExpanded ? '250px' : '0px')};
    overflow: hidden;
    transition: height 0.3s ease-in-out;

    @media (max-width: 860px) {
        height: ${(props) => (props.isExpanded ? '260px' : '0px')};
    }
`;

const PlanName = styled.h3`
    font-size: 20px;
    font-weight: 600;
    margin: 0;
`;

const PriceContainer = styled.div`
    display: flex;
    align-items: baseline;
`;

const Price = styled.span`
    font-size: 32px;
    font-weight: 600;
`;

const Unit = styled.span`
    font-size: 14px;
    color: #666;
    margin-left: 4px;
`;

const Subtitle = styled(BodyRegularOnboard)`
    font-size: 14px;
    color: #666;
`;

const FeatureList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0 0 24px 0;
`;

const FeatureItem = styled.li`
    display: flex;
    align-items: center;
    font-size: 17.5px;
    margin-bottom: 12px;
    font-weight: 600;
`;

const UpgradeButton = styled(PrimaryButton)`
    width: 100%;
`;

const TooltipContainer = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 17px;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.tertiary.midnight};
    background: var(--White, #fff);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
`;

const EnterprisePlanCard = styled(Card)`
    width: 100%;
    max-width: 1104px;
    height: auto;
`;

const EnterpriseFeatureCardFeatureList = styled(FeatureList)`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    width: 100%;
    margin: 0;
    padding: 0;
    @media (max-width: 860px) {
        display: flex;
        flex-direction: column;
    }
`;

const PlanCard: React.FC<PlanCardProps> = ({
    name,
    pricePerUnit,
    subtitle,
    unit,
    priceUnitTooltip,
    features,
    ctaText,
    ctaDisabled,
    onCtaClicked,
    style,
    isExpanded,
    children,
    id,
}) => {
    const [priceUnitTooltipOpen, setPriceUnitTooltipOpen] = useState(false);
    const track = useExperienceTracking();
    const [featureTooltips, setFeatureTooltips] = useState<string[]>([]);

    return (
        <Card id={id} style={style}>
            <CardTopSection>
                <PlanName>{name}</PlanName>
                <Spacing factor={1 / 2} />
                {pricePerUnit ? (
                    <PriceContainer>
                        <Price>{pricePerUnit}</Price>
                        {unit ? <Unit>/{unit}</Unit> : null}
                        {priceUnitTooltip ? (
                            <CustomTooltip
                                enterTouchDelay={0}
                                leaveTouchDelay={15000}
                                onOpen={() => {
                                    if (!priceUnitTooltipOpen) {
                                        setPriceUnitTooltipOpen(true);
                                        track(ClientEventType.AIDashboardActivity, {
                                            Activity: 'Price Unit Tooltip Opened',
                                            PlanName: name,
                                            Section: 'PlanSelectorV2',
                                        });
                                    }
                                }}
                                onClose={() => {
                                    setPriceUnitTooltipOpen(false);
                                }}
                                style={{ marginLeft: '5px' }}
                                title={
                                    <TooltipContainer
                                        style={{
                                            width: '267px',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        {priceUnitTooltip}
                                    </TooltipContainer>
                                }
                            >
                                <InfoOutlined
                                    htmlColor={lightTheme.primary.greenLight}
                                    fontSize="small"
                                    style={{ fontSize: '24px', marginLeft: '8px', transform: 'translateY(5px)' }}
                                />
                            </CustomTooltip>
                        ) : null}
                    </PriceContainer>
                ) : null}
                {subtitle ? (
                    <>
                        <Spacing factor={1 / 2} />
                        <Subtitle>{subtitle}</Subtitle>
                    </>
                ) : null}
            </CardTopSection>
            <Spacing factor={1 / 2} />
            <Divider />
            <Spacing factor={1 / 2} />
            <CardMiddleSection>
                <FeatureList>
                    {features.map((feature, index) => (
                        <FeatureItem key={index}>
                            <Check
                                style={{
                                    color: ctaDisabled ? '#707070' : '#F26E24',
                                    marginRight: '6px',
                                    width: '21px',
                                    height: '21px',
                                }}
                            />
                            {feature.text}
                            {feature.tooltip ? (
                                <CustomTooltip
                                    enterTouchDelay={0}
                                    leaveTouchDelay={15000}
                                    interactive={true}
                                    onOpen={() => {
                                        if (!featureTooltips.includes(feature.text)) {
                                            setFeatureTooltips([...featureTooltips, feature.text]);
                                            track(ClientEventType.AIDashboardActivity, {
                                                Activity: 'Feature Tooltip Opened',
                                                FeatureText: feature.text,
                                                Section: 'PlanSelectorV2',
                                            });
                                        }
                                    }}
                                    onClose={() => {
                                        setFeatureTooltips(featureTooltips.filter((t) => t !== feature.text));
                                    }}
                                    style={{ marginLeft: '5px' }}
                                    title={
                                        <TooltipContainer
                                            style={{
                                                width: 'auto',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            {feature.tooltip}
                                        </TooltipContainer>
                                    }
                                >
                                    <InfoOutlined
                                        htmlColor={lightTheme.primary.greenLight}
                                        fontSize="small"
                                        style={{
                                            fontSize: '24px',
                                            marginLeft: '8px',
                                        }}
                                    />
                                </CustomTooltip>
                            ) : null}
                        </FeatureItem>
                    ))}
                </FeatureList>
            </CardMiddleSection>
            <CardExpandSection isExpanded={isExpanded}>{children}</CardExpandSection>
            <Spacing factor={1 / 2} />
            <Divider />
            <Spacing factor={1 / 2} />
            {ctaText ? (
                <CardBottomSection>
                    <UpgradeButton
                        onClick={() => {
                            if (onCtaClicked) {
                                onCtaClicked();
                            }
                        }}
                        title={ctaText}
                        disabled={!!ctaDisabled}
                    >
                        {ctaText}
                    </UpgradeButton>
                </CardBottomSection>
            ) : null}
        </Card>
    );
};

const FeatureCardTopSection = styled(CardTopSection)`
    height: 24px;
`;

const FeatureCardSubtitle = styled(Subtitle)`
    font-size: 17.5px;
    margin-bottom: 12px;
    font-weight: 600;
    margin-bottom: 8px;
    color: black;
`;

const FeatureCardMiddleSection = styled(CardMiddleSection)`
    height: 206px;
`;

const PlanFeaturesCard: React.FC<{
    name: string;
    subtitle?: string;
    features: { text: string }[];
    style?: React.CSSProperties;
}> = ({ name, subtitle, features, style }) => {
    return (
        <Card style={{ ...style }}>
            <FeatureCardTopSection>
                <PlanName>{name}</PlanName>
                <Spacing factor={1 / 2} />
            </FeatureCardTopSection>
            <Spacing factor={1 / 2} />
            <FeatureCardMiddleSection>
                {subtitle ? (
                    <>
                        <FeatureCardSubtitle>{subtitle}</FeatureCardSubtitle>
                    </>
                ) : null}
                <FeatureList>
                    {features.map((feature, index) => (
                        <FeatureItem key={index}>
                            <Check
                                style={{
                                    color: '#F26E24',
                                    marginRight: '6px',
                                    width: '21px',
                                    height: '21px',
                                }}
                            />
                            {feature.text}
                        </FeatureItem>
                    ))}
                </FeatureList>
            </FeatureCardMiddleSection>
        </Card>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
`;

const PlanCardsContainer = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: 24px;
    align-items: flex-start;
    row-gap: 24px;
`;

const Divider = styled.div`
    height: 1px;
    width: 100%;
    background: ${lightTheme.neutrals.grayDark};
`;

const ProPlanTooltipListItem = styled.li`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    list-style: none;
    margin-bottom: 8px;
    &:last-child {
        margin-bottom: 0;
    }
`;

export function ProAndBusinessPlanSelectorV2({
    selectedProPlan,
    setSelectedProPlan,
    selectedBundle,
    setSelectedBundle,
    onCreateCheckoutSession,
    proPlanScope,
    setProPlanScope,
    setSelectedPlanType,
}: {
    selectedPlanType: PlanType | undefined;
    setSelectedPlanType: (planType: PlanType) => void;
    selectedProPlan: ProPlanType;
    setSelectedProPlan: (planType: ProPlanType) => void;
    onCreateCheckoutSession: () => void;
    selectedBundle: string;
    setSelectedBundle: (bundle: string) => void;
    proPlanScope: CheckoutBillingScope.Team | CheckoutBillingScope.Company;
    setProPlanScope: (scope: CheckoutBillingScope.Team | CheckoutBillingScope.Company) => void;
}) {
    const track = useExperienceTracking();
    const plans = useStripePlansV2();
    const [user] = useGlobalAuthedUser();
    const [planScopeTooltipOpen, setPlanScopeTooltipOpen] = useState(false);
    const hideBillingScopeDropdown = user.isPersonal;

    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <Container>
            <HeaderThreeOnboard
                id={'pro-and-business-plan-selector-header'}
                style={{
                    alignSelf: 'flex-start',
                    fontSize: '32px',
                    textAlign: 'left',
                }}
            >
                Flexible pricing, your choice
            </HeaderThreeOnboard>
            <Spacing factor={1 / 2} />
            <BodyRegularOnboard id={'spinach_intercom_link'}>
                Pay per meeting hour or pay per user. Have questions?{' '}
                <Anchor
                    id={'spinach_intercom'}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Checkout Modal Chat Live`,
                            Section: 'PlanSelectorV2',
                        });
                    }}
                >
                    Chat live
                </Anchor>
            </BodyRegularOnboard>
            <Spacing factor={1 / 2} />
            <PlanCardsContainer>
                <PlanCard
                    name="Starter"
                    pricePerUnit="Free"
                    features={[{ text: 'Individual user' }, { text: 'Basic features' }]}
                    ctaText="Your current plan"
                    ctaDisabled={true}
                    isExpanded={isExpanded}
                ></PlanCard>
                <PlanCard
                    name="Pro"
                    id="pro-plan-card"
                    pricePerUnit={`$${plans.pro.lowestPricePerHour}`}
                    unit="hour"
                    priceUnitTooltip={
                        <ul style={{ margin: 0, padding: 0 }}>
                            <ProPlanTooltipListItem>
                                <BundleOptionText>Pay per hour</BundleOptionText>
                                <SubText>(${plans.pro.payAsYouGo.pricePerHour}/hour)</SubText>
                            </ProPlanTooltipListItem>
                            {Object.entries(plans.pro.bundles).map(([key, bundle]) => (
                                <ProPlanTooltipListItem key={key}>
                                    <BundleOptionText>
                                        {key} hours for ${bundle.price}
                                    </BundleOptionText>
                                    <SubText style={{ fontSize: '16px' }}>
                                        (${(bundle.price / bundle.hours).toFixed(2)}/hour)
                                    </SubText>
                                </ProPlanTooltipListItem>
                            ))}
                        </ul>
                    }
                    subtitle={`(Pre-pay discount or $${plans.pro.payAsYouGo.pricePerHour.toFixed(2)} pay-as-you-go)`}
                    features={[
                        { text: 'Pay per meeting hour' },
                        { text: 'Unlimited users' },
                        {
                            text: 'Advanced AI Summaries',
                            tooltip: (
                                <>
                                    <BodyRegularOnboard style={{ fontSize: '18px', fontWeight: 600 }}>
                                        Receive concise details from your Summary
                                    </BodyRegularOnboard>
                                    <Spacing factor={1 / 2} />
                                    <img
                                        src={SummaryTooltipSrc}
                                        style={{ maxWidth: '393px', width: '100%' }}
                                        alt="Summary with key topics, action items, key decisions, blockers"
                                    />
                                </>
                            ),
                        },
                        {
                            text: 'Advanced Settings',
                            tooltip: (
                                <>
                                    <BodyRegularOnboard>
                                        Access additional settings such as customizing who meeting summaries is shared
                                        with, and a personalized meeting interface to reflect company branding.
                                    </BodyRegularOnboard>
                                </>
                            ),
                        },
                        {
                            text: 'Ask Spinach - AI Assistant',
                            tooltip: (
                                <>
                                    <BodyRegularOnboard>
                                        An AI assistant that answers questions about meeting content with tons of
                                        pre-configured prompts.
                                    </BodyRegularOnboard>
                                </>
                            ),
                        },
                        {
                            text: 'All Integrations',
                            tooltip: (
                                <>
                                    <img
                                        src={IntegrationsTooltipSrc}
                                        alt="All integrations"
                                        style={{ maxWidth: '393px', width: '100%' }}
                                    />
                                    <Spacing factor={1 / 2} />
                                    <Anchor
                                        href={'https://www.spinach.ai/integrations'}
                                        target="_blank"
                                        style={{
                                            textDecoration: 'none',
                                            fontWeight: 600,
                                            fontSize: '17.5px',
                                            margin: 'auto',
                                        }}
                                        onClick={() => {
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: 'Pro Plan Integrations Learn More Link',
                                                Section: 'PlanSelectorV2',
                                            });
                                        }}
                                    >
                                        Learn more
                                    </Anchor>
                                </>
                            ),
                        },
                    ]}
                    ctaText={isExpanded ? 'Upgrade to Pro' : 'Upgrade options'}
                    onCtaClicked={() => {
                        if (isExpanded) {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Pro Plan Selected',
                                Section: 'PlanSelectorV2',
                            });
                            setSelectedPlanType('pro');
                            onCreateCheckoutSession();
                        } else {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Pro Plan Expand',
                                Section: 'PlanSelectorV2',
                            });
                            setIsExpanded(true);
                            const element = document.getElementById('pro-plan-card');
                            if (element) {
                                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                            }
                        }
                    }}
                    isExpanded={isExpanded}
                >
                    <>
                        <Spacing factor={1 / 2} />
                        <Divider />
                        <Spacing factor={1 / 2} />
                        <BundleOption
                            key={'payAsYouGo'}
                            selected={selectedProPlan === 'payAsYouGo'}
                            onClick={() => {
                                setSelectedProPlan('payAsYouGo');
                            }}
                        >
                            <Row vCenter>
                                <RadioCircle selected={selectedProPlan === 'payAsYouGo'} />
                                <BundleOptionText>Pay per hour</BundleOptionText>
                                <SubText>(${plans.pro.payAsYouGo.pricePerHour}/hour)</SubText>
                            </Row>
                        </BundleOption>
                        {Object.entries(plans.pro.bundles).map(([key, bundle]) => (
                            <BundleOption
                                key={key}
                                selected={selectedBundle === key && selectedProPlan === 'bundles'}
                                onClick={() => {
                                    setSelectedBundle(key);
                                    setSelectedProPlan('bundles');
                                }}
                            >
                                <Row vCenter>
                                    <RadioCircle selected={selectedBundle === key && selectedProPlan === 'bundles'} />
                                    <BundleOptionText>
                                        {key} hours for ${bundle.price}
                                    </BundleOptionText>
                                    <SubText style={{ fontSize: '16px' }}>
                                        (${(bundle.price / bundle.hours).toFixed(2)}/hour)
                                    </SubText>
                                </Row>
                            </BundleOption>
                        ))}
                        <Spacing factor={1 / 2} />
                        {hideBillingScopeDropdown ? null : (
                            <Row vCenter>
                                <CustomTooltip
                                    enterTouchDelay={0}
                                    enterDelay={500}
                                    leaveTouchDelay={15000}
                                    onOpen={() => {
                                        if (!planScopeTooltipOpen) {
                                            setPlanScopeTooltipOpen(true);
                                            track(ClientEventType.AIDashboardActivity, {
                                                Activity: 'Plan Scope Tooltip Opened',
                                                Section: 'PlanSelectorV2',
                                            });
                                        }
                                    }}
                                    onClose={() => {
                                        setPlanScopeTooltipOpen(false);
                                    }}
                                    style={{ marginLeft: '5px' }}
                                    title={
                                        <TooltipContainer
                                            style={{
                                                width: '267px',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <ul style={{ color: 'black', paddingLeft: '16px', fontSize: '14px' }}>
                                                <li>
                                                    <BodyRegularOnboard>
                                                        Company - anyone from your domain
                                                    </BodyRegularOnboard>
                                                </li>
                                                <li>
                                                    <BodyRegularOnboard>
                                                        Team - you and anyone you add to the account
                                                    </BodyRegularOnboard>
                                                </li>
                                            </ul>
                                        </TooltipContainer>
                                    }
                                >
                                    <ButtonGroup
                                        style={{
                                            borderRadius: '20px',
                                            overflow: 'hidden',
                                            border: `1px solid ${lightTheme.primary.greenLight}`,
                                            height: '36px',
                                            width: '198px',
                                        }}
                                        disableElevation
                                    >
                                        <Button
                                            variant={
                                                proPlanScope === CheckoutBillingScope.Company ? 'contained' : 'outlined'
                                            }
                                            onClick={() => setProPlanScope(CheckoutBillingScope.Company)}
                                            style={{
                                                backgroundColor:
                                                    proPlanScope === CheckoutBillingScope.Company
                                                        ? lightTheme.primary.greenLight
                                                        : 'transparent',
                                                color:
                                                    proPlanScope === CheckoutBillingScope.Company
                                                        ? 'white'
                                                        : lightTheme.primary.greenLight,
                                                borderRadius: '20px',
                                                border: 'none',
                                                minWidth: '100px',
                                                padding: '6px 16px',
                                            }}
                                        >
                                            Company
                                        </Button>
                                        <Button
                                            variant={
                                                proPlanScope === CheckoutBillingScope.Team ? 'contained' : 'outlined'
                                            }
                                            onClick={() => setProPlanScope(CheckoutBillingScope.Team)}
                                            style={{
                                                backgroundColor:
                                                    proPlanScope === CheckoutBillingScope.Team
                                                        ? lightTheme.primary.greenLight
                                                        : 'transparent',
                                                color:
                                                    proPlanScope === CheckoutBillingScope.Team
                                                        ? 'white'
                                                        : lightTheme.primary.greenLight,
                                                borderRadius: '20px',
                                                border: 'none',
                                                minWidth: '100px',
                                                padding: '6px 16px',
                                            }}
                                        >
                                            Team
                                        </Button>
                                    </ButtonGroup>
                                </CustomTooltip>
                            </Row>
                        )}
                    </>
                </PlanCard>
                <PlanCard
                    name="Business"
                    pricePerUnit={`$${plans.business.pricePerSeatAnnually}`}
                    unit="user per month"
                    subtitle={`(Billed annually or $${plans.business.pricePerSeatMonthly} monthly)`}
                    features={[
                        { text: 'Pay per user' },
                        { text: 'Unlimited meeting hours' },
                        {
                            text: 'Advanced AI Summaries',
                            tooltip: (
                                <>
                                    <BodyRegularOnboard style={{ fontSize: '18px', fontWeight: 600 }}>
                                        Receive concise details from your Summary
                                    </BodyRegularOnboard>
                                    <Spacing factor={1 / 2} />
                                    <img
                                        src={SummaryTooltipSrc}
                                        style={{ maxWidth: '393px', width: '100%' }}
                                        alt="Summary with key topics, action items, key decisions, blockers"
                                    />
                                </>
                            ),
                        },
                        {
                            text: 'Advanced Settings',
                            tooltip: (
                                <>
                                    <BodyRegularOnboard>
                                        Access additional settings such as customizing who meeting summaries is shared
                                        with, and a personalized meeting interface to reflect company branding.
                                    </BodyRegularOnboard>
                                </>
                            ),
                        },
                        {
                            text: 'Ask Spinach - AI Assistant',
                            tooltip: (
                                <>
                                    <BodyRegularOnboard>
                                        An AI assistant that answers questions about meeting content with tons of
                                        pre-configured prompts.
                                    </BodyRegularOnboard>
                                </>
                            ),
                        },
                        {
                            text: 'All Integrations',
                            tooltip: (
                                <>
                                    <img
                                        src={IntegrationsTooltipSrc}
                                        alt="All integrations"
                                        style={{ maxWidth: '393px', width: '100%' }}
                                    />
                                    <Spacing factor={1 / 2} />
                                    <Anchor
                                        href={'https://www.spinach.ai/integrations'}
                                        target="_blank"
                                        style={{
                                            textDecoration: 'none',
                                            fontWeight: 600,
                                            fontSize: '17.5px',
                                            margin: 'auto',
                                        }}
                                        onClick={() => {
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: 'Pro Plan Integrations Learn More Link',
                                                Section: 'PlanSelectorV2',
                                            });
                                        }}
                                    >
                                        Learn more
                                    </Anchor>
                                </>
                            ),
                        },
                    ]}
                    ctaText="Upgrade to Business"
                    onCtaClicked={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Business Plan Selected`,
                            Section: 'PlanSelectorV2',
                        });
                        setSelectedPlanType('business');
                        onCreateCheckoutSession();
                    }}
                    isExpanded={isExpanded}
                />
            </PlanCardsContainer>
            <Spacing factor={1 / 2} />
            <EnterprisePlanCard>
                <PlanName>Enterprise</PlanName>
                <Spacing factor={1 / 2} />
                <BodyRegularOnboard>
                    Custom plan for your organization, volume discounts available.{' '}
                    <Anchor
                        style={{ marginLeft: '5px' }}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Talk to Sales',
                                Section: 'PlanSelectorV2',
                            });
                            URLUtil.openURL(user.talkToSalesUrl);
                        }}
                    >
                        Talk to Sales
                    </Anchor>
                </BodyRegularOnboard>
                <Spacing factor={1 / 2} />
                <Divider />
                <Spacing factor={1 / 2} />
                <FeatureList style={{ display: 'flex', flexDirection: 'row', gap: '10px', marginBottom: 0 }}>
                    {['SAML SSO & SCIM', 'Custom data retention period', 'Business Associate Agreement (BAA)'].map(
                        (feature, index) => (
                            <FeatureItem key={index}>
                                <Check
                                    style={{
                                        color: '#F26E24',
                                        marginRight: '6px',
                                        width: '21px',
                                        height: '21px',
                                    }}
                                />
                                {feature}
                            </FeatureItem>
                        )
                    )}
                </FeatureList>
            </EnterprisePlanCard>
            <Spacing factor={1 / 2} />
            <Row centered vCenter style={{ maxWidth: '1104px' }}>
                <Anchor
                    style={{ textDecoration: 'none', fontSize: '20px', fontWeight: 400 }}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Compare Features',
                            Section: 'PlanSelectorV2',
                        });
                        // scroll to put this element at the top of the viewport
                        const element = document.getElementById('compare-features');
                        if (element) {
                            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                        }
                    }}
                >
                    Compare features
                    <ChevronRight
                        style={{ fontSize: '16px', transform: 'rotate(90deg) translateX(4px)', marginLeft: '6px' }}
                    />
                </Anchor>
            </Row>
            <Spacing factor={1 / 2} />
            <PlanCardsContainer id="compare-features">
                <PlanFeaturesCard
                    name="Starter"
                    features={[
                        { text: 'Record Zoom, Meet, Teams, Webex, Slack Huddles' },
                        { text: 'Unlimited recording & transcripts' },
                        { text: '100 languages supported' },
                        { text: 'Basic AI meeting summary' },
                        { text: 'Integrate with Google Calendar, MS Calendar, Slack' },
                    ]}
                />
                <PlanFeaturesCard
                    name="Pro"
                    subtitle="Everything in Starter, PLUS:"
                    features={[
                        { text: 'Pay per meeting hour' },
                        { text: 'Unlimited users' },
                        { text: 'Advanced AI summary' },
                        { text: 'Ask Spinach - AI assistant' },
                        { text: 'Integrate with CRMs, Project Management, Zapier & more' },
                    ]}
                />
                <PlanFeaturesCard
                    name="Business"
                    subtitle="Everything in Starter, PLUS:"
                    features={[
                        { text: 'Pay per user' },
                        { text: 'Unlimited meetings' },
                        { text: 'Advanced AI summary' },
                        { text: 'Ask Spinach - AI assistant' },
                        { text: 'Integrate with CRMs, Project Management, Zapier & more' },
                    ]}
                />
            </PlanCardsContainer>
            <Spacing factor={1 / 2} />
            <EnterprisePlanCard>
                <PlanName>Enterprise</PlanName>
                <Spacing factor={1 / 2} />
                <FeatureCardSubtitle>All features & Integrations, PLUS:</FeatureCardSubtitle>
                <EnterpriseFeatureCardFeatureList>
                    {[
                        'Pay per user or per meeting hour',
                        'Business Associate Agreement (BAA)',
                        'Custom data retention period',
                        'SAML SSO & SCIM',
                        'Dedicated customer success manager & training',
                    ].map((feature, index) => (
                        <FeatureItem key={index}>
                            <Check
                                style={{
                                    color: '#F26E24',
                                    marginRight: '6px',
                                    width: '21px',
                                    height: '21px',
                                }}
                            />
                            {feature}
                        </FeatureItem>
                    ))}
                </EnterpriseFeatureCardFeatureList>
            </EnterprisePlanCard>
            <Spacing factor={1 / 2} />
        </Container>
    );
}
