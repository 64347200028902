import { Box } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import styled from 'styled-components';

import { ClientEventType, MeetingSelection, ScribeMeetingType } from '@spinach-shared/types';

import { postNotifyMeetingParticipants } from '../../../../apis';
import { useGlobalAiDashboard } from '../../../../hooks';
import { useExperienceTracking } from '../../../../hooks/useExperienceTracking';
import { BodyRegular } from '../../../../styles';
import { Column, Row, Spacing } from '../../../common';
import { PrimaryButton } from '../../../stand-up';
import { isUnpublishedDraft } from '../utils';

const DefaultSummaryContainer = styled.div<{ stackLayout: boolean }>`
    font-family: 'Gilroy';
    max-width: ${({ stackLayout }) => (stackLayout ? 'calc(100% - 20px)' : '650px')};
    margin-bottom: 20px;

    /* Create sections between h3 elements */
    h3 {
        background-color: #f5f5f5;
        padding: 20px 20px 0 20px;
        padding-bottom: 0px;
        margin: 24px 0 0 0;
        border-radius: 8px 8px 0 0;
        font-size: 18px;
        font-weight: 700;
    }

    /* Container for h3 and its content */
    h3 + * {
        background-color: #f5f5f5;
        padding: 20px;
        padding-bottom: 20px;
        margin-top: 0;
        border-radius: 0 0 8px 8px;
    }

    /* Handle case where there might be multiple elements after h3 before next h3 */
    h3 ~ *:not(h3):not(h3 + *) {
        background-color: #f5f5f5;
        padding: 20px;
        margin-top: 0;
        border-radius: 0;
    }

    /* First level ul only */
    > ul {
        list-style-type: none;
        padding-right: 20px;
        padding-left: 20px;
        margin: 0;
    }

    /* Nested uls should not have padding */
    ul ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    li {
        line-height: 1.4;
        margin-bottom: 12px;
        padding-left: 0;
    }

    li:last-child {
        margin-bottom: 0;
    }

    /* Style the first h3 to remove top margin */
    h3:first-child {
        margin-top: 0;
    }

    /* Add spacing between sections */
    h3:not(:first-child) {
        margin-top: 24px;
    }

    /* Style b elements that are not within li elements */
    b:not(li b) {
        display: flex;
        flex: 1;
        background-color: #f5f5f5;
        padding: 2px 20px;
        padding-bottom: 0px;
        margin-bottom: -10px;
    }

    b:not(li b):first-of-type {
        padding-top: 20px;
    }
`;

// New styled component specifically for user research interviews
const UserResearchSummaryContainer = styled.div<{ stackLayout: boolean }>`
    font-family: 'Gilroy';
    max-width: ${({ stackLayout }) => (stackLayout ? 'calc(100% - 20px)' : '650px')};
    margin-bottom: 20px;

    /* Create sections between h3 elements with proper styling */
    h3 {
        background-color: #f5f5f5;
        padding: 20px 20px 10px 20px;
        margin: 24px 0 0 0;
        border-radius: 8px 8px 0 0;
        font-size: 18px;
        font-weight: 700;
    }

    /* Style the first h3 to remove top margin */
    h3:first-child {
        margin-top: 0;
        margin-bottom: 0;
    }

    /* Add spacing between sections */
    h3:not(:first-child) {
        margin-top: 24px;
        margin-bottom: 0;
    }

    /* Common styling for all content within a section */
    p,
    ul {
        background-color: #f5f5f5;
        margin: 0;
        border-radius: 0;
    }

    /* Hide empty paragraph elements */
    p:empty {
        display: none;
    }

    /* Style for paragraphs */
    p {
        padding: 4px 20px;
        line-height: 1.5;
    }

    /* Style for bold text - general case */
    p b {
        display: inline-block;
        font-weight: 600;
        margin-right: 5px;
    }

    /* Style for bold text when it's the first child in a paragraph */
    p b:first-child {
        font-weight: 700;
        font-size: 16px;
        display: block;
        margin-top: 3px;
        margin-bottom: 2px;
    }

    /* But remove that top margin for the first subheading after an h3 */
    h3 + p b:first-child {
        margin-top: 0;
    }

    /* Adjust spacing after h3 */
    h3 + p {
        padding-top: 12px;
    }

    /* Targeted adjustments for specific elements - by position */
    /* Session Background section - close spacing */
    h3:first-child + p {
        padding-top: 12px;
        padding-bottom: 2px;
    }

    /* Participant Details section */
    h3:first-child + p + p {
        padding-top: 4px;
        padding-bottom: 2px;
    }

    /* Session Details section - last in Session Background */
    h3:first-child + p + p + p {
        padding-top: 4px !important;
        padding-bottom: 20px !important; /* Increased bottom padding */
        border-radius: 0 0 8px 8px !important; /* Ensure rounded corners */
    }

    /* Make sure the Session Details content has rounded corners and good spacing */
    h3:first-child ~ p:nth-of-type(4) {
        padding-bottom: 10px !important;
        border-radius: 0px !important;
    }

    /* Default bottom border radius for other sections */
    h3 ~ p:last-of-type,
    h3 ~ ul:last-of-type,
    p:last-child,
    ul:last-child {
        border-radius: 0 0 8px 8px;
        padding-bottom: 20px;
    }

    /* Override any potential conflicts to ensure bottom rounding */
    *:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    /* Reset border radius for h3 elements */
    h3 {
        border-radius: 8px 8px 0 0;
    }

    /* Direct target for the Session Details paragraph using nth-child */
    /* This is a more specific selector to ensure our styling gets applied */
    h3:first-of-type ~ p:nth-child(5) {
        padding-bottom: 10px !important;
        border-radius: 0 !important;
    }

    /* First level ul styling */
    ul {
        list-style-type: none;
        padding: 0 20px 10px 40px;
    }

    ul:last-of-type {
        padding-bottom: 20px;
    }

    /* Style for list items */
    li {
        line-height: 1.5;
        margin-bottom: 12px;
        padding-left: 0;
        position: relative;
    }

    /* Add bullet points to list items */
    li:before {
        content: '•';
        position: absolute;
        left: -15px;
    }

    li:last-child {
        margin-bottom: 0;
    }

    /* Make bold text inside list items normal weight */
    li b {
        font-weight: 600;
        display: inline;
        margin-top: 0;
    }
`;

interface SummaryTabContentContainerProps {
    cleanSummaryHtml: string;
    stackVideoLayout: boolean;
    withContentMasking: () => Record<string, unknown>;
    selection: MeetingSelection | null;
}

export function SummaryTabContentContainer({
    cleanSummaryHtml,
    stackVideoLayout,
    withContentMasking,
    selection,
}: SummaryTabContentContainerProps): JSX.Element {
    const track = useExperienceTracking();
    const { setToastText } = useGlobalAiDashboard();
    const [isLoading, setIsLoading] = useState(false);

    if (selection && !selection.isEditor && isUnpublishedDraft(selection)) {
        return (
            <Box padding={0}>
                <Box display="flex" flexDirection="column" alignItems="start" justifyContent="center" width="100%">
                    <Box display="flex" alignItems="center" bgcolor="#EEF9F6" p={2} borderRadius={1} mb={2} mt={2}>
                        <InfoOutlined style={{ color: '#4D9F8A', marginRight: '8px' }} />
                        <BodyRegular>
                            This summary is still being edited. You can nudge the editors to publish it.
                        </BodyRegular>
                    </Box>
                    <PrimaryButton
                        title="Nudge editor"
                        isLoading={isLoading}
                        onClick={async () => {
                            setIsLoading(true);
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Nudge Editor',
                                MeetingTitle: selection?.title || '',
                            });

                            try {
                                await postNotifyMeetingParticipants(selection.botId, 'request-publish');
                                setToastText('Publish request sent to editors');
                            } catch (error) {
                                track(ClientEventType.AIDashboardUnhappyPath, {
                                    Error: (error as Error)?.message,
                                    Flow: 'Nudge Editor',
                                });
                                setToastText('Failed to send request. Please try again later.');
                            } finally {
                                setIsLoading(false);
                            }
                        }}
                    />
                    <Spacing />
                </Box>
            </Box>
        );
    }

    // Determine which summary container to use based on meeting type
    let SummaryContainer = DefaultSummaryContainer;

    if (selection?.meetingType === ScribeMeetingType.UserResearchInterview) {
        SummaryContainer = UserResearchSummaryContainer;
    }

    return (
        <Column>
            {cleanSummaryHtml ? (
                <>
                    <SummaryContainer
                        stackLayout={stackVideoLayout}
                        {...withContentMasking()}
                        dangerouslySetInnerHTML={{ __html: cleanSummaryHtml }}
                    />
                </>
            ) : (
                <Row>
                    <BodyRegular>No summary found for this meeting.</BodyRegular>
                </Row>
            )}
        </Column>
    );
}
