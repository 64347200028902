import { Box } from '@material-ui/core';

import { useGlobalVideoAgent } from '../../../..';

export const AgentAgendaBreadcrumbs = () => {
    const { session } = useGlobalVideoAgent();
    const breadCrumbsSizeMultiplier = (session.agenda.topics.length ?? 0) > 13 ? 0.7 : 1;
    return (
        <Box
            flexWrap={'wrap'}
            top="45px"
            zIndex="20"
            display="flex"
            style={{ gap: `${18 * breadCrumbsSizeMultiplier}px` }}
        >
            {session.agenda.topics.map((topic, index) => {
                const isCurrentTopic = session.currentTopicIndex === index;
                const isCompletedTopic = session.currentTopicIndex !== undefined && session.currentTopicIndex > index;

                const backgroundColor = '#00FF00';

                return (
                    <Box
                        key={index}
                        width={`${breadCrumbsSizeMultiplier * 45}px`}
                        height={`${breadCrumbsSizeMultiplier * 45}px`}
                        style={{
                            backgroundColor,
                            boxShadow: isCurrentTopic ? '0 0 50px 8px #FFFFFF' : undefined,
                            opacity: isCurrentTopic ? 1 : isCompletedTopic ? 0.2 : 0.45,
                        }}
                    />
                );
            })}
        </Box>
    );
};
