import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ClientEventType } from '@spinach-shared/types';

import { postAnonymousUser } from '../../apis';
import { useExperienceTracking, useGlobalRouting, useGlobalUser } from '../../hooks';
import { AnonymousUserTracker } from '../../utils';
import { FYI, FYIState } from '../common';

export function TrySpinachContainer(): JSX.Element {
    const [user, setUser] = useGlobalUser();
    const { routeToVerify, routeToAIDashboard } = useGlobalRouting();
    const track = useExperienceTracking();
    const [params] = useSearchParams();
    // const demoForName = params.get(WebUrlQuery.DemoSandboxRecipient);
    // TODO: we may want recaptcha on this sucker

    /**
     * we want to call the create-anon-user api
     * but only if there already isn't a session
     *
     * this should be handled by the routing.
     * if we get null back, then we land on this and create one.
     * if we don't get null back (could be anon or known), we skip this
     */
    useEffect(() => {
        async function execute() {
            // Prevent non-anon users from triggering flow below
            if (user) {
                routeToAIDashboard({ replace: true });
                return;
            }

            const newAnonUser = await postAnonymousUser(params);
            if (!newAnonUser) {
                routeToVerify();

                // is this needed?
                setUser(null);
            } else {
                track(ClientEventType.AnonymousUserCreated);
                setUser(newAnonUser);
                AnonymousUserTracker.trackUser(newAnonUser._id);
                await new Promise((resolve) => setTimeout(resolve, 1000));
                routeToAIDashboard({ replace: true });
            }
        }

        execute();
    }, []);

    return <FYI state={FYIState.Loading} />;
}
