import { CallMade } from '@material-ui/icons';
import styled from 'styled-components';

import { DashboardSection } from '@spinach-shared/constants';
import { ClientEventType } from '@spinach-shared/types';
import { getWebAppHost } from '@spinach-shared/utils';

import { postLogOut } from '../../../../apis/postLogOut';
import { ReactComponent as SpinachLogo } from '../../../../assets/logo-spinachio.svg';
import { ReactComponent as FutureMeetingIcon } from '../../../../assets/sidebar/future-meeting_icon.svg';
import { ReactComponent as HelpCenterIcon } from '../../../../assets/sidebar/help-center_icon.svg';
import { ReactComponent as MeetingHistoryIcon } from '../../../../assets/sidebar/meeting-history_icon.svg';
import { ReactComponent as UploadIcon } from '../../../../assets/sidebar/upload_icon.svg';
import { GlobalModal } from '../../../../atoms';
import {
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalRouting,
    useSidebar,
} from '../../../../hooks';
import { BodyRegular, lightTheme } from '../../../../styles';
import { URLUtil } from '../../../../utils';
import { getClientConfigValue } from '../../../../utils/getClientConfigValue';
import { LoginWithGoogle } from '../../../auth';
import { LoginWithMicrosoft } from '../../../auth/LoginWithMicrosoft';
import { Anchor, Column, Hairline, Spacing } from '../../../common';
import { PrimaryButton } from '../../../stand-up';
import { isSummariesAndTranscriptsSection } from '../utils';
import { NavBarButton } from './NavBarButton';

const SidebarColumn = styled(Column)`
    padding: 20px;
    height: 100%;
    width: calc(100% - 40px);
`;

export function LimitedFeaturedSidebarContent(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const [, setGlobalModal] = useGlobalModal();
    const { routeToScribeOnboarding } = useGlobalRouting();
    const { isDesktopView } = useSidebar();
    const { activeSection, setActiveSection } = useGlobalAiDashboard();

    return (
        <SidebarColumn>
            {isDesktopView ? (
                <SpinachLogo
                    style={{
                        height: isDesktopView ? '35px' : '25px',
                        minHeight: isDesktopView ? '35px' : '25px',
                        cursor: 'pointer',
                        marginLeft: '-35px',
                        marginBottom: '20px',
                        marginTop: '5px',
                    }}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, { ClickedOn: 'Sidebar Logo' });
                        const searchParams = new URLSearchParams(window.location.search);
                        searchParams.append('ref', 'webapp');
                        URLUtil.openURL(`https://www.spinach.ai?${searchParams.toString()}`);
                    }}
                />
            ) : null}

            <Spacing factor={1 / 5} />

            {user.isAnonymous ? (
                <>
                    <BodyRegular
                        style={{
                            fontSize: '18px',
                            fontWeight: 700,
                            color: lightTheme.primary.midnight,
                        }}
                    >
                        Try Spinach Free
                    </BodyRegular>
                    <Spacing factor={1 / 5} />
                    <LoginWithGoogle
                        variant="orange"
                        label="Continue with Google"
                        buttonStyle={{
                            maxWidth: '225px',
                            minWidth: '200px',
                            width: '70vw',
                            justifyContent: 'center',
                            height: '40px',
                            padding: '5px',
                        }}
                    />
                    <Spacing factor={1 / 3} />
                    <LoginWithMicrosoft
                        variant="orange"
                        label="Continue with Microsoft"
                        buttonStyle={{
                            maxWidth: '225px',
                            minWidth: '200px',
                            width: '70vw',
                            justifyContent: 'center',
                            height: '40px',
                            padding: '5px',
                        }}
                    />
                </>
            ) : (
                <>
                    <PrimaryButton
                        title="Try Spinach free"
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Try Spinach free sidebar',
                            });
                            routeToScribeOnboarding();
                        }}
                    />
                </>
            )}

            <Spacing factor={1 / 2} />

            <BodyRegular style={{ fontSize: '14px', color: lightTheme.primary.midnight }}>
                Have an account?{' '}
                <Anchor
                    style={{ textDecoration: 'none' }}
                    onClick={async () => {
                        const response = await postLogOut();
                        if (response.success) {
                            window.location.href = getWebAppHost();
                        }
                    }}
                >
                    Sign in
                </Anchor>
            </BodyRegular>

            <Hairline style={{ width: '70%' }} />

            <NavBarButton
                title="Meeting History"
                startIcon={<MeetingHistoryIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />}
                section={DashboardSection.Summaries}
                activeSection={isSummariesAndTranscriptsSection(activeSection) ? DashboardSection.Summaries : undefined}
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, { ClickedOn: 'Summaries & Transcripts Tab' });
                    setActiveSection(DashboardSection.Summaries);
                }}
            />
            <NavBarButton
                title="Future Meetings"
                startIcon={<FutureMeetingIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />}
                section={DashboardSection.Meetings}
                activeSection={activeSection}
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, { ClickedOn: 'Meetings Tab' });
                    setGlobalModal(GlobalModal.CreateAccount);
                }}
            />

            {user.isEnabledForFileUpload ? (
                <NavBarButton
                    startIcon={<UploadIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />}
                    title="Upload"
                    section={DashboardSection.Upload}
                    activeSection={activeSection}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, { ClickedOn: 'Upload Tab' });
                        setGlobalModal(GlobalModal.CreateAccount);
                    }}
                />
            ) : null}

            <Hairline style={{ width: '70%' }} />

            <NavBarButton
                startIcon={<HelpCenterIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />}
                title="Help Center"
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, { ClickedOn: 'Help Center Link' });
                    URLUtil.openURL(getClientConfigValue('INTERCOM_SUPPORT_URL'));
                }}
                endIcon={<CallMade style={{ fontSize: '16px', marginLeft: '5px' }} htmlColor={'#0000009c'} />}
            />
        </SidebarColumn>
    );
}
