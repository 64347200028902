import { Box, Tooltip } from '@material-ui/core';
import { ArrowBack, InfoOutlined } from '@material-ui/icons';
import { useEffect, useMemo, useState } from 'react';
import React from 'react';
import styled from 'styled-components';

import { DashboardSection } from '@spinach-shared/constants';
import {
    AddScribeToMeetingsTitlesToMatch,
    AgentConfig,
    ClientEventType,
    CreateAgentPayload,
    OutputTargetKind,
    SlackChannelMetadata,
    SpinachWorkflowAction,
    SpinachWorkflowActionKind,
    SpinachWorkflowClientJSON,
    SpinachWorkflowOutputTarget,
    SpinachWorkflowSlackOutputTarget,
    SpinachWorkflowTriggerClientJSON,
    SpinachWorkflowTriggerKind,
    UpdateAgentPayload,
} from '@spinach-shared/types';

import { PrimaryButton, useExperienceTracking, useGlobalAiDashboard, useGlobalAuthedUser } from '../../../..';
import { useGlobalSlack } from '../../../../hooks/useSlack';
import { BodyRegularOnboard, ButtonSize, HeaderThreeOnboard, lightTheme } from '../../../../styles';
import { Column, Row, Spacing } from '../../../common';
import { SpinachSwitch } from '../../../common/SpinachSwitch';
import SecondaryButton from '../../../stand-up/SecondaryButton';
import { SlackChannelConfig, SlackChannelSelection } from '../integrations/SlackChannelSelection';
import { KeywordSelection } from '../meetings/KeywordSelection';

const NumberBullet = styled.div`
    width: 38px;
    height: 38px;
    background-color: #ecf1f0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    margin-right: 16px;
    flex-shrink: 0;
    flex-grow: 0;
`;

const AgentConfigurationContainer = styled.div`
    overflow-y: auto;
    container-type: inline-size;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 32px;

    @media (max-width: 1160px) {
        flex-direction: column;
    }
`;

const HorizontalDivider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${lightTheme.neutrals.grayDark};
`;

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    () => ({
        '& .MuiTooltip-tooltip': {
            backgroundColor: 'transparent',
        },
    })
);

const MeetingConditionButton = styled.span<{ isSelected: boolean }>`
    border-radius: 40px;
    padding: 6px 12px;
    background-color: ${(props) => (props.isSelected ? props.theme.secondary.green : props.theme.neutrals.grayLight)};
    cursor: pointer;
    display: flex;
    justify-content: center;
    color: ${(props) => (props.isSelected ? props.theme.neutrals.white : props.theme.primary.midnight)};
    white-space: nowrap;
    flex: 0 1 auto;

    &:hover {
        background-color: ${(props) =>
            props.isSelected ? props.theme.secondary.greenDark : props.theme.neutrals.gray};
    }
`;

function triggerToMeetingConditionKind(
    trigger?: SpinachWorkflowTriggerClientJSON
): CreateAgentPayload['workflow']['meetingConditions']['trigger'] | null {
    if (!trigger) {
        return null;
    }

    // not supported for now
    if (trigger.kind !== SpinachWorkflowTriggerKind.MeetingComplete) {
        return null;
    }

    if (trigger.conditions && 'isExternalMeeting' in trigger.conditions) {
        if (trigger.conditions.isExternalMeeting === true) {
            return 'external';
        }

        if (trigger.conditions.isExternalMeeting === false) {
            return 'internal';
        }
    }

    if (trigger.conditions && 'keywords' in trigger.conditions) {
        return 'keywords';
    }

    return null;
}

function triggerToKeywords(trigger?: SpinachWorkflowTriggerClientJSON): AddScribeToMeetingsTitlesToMatch[] {
    if (!trigger) {
        return [];
    }

    if (trigger.conditions && 'keywords' in trigger.conditions) {
        return (trigger.conditions.keywords ?? []).map((keyword) => ({ text: keyword, id: keyword }));
    }

    return [];
}

export function AgentConfigurationView({
    agentId,
    agentConfig,
    onBack,
    workflow,
    onCreateAgent,
    onUpdateAgent,
    trigger,
}: {
    agentId: string;
    agentConfig: AgentConfig;
    onBack: () => void;
    workflow?: SpinachWorkflowClientJSON;
    trigger?: SpinachWorkflowTriggerClientJSON;
    onCreateAgent: (payload: CreateAgentPayload) => Promise<void>;
    onUpdateAgent: (payload: UpdateAgentPayload) => Promise<void>;
}): JSX.Element {
    useGlobalSlack();
    const [user] = useGlobalAuthedUser();
    const [toastMessage, setToastMessage] = useState<string | null>(null);
    const track = useExperienceTracking();
    const [isLoading, setIsLoading] = useState(false);
    const isEditingExistingWorkflow = workflow !== undefined;
    const [meetingConditionKind, setMeetingConditionKind] =
        useState<CreateAgentPayload['workflow']['meetingConditions']['trigger']>('all');
    const [keywords, setKeywords] = useState<AddScribeToMeetingsTitlesToMatch[]>([]);
    const [inputActive, setInputActive] = useState(false);
    const [slackEnabled, setSlackEnabled] = useState(
        !!workflow?.outputTargets.find((target) => target.kind === OutputTargetKind.Slack)
    );
    const slackOutputTarget: SpinachWorkflowSlackOutputTarget | undefined = workflow?.outputTargets.find(
        (target) => target.kind === OutputTargetKind.Slack
    ) as SpinachWorkflowSlackOutputTarget | undefined;
    const slackOutputChannel: SlackChannelMetadata | undefined = slackOutputTarget?.config.channel;
    const [existingConfigurationChanged, setExistingConfigurationChanged] = useState(false);

    const [slackChannel, setSlackChannel] = useState<SlackChannelConfig | null>(
        slackOutputChannel
            ? {
                  code: slackOutputChannel.id,
                  label: slackOutputChannel.name ?? '',
                  isPrivate: slackOutputChannel.isPrivate,
              }
            : null
    );
    const [emailEnabled, setEmailEnabled] = useState(
        isEditingExistingWorkflow
            ? !!workflow.outputTargets.find(
                  (target) => target.kind === OutputTargetKind.Email && target.config.to.some((to) => to === user.email)
              )
            : true // default is enabled
    );
    const { setActiveSection } = useGlobalAiDashboard();
    const saveEnabled = useMemo(() => emailEnabled || slackEnabled, [emailEnabled, slackEnabled]);

    function buildActionsAndOutputTargets() {
        const actions: CreateAgentPayload['workflow']['actions'] = [
            {
                kind: SpinachWorkflowActionKind.Prompt,
                promptId: agentId,
            },
        ];

        const outputTargets: SpinachWorkflowOutputTarget[] = [];

        if (emailEnabled) {
            outputTargets.push({
                kind: OutputTargetKind.Email,
                config: {
                    to: [user.email],
                },
            });
        }

        if (slackEnabled) {
            // Determine the Slack channel to use for output
            let channel = null;

            // First priority: Use the selected Slack channel if available
            if (!!slackChannel?.label && !!slackChannel?.code) {
                channel = {
                    id: slackChannel.code,
                    name: slackChannel.label,
                    isPrivate: slackChannel.isPrivate,
                };
            }
            // Second priority: Use the default channel from user settings
            else if (!!user.slackSettings?.defaultChannelId && !!user.slackSettings?.defaultChannelName) {
                channel = {
                    id: user.slackSettings.defaultChannelId,
                    name: user.slackSettings.defaultChannelName,
                    isPrivate: user.slackSettings.isDefaultChannelPrivate,
                };
            }
            // Third priority: Use the user's direct message channel
            else if (!!user.slackSettings?.slackUserId) {
                channel = {
                    id: user.slackSettings.slackUserId,
                    name: user.slackSettings.slackUserId,
                    isPrivate: true,
                };
            }

            if (channel && user.slackSettings?.teamId) {
                outputTargets.push({
                    kind: OutputTargetKind.Slack,
                    config: {
                        channel,
                        teamId: user.slackSettings.teamId,
                        teamType: user.slackSettings.teamType,
                    },
                });
            }
        }

        return { actions, outputTargets };
    }

    function checkKeywordsAreValid(): boolean {
        if (meetingConditionKind === 'keywords' && keywords.length === 0) {
            setToastMessage('Add at least one keyword to enable the Agent');
            return false;
        }
        return true;
    }

    async function triggerAgentUpdate(isActive: boolean, errorMessage: string) {
        try {
            if (!workflow?.id || !trigger?.id) {
                throw new Error('Workflow or trigger ID is missing');
            }
            setIsLoading(true);
            const { actions, outputTargets } = buildActionsAndOutputTargets();
            const payload: UpdateAgentPayload = {
                workflow: {
                    workflowId: workflow.id,
                    triggerId: trigger.id,
                    isActive,
                    actions,
                    outputTargets,
                    meetingConditions: {
                        trigger: meetingConditionKind,
                        triggerKeywords: keywords.map((keyword) => keyword.text),
                    },
                },
            };
            await onUpdateAgent(payload);
        } catch (e) {
            setToastMessage(errorMessage);
            track(ClientEventType.AIDashboardUnhappyPath, {
                Flow: 'Agents',
                Error: errorMessage,
                AgentName: agentConfig.name,
                Section: 'Agents',
                ErrorMessage: e instanceof Error ? e.message : 'Unknown error',
            });
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (trigger) {
            setMeetingConditionKind(triggerToMeetingConditionKind(trigger) ?? 'all');
            setKeywords(triggerToKeywords(trigger));
        }
    }, [trigger]);

    return (
        <Box
            height="94%"
            display="flex"
            flexDirection="column"
            marginY="32px"
            marginX="0"
            overflow="hidden"
            justifyContent="flex-start"
            paddingRight="32px"
        >
            <SecondaryButton
                style={{
                    textDecoration: 'none',
                    alignItems: 'center',
                    display: 'flex',
                    color: lightTheme.primary.greenLight,
                }}
                title="Back to Agents"
                size={ButtonSize.Normal}
                preIcon={<ArrowBack style={{ marginRight: '8px' }} />}
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Back to Agents',
                        AgentName: agentConfig.name,
                        Section: 'Agents',
                    });
                    onBack();
                }}
            />
            <Spacing factor={1 / 2} />
            <HeaderThreeOnboard style={{ alignSelf: 'flex-start' }}>{agentConfig.name}</HeaderThreeOnboard>
            <Spacing factor={1 / 2} />
            <AgentConfigurationContainer>
                <Column style={{ flex: 1 }}>
                    {agentConfig.about.length > 0 ? (
                        <>
                            <BodyRegularOnboard style={{ fontWeight: 700 }}>About this Agent</BodyRegularOnboard>
                            <Spacing factor={1 / 4} />
                            <ul style={{ paddingTop: 0, margin: 0 }}>
                                {agentConfig.about.map((about) => (
                                    <li key={about}>
                                        <BodyRegularOnboard>{about}</BodyRegularOnboard>
                                    </li>
                                ))}
                            </ul>
                        </>
                    ) : null}
                    {agentConfig.output ? (
                        <>
                            <Spacing factor={1 / 2} />
                            <Row
                                style={{
                                    background: '#ECF1F0',
                                    borderRadius: '8px',
                                    padding: '30px',
                                    boxSizing: 'border-box',
                                }}
                            >
                                <div
                                    style={{
                                        background: 'white',
                                        borderRadius: '8px',
                                        padding: '16px',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                    }}
                                >
                                    <BodyRegularOnboard
                                        style={{ margin: 0, textAlign: 'left', fontWeight: 700, fontSize: 16 }}
                                    >
                                        {agentConfig.output.header}
                                    </BodyRegularOnboard>
                                    {agentConfig.output.sections.map((section) => (
                                        <React.Fragment key={section.header}>
                                            <Spacing factor={1 / 2} />
                                            <BodyRegularOnboard
                                                style={{ margin: 0, textAlign: 'left', fontWeight: 700, fontSize: 16 }}
                                            >
                                                {section.header}
                                            </BodyRegularOnboard>
                                            <Spacing factor={1 / 4} />
                                            <BodyRegularOnboard style={{ textAlign: 'left', fontSize: 14 }}>
                                                {section.description}
                                            </BodyRegularOnboard>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </Row>
                        </>
                    ) : null}
                </Column>
                <Column style={{ flex: 1 }}>
                    <div
                        style={{
                            border: `1px solid ${lightTheme.neutrals.grayDark}`,
                            borderRadius: '8px',
                            padding: '13px 24px',
                        }}
                    >
                        <BodyRegularOnboard style={{ fontWeight: 700 }}>Configure</BodyRegularOnboard>
                        <Spacing factor={1 / 2} />
                        <HorizontalDivider />
                        <Spacing factor={1 / 2} />
                        <Row>
                            <Column style={{ flex: 0 }}>
                                <NumberBullet>1</NumberBullet>
                            </Column>
                            <Column style={{ flex: 1 }}>
                                <Row>
                                    <BodyRegularOnboard style={{ fontWeight: 600 }}>
                                        Which meetings do you want the agent to analyze
                                    </BodyRegularOnboard>
                                    <CustomTooltip
                                        enterTouchDelay={0}
                                        leaveTouchDelay={15000}
                                        title={
                                            <div
                                                style={{
                                                    flexDirection: 'column',
                                                    backgroundColor: 'black',
                                                    padding: '7px 24px',
                                                }}
                                            >
                                                <BodyRegularOnboard style={{ color: 'white' }}>
                                                    Spinach must be present in the meeting for this Agent to analyze it.
                                                </BodyRegularOnboard>
                                            </div>
                                        }
                                    >
                                        <InfoOutlined
                                            style={{
                                                color: lightTheme.primary.greenLight,
                                                marginLeft: '10px',
                                                fontSize: '24px',
                                            }}
                                        />
                                    </CustomTooltip>
                                </Row>
                                <Spacing factor={1 / 4} />
                                <div
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: '8px',
                                    }}
                                >
                                    <MeetingConditionButton
                                        isSelected={meetingConditionKind === 'all'}
                                        onClick={() => {
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: 'Agents - All Meetings',
                                                AgentName: agentConfig.name,
                                                Section: 'Agents',
                                            });
                                            setMeetingConditionKind('all');
                                            setExistingConfigurationChanged(true);
                                        }}
                                    >
                                        All meetings
                                    </MeetingConditionButton>
                                    <MeetingConditionButton
                                        isSelected={meetingConditionKind === 'internal'}
                                        onClick={() => {
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: 'Agents - Internal Meetings',
                                                AgentName: agentConfig.name,
                                                Section: 'Agents',
                                            });
                                            setMeetingConditionKind('internal');
                                            setExistingConfigurationChanged(true);
                                        }}
                                    >
                                        Internal only
                                    </MeetingConditionButton>
                                    <MeetingConditionButton
                                        isSelected={meetingConditionKind === 'external'}
                                        onClick={() => {
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: 'Agents - External Meetings',
                                                AgentName: agentConfig.name,
                                                Section: 'Agents',
                                            });
                                            setMeetingConditionKind('external');
                                            setExistingConfigurationChanged(true);
                                        }}
                                    >
                                        External only
                                    </MeetingConditionButton>
                                    <MeetingConditionButton
                                        isSelected={meetingConditionKind === 'keywords'}
                                        onClick={() => {
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: 'Agents - Keywords Meetings',
                                                AgentName: agentConfig.name,
                                                Section: 'Agents',
                                            });
                                            setMeetingConditionKind('keywords');
                                            setExistingConfigurationChanged(true);
                                        }}
                                    >
                                        Meetings with keywords
                                    </MeetingConditionButton>
                                </div>
                                <Spacing factor={1 / 2} />
                                {meetingConditionKind === 'keywords' ? (
                                    <>
                                        <BodyRegularOnboard>
                                            Meetings titles with any of these keywords will be analyzed:
                                        </BodyRegularOnboard>
                                        <Spacing factor={1 / 4} />
                                        <KeywordSelection
                                            styleOverrides={{ flexDirection: 'row', flexWrap: 'wrap' }}
                                            newTagStyleOverrides={{ marginTop: '10px', marginBottom: '10px' }}
                                            inputActive={inputActive}
                                            setInputActive={setInputActive}
                                            setKeywords={setKeywords}
                                            keywords={keywords}
                                            onSave={() => {
                                                track(ClientEventType.AIDashboardClick, {
                                                    ClickedOn: 'Save Keywords',
                                                    Keywords: keywords.map((keyword) => keyword.text).join(', '),
                                                    AgentName: agentConfig.name,
                                                    Section: 'Agents',
                                                });
                                                setExistingConfigurationChanged(true);
                                            }}
                                        />
                                    </>
                                ) : null}
                            </Column>
                        </Row>
                        <Spacing factor={1 / 2} />
                        <HorizontalDivider />

                        <Spacing factor={1 / 2} />
                        <Row>
                            <Column style={{ flex: 0 }}>
                                <NumberBullet>2</NumberBullet>
                            </Column>
                            <Column style={{ flex: 1 }}>
                                <BodyRegularOnboard style={{ fontWeight: 600 }}>
                                    Where would you like the information to be sent?
                                </BodyRegularOnboard>
                                <Spacing factor={1 / 4} />

                                <Row vCenter>
                                    <SpinachSwitch
                                        checked={emailEnabled}
                                        onChange={async () => {
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: 'Agent - Email Me',
                                                AgentName: agentConfig.name,
                                                EmailEnabled: emailEnabled,
                                                Section: 'Agents',
                                            });
                                            setEmailEnabled(!emailEnabled);
                                            setExistingConfigurationChanged(true);
                                        }}
                                    />
                                    <BodyRegularOnboard
                                        style={{
                                            marginLeft: '10px',
                                        }}
                                    >
                                        Email me
                                    </BodyRegularOnboard>
                                </Row>
                                <Spacing factor={1 / 4} />
                                <Row vCenter>
                                    <SpinachSwitch
                                        checked={slackEnabled}
                                        onChange={async () => {
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: 'Agent - Send to Slack',
                                                AgentName: agentConfig.name,
                                                SlackEnabled: slackEnabled,
                                                Section: 'Agents',
                                            });
                                            setSlackEnabled(!slackEnabled);
                                            setExistingConfigurationChanged(true);
                                        }}
                                    />
                                    <BodyRegularOnboard
                                        style={{
                                            marginLeft: '10px',
                                        }}
                                    >
                                        Send to Slack channel
                                    </BodyRegularOnboard>
                                </Row>
                                {slackEnabled ? (
                                    <>
                                        <Spacing factor={1 / 4} />
                                        {user.isAuthedForSlack ? (
                                            <Row vCenter>
                                                <BodyRegularOnboard>Channel:</BodyRegularOnboard>
                                                <SlackChannelSelection
                                                    from="CommunicationsIntegrationSection"
                                                    selectedChannel={
                                                        slackChannel?.code === user.slackSettings?.slackUserId // don't show DM channel
                                                            ? null
                                                            : slackChannel
                                                    }
                                                    setSelectedChannel={setSlackChannel}
                                                    updateChannel={async (channel) => {
                                                        track(ClientEventType.AIDashboardClick, {
                                                            ClickedOn: 'Agent - Slack Channel Select',
                                                            AgentName: agentConfig.name,
                                                            SlackChannelName: channel?.label,
                                                            Section: 'Agents',
                                                        });
                                                        setExistingConfigurationChanged(true);
                                                    }}
                                                />
                                            </Row>
                                        ) : (
                                            <Row vCenter>
                                                <InfoOutlined
                                                    style={{
                                                        color: lightTheme.secondary.orangeLight,
                                                        marginRight: '10px',
                                                        fontSize: '18px',
                                                    }}
                                                />
                                                <Row vCenter>
                                                    <BodyRegularOnboard>Connect Slack in </BodyRegularOnboard>
                                                    <SecondaryButton
                                                        title="Settings"
                                                        size={ButtonSize.Normal}
                                                        onClick={() => {
                                                            setActiveSection(DashboardSection.Integrations);
                                                        }}
                                                        style={{
                                                            display: 'inline-block',
                                                            marginLeft: '8px',
                                                        }}
                                                    />
                                                </Row>
                                            </Row>
                                        )}
                                    </>
                                ) : null}
                            </Column>
                        </Row>
                        <Spacing factor={1 / 2} />
                        <HorizontalDivider />
                        {toastMessage ? (
                            <>
                                <Spacing factor={1 / 4} />
                                <Row
                                    style={{
                                        backgroundColor: 'rgba(250, 162, 30, 0.15)',
                                        padding: '8px',
                                        textAlign: 'center',
                                        color: 'black',
                                        borderRadius: '4px',
                                    }}
                                    vCenter
                                >
                                    <BodyRegularOnboard style={{ margin: 'auto', fontSize: '14px' }}>
                                        {toastMessage}
                                    </BodyRegularOnboard>
                                </Row>
                            </>
                        ) : null}
                        <Spacing factor={1 / 2} />
                        <Row centered>
                            {isEditingExistingWorkflow ? (
                                <Column centered>
                                    {existingConfigurationChanged && workflow?.isActive ? (
                                        <>
                                            <PrimaryButton
                                                disabled={!saveEnabled}
                                                isLoading={isLoading}
                                                size={ButtonSize.Normal}
                                                title={'Update Agent'}
                                                onClick={async () => {
                                                    track(ClientEventType.AIDashboardClick, {
                                                        ClickedOn: 'Agent - Update Agent',
                                                        AgentName: agentConfig.name,
                                                        Section: 'Agents',
                                                    });
                                                    if (!checkKeywordsAreValid()) {
                                                        return;
                                                    }
                                                    await triggerAgentUpdate(
                                                        true,
                                                        'Issue updating agent. Please try again later'
                                                    );
                                                }}
                                            />
                                            <Spacing factor={1 / 2} />
                                        </>
                                    ) : null}
                                    {workflow?.isActive ? (
                                        <SecondaryButton
                                            style={{
                                                textDecoration: 'none',
                                            }}
                                            size={ButtonSize.Normal}
                                            title={'Disable Agent'}
                                            onClick={async () => {
                                                track(ClientEventType.AIDashboardClick, {
                                                    ClickedOn: 'Agent - Disable Agent',
                                                    AgentName: agentConfig.name,
                                                    Section: 'Agents',
                                                });
                                                await triggerAgentUpdate(
                                                    false,
                                                    'Issue disabling agent. Please try again later'
                                                );
                                            }}
                                        />
                                    ) : (
                                        <PrimaryButton
                                            disabled={!saveEnabled}
                                            isLoading={isLoading}
                                            size={ButtonSize.Normal}
                                            title={'Enable Agent'}
                                            onClick={async () => {
                                                track(ClientEventType.AIDashboardClick, {
                                                    ClickedOn: 'Agent - Enable Agent',
                                                    AgentName: agentConfig.name,
                                                    Section: 'Agents',
                                                });

                                                if (!checkKeywordsAreValid()) {
                                                    return;
                                                }

                                                await triggerAgentUpdate(
                                                    true,
                                                    'Issue enabling agent. Please try again later'
                                                );
                                            }}
                                        />
                                    )}
                                </Column>
                            ) : (
                                <PrimaryButton
                                    disabled={!saveEnabled}
                                    isLoading={isLoading}
                                    size={ButtonSize.Normal}
                                    title={'Enable Agent'}
                                    onClick={async () => {
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: 'Agent - Enable Agent',
                                            AgentName: agentConfig.name,
                                            Section: 'Agents',
                                        });
                                        if (!checkKeywordsAreValid()) {
                                            return;
                                        }
                                        setIsLoading(true);
                                        const { actions, outputTargets } = buildActionsAndOutputTargets();
                                        const payload: CreateAgentPayload = {
                                            workflow: {
                                                actions,
                                                outputTargets,
                                                meetingConditions: {
                                                    trigger: meetingConditionKind,
                                                    triggerKeywords: keywords.map((keyword) => keyword.text),
                                                },
                                            },
                                        };

                                        try {
                                            await onCreateAgent(payload);
                                        } catch (e) {
                                            setToastMessage('Issue enabling agent. Please try again later');
                                            track(ClientEventType.AIDashboardUnhappyPath, {
                                                Flow: 'Agents',
                                                Error: 'Issue enabling agent',
                                                AgentName: agentConfig.name,
                                                Section: 'Agents',
                                                ErrorMessage: e instanceof Error ? e.message : 'Unknown error',
                                            });
                                        }
                                        setIsLoading(false);
                                    }}
                                />
                            )}
                        </Row>
                    </div>
                </Column>
            </AgentConfigurationContainer>
        </Box>
    );
}
