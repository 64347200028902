import { useCallback, useEffect, useState } from 'react';

import { useGlobalAuthedUser } from '../../../../hooks/useGlobalUser';
import { ClientLogger } from '../../../../utils';
import { LocalRecording } from './RecordingsStorageService';
import { createDownloadableBlob, downloadBlob, formatDate } from './audioUtils';
import { useRecordingsStorage } from './useRecordingsStorage';

export function useLocalRecordings() {
    const [recordings, setRecordings] = useState<LocalRecording[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const { getAllRecordings, deleteRecording } = useRecordingsStorage();
    const [user] = useGlobalAuthedUser();

    const fetchRecordings = useCallback(async () => {
        setIsLoading(true);
        try {
            const fetchedRecordings = await getAllRecordings();
            setRecordings(fetchedRecordings);
        } catch (error) {
            ClientLogger.error('Failed to fetch local recordings', { error, spinachUserId: user?._id });
        } finally {
            setIsLoading(false);
        }
    }, [getAllRecordings, user?._id]);

    useEffect(() => {
        fetchRecordings();
    }, [fetchRecordings]);

    const downloadRecording = useCallback(
        async (recording: LocalRecording) => {
            try {
                // Use the recording's mimeType or fall back to 'audio/webm'
                const blob = createDownloadableBlob(recording.chunks, recording.mimeType || 'audio/webm');

                // Determine file extension based on mimeType
                const fileExtension = recording.mimeType?.includes('mp4') ? 'mp4' : 'webm';
                const fileName = `${recording.name || 'Recording'}_${formatDate(recording.createdAt)}.${fileExtension}`;

                downloadBlob(blob, fileName);
                return true;
            } catch (error) {
                ClientLogger.error('Failed to download recording', {
                    error,
                    recordingId: recording.id,
                    spinachUserId: user?._id,
                });
                return false;
            }
        },
        [user?._id]
    );

    const deleteRecordingAndUpdate = useCallback(
        async (recordingId: string) => {
            try {
                const success = await deleteRecording(recordingId);
                if (success) {
                    // Update the recordings list after successful deletion
                    setRecordings((prevRecordings) =>
                        prevRecordings.filter((recording) => recording.id !== recordingId)
                    );
                }
                return success;
            } catch (error) {
                ClientLogger.error('Failed to delete recording', {
                    error,
                    recordingId,
                    spinachUserId: user?._id,
                });
                return false;
            }
        },
        [deleteRecording, user?._id]
    );

    return {
        recordings,
        isLoading,
        downloadRecording,
        deleteRecording: deleteRecordingAndUpdate,
        refreshRecordings: fetchRecordings,
    };
}
