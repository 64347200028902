import { useEffect } from 'react';

import { getAutoBrandingUrl } from '../apis/getAutoBrandingUrl';
import { ClientLogger } from '../utils';
import { useApolloLogoUrl } from './useApolloLogoUrl';

export function useApolloLogoUrlFetcher() {
    const [, setApolloLogoUrl] = useApolloLogoUrl();

    useEffect(() => {
        async function fetchApolloLogoUrl() {
            try {
                const url = await getAutoBrandingUrl();
                setApolloLogoUrl(url);
            } catch (error) {
                ClientLogger.error('Failed to fetch Apollo logo URL', error);
            }
        }

        fetchApolloLogoUrl();
    }, []);
}
