import { ReactNode } from 'react';

// keep the values short as they are persisted on the user's metadata
export enum ShowcaseGuideSlideId {
    AddSpinachToYourMeeting = 'asm',
    LetUsInFromTheWaitingRoom = 'lwt',
    ReviewYourMeeting = 'rym',
    RestartTrial = 'rst',
    DiscoverSpinachAi = 'dsa',
}

export enum ShowcaseGuideCtaTarget {
    Meetings = 'meetings',
    Settings = 'settings',
    RestartTrial = 'restart-trial',
    DiscoverSpinachAi = 'discover-spinach-ai',
}

export interface ShowcaseSlide {
    id: ShowcaseGuideSlideId;
    title: string;
    description: NonNullable<ReactNode>;
    ctaLabel: string;
    ctaTarget?: ShowcaseGuideCtaTarget;
    showPrimaryCta?: boolean;
}

export interface ShowcasePacket {
    title: string;
    slides: ShowcaseSlide[];
    isMinimizeable: boolean;
}

export interface ShowcaseGuideProps {
    packet: ShowcasePacket;
    className?: string;
}
