import { Box } from '@material-ui/core';
import { Lock } from '@material-ui/icons';

import { formatTime } from '@spinach-shared/utils';

import { BodyRegular, BodyRegularOnboard, Column, HeaderTwo, Row, useGlobalVideoAgent, useTimer } from '../../../..';
import { ReactComponent as SpinachLongWhite } from '../../../assets/s-logo.svg';
import { AgentAgendaBreadcrumbs } from './AgentAgendaBreadcrumbs';
import { AgentAgendaNextTopic } from './AgentAgendaNextTopic';

function RecordingAndNoteTaking(): JSX.Element {
    const { session } = useGlobalVideoAgent();
    return (
        <>
            {!session.isPaused ? (
                <>
                    <Row vCenter={true} centered={true}>
                        <Box
                            component="span"
                            display="inline-block"
                            sx={{
                                width: 20,
                                height: 20,
                                bgcolor: session.isPaused ? '#FB1C29' : '#A7FF46',
                                borderRadius: '50%',
                                marginRight: '10px',
                            }}
                        />
                        <BodyRegularOnboard style={{ color: 'white', fontSize: '30px' }}>
                            Note taking in progress
                        </BodyRegularOnboard>
                    </Row>
                    <BodyRegularOnboard style={{ color: 'white', fontSize: '30px' }}>
                        Type <b>pause</b> to stop recording
                    </BodyRegularOnboard>
                </>
            ) : (
                <>
                    <Row vCenter={true} centered={true}>
                        <Box
                            component="span"
                            display="inline-block"
                            sx={{
                                width: 20,
                                height: 20,
                                bgcolor: session.isPaused ? '#FB1C29' : '#A7FF46',
                                borderRadius: '50%',
                                marginRight: '10px',
                            }}
                        />
                        <BodyRegularOnboard style={{ color: '#FB1C29', fontSize: '30px' }}>
                            Recording paused
                        </BodyRegularOnboard>
                    </Row>
                    <BodyRegularOnboard style={{ color: 'white', fontSize: '30px' }}>
                        Type <b>resume</b> to continue
                    </BodyRegularOnboard>
                </>
            )}
        </>
    );
}

function LogoAndAgendaSection(): JSX.Element {
    const { session } = useGlobalVideoAgent();

    return (
        <Column style={{ alignItems: 'center', gap: '40px', width: '100%', height: '100%', justifyContent: 'center' }}>
            <Box
                bgcolor={'transparent'}
                borderRadius={16}
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={230}
            >
                {!session.shouldShowFrontAndCenterSpinachLogo && session.raw.accountLogoUrl ? (
                    <img
                        src={session.raw.accountLogoUrl}
                        alt="Company Logo"
                        style={{
                            height: '100%',
                            maxWidth: '100%',
                            objectFit: 'contain',
                            borderRadius: '4px',
                        }}
                    />
                ) : (
                    <SpinachLongWhite style={{ width: '110%', transform: 'scale(2.0)' }} />
                )}
            </Box>
            <Column centered={true}>
                <RecordingAndNoteTaking />
            </Column>
        </Column>
    );
}

export function NoAgendaContent(): JSX.Element {
    return (
        <PrimarySlideContent>
            <div style={{ flexGrow: 1 }} />
            <LogoAndAgendaSection />
            <div style={{ flexGrow: 1 }} />
        </PrimarySlideContent>
    );
}

export function OverallMeetingContent(): JSX.Element {
    const { session } = useGlobalVideoAgent();
    const timer = useTimer(session?.participants[0]?.firstJoinedAt);
    return (
        <Box display="flex" flexDirection="column" alignItems="flex-start" width={'100%'} style={{ marginTop: '30px' }}>
            <Row style={{ justifyContent: 'space-between' }}>
                <Box
                    style={{
                        fontSize: '24px',
                        color: 'white',
                        marginBottom: '8px',
                    }}
                >
                    <HeaderTwo
                        style={{
                            color: 'white',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: '650px',
                        }}
                    >
                        {session.raw.meetingTitle || ''}
                    </HeaderTwo>
                </Box>
                <Box
                    style={{
                        fontSize: '20px',
                        color: 'white',
                    }}
                >
                    <HeaderTwo style={{ color: 'white', minWidth: '130px', textAlign: 'left' }}>
                        {formatTime(timer)}
                    </HeaderTwo>
                </Box>
            </Row>
            <Row style={{ justifyContent: 'space-between', minHeight: '45px' }}>
                <AgentAgendaBreadcrumbs />

                <div style={{ flexGrow: 1 }} />
                <Box style={{ color: 'white', alignItems: 'center', display: 'flex', gap: '8px', flexShrink: 0 }}>
                    <Lock />{' '}
                    <BodyRegular style={{ color: 'white', fontSize: '22px' }}>All data is encrypted</BodyRegular>
                </Box>
            </Row>
        </Box>
    );
}

export function PrimarySlideContent({ children }: { children: React.ReactNode }): JSX.Element {
    const { session } = useGlobalVideoAgent();
    return (
        <Box
            position="absolute"
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-start"
        >
            <OverallMeetingContent />
            <Box
                bgcolor="#000000"
                borderRadius={24}
                height={490}
                padding={6}
                paddingBottom={0}
                paddingTop={0}
                width="110%"
                maxWidth={1000}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                style={{ marginTop: '20px' }}
            >
                {children}
                <AgentAgendaNextTopic />
            </Box>
        </Box>
    );
}

export function PoweredBySpinachSection(): JSX.Element {
    return (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
            <BodyRegular style={{ color: 'white', fontSize: '24px' }}>Powered by</BodyRegular>
            <Box>
                <SpinachLongWhite style={{ height: '30px', marginLeft: '-65px', marginRight: '-70px' }} />
            </Box>
        </Box>
    );
}
