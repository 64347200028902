import { useLocation } from 'react-router-dom';

import { isLocalStage } from '@spinach-shared/utils';

import { getAgentSearchParams } from '../../../..';
import { defaultLocalScribeServer, mockBotId, mockSeriesId, mockToken } from '../../../constants';
import { useSentry } from '../../../hooks/useStartupProcesses';
import { FYI, FYIState } from '../FYI';
import { VideoAgentContainer } from './VideoAgentContainer';

export function VideoAgent(): JSX.Element {
    useSentry();

    const { search } = useLocation();
    let { seriesId, scribeServer, token, botId } = getAgentSearchParams(search);

    if (isLocalStage()) {
        seriesId = seriesId || mockSeriesId;
        botId = botId || mockBotId;
        token = token || mockToken;
        scribeServer = scribeServer || defaultLocalScribeServer;

        return <VideoAgentContainer seriesId={seriesId} botId={botId} token={token} />;
    }

    if (!seriesId) {
        return <FYI state={FYIState.Error} header="missing series id" />;
    }

    if (!botId) {
        return <FYI state={FYIState.Error} header="missing bot id" />;
    }

    if (!token) {
        return <FYI state={FYIState.Error} header="missing token" />;
    }

    if (!scribeServer) {
        return <FYI state={FYIState.Error} header="missing server" />;
    }

    return <VideoAgentContainer seriesId={seriesId} botId={botId} token={token} />;
}
