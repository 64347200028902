import { SpinachAPIPath, SpinachWorkflowClientJSON, SpinachWorkflowTriggerClientJSON } from '@spinach-shared/types';

import { getSpinachAPI } from '../getSpinachAPI';

export async function fetchSpinachWorkflows(): Promise<{
    workflows: SpinachWorkflowClientJSON[];
    triggers: SpinachWorkflowTriggerClientJSON[];
}> {
    const response = await getSpinachAPI<{
        workflows: SpinachWorkflowClientJSON[];
        triggers: SpinachWorkflowTriggerClientJSON[];
    }>(SpinachAPIPath.UserWorkflows);
    return {
        workflows: response?.workflows ?? [],
        triggers: response?.triggers ?? [],
    };
}
