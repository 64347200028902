import { SpinachAPIPath } from '@spinach-shared/types';

import { postSpinachAPI } from './postSpinachAPI';

type NotifyIntent = 'request-access' | 'request-publish';

interface NotifyMeetingParticipantsRequest {
    botId: string;
    notifyIntent: NotifyIntent;
}

interface NotifyMeetingParticipantsResponse {
    message: string;
}

/**
 * Sends a notification to meeting participants based on the specified intent
 * @param botId The ID of the meeting
 * @param notifyIntent The type of notification to send ('request-access' or 'request-publish')
 * @returns A promise that resolves to the response or undefined if there was an error
 */
export async function postNotifyMeetingParticipants(
    botId: string,
    notifyIntent: NotifyIntent
): Promise<NotifyMeetingParticipantsResponse | undefined> {
    return postSpinachAPI<NotifyMeetingParticipantsResponse, NotifyMeetingParticipantsRequest>(
        SpinachAPIPath.NotifyMeetingParticipants,
        {
            botId,
            notifyIntent,
        }
    );
}
