import { useGlobalAuthedUser, useWindowSize } from './index';
import { useGlobalStoredSeriesList } from './useGlobalStoredSeriesList';

interface SidebarDimensions {
    appBarWidth: number;
    sidebarWidth: number;
    fullSidebarWidth: number;
    userShouldSeeBothApps: boolean;
    isDesktopView: boolean;
}

export function useSidebar(): SidebarDimensions {
    const {
        storedSeriesListState: { storedSeriesList },
    } = useGlobalStoredSeriesList();
    const { isDesktopView } = useWindowSize();
    const [user] = useGlobalAuthedUser();

    const hasLegacySeries = storedSeriesList.filter((s) => !s.isDemoSeries).some((s) => !s.isScribeSeries);

    const userShouldSeeBothApps =
        (hasLegacySeries || user.isForcedLegacyOnboarding) &&
        !user.isHidingAiDashboard &&
        !user.isHidingStandupAppDashboard;

    const appBarWidth = 68;
    const sidebarWidth = 267;
    const fullSidebarWidth = userShouldSeeBothApps ? appBarWidth + sidebarWidth : sidebarWidth;

    return {
        appBarWidth,
        sidebarWidth,
        fullSidebarWidth,
        userShouldSeeBothApps,
        isDesktopView,
    };
}
