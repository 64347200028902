import { Box, CircularProgress, Modal, TextField } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useEffect, useMemo, useState } from 'react';

import { demoBotId } from '@spinach-shared/constants';
import { AiHistoryUserAccessKind, ClientEventType } from '@spinach-shared/types';
import { getSummaryDeepLink, isEmailValid } from '@spinach-shared/utils';

import {
    BodyRegularOnboard,
    BodySubtitle,
    ClientPath,
    Column,
    DropDown,
    ErrorBodySubtitle,
    GlobalModal,
    GlobalModalMetadataType,
    ListItemValue,
    Notification,
    PrimaryButton,
    ResponsiveModalTitle,
    Row,
    ScrollArea,
    Spacing,
    SpinachModalContent,
    copyTextToClipboard,
    lightTheme,
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalModalState,
    useSpinachInputStyles,
    withAccessibleSubmitProps,
    withContentMasking,
} from '../../../..';
import { patchGiveMeetingNotesAccess } from '../../../apis/postGiveMeetingNotesAccess';
import { ItemList } from '../ItemList';

export function ShareAiHistoryModalContainer(): JSX.Element {
    const [globalModal] = useGlobalModal();

    if (globalModal !== GlobalModal.ShareAiHistory) {
        return <></>;
    }

    return <ShareAiHistoryModal />;
}

export function ShareAiHistoryModal(): JSX.Element {
    const globalModalState = useGlobalModalState();
    const [user] = useGlobalAuthedUser();
    const [, setGlobalModal] = useGlobalModal();
    const { setToastText } = useGlobalAiDashboard();
    const [isLoading, setIsLoading] = useState(false);
    const [isLinkCopied, setIsLinkCopied] = useState(false);
    const [indexBeingRemoved, setIndexBeingRemoved] = useState<number | null>(null);

    const track = useExperienceTracking();

    let usersWithHistoryAccess: { email?: string; kind: AiHistoryUserAccessKind }[] = useMemo(() => [], []);
    let botId: string;
    let seriesId: string;
    let meetingTitle: string | undefined;
    if (globalModalState.metadata?.metadataType === GlobalModalMetadataType.ShareAiHistory) {
        usersWithHistoryAccess = globalModalState.metadata.usersWithHistoryAccess;
        botId = globalModalState.metadata.botId;
        seriesId = globalModalState.metadata.seriesId;
        meetingTitle = globalModalState.metadata.meetingTitle;
    }

    const [userMetadataList, setUserMetadatList] = useState(usersWithHistoryAccess);
    const [numberOfUsersAdded, setNumberOfUsersAdded] = useState(0);

    const [newEmailInvite, setNewEmailInvite] = useState('');

    const classes = useSpinachInputStyles({ value: newEmailInvite });
    const hasAnyoneWithLinkCanView = usersWithHistoryAccess.some(
        (user) => user.kind === AiHistoryUserAccessKind.AnyoneWithLink
    );
    const [anyoneWithLinkCanView, setAnyoneWithLinkCanView] = useState(hasAnyoneWithLinkCanView);

    useEffect(() => {
        setUserMetadatList(usersWithHistoryAccess);
    }, [usersWithHistoryAccess]);

    const newEmailInvites = newEmailInvite
        .split(',')
        .map((email) => email.trim())
        .filter((email) => !!email);

    const historyHasUserWithSameEmail = !!userMetadataList.find((s) => s.email && newEmailInvites.includes(s.email));

    const MAX_EMAILS = 20 * 1000;

    const lengthError =
        newEmailInvites.length + userMetadataList.length > MAX_EMAILS ? 'You have exceeded the shared limit' : '';

    const error = historyHasUserWithSameEmail
        ? 'User is already added'
        : newEmailInvites.some((newEmail) => !isEmailValid(newEmail)) && !!newEmailInvite
        ? 'Invalid email'
        : '';

    const closeModal = () => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Close AiHistory Invite Modal',
            BotId: botId,
            NumberOfUsersAdded: numberOfUsersAdded,
        });
        setGlobalModal(null, {});
    };

    const onSubmitInvite = async () => {
        try {
            track(ClientEventType.AIDashboardClick, {
                ClickedOn: 'Submit AiHistory Email Invite',
                BotId: botId,
                NewEmailInvites: newEmailInvites.join(', '),
                UsersWithExistingHistoryAccess: usersWithHistoryAccess.map((u) => u.email).join(', '),
            });
            setIsLoading(true);
            const updatedAiHistory = await patchGiveMeetingNotesAccess(botId, [
                ...userMetadataList,
                ...newEmailInvites.map((e) => ({ email: e, kind: AiHistoryUserAccessKind.Shared })),
            ]);
            setNumberOfUsersAdded(numberOfUsersAdded + newEmailInvites.length);

            if (updatedAiHistory) {
                setUserMetadatList(updatedAiHistory.usersWithHistoryAccess);
            }

            setNewEmailInvite('');
        } catch (e) {
            setToastText('Only people on the original meeting invite can share.');
        } finally {
            setIsLoading(false);
        }
    };

    const onToggleAnyoneWithLinkCanView = async (selection: 'restricted' | 'anyone_with_link') => {
        try {
            track(ClientEventType.AIDashboardClick, {
                ClickedOn: 'Toggle Anyone With Link Can View',
                BotId: botId,
                UsersWithExistingHistoryAccess: usersWithHistoryAccess.map((u) => u.email).join(', '),
            });
            setIsLoading(true);
            const updatedAiHistory = await patchGiveMeetingNotesAccess(botId, [
                ...userMetadataList,
                { kind: AiHistoryUserAccessKind.AnyoneWithLink },
            ]);

            if (updatedAiHistory) {
                setUserMetadatList(updatedAiHistory.usersWithHistoryAccess);
            }
            setAnyoneWithLinkCanView(selection === 'anyone_with_link');
        } catch (e) {
            setToastText('Only people on the original meeting invite can share.');
        } finally {
            setIsLoading(false);
        }
    };

    const onRemoveUserClick = async (email: string) => {
        try {
            track(ClientEventType.AIDashboardClick, {
                ClickedOn: 'Remove User From AIHistory Invite',
                BotId: botId,
                EmailToRemove: email,
                UsersWithExistingHistoryAccess: usersWithHistoryAccess.map((u) => u.email).join(', '),
                NumberOfExistingUsersAttendedMeeting: usersWithHistoryAccess.reduce(
                    (acc, curr) => (curr.kind === AiHistoryUserAccessKind.Attended ? (acc += 1) : acc),
                    0
                ),
                NumberOfExistingUsersInvitedToHistory: usersWithHistoryAccess.reduce(
                    (acc, curr) => (curr.kind === AiHistoryUserAccessKind.Shared ? (acc += 1) : acc),
                    0
                ),
            });
            const updatedAiHistory = await patchGiveMeetingNotesAccess(
                botId,
                userMetadataList.filter((userMeta) => userMeta.email !== email)
            );

            if (updatedAiHistory) {
                setUserMetadatList(updatedAiHistory.usersWithHistoryAccess);
            }
        } catch (e) {
            setToastText('Failed to remove user');
        } finally {
            setTimeout(() => {
                setIndexBeingRemoved(null);
            }, 200);
        }
    };

    const listItemValues: ListItemValue<string, JSX.Element>[] = userMetadataList
        .filter(
            (userMeta): userMeta is { email: string; kind: AiHistoryUserAccessKind } =>
                userMeta.kind !== AiHistoryUserAccessKind.AnyoneWithLink
        )
        .map((userMeta, index) => {
            const allUserEmails = [user.email, ...user.linkedUserEmails];
            const isThisUser = allUserEmails.includes(userMeta.email);
            return {
                code: userMeta.email,
                label: (
                    <Row style={{ width: 'fit-content' }} vCenter>
                        <BodyRegularOnboard>
                            {userMeta.email} {isThisUser ? '(You)' : ''}
                        </BodyRegularOnboard>
                        <Spacing horizontal factor={1 / 3} />
                        {isThisUser ? null : userMeta.kind === AiHistoryUserAccessKind.Attended ? (
                            <BodySubtitle style={{ color: lightTheme.primary.greenLight }}>attended</BodySubtitle>
                        ) : (
                            <BodySubtitle style={{ color: lightTheme.primary.greenLight }}>invited</BodySubtitle>
                        )}
                    </Row>
                ),
                postContent:
                    !isThisUser && userMeta.kind !== AiHistoryUserAccessKind.Attended ? (
                        indexBeingRemoved === index ? (
                            <CircularProgress size={'30px'} style={{ color: lightTheme.primary.orangeDark }} />
                        ) : (
                            <span
                                key={userMeta.email}
                                style={{
                                    cursor: 'pointer',
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                {...withAccessibleSubmitProps(() => {
                                    setIndexBeingRemoved(index);
                                    onRemoveUserClick(userMeta.email);
                                })}
                            >
                                <HighlightOffIcon htmlColor="gray" />
                            </span>
                        )
                    ) : null,
            };
        });

    if (globalModalState.metadata?.metadataType !== GlobalModalMetadataType.ShareAiHistory) {
        return <></>;
    }

    if (globalModalState.modal !== GlobalModal.ShareAiHistory) {
        return <></>;
    }

    if (globalModalState.metadata.botId === demoBotId) {
        return <DemoMeetingShareModal />;
    }

    return (
        <Modal open={globalModalState.modal === GlobalModal.ShareAiHistory} onClose={closeModal}>
            <SpinachModalContent onClose={closeModal} style={{ overflow: 'hidden', minHeight: '300px' }}>
                <Column centered>
                    <Row centered>
                        <ResponsiveModalTitle>
                            <b>Share</b>
                        </ResponsiveModalTitle>
                    </Row>
                    <Spacing factor={1 / 3} />
                    <Row centered>
                        <BodyRegularOnboard style={{ fontSize: '16px', fontWeight: 500 }}>
                            Add users below to enable them to access the meeting summary, transcript and video.
                        </BodyRegularOnboard>
                    </Row>

                    <Spacing />

                    <Row style={{ flex: 'unset' }}>
                        <TextField
                            InputProps={{ classes: { root: classes.base } }}
                            {...withContentMasking(classes.root)}
                            fullWidth
                            placeholder="Add by email"
                            value={newEmailInvite}
                            onKeyDown={async (e) => {
                                if (e.key === 'Enter') {
                                    await onSubmitInvite();
                                }
                            }}
                            onChange={(e) => {
                                setNewEmailInvite(e.target.value.toLowerCase());
                            }}
                        />

                        <PrimaryButton
                            title="Add"
                            isLoading={isLoading}
                            loadingText={''}
                            onClick={onSubmitInvite}
                            disabled={!!error || !!lengthError || !newEmailInvite.trim()}
                        />
                    </Row>
                    <ErrorBodySubtitle>{lengthError || error}</ErrorBodySubtitle>

                    <Spacing factor={1 / 3} />
                    {user.isEnabledForAnyoneWithLinkCanView ? (
                        <Row style={{ alignItems: 'end' }}>
                            <Column style={{ width: '100%', alignItems: 'start' }}>
                                <BodyRegularOnboard style={{ fontSize: '18px', textAlign: 'center', fontWeight: 600 }}>
                                    General access
                                </BodyRegularOnboard>
                                <Row>
                                    <DropDown
                                        title="General access"
                                        values={[
                                            { label: 'Restricted', code: 'restricted' },
                                            { label: 'Anyone with the link', code: 'anyone_with_link' },
                                        ]}
                                        selected={anyoneWithLinkCanView ? 'Anyone with the link' : 'Restricted'}
                                        handleSelection={(selection) => {
                                            onToggleAnyoneWithLinkCanView(selection);
                                        }}
                                        disabled={isLoading}
                                    />

                                    {isLoading ? (
                                        <Box style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
                                            <CircularProgress size={'20px'} />
                                        </Box>
                                    ) : null}
                                </Row>
                            </Column>
                            <PrimaryButton
                                title="Copy Link"
                                style={{ height: '40px' }}
                                onClick={() => {
                                    copyTextToClipboard(getSummaryDeepLink({ botId, seriesId }), undefined, true);
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Copy Share Link',
                                        BotId: botId,
                                        SeriesId: seriesId,
                                    });
                                    setIsLinkCopied(true);
                                }}
                            />
                        </Row>
                    ) : (
                        <Row>
                            <PrimaryButton
                                title="Copy Link"
                                onClick={() => {
                                    copyTextToClipboard(getSummaryDeepLink({ botId, seriesId }), undefined, true);
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Copy Share Link',
                                        BotId: botId,
                                        SeriesId: seriesId,
                                    });
                                    setIsLinkCopied(true);
                                }}
                            />
                        </Row>
                    )}

                    <Spacing />

                    <Row>
                        <BodyRegularOnboard style={{ fontSize: '18px', fontWeight: 600 }}>
                            People with access to this summary
                        </BodyRegularOnboard>
                    </Row>
                    <Row>
                        {hasAnyoneWithLinkCanView ? (
                            <BodySubtitle style={{ color: '#707070', alignItems: 'center', display: 'flex' }}>
                                Anyone with the link can access.
                            </BodySubtitle>
                        ) : (
                            <BodySubtitle style={{ color: '#707070', alignItems: 'center', display: 'flex' }}>
                                Only people in the list can access the summary.
                            </BodySubtitle>
                        )}
                    </Row>
                    <ScrollArea style={{ height: '30vh', maxHeight: '250px' }} sidePadding={0}>
                        <ItemList style={{ borderBottom: '1px solid lightgray' }} values={listItemValues} />
                    </ScrollArea>
                    <Spacing />

                    <Notification
                        containerStyle={{ bottom: '-10px', position: 'absolute' }}
                        isOpen={isLinkCopied}
                        message="Link Copied!"
                        onClose={() => setIsLinkCopied(false)}
                    />
                </Column>
            </SpinachModalContent>
        </Modal>
    );
}

const DemoMeetingShareModal = () => {
    const [isLinkCopied, setIsLinkCopied] = useState(false);
    const globalModalState = useGlobalModalState();
    const track = useExperienceTracking();
    const [, setGlobalModal] = useGlobalModal();
    const closeModal = () => {
        setGlobalModal(null, {});
    };

    return (
        <Modal open={globalModalState.modal === GlobalModal.ShareAiHistory} onClose={closeModal}>
            <SpinachModalContent onClose={closeModal} style={{ overflow: 'hidden', minHeight: '150px' }}>
                <Column centered>
                    <Row centered>
                        <ResponsiveModalTitle>Share</ResponsiveModalTitle>
                    </Row>
                    <Spacing factor={1 / 3} />
                    <Row centered>
                        <BodyRegularOnboard style={{ fontSize: '16px', textAlign: 'center', fontWeight: 500 }}>
                            You can share meeting notes with people that weren't on the meeting
                        </BodyRegularOnboard>
                    </Row>

                    <Spacing factor={1} />
                    <Row centered>
                        <PrimaryButton
                            title="Copy Link"
                            onClick={() => {
                                copyTextToClipboard(location.origin + ClientPath.Sample, undefined, true);
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: 'Copy Demo Share Link',
                                });
                                setIsLinkCopied(true);
                            }}
                        />
                    </Row>

                    <Notification
                        containerStyle={{ bottom: '-10px', position: 'relative' }}
                        isOpen={isLinkCopied}
                        message="Link Copied!"
                        onClose={() => setIsLinkCopied(false)}
                    />
                </Column>
            </SpinachModalContent>
        </Modal>
    );
};
