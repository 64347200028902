import { Box } from '@material-ui/core';
import { CheckCircle, InfoOutlined } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';

import {
    AgentConfig,
    ClientEventType,
    SpinachWorkflowActionKind,
    SpinachWorkflowClientJSON,
} from '@spinach-shared/types';

import { useExperienceTracking } from '../../../..';
import { BodyRegularOnboard, ButtonSize, HeaderThreeOnboard, lightTheme } from '../../../../styles';
import { BetaFeatureTag, BootstrapTooltip, Column, Row, Spacing } from '../../../common';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import { PrimaryButton } from '../../../stand-up/PrimaryButton';

const AgentItemContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 27px;
    border: 1px solid #e5e5ea;
    border-radius: 6px;
    width: 100%;
    box-sizing: border-box;
`;

const AgentInfoContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

const AgentDetails = styled.div`
    display: flex;
    flex-direction: column;
`;

const AgentDescription = styled(BodyRegularOnboard)`
    font-size: 14px;
`;

const AgentName = styled(BodyRegularOnboard)`
    font-weight: 700;
    font-size: 16px;
`;

const HowItWorksContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    border: 1px solid #E5E5EA;
    border-radius: 6px;
    background-color: #F6F8F8;
    margin-bottom: 16px;
    width: 100%;
    box-sizing: border-box;
`;

export function getWorkflowForAgent(
    agentId: string,
    workflows: SpinachWorkflowClientJSON[]
): SpinachWorkflowClientJSON | undefined {
    return workflows.find((w) =>
        w.actions.find((action) => action.kind === SpinachWorkflowActionKind.Prompt && action.promptId === agentId)
    );
}

function AgentItem({
    agent,
    isEnabled,
    onConfigure,
}: {
    agent: AgentConfig;
    isEnabled: boolean;
    onConfigure: () => void;
}) {
    const track = useExperienceTracking();
    return (
        <AgentItemContainer>
            <AgentInfoContainer>
                <img src={agent.iconUrl} alt={agent.name} width="48" height="48" />
                <AgentDetails>
                    <Row vCenter>
                        <AgentName>{agent.name}</AgentName>
                        {isEnabled ? (
                            <>
                                <CheckCircle
                                    style={{
                                        color: lightTheme.primary.greenLight,
                                        fontSize: '16px',
                                        marginLeft: '16px',
                                    }}
                                />
                                <AgentName style={{ color: lightTheme.primary.greenLight, marginLeft: '8px' }}>
                                    Enabled
                                </AgentName>
                            </>
                        ) : null}
                    </Row>
                    <Spacing factor={1 / 4} />
                    <AgentDescription>{agent.description}</AgentDescription>
                </AgentDetails>
            </AgentInfoContainer>
            {isEnabled ? (
                <OutlinedButton
                    size={ButtonSize.Normal}
                    title={'Configure'}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Configure Agent',
                            AgentName: agent.name,
                            Section: 'Agents',
                        });
                        onConfigure();
                    }}
                />
            ) : (
                <PrimaryButton
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Customize Agent',
                            AgentName: agent.name,
                            Section: 'Agents',
                        });
                        onConfigure();
                    }}
                    title="Customize Agent"
                />
            )}
        </AgentItemContainer>
    );
}

export function AgentsDirectoryView({
    enabledAgents,
    workflows,
    onConfigure,
    toastMessage,
}: {
    enabledAgents: Record<string, AgentConfig>;
    workflows: SpinachWorkflowClientJSON[];
    onConfigure: (agentId: string, workflow?: SpinachWorkflowClientJSON) => void;
    toastMessage: string | null;
}): JSX.Element {
    return (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
            overflow="hidden"
            justifyContent="flex-start"
            paddingRight="32px"
            style={{ overflowY: 'auto', margin: '32px 0 0 0' }}
        >
            <HowItWorksContainer>
                <Row vCenter>
                    <BodyRegularOnboard style={{ fontWeight: 700 }}>
                        How it works
                    </BodyRegularOnboard>
                    <BootstrapTooltip
                        title="Complementing your meeting summaries, Agents deliver specialized insights to email or Slack. Select which Agents to run, when they activate, and where they send results—handling routine tasks while you focus on meaningful conversations."
                        placement="top"
                    >
                        <InfoOutlined 
                            style={{ 
                                fontSize: '16px', 
                                marginLeft: '5px',
                                color: lightTheme.primary.greenLight 
                            }} 
                        />
                    </BootstrapTooltip>
                </Row>
                <Spacing factor={1/4} />
                <BodyRegularOnboard>
                    Complementing your meeting summaries, Agents deliver specialized insights to email or Slack. Select which Agents to run, when they activate, and where they send results—handling routine tasks while you focus on meaningful conversations.
                </BodyRegularOnboard>
            </HowItWorksContainer>
            <Row vCenter>
                <HeaderThreeOnboard style={{ alignSelf: 'flex-start' }}>Your Agents</HeaderThreeOnboard>
                <span style={{ marginLeft: '5px' }}>
                    <BetaFeatureTag />
                </span>
            </Row>
            <Spacing factor={1 / 2} />
            <BodyRegularOnboard>Automate your workflow with specialized Agents for the way you work</BodyRegularOnboard>
            <Spacing factor={1 / 2} />
            {toastMessage ? (
                <>
                    <Row
                        style={{
                            backgroundColor: 'rgba(53, 162, 137, 0.15)',
                            padding: '8px',
                            textAlign: 'center',
                            color: 'black',
                            borderRadius: '4px',
                        }}
                        vCenter
                        centered
                    >
                        <CheckCircle style={{ marginRight: '8px', color: lightTheme.primary.greenLight }} />
                        <BodyRegularOnboard style={{ fontSize: '14px', color: lightTheme.primary.green }}>
                            {toastMessage}
                        </BodyRegularOnboard>
                    </Row>
                    <Spacing factor={1 / 2} />
                </>
            ) : null}
            <Column style={{ width: '100%' }}>
                {Object.entries(enabledAgents)
                    .sort(([, agentA], [, agentB]) => agentB.sortingScore - agentA.sortingScore)
                    .map(([agentId, agent]) => {
                        if (agent.isHidden) {
                            return null;
                        }
                        const workflow = getWorkflowForAgent(agentId, workflows);
                        return (
                            <React.Fragment key={agentId}>
                                <AgentItem
                                    agent={agent}
                                    isEnabled={!!workflow?.isActive}
                                    onConfigure={() => {
                                        onConfigure(agentId, workflow);
                                    }}
                                />
                                <Spacing factor={1 / 2} />
                            </React.Fragment>
                        );
                    })}
            </Column>
        </Box>
    );
}
