import { ReactNode } from 'react';

import { DashboardSection } from '@spinach-shared/constants';

import { lightTheme } from '../../../../styles';
import { Row } from '../../../common';
import SecondaryButton from '../../../stand-up/SecondaryButton';

interface NavBarButtonProps {
    title: Omit<ReactNode, 'number' | 'boolean' | 'null' | 'undefined'>;
    section?: DashboardSection;
    activeSection?: DashboardSection;
    onClick: () => void;
    endIcon?: JSX.Element;
    startIcon: JSX.Element;
}

export function NavBarButton({
    title,
    section,
    activeSection,
    onClick,
    endIcon,
    startIcon,
}: NavBarButtonProps): JSX.Element {
    return (
        <Row>
            <Row
                style={{
                    marginTop: '1px',
                    marginBottom: '1px',
                    borderRadius: '8px',
                    padding: '5px 8px',
                    width: 'calc(100% - 7px)',
                    backgroundColor: section && activeSection === section ? '#DBEAE7' : 'transparent',
                    transition: 'background-color 0.2s ease',
                    cursor: 'pointer',
                    alignItems: 'center',
                }}
                onMouseOver={(e) => {
                    if (!(section && activeSection === section)) {
                        e.currentTarget.style.backgroundColor = 'rgba(219, 234, 231, 0.6)';
                    }
                }}
                onMouseOut={(e) => {
                    if (!(section && activeSection === section)) {
                        e.currentTarget.style.backgroundColor = 'transparent';
                    }
                }}
                onClick={onClick}
            >
                {startIcon}
                <SecondaryButton
                    title={title}
                    buttonStyles={{ textDecoration: 'none', display: 'flex' }}
                    labelStyles={{
                        fontSize: '15px',
                        fontWeight: 600,
                        display: 'flex',
                        width: '100%',
                        textAlign: 'left',
                    }}
                    color={lightTheme.primary.midnight}
                    onClick={onClick}
                    endIcon={endIcon}
                />
            </Row>
        </Row>
    );
}
