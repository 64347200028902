import { Modal } from '@material-ui/core';
import { ChevronLeft, InfoOutlined } from '@material-ui/icons';
import { EmbeddedCheckout } from '@stripe/react-stripe-js';
import { EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { CheckoutBillingScope, ClientEventType, PlanType, ProPlanType } from '@spinach-shared/types';

import {
    Anchor,
    BodyRegularOnboard,
    ClientLogger,
    GlobalModal,
    HeaderThreeOnboard,
    LoadingSquares,
    Row,
    Spacing,
    getClientConfigValue,
    lightTheme,
    postCreateCheckoutSessionV2,
    useClickTracking,
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalModalState,
} from '../../../..';
import { CheckoutModalMetadata, GlobalModalMetadataType } from '../../../atoms';
import { SpinachModalContent } from '../../../components/common/SpinachModalContent';
import { ProAndBusinessPlanSelectorV2 } from '../../spinach-ai/dashboard/AccountManagement/ProAndBusinessPlanSelectorV2';

let stripePromise: Promise<Stripe | null> | null = null;
const ModalContentContainer = styled.div`
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    padding-top: 0;
    padding-left: 46px;
    padding-right: 46px;
    box-sizing: border-box;
`;

const StyledSpinachModalContent = styled(SpinachModalContent)`
    box-sizing: border-box;
    overflow: hidden;
    min-height: 810px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 1213px;
    max-width: 1213px;

    @media (max-width: 1220px) {
        width: 830px;
        max-width: 830px;
    }

    @media (max-width: 860px) {
        width: 383px;
        max-width: 383px;
    }
`;

function CheckoutModalContent({ closeModal, metadata }: { closeModal: () => void; metadata: CheckoutModalMetadata }) {
    const [user] = useGlobalAuthedUser();
    const [selectedPlanType, setSelectedPlanType] = useState<PlanType>(
        !!metadata.skipPlanSelectionStep ? metadata.selectedPlanType : 'pro'
    );
    const [proPlanScope, setProPlanScope] = useState<CheckoutBillingScope.Team | CheckoutBillingScope.Company>(
        !!metadata.skipPlanSelectionStep
            ? metadata.proPlanScope
            : user.isPersonal
            ? CheckoutBillingScope.Team
            : CheckoutBillingScope.Company
    );
    const [selectedProPlan, setSelectedProPlan] = useState<ProPlanType>(
        !!metadata.skipPlanSelectionStep ? metadata.selectedProPlan : 'payAsYouGo'
    );
    const [clientSecret, setClientSecret] = useState<string | null>(null);
    const [isStripeInitialized, setIsStripeInitialized] = useState(false);
    const [modalStep, setModalStep] = useState<
        'loading-plan-selection' | 'error' | 'plan-selection' | 'loading-checkout' | 'checkout'
    >(!!metadata.skipPlanSelectionStep ? 'loading-checkout' : 'loading-plan-selection');
    const track = useExperienceTracking();
    const [selectedBundle, setSelectedBundle] = useState(
        !!metadata.skipPlanSelectionStep ? metadata.selectedBundle : '100'
    );

    const [shouldCreateCheckoutSession, setShouldCreateCheckoutSession] = useState(!!metadata.skipPlanSelectionStep);

    useEffect(() => {
        async function initializeStripe() {
            if (isStripeInitialized) {
                return;
            }

            try {
                const apiKey = getClientConfigValue('REACT_APP_STRIPE_PUBLIC_API_KEY') as string;

                stripePromise = loadStripe(apiKey);
                setIsStripeInitialized(true);
                setModalStep('plan-selection');
            } catch (e: any) {
                ClientLogger.error('Failed to load stripe', { errorMessage: e.message });
                track(ClientEventType.AIDashboardActivity, {
                    Activity: 'Failed to load stripe on Checkout Modal',
                    Section: 'Checkout Modal',
                });
                setModalStep('error');
                return;
            }
        }

        initializeStripe();
    }, [setIsStripeInitialized, setModalStep, isStripeInitialized]);

    useEffect(() => {
        if (!shouldCreateCheckoutSession || !isStripeInitialized) {
            return;
        }

        setModalStep('loading-checkout');

        const createCheckoutSession = async () => {
            try {
                setClientSecret(null);
                const planScope = selectedPlanType === 'pro' ? proPlanScope : CheckoutBillingScope.Team; // business plans are always team scoped

                const response = await postCreateCheckoutSessionV2({
                    planType: selectedPlanType,
                    proPlanType: selectedPlanType === 'pro' ? selectedProPlan : undefined,
                    bundle: selectedPlanType === 'pro' && selectedProPlan === 'bundles' ? selectedBundle : undefined,
                    planScope,
                });
                if (!!response?.clientSecret) {
                    setClientSecret(response.clientSecret);
                    setModalStep('checkout');
                } else {
                    throw new Error('Failed to get client secret from response');
                }
            } catch (e: any) {
                ClientLogger.error('Failed to create checkout session', { errorMessage: e.message });
                track(ClientEventType.AIDashboardUnhappyPath, {
                    Activity: 'Failed to Create Checkout Session',
                    Flow: 'Checkout Modal',
                    Error: e.message,
                });
                setModalStep('error');
            } finally {
                setShouldCreateCheckoutSession(false);
            }
        };

        createCheckoutSession();
    }, [
        shouldCreateCheckoutSession,
        isStripeInitialized,
        selectedPlanType,
        proPlanScope,
        selectedProPlan,
        selectedBundle,
    ]);

    if (modalStep === 'loading-plan-selection' || modalStep === 'loading-checkout') {
        return (
            <div>
                <LoadingSquares />
            </div>
        );
    }

    if (modalStep === 'checkout') {
        return (
            <ModalContentContainer>
                <Spacing factor={1} />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        width: '100%',
                    }}
                >
                    <Spacing factor={1 / 2} />
                    <Row vCenter>
                        <ChevronLeft
                            style={{
                                fontSize: '22px',
                                fontWeight: 700,
                                color: lightTheme.primary.greenLight,
                                marginLeft: '-7px',
                                transform: 'translateY(2px)',
                            }}
                        />
                        <Anchor
                            style={{
                                textDecoration: 'none',
                                fontSize: '16px',
                                fontWeight: 700,
                                color: lightTheme.primary.greenLight,
                            }}
                            onClick={() => {
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: 'Back to Plans',
                                    Section: 'Checkout Modal',
                                });
                                if (metadata.skipPlanSelectionStep) {
                                    closeModal();
                                } else {
                                    setModalStep('plan-selection');
                                    setClientSecret(null);
                                }
                            }}
                        >
                            back to plans
                        </Anchor>
                        <BodyRegularOnboard style={{ fontSize: '18px', fontWeight: 700, margin: '0 10px' }}>
                            |
                        </BodyRegularOnboard>
                        <BodyRegularOnboard style={{ fontSize: '18px', fontWeight: 700 }}>
                            {selectedPlanType === 'pro'
                                ? `Pro (${
                                      selectedProPlan === 'payAsYouGo' ? 'Pay-As-You-Go' : `${selectedBundle} hours`
                                  })`
                                : 'Business'}
                            {selectedPlanType === 'pro'
                                ? proPlanScope === CheckoutBillingScope.Company
                                    ? ' - Company'
                                    : ' - Team'
                                : ''}
                        </BodyRegularOnboard>
                    </Row>
                    <Spacing factor={1 / 2} />
                    <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
                        <EmbeddedCheckout className="spinach-checkout" />
                    </EmbeddedCheckoutProvider>
                </div>
            </ModalContentContainer>
        );
    }

    if (modalStep === 'error') {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: 600,
                    textAlign: 'center',
                }}
            >
                <InfoOutlined style={{ color: lightTheme.tertiary.midnight, fontSize: 40 }} />
                <Spacing factor={1 / 2} />
                <HeaderThreeOnboard>Something went wrong</HeaderThreeOnboard>
                <Spacing factor={1 / 2} />
                <BodyRegularOnboard style={{ fontSize: 18, lineHeight: '27px' }}>
                    {`Looks like we ran into an issue. Refresh your browser, and try again. If that doesn't resolve the issue, we'd love to fix it. Use the Intercom icon at the bottom right to get instant support.`}
                </BodyRegularOnboard>
            </div>
        );
    }

    if (modalStep === 'plan-selection') {
        return (
            <ModalContentContainer className="checkout-modal-content">
                <Spacing factor={1} />
                <ProAndBusinessPlanSelectorV2
                    selectedPlanType={selectedPlanType}
                    setSelectedPlanType={setSelectedPlanType}
                    selectedProPlan={selectedProPlan}
                    setSelectedProPlan={setSelectedProPlan}
                    onCreateCheckoutSession={() => {
                        setShouldCreateCheckoutSession(true);
                    }}
                    proPlanScope={proPlanScope}
                    setProPlanScope={setProPlanScope}
                    selectedBundle={selectedBundle}
                    setSelectedBundle={setSelectedBundle}
                />
            </ModalContentContainer>
        );
    }

    return null;
}

export function CheckoutModal(): JSX.Element {
    const [globalModal, setGlobalModal] = useGlobalModal();
    const { modal, metadata } = useGlobalModalState();
    const clickTracking = useClickTracking();

    if (modal !== GlobalModal.Checkout || metadata?.metadataType !== GlobalModalMetadataType.Checkout) {
        return <></>;
    }

    const closeModal = () => {
        clickTracking(ClientEventType.AIDashboardClick, 'Close Checkout Modal');
        setGlobalModal(null);
    };

    if (globalModal !== GlobalModal.Checkout) {
        return <></>;
    }
    return (
        <Modal open={globalModal === GlobalModal.Checkout} onClose={closeModal}>
            <StyledSpinachModalContent onClose={closeModal}>
                <CheckoutModalContent closeModal={closeModal} metadata={metadata} />
            </StyledSpinachModalContent>
        </Modal>
    );
}
