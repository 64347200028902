import { Modal } from '@material-ui/core';

import { ClientEventType } from '@spinach-shared/types';
import { getWebAppHost } from '@spinach-shared/utils';

import { PrimaryButton, postLogOut, useGlobalAuthedUser, useGlobalRouting } from '../../../..';
import { GlobalModal } from '../../../atoms';
import { useExperienceTracking, useGlobalModal } from '../../../hooks';
import { BodyRegular, HeaderThree } from '../../../styles';
import { LoginWithGoogle } from '../../auth';
import { LoginWithMicrosoft } from '../../auth/LoginWithMicrosoft';
import { SpinachModalContent } from '../SpinachModalContent';
import { Column, Spacing } from '../framing';

export function CreateAccountModal(): JSX.Element {
    const [openModal, setOpenModal] = useGlobalModal();
    const track = useExperienceTracking();
    const [user] = useGlobalAuthedUser();
    const { routeToScribeOnboarding } = useGlobalRouting();
    const header = user.isAnonymous ? 'Create an account' : 'Try Spinach free';

    return (
        <Modal
            open={openModal === GlobalModal.CreateAccount}
            onClose={() => {
                track(ClientEventType.AIDashboardClick, {
                    ClickedOn: 'Close create account modal',
                });
                setOpenModal(null);
            }}
        >
            <SpinachModalContent
                onClose={() => setOpenModal(null)}
                style={{
                    minWidth: '250px',
                    height: 'auto',
                    maxWidth: '600px',
                    width: '70vw',
                    minHeight: 'unset',
                    overflow: 'initial',
                }}
            >
                <Column>
                    <BodyRegular
                        style={{
                            color: '#E86C3A',
                            fontWeight: 600,
                            textTransform: 'uppercase',
                            fontSize: '14px',
                            letterSpacing: '0.5px',
                            marginBottom: '12px',
                        }}
                    >
                        Unlock all features
                    </BodyRegular>
                    <HeaderThree style={{ fontSize: '32px', marginBottom: '16px' }}>{header}</HeaderThree>
                    <BodyRegular
                        style={{
                            textAlign: 'center',
                            fontSize: '18px',
                            lineHeight: '1.5',
                            marginBottom: '32px',
                            maxWidth: '420px',
                        }}
                    >
                        Receive <b>unlimited summaries</b> from your meeting for <b>free</b>. You're a few steps away
                        from accessing all of Spinach AI's powerful features.
                    </BodyRegular>
                    {user.isAnonymous ? (
                        <>
                            <LoginWithGoogle
                                variant="orange"
                                label="Continue with Google"
                                buttonStyle={{
                                    maxWidth: '360px',
                                    minWidth: '200px',
                                    width: '70vw',
                                    justifyContent: 'center',
                                }}
                            />
                            <Spacing factor={1 / 2} />
                            <LoginWithMicrosoft
                                variant="orange"
                                label="Continue with Microsoft"
                                buttonStyle={{
                                    maxWidth: '360px',
                                    minWidth: '200px',
                                    width: '70vw',
                                    justifyContent: 'center',
                                }}
                            />
                        </>
                    ) : (
                        <PrimaryButton
                            title="Setup your account"
                            onClick={() => {
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: 'Try Spinach free create account modal',
                                });
                                routeToScribeOnboarding();
                                setOpenModal(null);
                            }}
                        />
                    )}
                    <Spacing factor={1 / 2} />
                    <BodyRegular style={{ textAlign: 'center' }}>No credit card required</BodyRegular>
                    <Spacing factor={2} />
                    <BodyRegular style={{ textAlign: 'center' }}>
                        Have an account?{' '}
                        <span
                            style={{
                                color: '#2E7D32',
                                cursor: 'pointer',
                                textDecoration: 'none',
                            }}
                            onClick={async () => {
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: 'Sign in from create account modal',
                                });
                                const response = await postLogOut();
                                if (response.success) {
                                    window.location.href = getWebAppHost();
                                }
                            }}
                        >
                            Sign in
                        </span>
                    </BodyRegular>
                </Column>
            </SpinachModalContent>
        </Modal>
    );
}
