import { useCallback, useEffect, useRef } from 'react';

import { useGlobalAuthedUser } from '../../../../hooks/useGlobalUser';
import { ClientLogger } from '../../../../utils';
import { LocalRecording, RecordingsStorageService } from './RecordingsStorageService';

export function useRecordingsStorage() {
    const [user] = useGlobalAuthedUser();

    const recordingsStorageService = useRef<RecordingsStorageService>(new RecordingsStorageService());

    // Initialize IndexedDB
    useEffect(() => {
        const currentRecordingService = recordingsStorageService.current;
        currentRecordingService.initDB().catch((error) => {
            ClientLogger.error('Failed to initialize IndexedDB', { error, spinachUserId: user?._id });
        });
        return () => {
            currentRecordingService.dispose().catch((error) => {
                ClientLogger.error('Failed to dispose IndexedDB', { error, spinachUserId: user?._id });
            });
        };
    }, [user?._id]);

    // Save a recording to IndexedDB
    const saveRecording = useCallback(
        async (recording: LocalRecording): Promise<string | null> => {
            try {
                return await recordingsStorageService.current.saveRecording(recording);
            } catch (error) {
                ClientLogger.error('Failed to save recording to IndexedDB', {
                    error,
                    recordingId: recording.id,
                    spinachUserId: user?._id,
                });
                return null;
            }
        },
        [user?._id]
    );

    // Get all local recordings
    const getAllRecordings = useCallback(async (): Promise<LocalRecording[]> => {
        try {
            return await recordingsStorageService.current.getAllRecordings();
        } catch (error) {
            ClientLogger.error('Failed to get local recordings', { error, spinachUserId: user?._id });
            return [];
        }
    }, [user?._id]);

    // Delete a local recording
    const deleteRecording = useCallback(
        async (id: string): Promise<boolean> => {
            try {
                await recordingsStorageService.current.deleteRecording(id);
                return true;
            } catch (error) {
                ClientLogger.error('Failed to delete local recording', {
                    error,
                    recordingId: id,
                    spinachUserId: user?._id,
                });
                return false;
            }
        },
        [user?._id]
    );

    // Get a specific recording
    const getRecording = useCallback(
        async (id: string): Promise<LocalRecording | null> => {
            try {
                return await recordingsStorageService.current.getRecording(id);
            } catch (error) {
                ClientLogger.error('Failed to get recording', { error, recordingId: id, spinachUserId: user?._id });
                return null;
            }
        },
        [user?._id]
    );

    // Update a recording
    const updateRecording = useCallback(
        async (recording: Partial<LocalRecording> & { id: string }): Promise<boolean> => {
            try {
                await recordingsStorageService.current.updateRecording(recording);
                return true;
            } catch (error) {
                ClientLogger.error('Failed to update recording', {
                    error,
                    recordingId: recording.id,
                    spinachUserId: user?._id,
                });
                return false;
            }
        },
        [user?._id]
    );

    return {
        saveRecording,
        getAllRecordings,
        deleteRecording,
        getRecording,
        updateRecording,
    };
}
