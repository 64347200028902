import { Box } from '@material-ui/core';
import { useEffect, useRef } from 'react';

import { useGlobalVideoAgent } from '../../../hooks/agent/useGlobalVideoAgent';
import { AboutToMatchIndicator } from './AboutToMatchIndicator';
import { DiagonalBox } from './DiagonalBox';
import { useVideoOpacity } from './VideoPlayerComponent';

export const IntroComponent = ({
    playingIntro,
    speaking,
    send,
    aboutToMatch,
    wakeWordDetected,
    unknown,
}: {
    playingIntro: boolean;
    speaking: boolean;
    send: any;
    aboutToMatch: boolean;
    wakeWordDetected: boolean;
    unknown: boolean;
}) => {
    const {
        state: { session, config },
    } = useGlobalVideoAgent();
    const videoRef = useRef<HTMLVideoElement>(null);
    const loopVideoRef = useRef<HTMLVideoElement>(null);
    const opacity = useVideoOpacity(videoRef);

    useEffect(() => {
        if (playingIntro) {
            const video = videoRef.current;
            if (video) {
                video.play();
            }
        }
    }, [playingIntro]);

    useEffect(() => {
        if (loopVideoRef.current) {
            loopVideoRef.current.play();
        }
    }, []);

    if (session?.isPendingIntro || playingIntro) {
        return (
            <Box display="flex" flexDirection="column" alignItems="center" height="100vh">
                {session?.isPendingIntro && !playingIntro ? (
                    <Box
                        style={{
                            display: 'span',
                            textAlign: 'center',
                            fontSize: '75px',
                            color: 'white',
                            marginTop: '75px',
                            transition: 'opacity 0.5s ease-in-out',
                            zIndex: 100,
                        }}
                    >
                        Type <DiagonalBox color="#00DD00" text="Intro" /> to start
                    </Box>
                ) : undefined}
                <Box mt="10px"></Box>
                <video
                    ref={videoRef}
                    src={config.spinachIntro.introVideo}
                    playsInline
                    onEnded={() => {
                        send({
                            type: 'VIDEO_END',
                        });
                    }}
                    onAbort={() => {
                        send({
                            type: 'VIDEO_END',
                        });
                    }}
                    style={{
                        display: speaking ? 'none' : 'block',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        opacity: opacity,
                        transition: 'opacity 0.5s ease-in-out',
                        zIndex: 100,
                    }}
                />
                <video
                    ref={loopVideoRef}
                    src={config.spinachIntro.loopVideo}
                    id="introLoopVid"
                    loop
                    autoPlay
                    playsInline
                    style={{
                        display: speaking ? 'none' : 'block',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        zIndex: 99,
                        opacity: playingIntro ? 0 : 1,
                        transition: 'opacity 5s ease-in-out',
                    }}
                />
            </Box>
        );
    }

    return null;
};
