import { CSSProperties, ReactNode } from 'react';

import { DashboardSection } from '@spinach-shared/constants';
import { StoredSpinachSeries } from '@spinach-shared/utils';

import { GlobalAiDashboardState } from '../../../atoms';

export enum IntegrationSettingsModalKind {
    SettingsSlack = 'settings-slack',
    SettingsGoogleCalendar = 'settings-google-calendar',
    SettingsMicrosoftCalendar = 'settings-microsoft-calendar',
    SettingsJira = 'settings-jira',
    SettingsAsana = 'settings-asana',
    SettingsLinear = 'settings-linear',
    SettingsClickUp = 'settings-clickup',
    SettingsTrello = 'settings-trello',
    SettingsMondayDotCom = 'settings-monday-dot-com',
    SettingsConfluence = 'settings-confluence',
    SettingsNotion = 'settings-notion',
    SettingsGoogleDrive = 'settings-google-drive',
    SettingsZoom = 'settings-zoom',
    SettingsHubSpot = 'settings-hub-spot',
    SettingsSalesforce = 'settings-salesforce',
    SettingsAttio = 'settings-attio',
    SettingsZoho = 'settings-zoho',
}

export type IntegrationSettingsModalMetadata = {
    [IntegrationSettingsModalKind.SettingsConfluence]?: {
        isFirstTimeAuth?: boolean;
    };
    [IntegrationSettingsModalKind.SettingsJira]?: {
        isFirstTimeAuth?: boolean;
    };
};

export type IntegrationSettingsModalProps = {
    modalKind: IntegrationSettingsModalKind | null;
    setModalKind: (modalKind: IntegrationSettingsModalKind | null) => void;
    modalMetadata?: IntegrationSettingsModalMetadata;
    setModalMetadata?: (metadata: IntegrationSettingsModalMetadata) => void;
};

export type UseAiModalProps = {
    header: ReactNode;
    body: ReactNode;
    isLoading: boolean;
    onConfirm: () => Promise<void>;
    onClose: (() => Promise<void>) | (() => void);
};

export type ActiveSectionComponent = {
    storedSeriesList: StoredSpinachSeries[];
    setModalKind: (modalKind: IntegrationSettingsModalKind | null) => void;
    setModalMetadata: (metadata: IntegrationSettingsModalMetadata) => void;
};

export type UseHoverRowProps = {
    hoveredRow: null | string;
    setHoveredRow: (value: null | string) => void;
    getHoverableRowProps: (rowId: string) => {
        onMouseEnter: () => void;
        onMouseLeave: () => void;
        style: CSSProperties;
    };
    getVisibleRemoveProps: (rowId: string) => {
        isVisible: boolean;
    };
};
