import { ClientAiHistory, SpinachAPIPath, TranscriptData, ViewableTranscriptLineData } from '@spinach-shared/types';

import { getSpinachAPI } from './getSpinachAPI';

/**
 * Options for fetching AI histories
 */
interface GetAiHistoriesOptions {
    /** Maximum number of histories to fetch */
    limit?: number;
    /** Specific bot ID to fetch */
    botId?: string;
    /** createdAtPageStart for pagination (timestamp of oldest entry from previous request) */
    createdAtPageStart?: string;
}

/**
 * Response from the AI Histories API
 */
interface AiHistoriesResponse {
    /** List of AI meeting histories */
    histories: ClientAiHistory[];
    /** createdAtPageStart to use for fetching the next page of results (if available) */
    nextCreatedAtPageStart?: string;
}

/**
 * Fetches AI histories with optional pagination support
 *
 * @param options - Optional parameters for fetching (limit, botId, cursor)
 * @returns Promise resolving to AI histories and optional nextCursor for pagination
 */
export async function getAiHistories(options: GetAiHistoriesOptions = {}): Promise<AiHistoriesResponse | undefined> {
    const params: { limit?: number; botId?: string; createdAtPageStart?: string } = {};
    if (options) {
        params.botId = options.botId;
        params.limit = options.limit;
        params.createdAtPageStart = options.createdAtPageStart;
    }

    const response = await getSpinachAPI<AiHistoriesResponse>(SpinachAPIPath.AIHistory, {
        params,
    });

    return response
        ? {
              histories: response.histories,
              nextCreatedAtPageStart: response.nextCreatedAtPageStart,
          }
        : undefined;
}

export async function getAiTranscript({
    seriesId,
    botId,
}: {
    seriesId: string;
    botId: string;
}): Promise<TranscriptData | undefined | null> {
    const response = await getSpinachAPI<
        { results: ViewableTranscriptLineData[] | null; isTranscriptDisabled: false } | { isTranscriptDisabled: true }
    >(SpinachAPIPath.AITranscript, {
        params: {
            seriesId,
            botId,
        },
    });

    if (response?.isTranscriptDisabled) {
        return response;
    }

    if (!response?.results) {
        return null;
    }

    return { isTranscriptDisabled: response.isTranscriptDisabled, transcriptLineData: response.results };
}

export async function getAiSummaryHtml({
    seriesId,
    botId,
}: {
    seriesId: string;
    botId: string;
}): Promise<string | undefined | null> {
    const response = await getSpinachAPI<{ results: string | null }>(SpinachAPIPath.AiSummaryHtml, {
        params: {
            seriesId,
            botId,
        },
    });

    return response?.results;
}
