import { FormControl, FormControlLabel, Modal, Radio, RadioGroup, TextField } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { useState } from 'react';
import styled from 'styled-components';

import { ClientEventType, FeatureToggle } from '@spinach-shared/types';

import {
    BootstrapTooltip,
    deleteUserAccount,
    getClientConfigValue,
    postLogOut,
    useClickTracking,
    useGlobalAiDashboard,
} from '../../../..';
import { GlobalModal } from '../../../atoms';
import { useGlobalAuthedUser, useGlobalModal } from '../../../hooks';
import { BodyRegularOnboard, ResponsiveModalTitle, lightTheme } from '../../../styles';
import { URLUtil } from '../../../utils';
import { PrimaryButton } from '../../stand-up';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { SpinachModalContent } from '../SpinachModalContent';
import { SpinachSwitch } from '../SpinachSwitch';
import { Column, Row, Spacing } from '../framing';

const Link = styled.span`
    fontweight: bold;
    color: ${lightTheme.primary.green};
    cursor: pointer;
`;

enum AccountManagementStep {
    Default = 'default',
    ReasonForAccountDeletion = 'reason-for-account-deletion',
    RemoveSpinachFromMeetingsHelpStep = 'remove-spinach-from-meetings-help',
    SecurityConcernsHelpStep = 'security-concerns-help',
    ProcurementProcessHelpStep = 'procurement-process-help',
    PricingConcernHelpStep = 'pricing-concern-help',
    WeCanHelp = 'we-can-help',
}

type AccountManagementStepProps<T extends object | undefined = undefined> = {
    nextStep: (options?: T) => void;
    previousStep: () => void;
};

const AccountManagementDefaultStep = ({ nextStep }: AccountManagementStepProps) => {
    const [user] = useGlobalAuthedUser();
    const clickTracking = useClickTracking();
    return (
        <Column centered>
            <Row centered>
                <ResponsiveModalTitle>Account Management</ResponsiveModalTitle>
            </Row>
            <Spacing factor={1 / 3} />

            <Column style={{ alignItems: 'start' }}>
                <BodyRegularOnboard style={{ fontWeight: 'bolder' }}>Email</BodyRegularOnboard>
                <Spacing factor={1 / 3} />
                <BodyRegularOnboard>{user.email}</BodyRegularOnboard>
            </Column>

            {user.isPayingUser && !user.featureToggles[FeatureToggle.EnterpriseAccounts] ? (
                <>
                    <Spacing />

                    <Column style={{ alignItems: 'start' }}>
                        <BodyRegularOnboard style={{ fontWeight: 'bolder' }}>Cancel subscription</BodyRegularOnboard>
                        <Spacing factor={1 / 3} />
                        <BodyRegularOnboard>Cancel your subscription and stop future billing</BodyRegularOnboard>
                        <Spacing factor={1 / 3} />
                        <SecondaryButton
                            title="Cancel subscription"
                            onClick={() => {
                                clickTracking(ClientEventType.AIDashboardClick, 'Cancel Subscription', {
                                    From: 'Account Management Modal',
                                });
                                // Open Intercom with pre-populated message
                                if (window.Intercom) {
                                    window.Intercom('showNewMessage', 'I would like to cancel my subscription.');
                                }
                            }}
                        />
                    </Column>
                </>
            ) : null}

            <Spacing />
            <Column style={{ alignItems: 'start' }}>
                <Row vCenter>
                    <BodyRegularOnboard style={{ fontWeight: 'bolder' }}>Delete account</BodyRegularOnboard>
                    <Spacing horizontal factor={1 / 5} />
                    <BootstrapTooltip
                        placement="right"
                        title={
                            <>
                                <strong>Note:</strong> Deleting your account does not automatically cancel your
                                subscription.
                            </>
                        }
                    >
                        <InfoOutlined htmlColor={lightTheme.primary.greenLight} style={{ marginRight: '10px' }} />
                    </BootstrapTooltip>
                </Row>
                <Spacing factor={1 / 3} />
                <BodyRegularOnboard>Permanently delete your personal data and account</BodyRegularOnboard>
                <Spacing factor={1 / 3} />
                <SecondaryButton color={lightTheme.status.negative} title="Delete account" onClick={() => nextStep()} />
            </Column>
        </Column>
    );
};

const ReasonForDeletingAccountRow = styled(Row)`
    background-color: ${() => lightTheme.neutrals.grayLight};
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 5px;
`;

enum ReasonsForDeletingAccount {
    RemoveSpinach = 'Remove Spinach from my meetings',
    SecurityConcerns = 'Security concerns',
    ProcurementProcessRequired = 'Procurement process required',
    FoundAnotherTool = 'Found another tool',
    PricingConcerns = 'Pricing concerns',
    NotEnoughValue = 'Not enough value',
    Other = 'Other',
}
const ReasonForAccountDeletionStep = ({
    nextStep,
    previousStep,
}: AccountManagementStepProps<{ selectedDeletionReason: ReasonsForDeletingAccount; [key: string]: string | null }>) => {
    const [selectedDeletionReason, setSelectedDeletionReason] = useState<ReasonsForDeletingAccount | null>(null);
    const [moreInformation, setMoreInformation] = useState<string>('');
    const [user] = useGlobalAuthedUser();
    return (
        <Column centered>
            <Row centered>
                <ResponsiveModalTitle>Reason for deleting account</ResponsiveModalTitle>
            </Row>
            <Spacing factor={1 / 3} />

            <FormControl style={{ width: '100%' }}>
                <RadioGroup
                    value={selectedDeletionReason}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setSelectedDeletionReason(event.target.value as ReasonsForDeletingAccount);
                    }}
                >
                    {Object.values(ReasonsForDeletingAccount)
                        .filter((value) => !(value === ReasonsForDeletingAccount.RemoveSpinach && user.isUsingRecallV2))
                        .map((value) => (
                            <>
                                <ReasonForDeletingAccountRow key={value}>
                                    <FormControlLabel
                                        style={{ width: '100%', marginLeft: 'unset', justifyContent: 'space-between' }}
                                        value={value}
                                        control={<Radio />}
                                        label={value}
                                        labelPlacement="start"
                                    />
                                </ReasonForDeletingAccountRow>
                                <Spacing factor={1 / 3} />
                            </>
                        ))}
                </RadioGroup>
            </FormControl>
            {!!selectedDeletionReason ? (
                <TextField
                    onChange={(e) => setMoreInformation(e.target.value)}
                    value={moreInformation}
                    style={{ width: '100%' }}
                    placeholder="Provide additional detail"
                />
            ) : (
                <></>
            )}
            <Spacing factor={1 / 3} />
            <span style={{ flexGrow: 1 }} />
            <PrimaryButton
                disabled={!selectedDeletionReason || !moreInformation}
                title="Continue"
                onClick={() => nextStep({ selectedDeletionReason: selectedDeletionReason!, moreInformation })}
            />
            <Spacing factor={1 / 2} />
            <SecondaryButton title="Back" onClick={() => previousStep()} />
        </Column>
    );
};

const PricingHelpStep = ({ nextStep, previousStep }: AccountManagementStepProps) => {
    const clickTracking = useClickTracking();
    const [user] = useGlobalAuthedUser();
    return (
        <>
            <Row centered>
                <ResponsiveModalTitle>Pricing Concerns</ResponsiveModalTitle>
            </Row>
            <Spacing factor={1 / 2} />

            <Spacing factor={1 / 3} />
            <BodyRegularOnboard>
                We’re happy to discuss options that makes sense for you and your business.{' '}
                <Link
                    onClick={() => {
                        clickTracking(ClientEventType.AIDashboardClick, 'Talk to Sales Account Management Modal', {
                            From: 'Account Management Modal',
                        });
                        URLUtil.openURL(user.talkToSalesUrl);
                    }}
                >
                    Talk to sales
                </Link>{' '}
                to learn more.
            </BodyRegularOnboard>

            <span style={{ flexGrow: 1 }} />
            <PrimaryButton title="Continue to delete" onClick={() => nextStep()} />
            <Spacing factor={1 / 2} />
            <SecondaryButton title="Back" onClick={() => previousStep()} />
        </>
    );
};

const WeCanHelpStep = ({
    isAccountDeletionLoading,
    nextStep,
    previousStep,
}: AccountManagementStepProps<{ removeFromMeetings: boolean }> & { isAccountDeletionLoading: boolean }) => {
    const [removeFromMeetings, setRemoveFromMeetings] = useState(true);

    return (
        <>
            <Row centered>
                <ResponsiveModalTitle>We can help!</ResponsiveModalTitle>
            </Row>
            <Spacing factor={1 / 2} />

            <Column style={{ alignItems: 'start' }}>
                <BodyRegularOnboard style={{ fontWeight: 'bolder' }}>Delete account</BodyRegularOnboard>
                <Spacing factor={1 / 3} />
                <BodyRegularOnboard>
                    Deleting your account will permanently delete your personal data associated with your account. This
                    cannot be undone.
                </BodyRegularOnboard>
            </Column>

            <Spacing factor={1 / 2} />

            <Row style={{ alignItems: 'center' }}>
                <SpinachSwitch checked={removeFromMeetings} onChange={(e) => setRemoveFromMeetings(e.target.checked)} />
                <Spacing factor={1 / 2} horizontal />
                <BodyRegularOnboard>Remove Spinach from meetings you added through the dashboard.</BodyRegularOnboard>
            </Row>

            <span style={{ flexGrow: 1 }} />
            <PrimaryButton
                isLoading={isAccountDeletionLoading}
                containerStyles={{ backgroundColor: '#E73131' }}
                title="Yes, delete account"
                onClick={() => nextStep({ removeFromMeetings })}
            />
            <Spacing factor={1 / 2} />
            <SecondaryButton title="Cancel" onClick={() => previousStep()} />
        </>
    );
};

const ProcurementProcessHelpStep = ({ nextStep, previousStep }: AccountManagementStepProps) => {
    const clickTracking = useClickTracking();
    return (
        <>
            <Row centered>
                <ResponsiveModalTitle>Need help fast-tracking procurement?</ResponsiveModalTitle>
            </Row>
            <Spacing factor={1 / 3} />
            <BodyRegularOnboard style={{ justifyContent: 'start' }}>
                <Link
                    onClick={() => {
                        clickTracking(ClientEventType.AIDashboardClick, 'Contact Support Account Management Modal', {
                            From: 'Account Management Modal',
                            About: 'Procurement Help',
                        });
                        URLUtil.openURL(`mailto:security@spinach.io?subject${encodeURIComponent('Procurement Help')}`);
                    }}
                >
                    Contact support
                </Link>{' '}
                for assistance. We are always happy to hear from you!
            </BodyRegularOnboard>
            <span style={{ flexGrow: 1 }} />
            <PrimaryButton title="Continue to delete" onClick={() => nextStep()} />
            <Spacing factor={1 / 2} />
            <SecondaryButton title="Back" onClick={() => previousStep()} />
        </>
    );
};

const RemoveSpinachFromMeetingsHelpStep = ({ nextStep, previousStep }: AccountManagementStepProps) => {
    const clickTracking = useClickTracking();
    return (
        <>
            <Row centered>
                <ResponsiveModalTitle>Remove Spinach from meetings</ResponsiveModalTitle>
            </Row>
            <Spacing factor={1 / 3} />
            <BodyRegularOnboard style={{ justifyContent: 'start' }}>
                Deleting your account won’t remove Spinach from your meetings due to calendar provider permissions.
                Please have your meeting organizers remove Spinach from the calendar events.
            </BodyRegularOnboard>

            <BodyRegularOnboard>
                Please check out our{' '}
                <Link
                    onClick={() => {
                        clickTracking(
                            ClientEventType.AIDashboardClick,
                            'Remove Spinach From Meetings Help Account Management Modal',
                            {
                                From: 'Account Management Modal',
                            }
                        );
                        URLUtil.openURL(
                            `${getClientConfigValue(
                                'INTERCOM_SUPPORT_URL'
                            )}/en/articles/9034850-remove-from-meetings-or-delete-account`
                        );
                    }}
                >
                    help article
                </Link>{' '}
                to learn more about how to remove Spinach from your meetings.
            </BodyRegularOnboard>

            <span style={{ flexGrow: 1 }} />
            <PrimaryButton title="Continue to delete" onClick={() => nextStep()} />
            <Spacing factor={1 / 2} />
            <SecondaryButton title="Back" onClick={() => previousStep()} />
        </>
    );
};

const SecurityConcernsHelpStep = ({ nextStep, previousStep }: AccountManagementStepProps) => {
    const clickTracking = useClickTracking();
    return (
        <>
            <Row centered>
                <ResponsiveModalTitle>Security Concerns</ResponsiveModalTitle>
            </Row>
            <Spacing factor={1 / 3} />
            <BodyRegularOnboard>
                Check out the{' '}
                <Link
                    onClick={() => {
                        clickTracking(ClientEventType.AIDashboardClick, 'Security Center Account Management Modal', {
                            From: 'Account Management Modal',
                        });
                        URLUtil.openURL('https://security.spinach.io/');
                    }}
                >
                    trust center
                </Link>{' '}
                for our latest security updates or{' '}
                <Link
                    onClick={() => {
                        clickTracking(ClientEventType.AIDashboardClick, 'Contact Support Account Management Modal', {
                            From: 'Account Management Modal',
                            About: 'Security Concerns',
                        });
                        URLUtil.openURL(
                            `mailto:security@spinach.io?subject${encodeURIComponent('Security Questions')}`
                        );
                    }}
                >
                    contact support
                </Link>{' '}
                for assistance. We are always happy to hear from you!
            </BodyRegularOnboard>

            <span style={{ flexGrow: 1 }} />
            <PrimaryButton title="Continue to delete" onClick={() => nextStep()} />
            <Spacing factor={1 / 2} />
            <SecondaryButton title="Back" onClick={() => previousStep()} />
        </>
    );
};

const AccountManagementModalContent = ({ closeModal }: { closeModal: () => void }) => {
    const clickTracking = useClickTracking();
    const [steps, setSteps] = useState<AccountManagementStep[]>([AccountManagementStep.Default]);
    const [isAccountDeletionLoading, setIsAccountDeletionLoading] = useState(false);
    const { setToastText } = useGlobalAiDashboard();

    const latestStep = steps[steps.length - 1];
    const goBack = () => {
        clickTracking(ClientEventType.AIDashboardClick, 'Account Management Go Back Step', {
            LatestStep: latestStep,
            PreviousStep: steps[steps.length - 2],
        });
        setSteps((prevSteps) => prevSteps.slice(0, -1));
    };

    const advance = (nextStep: AccountManagementStep) => {
        setSteps((prevSteps) => [...prevSteps, nextStep]);
    };

    switch (latestStep) {
        case AccountManagementStep.Default:
            return (
                <AccountManagementDefaultStep
                    nextStep={() => {
                        clickTracking(ClientEventType.AIDashboardClick, 'Account Management Delete Account', {
                            From: 'Account Management Modal',
                        });
                        advance(AccountManagementStep.ReasonForAccountDeletion);
                    }}
                    previousStep={closeModal}
                />
            );
        case AccountManagementStep.ReasonForAccountDeletion:
            return (
                <ReasonForAccountDeletionStep
                    nextStep={(metadata) => {
                        clickTracking(ClientEventType.AIDashboardClick, 'Reason For Account Deletion Step', {
                            ...metadata,
                            From: 'Account Management Modal',
                        });
                        if (metadata?.selectedDeletionReason === ReasonsForDeletingAccount.RemoveSpinach) {
                            advance(AccountManagementStep.RemoveSpinachFromMeetingsHelpStep);
                        } else if (metadata?.selectedDeletionReason === ReasonsForDeletingAccount.SecurityConcerns) {
                            advance(AccountManagementStep.SecurityConcernsHelpStep);
                        } else if (
                            metadata?.selectedDeletionReason === ReasonsForDeletingAccount.ProcurementProcessRequired
                        ) {
                            advance(AccountManagementStep.ProcurementProcessHelpStep);
                        } else if (metadata?.selectedDeletionReason === ReasonsForDeletingAccount.PricingConcerns) {
                            advance(AccountManagementStep.PricingConcernHelpStep);
                        } else {
                            advance(AccountManagementStep.WeCanHelp);
                        }
                    }}
                    previousStep={goBack}
                />
            );
        case AccountManagementStep.PricingConcernHelpStep:
            return (
                <PricingHelpStep
                    nextStep={() => {
                        clickTracking(ClientEventType.AIDashboardClick, 'Delete Account From Pricing Step', {
                            From: 'Account Management Modal',
                        });
                        advance(AccountManagementStep.WeCanHelp);
                    }}
                    previousStep={goBack}
                />
            );
        case AccountManagementStep.SecurityConcernsHelpStep:
            return (
                <SecurityConcernsHelpStep
                    nextStep={() => {
                        clickTracking(ClientEventType.AIDashboardClick, 'Delete Account From Security Concerns', {
                            From: 'Account Management Modal',
                        });
                        advance(AccountManagementStep.WeCanHelp);
                    }}
                    previousStep={goBack}
                />
            );
        case AccountManagementStep.RemoveSpinachFromMeetingsHelpStep:
            return (
                <RemoveSpinachFromMeetingsHelpStep
                    nextStep={() => {
                        clickTracking(ClientEventType.AIDashboardClick, 'Delete Account Remove Spinach From Meetings', {
                            From: 'Account Management Modal',
                        });
                        advance(AccountManagementStep.WeCanHelp);
                    }}
                    previousStep={goBack}
                />
            );
        case AccountManagementStep.ProcurementProcessHelpStep:
            return (
                <ProcurementProcessHelpStep
                    nextStep={() => {
                        clickTracking(ClientEventType.AIDashboardClick, 'Delete Account Procurement Process Help', {
                            From: 'Account Management Modal',
                        });
                        advance(AccountManagementStep.WeCanHelp);
                    }}
                    previousStep={goBack}
                />
            );
        case AccountManagementStep.WeCanHelp:
            return (
                <WeCanHelpStep
                    isAccountDeletionLoading={isAccountDeletionLoading}
                    nextStep={async (metadata) => {
                        try {
                            clickTracking(ClientEventType.AIDashboardClick, 'Final Delete Account Confirmation', {
                                From: 'Account Management Modal',
                            });
                            setIsAccountDeletionLoading(true);
                            await deleteUserAccount(metadata?.removeFromMeetings ?? false);
                            const response = await postLogOut();
                            if (response.success) {
                                window.location.href = 'https://www.spinach.io/account-deleted';
                            }
                        } catch (e) {
                            setToastText(
                                'We had some trouble deleting your account. Please open a support chat for assistance'
                            );
                        } finally {
                            setIsAccountDeletionLoading(false);
                        }
                    }}
                    previousStep={goBack}
                />
            );
    }
};

export function AccountManagementModal(): JSX.Element {
    const [globalModal, setGlobalModal] = useGlobalModal();

    const clickTracking = useClickTracking();

    const closeModal = () => {
        clickTracking(ClientEventType.AIDashboardClick, 'Close Account Management Modal');
        setGlobalModal(null);
    };

    if (globalModal !== GlobalModal.UserAccountManagement) {
        return <></>;
    }
    return (
        <Modal open={globalModal === GlobalModal.UserAccountManagement} onClose={closeModal}>
            <SpinachModalContent onClose={closeModal} style={{ overflow: 'hidden', minHeight: '300px' }}>
                <AccountManagementModalContent closeModal={closeModal} />
            </SpinachModalContent>
        </Modal>
    );
}
