import { z } from 'zod';

import {
    SpinachWorkflowActionKind,
    spinachWorkflowEmailOutputTargetSchema,
    spinachWorkflowSlackOutputTargetSchema,
} from './Workflows';

// Define the schema for an individual agent
const EnabledAgentConfigSchema = z.object({
    sortingScore: z.number(),
    name: z.string(),
    description: z.string(),
    about: z.array(z.string()),
    imageUrl: z.string().url(),
    iconUrl: z.string().url(),
    isHidden: z.boolean().optional(),
    output: z.object({
        header: z.string(),
        sections: z.array(
            z.object({
                header: z.string(),
                description: z.string(),
            })
        ),
    }),
});

const DisabledAgentConfigSchema = z.object({
    disabled: z.literal(true),
});

const AgentConfigSchema = z.union([EnabledAgentConfigSchema, DisabledAgentConfigSchema]);

// Define the schema for the collection of agents
export const AgentsConfigSchema = z.record(z.string(), AgentConfigSchema);

// TypeScript type derived from the Zod schema
export type AgentConfig = z.infer<typeof EnabledAgentConfigSchema>;

const WorkflowBaseSchema = z.object({
    actions: z.array(
        z.object({
            kind: z.enum([SpinachWorkflowActionKind.Prompt]),
            promptId: z.string(),
        })
    ),
    meetingConditions: z.object({
        trigger: z.enum(['external', 'internal', 'all', 'keywords']),
        triggerKeywords: z.array(z.string()).optional(),
    }),
    outputTargets: z.array(z.union([spinachWorkflowSlackOutputTargetSchema, spinachWorkflowEmailOutputTargetSchema])),
});

export const CreateAgentSchema = z.object({
    workflow: WorkflowBaseSchema,
});

export const UpdateAgentSchema = z.object({
    workflow: WorkflowBaseSchema.extend({
        workflowId: z.string(),
        triggerId: z.string(),
        isActive: z.boolean(),
    }),
});

export type WorkflowBasePayload = z.infer<typeof WorkflowBaseSchema>;
export type SlackTarget = z.infer<typeof spinachWorkflowSlackOutputTargetSchema>;
export type CreateAgentPayload = z.infer<typeof CreateAgentSchema>;
export type UpdateAgentPayload = z.infer<typeof UpdateAgentSchema>;
