import { Box, Divider, IconButton, TextField } from '@material-ui/core';
import { Cancel, CheckCircle, Edit } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { MEETING_TYPE_FEATURE_INTENT_MAP } from '@spinach-shared/constants';
import {
    ClientEventType,
    FeatureIntent,
    SpinachAPIPath,
    SummaryFeatureIntents,
    SummaryJson,
    UserNameWithEmailConfidenceMap,
    isCustomizableSummaryFeatureIntent,
} from '@spinach-shared/types';
import { MeetingSelection } from '@spinach-shared/types/src/Scribe';

import { getAiSummaryJson } from '../../../../../apis';
import { patchSpinachAPI } from '../../../../../apis/patchSpinachAPI';
import { postEditedSummary } from '../../../../../apis/postEditedSummary';
import { GlobalModal, GlobalModalMetadataType } from '../../../../../atoms';
import { useExperienceTracking, useGlobalAuthedUser, useGlobalModal, useLandingAnalytic } from '../../../../../hooks';
import { useGlobalAiDashboard } from '../../../../../hooks/useGlobalAiDashboard';
import { clearEditedSummary, getEditedSummary, saveEditedSummary } from '../../../../../hooks/useLocalStorage';
import { BodyRegularOnboard, HeaderThree, HeaderThreeOnboard, lightTheme } from '../../../../../styles';
import { ClientLogger } from '../../../../../utils';
import { LoadingSquares, Row, Spacing, ViewContainer } from '../../../../common';
import { PrimaryButton } from '../../../../stand-up';
import { useNavigateToUpgrade } from '../../../hooks/useNavigateToUpgrade';
import { OutlineButton } from '../../../onboarding/step-tracker-onboarding/shared';
import { ActionItemSectionEditor } from './section-editors/ActionItemSectionEditor';
import { AgendaItemSectionEditor } from './section-editors/AgendaItemSectionEditor';
import { AttendeesSectionEditor } from './section-editors/AttendeesSectionEditor';
import { BlockerSectionEditor } from './section-editors/BlockerSectionEditor';
import { CelebrationSectionEditor } from './section-editors/CelebrationSectionEditor';
import { CompanyAllHandsSectionEditor } from './section-editors/CompanyAllHandsSectionEditor';
import { CustomerSuccessCheckInSectionEditor } from './section-editors/CustomerSuccessCheckInSectionEditor';
import { GeneralSalesCallSectionEditor } from './section-editors/GeneralSalesCallSectionEditor';
import { KeyDecisionSectionEditor } from './section-editors/KeyDecisionSectionEditor';
import { PersonalUpdatesSectionEditor } from './section-editors/PersonalUpdatesSectionEditor';
import { WorkstreamsSectionEditor } from './section-editors/WorkstreamsSectionEditor';
import { SectionComponentProps } from './shared';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 32px;
`;

type SectionEditComponent = React.ComponentType<SectionComponentProps>;

function mapIntentToEditorSection(intent: SummaryFeatureIntents): SectionEditComponent | null {
    switch (intent) {
        case FeatureIntent.MeetingAgendaItems:
            return AgendaItemSectionEditor;
        case FeatureIntent.MeetingActionItems:
            return ActionItemSectionEditor;
        case FeatureIntent.MeetingKeyDecisions:
            return KeyDecisionSectionEditor;
        case FeatureIntent.MeetingBlockers:
            return BlockerSectionEditor;
        case FeatureIntent.MeetingCelebrations:
            return CelebrationSectionEditor;
        case FeatureIntent.PerPersonUpdates:
            return PersonalUpdatesSectionEditor;
        case FeatureIntent.MeetingCustomerSuccessCheckIn:
            return CustomerSuccessCheckInSectionEditor;
        case FeatureIntent.MeetingGeneralSalesCall:
            return GeneralSalesCallSectionEditor;
        case FeatureIntent.MeetingWorkstreams:
            return WorkstreamsSectionEditor;
        case FeatureIntent.MeetingCompanyAllHandsSummary:
            return CompanyAllHandsSectionEditor;
        default:
            return null;
    }
}

export type DraftEditorProps = {
    botId: string;
    onDraftSent: () => void;
    onErrorLoadingSummary: () => void;
    selection: MeetingSelection;
    onMetadataUpdate?: (updatedSelection: MeetingSelection) => void;
};

export function DraftEditor({
    botId,
    onDraftSent,
    onErrorLoadingSummary,
    selection,
    onMetadataUpdate,
}: DraftEditorProps): JSX.Element {
    const track = useExperienceTracking();
    const [summaryJSON, setSummaryJSON] = useState<SummaryJson | null>();
    const [meetingTime, setMeetingTime] = useState('');
    const [meetingTitle, setMeetingTitle] = useState('');
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [editedTitle, setEditedTitle] = useState('');
    const [isEditingDate, setIsEditingDate] = useState(false);
    const [editedDate, setEditedDate] = useState<Date | null>(null);
    const [usernameEmailMapping, setUsernameEmailMapping] = useState<UserNameWithEmailConfidenceMap | undefined>(
        undefined
    );
    const [summaryIntents, setSummaryIntents] = useState<SummaryFeatureIntents[]>([]);
    const [attendees, setAttendees] = useState<{ email: string; rootDomain: string }[]>([]);
    const [emailsWithAccess, setEmailsWithAccess] = useState<string[]>([]);
    const [hostUserEmail, setHostUserEmail] = useState<string | undefined>();
    const navigateToUpgrade = useNavigateToUpgrade();

    const [user] = useGlobalAuthedUser();
    const { setToastText } = useGlobalAiDashboard();
    useLandingAnalytic(ClientEventType.AIDashboardActivity, {
        Activity: 'Opened draft editor',
        BotId: botId,
    });

    useEffect(() => {
        async function exec() {
            try {
                // Check if we have an edited summary in localStorage
                const savedSummary = getEditedSummary(botId);

                const response = await getAiSummaryJson(botId, true);

                if (!response) {
                    throw new Error('Failed to load summary');
                } else if ('summaryJson' in response) {
                    // Use the saved summary from localStorage if available, otherwise use the one from the backend
                    setSummaryJSON(savedSummary || response.summaryJson);
                    setMeetingTime(
                        moment(selection.customMeetingDate || selection.createdAt).format('dddd, MMM Do YYYY')
                    );
                    setMeetingTitle(response.meetingTitle);
                    setUsernameEmailMapping(response.usernameEmailMapping);
                    setAttendees(response.attendees ?? []);
                    setEmailsWithAccess(response.emailsWithAccess);
                    setHostUserEmail(response.hostUserEmail);

                    const defaultIntents = MEETING_TYPE_FEATURE_INTENT_MAP[response.meetingType];
                    // the meeting's intents are those that are in the default list and are not customizable or are customizable and enabled
                    const enabledIntents = defaultIntents.filter(
                        (intent) =>
                            !isCustomizableSummaryFeatureIntent(intent) ||
                            !response.enabledSummarySections?.length ||
                            response.enabledSummarySections?.includes(intent)
                    );
                    setSummaryIntents(enabledIntents);
                }
            } catch (e) {
                ClientLogger.error('Error loading summary for draft edit', e);
                onErrorLoadingSummary();
                setToastText(
                    'Oops, something went wrong, please try again later. If the problem persists, please contact support'
                );
            }
        }
        exec();
    }, [botId, selection.customMeetingDate, selection.createdAt]);

    const [isLoading, setIsLoading] = useState(false);

    const [, setGlobalModal] = useGlobalModal();

    // Save summary to localStorage when it changes
    useEffect(() => {
        if (summaryJSON) {
            saveEditedSummary(botId, summaryJSON);
        }
    }, [botId, summaryJSON]);

    const handleSaveTitle = async () => {
        try {
            track(ClientEventType.AIDashboardClick, {
                ClickedOn: 'Save Meeting Title',
            });

            await patchSpinachAPI(SpinachAPIPath.UpdateMeetingMetadata.replace(':botId', botId), {
                meetingTitle: editedTitle,
            });
            setIsEditingTitle(false);
            setMeetingTitle(editedTitle);

            // Create updated selection object
            const updatedSelection = {
                ...selection,
                title: editedTitle,
                label: `${editedTitle} (${moment(selection.customMeetingDate || selection.createdAt).format(
                    'YYYY/MM/DD'
                )})`,
            };

            // Call the callback to update parent's selection state
            if (onMetadataUpdate) {
                onMetadataUpdate(updatedSelection);
            }

            setToastText('Meeting title updated successfully');
        } catch (error) {
            setToastText('Failed to update meeting title');
            track(ClientEventType.AIDashboardUnhappyPath, {
                Flow: 'Update Meeting Title',
                Error: (error as Error)?.message,
                BotId: botId,
            });
        }
    };

    const handleCancelTitle = () => {
        setEditedTitle(meetingTitle);
        setIsEditingTitle(false);
    };

    const handleDateSave = async () => {
        if (!editedDate) {
            return;
        }

        try {
            track(ClientEventType.AIDashboardClick, {
                ClickedOn: 'Save Meeting Date',
            });

            await patchSpinachAPI(SpinachAPIPath.UpdateMeetingMetadata.replace(':botId', botId), {
                meetingDate: moment(editedDate).toISOString(),
            });
            setIsEditingDate(false);
            setMeetingTime(moment(editedDate).format('dddd, MMM Do YYYY'));

            // Create updated selection object with new date
            const updatedSelection = {
                ...selection,
                createdAt: editedDate,
                customMeetingDate: editedDate,
                verbalDate: moment(editedDate).format('dddd, MMM Do YYYY'),
                label: `${selection.title} (${moment(editedDate).format('YYYY/MM/DD')})`,
            };

            // Call the callback to update parent's selection state
            if (onMetadataUpdate) {
                onMetadataUpdate(updatedSelection);
            }

            setToastText('Meeting date updated successfully');
        } catch (error) {
            setToastText('Failed to update meeting date');
            track(ClientEventType.AIDashboardUnhappyPath, {
                Flow: 'Update Meeting Date',
                Error: (error as Error)?.message,
                BotId: botId,
                AttemptedDate: editedDate.toISOString(),
            });
        }
    };

    const handleCancelDate = () => {
        setEditedDate(moment(meetingTime, 'dddd, MMM Do YYYY').toDate());
        setIsEditingDate(false);
    };

    const [datePickerOpen, setDatePickerOpen] = useState(false);

    if (!summaryJSON) {
        return (
            <ViewContainer>
                <LoadingSquares />
            </ViewContainer>
        );
    }

    const isUploadedMeeting = botId.startsWith('upload-');

    return (
        <Container>
            <Box display="flex" flexDirection="column" style={{ marginBottom: '20px', zIndex: 1000 }}>
                {/* Title Section */}
                <Box display="flex" alignItems="center">
                    {isEditingTitle ? (
                        <>
                            <TextField
                                value={editedTitle}
                                onChange={(e) => setEditedTitle(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSaveTitle();
                                    } else if (e.key === 'Escape') {
                                        handleCancelTitle();
                                    }
                                }}
                                autoFocus
                                fullWidth
                                inputProps={{
                                    style: {
                                        fontSize: '27px',
                                        fontWeight: 700,
                                        padding: '0px',
                                        height: '40px',
                                    },
                                }}
                                style={{
                                    minWidth: '300px',
                                }}
                            />
                            <IconButton
                                onClick={handleSaveTitle}
                                size="small"
                                style={{
                                    color: lightTheme.primary.greenLight,
                                    marginLeft: '8px',
                                }}
                            >
                                <CheckCircle />
                            </IconButton>
                            <IconButton
                                onClick={handleCancelTitle}
                                size="small"
                                style={{
                                    color: lightTheme.status.negative,
                                    marginLeft: '4px',
                                }}
                            >
                                <Cancel />
                            </IconButton>
                        </>
                    ) : (
                        <>
                            <Box fontWeight={700} fontSize="27px">
                                {meetingTitle}
                            </Box>
                            <IconButton
                                onClick={() => {
                                    setEditedTitle(meetingTitle);
                                    setIsEditingTitle(true);
                                }}
                                size="small"
                                style={{
                                    color: lightTheme.primary.greenLight,
                                    marginLeft: '8px',
                                }}
                            >
                                <Edit />
                            </IconButton>
                        </>
                    )}
                </Box>

                {/* Date Section */}
                <Box display="flex" alignItems="center">
                    {isEditingDate ? (
                        <>
                            <KeyboardDatePicker
                                disableToolbar
                                value={editedDate}
                                onChange={(date) => setEditedDate(date)}
                                maxDate={new Date()}
                                disableFuture
                                autoOk
                                variant="inline"
                                format="yyyy-MM-dd"
                                margin="normal"
                                open={datePickerOpen}
                                onOpen={() => setDatePickerOpen(true)}
                                onClose={() => {
                                    setDatePickerOpen(false);
                                    if (!editedDate) {
                                        setIsEditingDate(false);
                                    }
                                }}
                                id="date-picker-inline"
                                InputProps={{
                                    style: {
                                        fontSize: '16px',
                                        fontWeight: 700,
                                    },
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            <IconButton
                                onClick={handleDateSave}
                                size="small"
                                style={{
                                    color: lightTheme.primary.greenLight,
                                    marginLeft: '8px',
                                }}
                            >
                                <CheckCircle />
                            </IconButton>
                            <IconButton
                                onClick={handleCancelDate}
                                size="small"
                                style={{
                                    color: lightTheme.status.negative,
                                    marginLeft: '4px',
                                }}
                            >
                                <Cancel />
                            </IconButton>
                        </>
                    ) : (
                        <>
                            <Box fontWeight={700} fontSize="16px">
                                {meetingTime}
                            </Box>
                            {isUploadedMeeting ? (
                                <IconButton
                                    onClick={() => {
                                        setEditedDate(moment(meetingTime, 'dddd, MMM Do YYYY').toDate());
                                        setIsEditingDate(true);
                                        setTimeout(() => {
                                            setDatePickerOpen(true);
                                        }, 0);
                                    }}
                                    size="small"
                                    style={{
                                        color: lightTheme.primary.greenLight,
                                        marginLeft: '8px',
                                    }}
                                >
                                    <Edit />
                                </IconButton>
                            ) : null}
                        </>
                    )}
                </Box>

                {/* Action Buttons */}
                <Row style={{ marginTop: '16px' }}>
                    <BodyRegularOnboard>
                        {user.hasProFeatures
                            ? isUploadedMeeting
                                ? 'Review and edit the summary below before saving.'
                                : 'Review and edit the summary below before sending.'
                            : 'Note: This summary has not been sent to attendees. Upgrade to edit.'}
                    </BodyRegularOnboard>
                    <div style={{ flex: 1 }} />
                    {user.hasProFeatures ? (
                        <>
                            <PrimaryButton
                                title={isUploadedMeeting ? 'Save' : 'Send'}
                                isLoading={isLoading}
                                loadingText={''}
                                disabled={!botId || isEditingTitle || isEditingDate}
                                style={{ height: '40px', marginRight: '10px' }}
                                onClick={async () => {
                                    if (!botId) {
                                        return;
                                    }

                                    const iEnabledForManageDraftSummaryRecipients =
                                        user.isEnabledForManageDraftSummaryRecipients;

                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: isUploadedMeeting ? 'Save Draft Summary' : 'Send Draft Summary',
                                        IsEnabledForManageDraftSummaryRecipients:
                                            iEnabledForManageDraftSummaryRecipients,
                                    });

                                    setIsLoading(true);

                                    // Clear the localStorage entry before sending the API request
                                    // to prevent potential data loss if the request succeeds but the clear fails
                                    clearEditedSummary(botId);

                                    const response = await postEditedSummary({
                                        summaryJSON,
                                        botId,
                                    });

                                    if (!response.success) {
                                        setToastText(
                                            isUploadedMeeting
                                                ? "We couldn't save your summary at this time. Try again in a bit or contact us."
                                                : "We couldn't send your summary at this time. Try again in a bit or contact us."
                                        );
                                    } else {
                                        setToastText(
                                            isUploadedMeeting
                                                ? 'Your summary has been saved!'
                                                : 'Your summary has been sent!'
                                        );
                                        onDraftSent();
                                    }
                                    setIsLoading(false);
                                }}
                            />
                            {user.isEnabledForManageDraftSummaryRecipients ? (
                                <OutlineButton
                                    style={{
                                        marginRight: '16px',
                                        height: '28px',
                                        width: '16px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '4px 6px',
                                        fontSize: '24px',
                                    }}
                                    isSelected={false}
                                    onClick={() => {
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: 'Edit Draft Summary Recipients',
                                        });
                                        setGlobalModal(GlobalModal.ManageDraftSummaryRecipients, {
                                            metadata: {
                                                botId,
                                                summaryJSON,
                                                metadataType: GlobalModalMetadataType.ManageDraftSummaryRecipients,
                                                defaultList: emailsWithAccess,
                                                attendees: attendees,
                                                onSaved: (emails) => {
                                                    setEmailsWithAccess(emails);
                                                },
                                                hostUserEmail: hostUserEmail ?? '',
                                            },
                                        });
                                    }}
                                >
                                    •••
                                </OutlineButton>
                            ) : null}
                        </>
                    ) : (
                        <PrimaryButton
                            title="Upgrade"
                            style={{ height: '40px' }}
                            onClick={() => {
                                navigateToUpgrade('Draft Editor');
                            }}
                        />
                    )}
                </Row>
            </Box>

            <AttendeesSectionEditor
                summaryJSON={summaryJSON}
                setSummaryJSON={setSummaryJSON}
                usernameEmailMapping={usernameEmailMapping}
            />
            <Spacing factor={1 / 2} />
            <Divider />
            {summaryIntents.map((intent, i) => {
                const EditorSection = mapIntentToEditorSection(intent);
                if (!EditorSection) {
                    return null;
                }

                return (
                    <div key={intent}>
                        <EditorSection
                            setSummaryJSON={setSummaryJSON}
                            summaryJSON={summaryJSON}
                            usernameEmailMapping={usernameEmailMapping}
                        />
                        {i < summaryIntents.length - 1 ? (
                            <>
                                <Spacing factor={1 / 2} />
                                <Divider />
                            </>
                        ) : null}
                    </div>
                );
            })}
            {/* padding the bottom */}
            <Row style={{ height: '16px' }} />
        </Container>
    );
}
