import { useEffect, useState } from 'react';

import { SummaryJson } from '@spinach-shared/types';
import { SetValue } from '../types';

export enum LocalStorageKeys {
    HasUserSeenProvisioningInstructions = 'hasUserSeenProvisioningInstructions',
    HasDismissedGoogleDriveVideoBackupWarning = 'hasDismissedGoogleDriveVideoBackupWarning',
    EditedSummaries = 'editedSummaries',
}

const storage = window.localStorage;

function readStorageValue<T>(key: string, defaultValue?: T): T {
    try {
        return JSON.parse(storage.getItem(key) as string);
    } catch (e) {
        return defaultValue as T;
    }
}

export function useLocalStorage<T>(key: string, defaultValue?: T): [T, SetValue<T>] {
    const [value, setValue] = useState(readStorageValue(key, defaultValue));

    useEffect(() => {
        storage.setItem(key, JSON.stringify(value));
        window.dispatchEvent(new Event('localStorageUpdate'));
    }, [key, value]);

    useEffect(() => {
        const onStorageUpdateValue = () => setValue(readStorageValue(key, value));
        window.addEventListener('storage', onStorageUpdateValue);
        window.addEventListener('localStorageUpdate', onStorageUpdateValue);
        return () => {
            window.removeEventListener('storage', onStorageUpdateValue);
            window.removeEventListener('localStorageUpdate', onStorageUpdateValue);
        };
    }, [key, value]);

    return [value, setValue];
}

// Helper functions for managing edited summaries in localStorage
export function getEditedSummary(botId: string): SummaryJson | null {
    try {
        const storageKey = `${LocalStorageKeys.EditedSummaries}_${botId}`;
        const savedSummary = storage.getItem(storageKey);
        return savedSummary ? JSON.parse(savedSummary) : null;
    } catch (e) {
        return null;
    }
}

export function saveEditedSummary(botId: string, summary: SummaryJson): void {
    try {
        const storageKey = `${LocalStorageKeys.EditedSummaries}_${botId}`;
        storage.setItem(storageKey, JSON.stringify(summary));
    } catch (e) {
        console.error('Failed to save edited summary to localStorage', e);
    }
}

export function clearEditedSummary(botId: string): void {
    try {
        const storageKey = `${LocalStorageKeys.EditedSummaries}_${botId}`;
        storage.removeItem(storageKey);
    } catch (e) {
        console.error('Failed to clear edited summary from localStorage', e);
    }
}
