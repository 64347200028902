import { useEffect } from 'react';
import { v4 } from 'uuid';

import {
    ClientUser,
    VideoAgentSession,
    getMockPreviousContext,
    getNewAgentTopic,
    getNewVideoAgentSessionJSON,
    pseudoUniqueParticipantIdSuffix,
} from '@spinach-shared/models';
import { isLocalStage } from '@spinach-shared/utils';

import { getUser, useGlobalNullableVideoAgent } from '../../..';

const sampleLogos = [
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/6730f3905a84f000013035cb/picture',
    'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/6730f6ebeb72530001334a4a/picture',
    'https://upload.wikimedia.org/wikipedia/en/thumb/f/f6/Caesars_Entertainment_logo_2020.svg/440px-Caesars_Entertainment_logo_2020.svg.png',
    'https://www.bluleadz.com/hs-fs/hubfs/NintendoLogo1.png?width=1100&name=NintendoLogo1.png',
];

const participantNames = ['Coburn', 'Kirill', 'Matan', 'Josh', 'Lucas', 'Oded', 'Yoav', 'Derek', 'Karin'];

export function useTestAgentSession({ botId, seriesId }: { botId: string; seriesId: string }) {
    const { session, setSession } = useGlobalNullableVideoAgent();
    useEffect(() => {
        async function exec() {
            const user = await getUser();
            const host = user.user ? new ClientUser(user.user) : undefined;
            const randomLogo = sampleLogos[Math.floor(Math.random() * sampleLogos.length)];
            const mockSession = getNewVideoAgentSessionJSON({
                botId,
                seriesId,
                hostId: host?._id || v4(),
                hostSubscriptionMetadata: host?.aiSubscriptionMetadata,
                pendingIntro: !!window.location.search?.includes('intro'),
                accountLogoUrl: randomLogo,
                meetingTitle: 'Daily Test Standup',
                agenda: {
                    currentTopicId: undefined,
                    topics: [
                        // comment to keep line broken for easy disabling
                        // getNewAgentTopic('Discussion Topic'),
                    ],
                },
                settings: {
                    isRoundtableEnabled: false,
                    isFeedbackCollectionEnabled: false,
                    isEnabledForChatCommandAudioOutput: true,
                    isVoicePrimaryCommandKind: false,
                    isAssistantEnabled: host?.isEnabledForVideoAgentAiControlChat ?? true,
                    isAudioOutputForcedOff: host?.isEnabledForVideoAgentForceAudioOff ?? true,
                },
                // previousContext: getMockPreviousContext('coburn'),
                participants: participantNames.map((name) => ({
                    name,
                    pseudoUniqueId: `${name}${pseudoUniqueParticipantIdSuffix}`,
                    firstJoinedAt: new Date().toISOString(),
                })),
                scheduledStartTime: new Date(Date.now() - 1000 * 60 * 5).toISOString(),
                scheduledEndTime: new Date(Date.now() + 1000 * 15).toISOString(),
            });

            const wrapper = new VideoAgentSession(mockSession);

            setSession(wrapper);
        }

        if (isLocalStage() && !session) {
            exec();
        }
    }, [botId, seriesId, session, setSession]);
}
