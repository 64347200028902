import { DashboardSection } from '@spinach-shared/constants';
import { ClientUser } from '@spinach-shared/models';
import { ClientPlatform, WebUrlQuery } from '@spinach-shared/types';

import { isProductionStage, isStagingStage, isTestStage } from '.';

// TODO: update when chrome comes in between subdomains
export function getWebAppHost(): string {
    const url = isProductionStage()
        ? 'https://app.spinach.ai'
        : isStagingStage()
        ? 'https://staging.app.spinach.ai'
        : isTestStage() || process.env.REACT_APP_PLATFORM === ClientPlatform.Desktop
        ? 'https://internal.app.spinach.ai'
        : 'http://localhost:3001';

    return url;
}

export function OLD_IO_getWebAppHost(): string {
    const url = isProductionStage()
        ? 'https://app.spinach.io'
        : isStagingStage()
        ? 'https://staging.app.spinach.io'
        : isTestStage() || process.env.REACT_APP_PLATFORM === ClientPlatform.Desktop
        ? 'https://internal.app.spinach.io'
        : 'http://localhost:3001';

    return url;
}

export type AiDeepLinkIds = {
    botId: string;
    seriesId: string;
};

export function getSharedLink(code: string): string {
    const params = new URLSearchParams({
        [WebUrlQuery.Meeting]: code,
    });

    return `${getWebAppHost()}?${params.toString()}`;
}

export function getAiUserSettingsUrl(): string {
    const params = new URLSearchParams({
        [WebUrlQuery.Section]: DashboardSection.Integrations,
        [WebUrlQuery.Redirect]: '/ai/home',
    });

    return `${getWebAppHost()}?${params.toString()}`;
}

export function getTranscriptDeepLink({ botId, seriesId }: AiDeepLinkIds): string {
    const params = new URLSearchParams({
        [WebUrlQuery.Section]: DashboardSection.Transcripts,
        [WebUrlQuery.Redirect]: '/ai/home',
        [WebUrlQuery.SeriesId]: seriesId,
        [WebUrlQuery.BotId]: botId,
    });

    return `${getWebAppHost()}?${params.toString()}`;
}

export function getChaptersDeepLink({ botId, seriesId }: AiDeepLinkIds): string {
    const params = new URLSearchParams({
        [WebUrlQuery.Section]: DashboardSection.Chapters,
        [WebUrlQuery.Redirect]: '/ai/home',
        [WebUrlQuery.SeriesId]: seriesId,
        [WebUrlQuery.BotId]: botId,
    });

    return `${getWebAppHost()}?${params.toString()}`;
}

export function getSummaryDeepLink({ botId, seriesId }: AiDeepLinkIds): string {
    const params = new URLSearchParams({
        [WebUrlQuery.Section]: DashboardSection.Summaries,
        [WebUrlQuery.Redirect]: '/ai/home',
        [WebUrlQuery.SeriesId]: seriesId,
        [WebUrlQuery.BotId]: botId,
    });

    return `${getWebAppHost()}?${params.toString()}`;
}

export function getDraftDeepLink({ botId, seriesId }: AiDeepLinkIds): string {
    const params = new URLSearchParams({
        [WebUrlQuery.Section]: DashboardSection.Drafts,
        [WebUrlQuery.Redirect]: '/ai/home',
        [WebUrlQuery.SeriesId]: seriesId,
        [WebUrlQuery.BotId]: botId,
    });

    return `${getWebAppHost()}?${params.toString()}`;
}

export function getBillingAccountDeepLink(): string {
    const params = new URLSearchParams({
        [WebUrlQuery.Section]: DashboardSection.Account,
        [WebUrlQuery.Redirect]: '/ai/home',
    });

    return `${getWebAppHost()}?${params.toString()}`;
}

export function getCalendarMeetingsDeepLink(): string {
    const params = new URLSearchParams({
        [WebUrlQuery.Section]: DashboardSection.Meetings,
        [WebUrlQuery.Redirect]: '/ai/home',
    });

    return `${getWebAppHost()}?${params.toString()}`;
}

export function getActionItemsDeepLink({ botId, seriesId }: AiDeepLinkIds): string {
    const params = new URLSearchParams({
        [WebUrlQuery.Section]: DashboardSection.Actions,
        [WebUrlQuery.Redirect]: '/ai/home',
        [WebUrlQuery.SeriesId]: seriesId,
        [WebUrlQuery.BotId]: botId,
    });

    return `${getWebAppHost()}?${params.toString()}`;
}

export type ExternalClickTrackMetadata = Record<string, any> & {
    experiment: string;
    distinct_id: string;
    cohort?: string | null;
};
export type ExternalClickTrackPayload = ExternalClickTrackMetadata & {
    redirect_url: string;
};

export function getEmailCtaMetadataFromQuery(metadata: string): ExternalClickTrackPayload {
    const decoded = decodeURIComponent(metadata);
    const json: ExternalClickTrackPayload = JSON.parse(decoded);
    return json;
}

export enum KnownCookie {
    UTM_COOKIE_NAME = 'spinachSource',
    ANONYMOUS_UUID_COOKIE_NAME = 'anonymousUserId',
    CELLO_REFERRER_COOKIE_NAME = 'cello-referral',
    CELLO_UCC_QUERY_PARAM_NAME = 'ucc',
    SPINACH_REFERRER_COOKIE_NAME = 'referrer',
}

export const UTM_COOKIE_NAME = KnownCookie.UTM_COOKIE_NAME;
export const CELLO_COOKIE_NAME = KnownCookie.CELLO_REFERRER_COOKIE_NAME;
export const ANONYMOUS_USER_ID_COOKIE_NAME = KnownCookie.ANONYMOUS_UUID_COOKIE_NAME;
