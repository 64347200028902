import { ClientEventType } from '@spinach-shared/types';
import { useEffect, useState } from 'react';

import { useExperienceTracking } from '../../../../../../..';
import { HeaderThree } from '../../../../../../styles';
import { Row } from '../../../../../common';
import { SectionComponentProps, SummaryTextField } from '../shared';

export function AttendeesSectionEditor({ summaryJSON, setSummaryJSON }: SectionComponentProps): JSX.Element {
    const track = useExperienceTracking();
    const [rawInput, setRawInput] = useState<string>(
        summaryJSON.attendees
            .filter((attendee) => attendee.toLowerCase() !== 'spinach.io')
            .filter((attendee) => attendee.toLowerCase() !== 'spinach ai')
            .join(', ')
    );

    useEffect(() => {
        const attendeesString = summaryJSON.attendees
            .filter((attendee) => attendee.toLowerCase() !== 'spinach.io')
            .filter((attendee) => attendee.toLowerCase() !== 'spinach ai')
            .join(', ');
        setRawInput(attendeesString);
    }, [summaryJSON.attendees]);

    const handleAttendeesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRawInput(event.target.value);
    };

    const processAttendees = () => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Edit Attendees',
        });

        // Split by comma, trim whitespace, and filter out empty strings
        const newAttendees = rawInput
            .split(',')
            .map((name) => name.trim())
            .filter((name) => name !== '');

        const updatedJson = {
            ...summaryJSON,
            attendees: newAttendees,
        };
        setSummaryJSON(updatedJson);
    };

    return (
        <>
            <Row style={{ marginTop: '10px' }}>
                <HeaderThree>Attendees</HeaderThree>
            </Row>
            <Row>
                <SummaryTextField
                    id="attendees-input"
                    placeholder="Add attendee names separated by commas"
                    value={rawInput}
                    onChange={handleAttendeesChange}
                    onBlur={processAttendees}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            processAttendees();
                        }
                    }}
                />
            </Row>
        </>
    );
}
