import { Checkbox, FormControlLabel, Modal } from '@material-ui/core';
import { Mic } from '@material-ui/icons';
import { useState } from 'react';
import styled from 'styled-components';

import { ClientEventType } from '@spinach-shared/types';

import {
    BodyRegularOnboard,
    GlobalModal,
    HeaderThreeOnboard,
    PrimaryButton,
    Row,
    Spacing,
    useExperienceTracking,
    useGlobalModal,
} from '../../../..';
import { SpinachModalContent } from '../SpinachModalContent';

export const QUICK_RECORD_SYSTEM_AUDIO_DISCLAIMER_DONT_SHOW_AGAIN_KEY =
    'quick_record_system_audio_disclaimer_dont_show_again';

const ModalContainer = styled.div`
    align-items: center;
    text-align: center;
    display: flex;
    padding: 24px;
    flex-direction: column;
`;

const RecordingIconContainer = styled.div`
    position: relative;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const RecordingCircle = styled.div`
    margin: auto;
    border-radius: 50%;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

const CheckboxContainer = styled.div`
    margin-top: 16px;
    display: flex;
    justify-content: center;
`;

export function QuickRecordSystemAudioDisclaimerModal(): JSX.Element {
    const [globalModal, setGlobalModal] = useGlobalModal();

    if (globalModal !== GlobalModal.QuickRecordSystemAudioDisclaimer) {
        return <></>;
    }

    return (
        <Modal open={true} onClose={() => setGlobalModal(null)}>
            <QuickRecordSystemAudioDisclaimerContent closeModal={() => setGlobalModal(null)} />
        </Modal>
    );
}

function QuickRecordSystemAudioDisclaimerContent({ closeModal }: { closeModal: () => void }): JSX.Element {
    const [hideReminders, setHideReminders] = useState(false);
    const track = useExperienceTracking();

    const handleContinue = () => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Quick Record System Audio Disclaimer Modal Continue button',
        });
        if (hideReminders) {
            localStorage.setItem(QUICK_RECORD_SYSTEM_AUDIO_DISCLAIMER_DONT_SHOW_AGAIN_KEY, 'true');
        }
        closeModal();
    };

    return (
        <SpinachModalContent>
            <ModalContainer>
                <RecordingIconContainer>
                    <RecordingCircle
                        style={{
                            backgroundColor: '#f44336',
                            height: 64,
                            width: 64,
                            zIndex: -10,
                        }}
                    />
                    <RecordingCircle
                        style={{
                            backgroundColor: '#e68481',
                            height: 50,
                            width: 50,
                            zIndex: -5,
                        }}
                    />
                    <Mic style={{ color: 'white', fontSize: '42px' }} />
                </RecordingIconContainer>
                <Spacing factor={1} />
                <HeaderThreeOnboard>Currently recording microphone input only</HeaderThreeOnboard>
                <Spacing factor={0.5} />
                <BodyRegularOnboard>Computer audio (including browser) is not yet supported.</BodyRegularOnboard>
                <Spacing factor={1} />
                <PrimaryButton onClick={handleContinue} title={'Continue'} />
                <CheckboxContainer>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={hideReminders}
                                onChange={(e) => {
                                    setHideReminders(e.target.checked);
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Quick Record Disclaimer dont show again checkbox',
                                        Checked: e.target.checked,
                                    });
                                }}
                            />
                        }
                        label={<BodyRegularOnboard>Got it, hide future reminders</BodyRegularOnboard>}
                    />
                </CheckboxContainer>
            </ModalContainer>
        </SpinachModalContent>
    );
}
