import { Box } from '@material-ui/core';
import { useState } from 'react';
import { useInterval } from 'react-use';
import styled from 'styled-components';

import { formatTime, isLocalStage } from '@spinach-shared/utils';

import { Spacing, useGlobalVideoAgent, useTimer } from '../../../..';
import { safeWidth } from '../../../constants';
import { DiagonalBox } from './DiagonalBox';
import { PrimarySlideContent } from './NoAgendaContent';
import { PreviousPersonsContext } from './PreviousPersonsContext';

const EmphasizedCommand = styled.span`
    color: #00ff00;
    font-weight: 700;
`;

export function AgentAgendaContent(): JSX.Element {
    const { session } = useGlobalVideoAgent();

    const timer = useTimer(session?.currentTopicStartedAt);
    const phaseTimer = useTimer(session?.currentPhaseStartedAt);

    let text = session?.currentTopic?.title || <></>;
    if (session.isConclusionPhase) {
        text = <EmphasizedCommand>Thanks for joining!</EmphasizedCommand>;
    }

    const shouldShowPhaseTimer = (session?.isLobbyPhase || session?.isConclusionPhase) && isLocalStage();

    const previousActionItems = session?.previousActionItemsForCurrentTopic;
    const previousBlockers = session?.previousBlockersForCurrentTopic;
    const shouldAnimateBetweenContexts = previousActionItems.length && previousBlockers.length;
    const [showBlockers, setShowBlockers] = useState(!!previousBlockers?.length);
    const [opacity, setOpacity] = useState(1);

    useInterval(() => {
        if (shouldAnimateBetweenContexts && previousActionItems?.length && previousActionItems.length) {
            setOpacity(0);
            setTimeout(() => {
                setOpacity(1);
                setShowBlockers((prev) => !prev);
            }, 500);
        }
    }, 10_000);

    return (
        <>
            <Box
                position="absolute"
                width={safeWidth}
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection={'column'}
                zIndex={30}
            >
                <PrimarySlideContent>
                    <Box flexGrow={1} />
                    <Box
                        style={{
                            textAlign: 'center',
                            fontSize: '72px',
                            fontWeight: 600,
                            color: 'white',
                        }}
                    >
                        {text}
                    </Box>
                    <Box
                        style={{
                            textAlign: 'center',
                            fontSize: '35px',
                            color: 'white',
                            marginTop: '20px',
                        }}
                    >
                        {session?.currentTopic ? formatTime(timer + session.currentTopicTimeOffset) : null}
                    </Box>

                    {session.isPaused ? (
                        <Box fontSize={65} marginTop={'20px'}>
                            <DiagonalBox color="#FCD008" text="Recording Paused" />
                        </Box>
                    ) : !showBlockers ? (
                        <PreviousPersonsContext
                            items={previousActionItems?.map((item) => item.title)}
                            title={'PREVIOUS ACTION ITEMS'}
                            opacity={opacity}
                        />
                    ) : (
                        <PreviousPersonsContext
                            items={previousBlockers?.map((item) => item.content)}
                            title={'PREVIOUS BLOCKERS'}
                            opacity={opacity}
                        />
                    )}

                    {shouldShowPhaseTimer ? (
                        <Box
                            style={{
                                textAlign: 'center',
                                fontSize: '42px',
                                color: 'white',
                                marginTop: '20px',
                            }}
                        >
                            {session?.currentPhase ? formatTime(phaseTimer + session.currentPhaseTimeOffset) : null}
                        </Box>
                    ) : null}

                    <Box flexGrow={1} />
                </PrimarySlideContent>
            </Box>
        </>
    );
}
