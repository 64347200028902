import React from 'react';
import { useLocation } from 'react-router-dom';

import { DashboardSection } from '@spinach-shared/constants';
import { ClientEventType, WebUrlQuery } from '@spinach-shared/types';

import { GlobalModal, GlobalModalMetadataType } from '../../../atoms';
import {
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalRouting,
    usePaymentUrl,
    useStripeUpgradeInAppEnablement,
    useWindowSize,
} from '../../../hooks';
import { URLUtil } from '../../../utils';
import { initFullstory } from '../../../utils/fullstory';
import { ClientPath } from '../../common';

export function useNavigateToUpgrade() {
    const paymentUrl = usePaymentUrl();
    const isStripeUpgradeInAppEnabled = useStripeUpgradeInAppEnablement();
    const track = useExperienceTracking();
    const { routeToAIDashboard } = useGlobalRouting();
    const { setActiveSection } = useGlobalAiDashboard();
    const location = useLocation();
    const [user] = useGlobalAuthedUser();
    const isCheckoutInModalEnabled = user?.isEnabledForCheckoutInModal;
    const [_, setGlobalModal] = useGlobalModal();
    const { width: windowWidth } = useWindowSize();

    return (clickedOn: string) => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: clickedOn,
            IsStripeUpgradeInAppEnabled: isStripeUpgradeInAppEnabled,
        });
        initFullstory();

        if (isCheckoutInModalEnabled && windowWidth > 600) {
            setGlobalModal(GlobalModal.Checkout, {
                metadata: {
                    metadataType: GlobalModalMetadataType.Checkout,
                    skipPlanSelectionStep: false,
                },
            });
            return;
        }

        if (isStripeUpgradeInAppEnabled) {
            if (location.pathname === ClientPath.AIHome) {
                setActiveSection(DashboardSection.Account);
            } else {
                routeToAIDashboard(
                    { replace: true },
                    new URLSearchParams({
                        [WebUrlQuery.Section]: DashboardSection.Account,
                    })
                );
            }
        } else {
            URLUtil.openURL(paymentUrl, {
                redirectThroughBackendWhenOnZoom: true,
            });
        }
    };
}
