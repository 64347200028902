import { Box, CircularProgress } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { useState } from 'react';

import { DashboardSection } from '@spinach-shared/constants';
import { isMeetingHeySpinachTicketCreationResult } from '@spinach-shared/schemas';
import {
    ClientEventType,
    MeetingActionItem,
    MeetingHeySpinachTicketCreationResult,
    MeetingSelection,
    SummaryJson,
} from '@spinach-shared/types';

import { postNotifyMeetingParticipants } from '../../../../apis';
import { useGlobalAiDashboard, useGlobalAuthedUser, useLandingAnalytic } from '../../../../hooks';
import { useExperienceTracking } from '../../../../hooks/useExperienceTracking';
import { BodyRegular, BodyRegularOnboard, HeaderThree, lightTheme } from '../../../../styles';
import { SetValue } from '../../../../types';
import { BootstrapTooltip, Column, Row, Spacing } from '../../../common';
import { PrimaryButton } from '../../../stand-up';
import { isUnpublishedDraft } from '../utils';
import { ActionItemSummaryComponent } from './ActionItemSummaryComponent';
import { HeySpinachActionItemComponent } from './HeySpinachActionItemComponent';

export function ActionsSectionContainer({
    summaryJson,
    botId,
    setSummaryJson,
    selection,
}: {
    summaryJson: SummaryJson | null;
    setSummaryJson: SetValue<SummaryJson | null>;
    botId: string;
    selection: MeetingSelection | null;
}): JSX.Element {
    const [isRequestingAccess, setIsRequestingAccess] = useState(false);
    const { setToastText } = useGlobalAiDashboard();
    const track = useExperienceTracking();

    if (!summaryJson) {
        return (
            <CircularProgress
                size={'30px'}
                style={{ marginLeft: '10px', marginBottom: '15px', color: lightTheme.primary.orangeDark }}
            />
        );
    }

    if (selection && !selection.isEditor && isUnpublishedDraft(selection)) {
        return (
            <Box padding={0}>
                <Box display="flex" flexDirection="column" alignItems="start" justifyContent="center" width="100%">
                    <Box display="flex" alignItems="center" bgcolor="#EEF9F6" p={2} borderRadius={1} mb={2} mt={2}>
                        <InfoOutlined style={{ color: '#4D9F8A', marginRight: '8px' }} />
                        <BodyRegular>
                            This summary is still being edited. You can nudge the editors to publish it.
                        </BodyRegular>
                    </Box>
                    <PrimaryButton
                        title="Nudge editor"
                        isLoading={isRequestingAccess}
                        onClick={async () => {
                            setIsRequestingAccess(true);
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Nudge editor',
                                MeetingTitle: selection?.title || '',
                            });

                            try {
                                await postNotifyMeetingParticipants(selection.botId, 'request-publish');
                                setToastText('Publish request sent to editors');
                            } catch (error) {
                                track(ClientEventType.AIDashboardUnhappyPath, {
                                    Error: (error as Error)?.message,
                                    Flow: 'Nudge Editor',
                                });
                                setToastText('Failed to send request. Please try again later.');
                            } finally {
                                setIsRequestingAccess(false);
                            }
                        }}
                    />
                    <Spacing />
                </Box>
            </Box>
        );
    }

    const actionItems = summaryJson.actionItems;

    return (
        <Column>
            {!actionItems?.length ? (
                <BodyRegular>No action items for this meeting</BodyRegular>
            ) : (
                <ActionsSection
                    botId={botId}
                    summaryJson={{ ...summaryJson, actionItems }}
                    setSummaryJson={setSummaryJson}
                />
            )}
        </Column>
    );
}

function ActionsSection({
    botId,
    summaryJson,
    setSummaryJson,
}: {
    botId: string;
    summaryJson: Omit<SummaryJson, 'actionItems'> & { actionItems: MeetingActionItem[] };
    setSummaryJson: SetValue<SummaryJson | null>;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const actionItems = summaryJson.actionItems;
    const { setActiveSection } = useGlobalAiDashboard();
    const heySpinachTickets: MeetingHeySpinachTicketCreationResult[] =
        summaryJson.heySpinach?.filter(isMeetingHeySpinachTicketCreationResult) ?? [];
    // Only run landing analytics after load, the user has not really "landed" on the page until then
    useLandingAnalytic(ClientEventType.AIDashboardActivity, {
        Action: 'Opened Actions Section',
        BotId: botId,
        NumberOfActionItems: actionItems?.length,
        NumberOfActionItemsWithTicket: actionItems?.filter((ai) => !!ai.ticketData).length,
    });

    const isMultipleTicketProjectsEnabled = user.isMultiTicketProjectsDuringTicketCreationEnabled;
    const [isHoveringSettingsButton, setIsHoveringSettingsButton] = useState(false);

    return (
        <>
            <Row vCenter style={{ width: 'unset' }}>
                <HeaderThree>Action Items</HeaderThree>
                <Spacing horizontal factor={1 / 4} />
                {isMultipleTicketProjectsEnabled ? (
                    <BootstrapTooltip
                        arrow
                        interactive
                        placement="top"
                        title={
                            <>
                                <BodyRegularOnboard style={{ color: lightTheme.neutrals.white }}>
                                    Default ticket preferences can be configured in{' '}
                                    <span
                                        style={{
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                            color: isHoveringSettingsButton
                                                ? lightTheme.primary.green
                                                : lightTheme.primary.greenLight,
                                        }}
                                        onMouseEnter={() => setIsHoveringSettingsButton(true)}
                                        onMouseLeave={() => setIsHoveringSettingsButton(false)}
                                        onClick={() => setActiveSection(DashboardSection.Integrations)}
                                    >
                                        Settings
                                    </span>
                                </BodyRegularOnboard>
                            </>
                        }
                    >
                        <InfoOutlined
                            htmlColor={lightTheme.primary.greenLight}
                            style={{ marginRight: '10px' }}
                            fontSize="small"
                        />
                    </BootstrapTooltip>
                ) : (
                    <></>
                )}
            </Row>
            <Spacing factor={1 / 3} />
            {user.isHeySpinachCreateTicketEnabled
                ? heySpinachTickets.map((item) => {
                      const onItemUpdated = (updatedHeySpinach: MeetingHeySpinachTicketCreationResult) => {
                          setSummaryJson({
                              ...summaryJson,
                              heySpinach: heySpinachTickets.map((ticket) =>
                                  ticket.id === item.id ? updatedHeySpinach : ticket
                              ),
                          });
                      };

                      return (
                          <HeySpinachActionItemComponent
                              key={item.id}
                              actionItem={item}
                              onItemUpdated={onItemUpdated}
                              botId={botId}
                          />
                      );
                  })
                : null}
            {actionItems.map((actionItem, index) => {
                const setActionItem = (updatedActionItem: MeetingActionItem) => {
                    setSummaryJson({
                        ...summaryJson,
                        actionItems: [
                            ...actionItems.slice(0, index),
                            updatedActionItem,
                            ...actionItems.slice(index + 1),
                        ],
                    });
                };

                return (
                    <ActionItemSummaryComponent
                        key={actionItem.id}
                        actionItem={actionItem}
                        setActionItem={setActionItem}
                        botId={botId}
                    />
                );
            })}
        </>
    );
}
