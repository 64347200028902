import { Check, Lens, PanTool, VisibilityOff } from '@material-ui/icons';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { CURRENT_ITEMS_CUSTOM_LIST_ID } from '@spinach-shared/constants';
import {
    AttachmentBarOptions,
    ClientEventType,
    GoalStatus,
    ResolverAttachmentButtonAction,
    ResolverMetadata,
    TypedUpdate,
    TypedUpdateWrapper,
} from '@spinach-shared/types';

import { ReactComponent as AsanaLogo } from '../../assets/Asana-logo.svg';
import { ReactComponent as AddReactionIcon } from '../../assets/add-reaction-icon.svg';
import { ReactComponent as JiraLogo } from '../../assets/jira-logo.svg';
import { ReactComponent as MultilineIcon } from '../../assets/multiline-attachment-icon.svg';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalLiveSeries,
    useGlobalSeriesId,
    useGlobalStoredSeries,
    useIssueBasedEnablement,
    useIssueResolutionEnablement,
    useReadOnlyPresentation,
} from '../../hooks';
import { useGlobalAttachment } from '../../hooks/useGlobalAttachments';
import { BodyRegular, lightTheme } from '../../styles';
import {
    AsanaAttachmentProps,
    IssueResolutionAttachmentProps,
    JiraAttachmentProps,
    MultiLineAttachmentProps,
    ReactionsAttachmentProps,
} from '../../types';
import { BootstrapTooltip, Row } from '../common';
import { AsanaPicker } from './AsanaPicker';
import { ReactionPicker } from './Reaction';
import { JiraPicker } from './jira';

const AttachmentBar = styled.div`
    position: absolute;
    height: 34px;
    right: 15px;
    top: -24px;
    padding: 0 5px;
    border: 1px solid #b8b8c5;
    background-color: white;
    box-sizing: border-box;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    display: flex;
    z-index: 10000;
`;

interface AttachmentsProps {
    asanaProps: AsanaAttachmentProps;
    jiraProps: JiraAttachmentProps;
    reactionsProps: ReactionsAttachmentProps;
    issueResolutionProps: IssueResolutionAttachmentProps;
    multiLineProps: MultiLineAttachmentProps;
    shouldShow: boolean;
    isInNavDrawer: boolean;
}

const AttachmentButton = styled.span`
    position: relative;
    width: 16px;
    height: 16px;
    padding: 3px;
    margin-left: 2px;
    border-radius: 2px;
    border: 1px solid transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin: auto;

    &:hover {
        border-color: #b8b8c5;
        background-color: ${() => lightTheme.neutrals.grayLight};
    }
`;

export function Attachments({
    asanaProps,
    jiraProps,
    reactionsProps,
    multiLineProps,
    shouldShow,
    isInNavDrawer,
    issueResolutionProps,
}: AttachmentsProps) {
    const { typedUpdate } = reactionsProps;
    const wrapper = new TypedUpdateWrapper(typedUpdate);
    const { saveFullTypedUpdate } = multiLineProps;
    const { setTypedUpdate } = issueResolutionProps;
    const {
        isAsanaOpen,
        isReactionsOpen,
        isJiraOpen,
        setAttachment,
        attachment,
        closeAttachments,
        itemId: attachmentItemId,
    } = useGlobalAttachment();

    const isIssueResolutionEnabled = useIssueResolutionEnablement();
    const showJira = !jiraProps.isDisabled && !typedUpdate.jiraData && !typedUpdate.ticketData?.ticket;
    const showAsana = !asanaProps.isDisabled && !typedUpdate.asanaData;
    const [reactionColor, setReactionColor] = useState(lightTheme.neutrals.white);

    const showAttachmentsThatRequireText = typedUpdate.text !== '';
    const [user] = useGlobalAuthedUser();
    const seriesId = useGlobalSeriesId();
    const [series] = useGlobalStoredSeries();
    const track = useExperienceTracking();
    const ref = useRef<HTMLDivElement | null>(null);

    const isIssueBasedEnabled = useIssueBasedEnablement();
    const isReadOnlyPresentation = useReadOnlyPresentation();
    const shouldHideReactions =
        isReadOnlyPresentation && isInNavDrawer && typedUpdate.customListId === CURRENT_ITEMS_CUSTOM_LIST_ID;

    useEffect(() => {
        return () => {
            return closeAttachments();
        };
    }, []);

    useEffect(() => {
        if (!shouldShow && isReactionsOpen() && attachmentItemId === typedUpdate.id) {
            closeAttachments();
            handleButtonAction({ method: 'mouse-off' });
        }
    }, [shouldShow]);

    const pickerOffset = ref.current?.clientWidth ?? 0;

    // Closes all toolbar modals except for the one clicked, which is toggled
    // Method should be used in any new components used as a child in the AttachmentBar
    const handleButtonAction = ({
        attachmentToolbarType,
        method,
        data = {},
    }: {
        attachmentToolbarType?: AttachmentBarOptions;
        method: 'click' | 'mouse-off';
        data?: object;
    }) => {
        if (!attachmentToolbarType || attachmentToolbarType === attachment) {
            closeAttachments();
        } else {
            setAttachment(attachmentToolbarType ?? null, typedUpdate.id);
        }
        track(ClientEventType.AttachmentButtonClick, {
            AttachmentOption: attachmentToolbarType,
            SeriesId: seriesId ?? '',
            ButtonAction: attachment === attachmentToolbarType ? 'Close' : !!attachmentToolbarType ? 'Open' : 'Close',
            Method: method,
            ...data,
        });
    };

    return shouldShow && showAttachmentsThatRequireText ? (
        <div style={{ position: 'absolute', right: '0px' }}>
            <ReactionPicker
                {...reactionsProps}
                offset={pickerOffset}
                isOpen={isReactionsOpen() && typedUpdate.id === attachmentItemId}
                setIsOpen={(isOpen: boolean) =>
                    isOpen ? setAttachment(AttachmentBarOptions.Reaction, typedUpdate.id) : closeAttachments()
                }
            />
            <AttachmentBar ref={ref}>
                {showAttachmentsThatRequireText && (
                    <>
                        <IssueBasedAttachments
                            isInNavDrawer={isInNavDrawer}
                            saveFullTypedUpdate={saveFullTypedUpdate}
                            typedUpdate={typedUpdate}
                        />
                        {isIssueResolutionEnabled && !isInNavDrawer ? (
                            <BootstrapTooltip title={'Help with this?'} arrow placement="top">
                                <AttachmentButton
                                    onClick={() => {
                                        if (wrapper.isUserResolverForItem(user.spinachUserId)) {
                                            /** @NOTE typedUpdate.resolvers is always non-null due to isUserResolverForItem checking for this */
                                            const resolverWithoutUser = typedUpdate.resolvers?.filter(
                                                (resolver) => resolver.spinachUserId !== user.spinachUserId
                                            );
                                            setTypedUpdate?.({ ...typedUpdate, resolvers: resolverWithoutUser });
                                            saveFullTypedUpdate?.({
                                                ...typedUpdate,
                                                resolvers: resolverWithoutUser,
                                            });
                                        } else {
                                            const userMeta = series.userMetadataList.find(
                                                (um) => um._id === user.spinachUserId
                                            );
                                            if (userMeta) {
                                                const now = new Date().toISOString();
                                                const resolver: ResolverMetadata = {
                                                    spinachUserId: userMeta._id,
                                                    email: userMeta.email,
                                                    preferredName: userMeta.preferredName ?? '',
                                                    createdOn: now,
                                                    updatedOn: now,
                                                };
                                                const resolverWithUser = [...(typedUpdate.resolvers ?? []), resolver];
                                                setTypedUpdate?.({ ...typedUpdate, resolvers: resolverWithUser });
                                                saveFullTypedUpdate?.({
                                                    ...typedUpdate,
                                                    resolvers: resolverWithUser,
                                                });
                                            }
                                        }

                                        handleButtonAction({
                                            attachmentToolbarType: AttachmentBarOptions.IssueResolution,
                                            method: 'click',
                                            data: {
                                                Action: wrapper.isUserResolverForItem(user.spinachUserId)
                                                    ? ResolverAttachmentButtonAction.Remove
                                                    : ResolverAttachmentButtonAction.Add,
                                            },
                                        });
                                    }}
                                >
                                    <PanTool
                                        htmlColor={
                                            wrapper.isUserResolverForItem(user.spinachUserId)
                                                ? lightTheme.primary.greenDark
                                                : lightTheme.primary.greenLight
                                        }
                                        style={{ width: '14px', height: '14px' }}
                                    />
                                </AttachmentButton>
                            </BootstrapTooltip>
                        ) : (
                            <></>
                        )}
                        {!shouldHideReactions ? (
                            <AttachmentButton
                                onMouseEnter={() => setReactionColor(lightTheme.tertiary.orangeDark)}
                                onMouseLeave={() => setReactionColor(lightTheme.neutrals.white)}
                                onClick={() => {
                                    handleButtonAction({
                                        attachmentToolbarType: AttachmentBarOptions.Reaction,
                                        method: 'click',
                                    });
                                }}
                            >
                                <AddReactionIcon fill={reactionColor} />
                            </AttachmentButton>
                        ) : null}

                        {!isIssueBasedEnabled || (isReadOnlyPresentation && isInNavDrawer) ? (
                            <BootstrapTooltip title={'Hint: You can also Shift+Enter to create a sub item'}>
                                <AttachmentButton
                                    id={'multiline-attachment-button'}
                                    style={{ alignItems: 'center' }}
                                    onClick={() => {
                                        multiLineProps.handleClick?.();
                                        track(ClientEventType.MultilineButtonClick);
                                    }}
                                >
                                    <MultilineIcon />
                                </AttachmentButton>
                            </BootstrapTooltip>
                        ) : null}
                    </>
                )}
                {showJira && (
                    <AttachmentButton
                        onClick={() =>
                            handleButtonAction({ attachmentToolbarType: AttachmentBarOptions.Jira, method: 'click' })
                        }
                    >
                        <JiraLogo />
                    </AttachmentButton>
                )}
                {showAsana && (
                    <AttachmentButton
                        onClick={() =>
                            handleButtonAction({ attachmentToolbarType: AttachmentBarOptions.Asana, method: 'click' })
                        }
                    >
                        <AsanaLogo />
                    </AttachmentButton>
                )}
            </AttachmentBar>
            <JiraPicker
                {...jiraProps}
                isOpen={isJiraOpen() && typedUpdate.id === attachmentItemId}
                setIsOpen={(isOpen: boolean) =>
                    isOpen ? setAttachment(AttachmentBarOptions.Jira, typedUpdate.id) : closeAttachments()
                }
            />
            <AsanaPicker
                {...asanaProps}
                isOpen={isAsanaOpen() && typedUpdate.id === attachmentItemId}
                setIsOpen={(isOpen: boolean) =>
                    isOpen ? setAttachment(AttachmentBarOptions.Asana, typedUpdate.id) : closeAttachments()
                }
            />
        </div>
    ) : null;
}

export function IssueBasedAttachments({
    typedUpdate,
    isInNavDrawer,
    saveFullTypedUpdate,
    setTypedUpdate,
}: {
    typedUpdate: TypedUpdate;
    isInNavDrawer?: boolean;
    setTypedUpdate?: (typedUpdate: TypedUpdate) => void;
    saveFullTypedUpdate?: (typedUpdate: TypedUpdate) => void;
}): JSX.Element {
    const track = useExperienceTracking();
    const [liveSeries] = useGlobalLiveSeries();
    const isIssueBasedEnabled = useIssueBasedEnablement();
    const wrapper = new TypedUpdateWrapper(typedUpdate);
    const goal = wrapper.asGoal;

    const shouldShow =
        isIssueBasedEnabled && isInNavDrawer && !!goal && typedUpdate.customListId === CURRENT_ITEMS_CUSTOM_LIST_ID;

    if (!shouldShow) {
        return <></>;
    }

    const onButtonClick = async (status: GoalStatus) => {
        if (!typedUpdate.goalMetadata) {
            return;
        }

        let sentimentProps: Pick<TypedUpdate, 'sentiment' | 'sentimentDetails'> = {
            sentiment: typedUpdate.sentiment,
            sentimentDetails: typedUpdate.sentimentDetails,
        };

        if (status === GoalStatus.Complete) {
            sentimentProps = {};
        }

        const updated: TypedUpdate = {
            ...typedUpdate,
            goalMetadata: {
                ...typedUpdate.goalMetadata,
                ...sentimentProps,
                status,
                sharedInSeries: [liveSeries.slug],
            },
        };

        setTypedUpdate?.(updated);
        saveFullTypedUpdate?.(updated);
        track(ClientEventType.HiddenItemStatusChanged, {
            Status: status,
            OriginalStatus: typedUpdate.goalMetadata.status,
            UpdateId: typedUpdate.id,
            GoalId: typedUpdate.goalMetadata.goalId,
        });
    };

    return (
        <Row>
            <AttachmentButton
                style={{
                    width: '90px',
                    alignItems: 'center',
                    backgroundColor: 'initial',
                }}
                onClick={() => {
                    if (goal.status === GoalStatus.InProgress) {
                        return;
                    }
                    onButtonClick(GoalStatus.InProgress);
                }}
            >
                <Lens
                    style={{ fontSize: '12px' }}
                    htmlColor={
                        goal.status === GoalStatus.InProgress && !goal.isArchived
                            ? lightTheme.status.info
                            : lightTheme.secondary.midnight
                    }
                />
                <BodyRegular
                    style={{
                        fontWeight: goal.status === GoalStatus.InProgress ? 'bold' : 'normal',
                        color:
                            goal.status === GoalStatus.InProgress && !goal.isArchived
                                ? lightTheme.status.info
                                : '#757575',
                    }}
                >
                    WORKING
                </BodyRegular>
            </AttachmentButton>
            <AttachmentButton
                style={{
                    width: '70px',
                    alignItems: 'center',
                    backgroundColor: 'initial',
                }}
                onClick={() => {
                    if (goal.status === GoalStatus.Complete) {
                        return;
                    }
                    onButtonClick(GoalStatus.Complete);
                }}
            >
                <Check
                    fontSize="small"
                    htmlColor={
                        goal.status === GoalStatus.Complete && !goal.isArchived ? lightTheme.secondary.green : '#757575'
                    }
                />
                <BodyRegular
                    style={{
                        fontWeight: goal.status === GoalStatus.Complete ? 'bold' : 'normal',
                        color:
                            goal.status === GoalStatus.Complete && !goal.isArchived
                                ? lightTheme.secondary.green
                                : '#757575',
                    }}
                >
                    DONE
                </BodyRegular>
            </AttachmentButton>
            {!typedUpdate.goalMetadata?.isArchived ? (
                <AttachmentButton
                    style={{
                        width: '70px',
                        alignItems: 'center',
                        backgroundColor: 'initial',
                    }}
                    onClick={() => {
                        if (goal.status === GoalStatus.Unset) {
                            return;
                        }
                        onButtonClick(GoalStatus.Unset);
                    }}
                >
                    <VisibilityOff
                        htmlColor={goal.status === GoalStatus.Unset ? '#E4A988' : '#757575'}
                        style={{ fontSize: '12px' }}
                    />
                    <BodyRegular
                        style={{
                            fontWeight: goal.status === GoalStatus.Unset ? 'bold' : 'normal',
                            color: goal.status === GoalStatus.Unset ? '#E4A988' : '#757575',
                        }}
                    >
                        HIDE
                    </BodyRegular>
                </AttachmentButton>
            ) : (
                <></>
            )}
        </Row>
    );
}
