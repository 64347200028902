import { Box } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { ClientEventType } from '@spinach-shared/types';

import { useExperienceTracking, useGlobalAiDashboard, useGlobalSearchShortcut } from '../../../../hooks';

export function SearchBox(): JSX.Element {
    const { setIsSearchVisible, isSearchVisible } = useGlobalAiDashboard();
    const { isMac } = useGlobalSearchShortcut('');
    const track = useExperienceTracking();

    return (
        <Box
            style={{
                padding: 5,
                cursor: 'pointer',
                marginBottom: '20px',
                border: '1px solid rgba(160, 160, 160, 0.3)',
                minWidth: '60%',
                width: 'calc(100% - 10px)',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '8px',
                backgroundColor: 'white',
                transition: 'background-color 0.2s ease',
            }}
            onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = '#f5f5f5';
            }}
            onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = 'white';
            }}
            onClick={() => {
                setIsSearchVisible(!isSearchVisible);
                track(ClientEventType.AIDashboardClick, {
                    ClickedOn: 'Search Meeting Notes in Sidebar',
                });
            }}
        >
            <SearchIcon style={{ color: '#A0A0A0' }} />
            <span style={{ color: 'gray', marginLeft: 5 }}>
                {`Search notes `}
                {isMac ? `(⌘ + K)` : `(Ctrl + K)`}
            </span>
        </Box>
    );
}
