import { useGlobalAuthedUser, useSidebar } from '../../../../hooks';
import { Column, Row } from '../../../common';
import { DashboardSelectorIcons } from '../DashboardSelectorIcons';
import { ShowcaseGuide } from '../ShowcaseGuide';
import { FullFeaturedSidebarContent } from './FullFeaturedSidebarContent';
import { LimitedFeaturedSidebarContent } from './LimitedFeaturedSidebarContent';

export function Sidebar({ shouldShowShowcaseGuide }: { shouldShowShowcaseGuide: boolean }): JSX.Element {
    const { fullSidebarWidth, userShouldSeeBothApps, appBarWidth, sidebarWidth, isDesktopView } = useSidebar();
    const [user] = useGlobalAuthedUser();
    return (
        <Row
            style={{
                width: isDesktopView ? fullSidebarWidth : '100%',
                height: '100%',
                marginRight: '20px',
            }}
        >
            {userShouldSeeBothApps && isDesktopView ? (
                <Column
                    style={{
                        height: '100%',
                        width: `${appBarWidth}px`,
                        backgroundColor: `#E2E8E8`,
                        zIndex: 1,
                        paddingRight: '5px',
                        paddingLeft: '5px',
                    }}
                >
                    <DashboardSelectorIcons />
                </Column>
            ) : null}
            <Column
                style={{
                    height: '100%',
                    width: isDesktopView ? `${sidebarWidth}px` : '100%',
                    backgroundColor: `#ECF1F0`,
                    zIndex: 1,
                    position: 'relative',
                }}
            >
                {user.isNotSetupForFullDashboardFeatures ? (
                    <LimitedFeaturedSidebarContent />
                ) : (
                    <FullFeaturedSidebarContent />
                )}

                {shouldShowShowcaseGuide ? (
                    <div style={{ padding: '12px' }}>
                        <ShowcaseGuide />
                    </div>
                ) : null}
            </Column>
        </Row>
    );
}
