import { SummaryJson, TranscriptData, ViewableTranscriptLineData } from '@spinach-shared/types';

export const demoBotId = 'ea82a44b-aaaa-aaaa-aaaa-355b4e133e5d';
export const demoSeriesId = 'SPS-demo';

export const demoTranscriptLineData: ViewableTranscriptLineData[] = [
    {
        speaker: 'Yoav',
        statement: 'Hey, everyone.',
        timestamp: 0,
    },
    {
        speaker: 'Yoav',
        statement: 'Thank you so much for signing up for Spinach.',
        timestamp: 1,
    },
    {
        speaker: 'Yoav',
        statement: "We're super excited to have you.",
        timestamp: 3,
    },
    {
        speaker: 'Yoav',
        statement:
            "What we'd love to do in this video is show you all of the different functionality, how to use the platform, and with it, you'll also be able to see a lot of the different experience.",
        timestamp: 5,
    },
    {
        speaker: 'Yoav',
        statement:
            "Over here on the right hand side, you'll be able to see the summary, you'll be able to interact with it.",
        timestamp: 17,
    },
    {
        speaker: 'Yoav',
        statement: "I'll just give you a chance to really understand.",
        timestamp: 22,
    },
    {
        speaker: 'Yoav',
        statement:
            "What we're so excited for you is our mission with Spinach is to help you get the most out of meetings and then ultimately be able to take all of that information and automate a lot of the things that you're doing.",
        timestamp: 26,
    },
    {
        speaker: 'Yoav',
        statement:
            "One of the things that's really powerful is you can be fully present in meetings because spinach does a lot of that heavy lifting.",
        timestamp: 39,
    },
    {
        speaker: 'Yoav',
        statement:
            'And then afterwards, you can take all of that information and create tickets, create follow ups, automate that into your CRM or send it to your ticketing system.',
        timestamp: 46,
    },
    {
        speaker: 'Yoav',
        statement: "And so that's really the magic of what we're trying to do.",
        timestamp: 56,
    },
    {
        speaker: 'Yoav',
        statement:
            'We ultimately want to give you as much time back so you can focus on that human one to one interaction and let AI do a lot of the, a lot of the work for you to really automate things for that.',
        timestamp: 58,
    },
    {
        speaker: 'Yoav',
        statement: "So I'll turn that over to Karin.",
        timestamp: 71,
    },
    {
        speaker: 'Yoav',
        statement:
            "She'll show you a lot of the different experiences and the magic that we've built with spinach to help you really get the most out of your day.",
        timestamp: 74,
    },
    {
        speaker: 'Yoav',
        statement: 'So here you go, Karin, go ahead.',
        timestamp: 82,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'Thanks, Yoav.',
        timestamp: 86,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            "I'm really excited to show you all how spinnish can really help you with your meetings and workflows.",
        timestamp: 87,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'As Yoav said, so much data exists within your meetings.',
        timestamp: 92,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            "We really want to make sure that you're able to be present in the meeting and also extract the most data possible after the meeting ends and automate into your workflows.",
        timestamp: 96,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'So to get started, spinach joins the call like a participant.',
        timestamp: 105,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            'As you can see here, spinach is a participant in the call, same way that any of us or myself joins the call.',
        timestamp: 110,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            'Spinach uses your calendar invite as the source of truth, of where to join, when to join, and who else is going to be on the call.',
        timestamp: 118,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'So just trying to orient you into how spinach works, how it knows where to join.',
        timestamp: 126,
    },
    {
        speaker: 'Karin Sharon',
        statement: "So let me share my screen and I'll show you how to get started and provide more context.",
        timestamp: 130,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            "So to get started, you can just go to spinach IO and you can continue with your Microsoft account or your Google account and then we'll ask you to connect the calendar and recommend the meetings we think spinach will be great on and allow and provide you the opportunity to say, yes, I want spinach to join on these meetings.",
        timestamp: 138,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            "We really recommend that you start off with spinach with some real meetings, because that's really where the magic happens.",
        timestamp: 155,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            "Then you go into your dashboard after you sign in, and then you'll land here where you can see your entire schedule of meetings.",
        timestamp: 163,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'You can easily toggle spinach on to meetings.',
        timestamp: 171,
    },
    {
        speaker: 'Karin Sharon',
        statement: "You can toggle off if you decide you don't want spinach to join a specific meeting.",
        timestamp: 173,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            "You can also set up an automation say hey, I don't want to have to remember to come in here to add spinach to any of my meetings.",
        timestamp: 178,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            'So you can set up an automation in all meetings, meetings are organized internal, external, or by keyword, and then you can leave it and forget it kind of situation.',
        timestamp: 185,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'Before meeting starts, you can come in here and customize the settings.',
        timestamp: 196,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'Now AI may be a new tool for you, a new experience for you.',
        timestamp: 200,
    },
    {
        speaker: 'Karin Sharon',
        statement: "You want to make sure you're always in control.",
        timestamp: 205,
    },
    {
        speaker: 'Karin Sharon',
        statement: "So ahead of the call we make sure that you're able to really customize your experience.",
        timestamp: 207,
    },
    {
        speaker: 'Karin Sharon',
        statement: "So here's where you can see who the spinach host is for the call.",
        timestamp: 213,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'Spinach host is a really important role.',
        timestamp: 218,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            'So the meeting organizer may be the one that scheduled that calendar invite, but you are the spinach host.',
        timestamp: 220,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            "You're the person who wants to invite Spinach to the meeting and all the settings integrations follow you.",
        timestamp: 226,
    },
    {
        speaker: 'Karin Sharon',
        statement: "So as you can see, I'm the Corinne myself is spinach host on a different account.",
        timestamp: 232,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            "But since I want to own the Spinach host responsibilities and be able to edit and I'll show you some of the other features you can unlock here.",
        timestamp: 239,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'Soon I can claim host and now I get the baton.',
        timestamp: 248,
    },
    {
        speaker: 'Karin Sharon',
        statement: "So then you can see I'm the host.",
        timestamp: 257,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'I could also set to review and edit the summary before it gets sent out.',
        timestamp: 259,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            "So after the call I'll get a prompt in my inbox to review the summary, make sure everything's correct, and then send it on.",
        timestamp: 263,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            "I could also control who receives the summary, whether it's everyone on the invite, just myself or internal participants as well.",
        timestamp: 269,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            "If you let's say you're on a client call, you don't want the client to receive it, but you don't mind everyone in the company receive it.",
        timestamp: 278,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'You could set that up and then you can also see what that meeting format will look like.',
        timestamp: 283,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            'So we auto match the meeting type and template based on the meeting title but you can also customize it ahead of the call.',
        timestamp: 288,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            'And if you click here you can see quickly see what all our meeting templates provide and what the sections would be right here.',
        timestamp: 295,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            'In general, as you can see, we cover key topics, action items, decisions, blockers in the call and this is what we listen to.',
        timestamp: 303,
    },
    {
        speaker: 'Karin Sharon',
        statement: "We also have sales templates, customer success, all hands and we'll be adding more soon.",
        timestamp: 311,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            "Once you have your meeting set up for success, you can change the language if you have a global team, we support over 100 languages and you're ready to go for your meeting.",
        timestamp: 317,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'Now spinach joins your meeting.',
        timestamp: 326,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            'We listen in for that important conversation and then we produce a summary at the end that gets sent to your inbox as well as this meeting history.',
        timestamp: 329,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'Now this is the magic of spinach.',
        timestamp: 337,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'As you can see, this is my past meeting.',
        timestamp: 340,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'I can look at all my meetings here real quick.',
        timestamp: 342,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'I can see the summary as you can see, hey spinach.',
        timestamp: 344,
    },
    {
        speaker: 'Karin Sharon',
        statement: "I'll cover if you want it.",
        timestamp: 348,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'Spinach listens into action items and the call.',
        timestamp: 350,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            "But if you want to make sure that spinach really doesn't forget something and let's say it's your top three items that you are in the call with and you want to make sure we cover, you can always say hey spinach, capture this action item or hey spinach, create a ticket and we'll listen in for that.",
        timestamp: 353,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'So as you can see, we have that here.',
        timestamp: 367,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            'Key topics broken down by percentage of the time spent on them action items key decisions and celebrations.',
        timestamp: 369,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'You can also see the raw transcript from the previous meeting if you ever need to revisit it.',
        timestamp: 378,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            'Provide you with the recording and video for seven days so you can listen back in if you missed a meeting or revisit what a client said.',
        timestamp: 384,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            'You can download to keep the video or integrate with Google Drive to automatically save the video as well.',
        timestamp: 392,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            "You can also copy out the summary, copy the transcript in case you need that and then now I'll cover action items.",
        timestamp: 398,
    },
    {
        speaker: 'Karin Sharon',
        statement: "So I'll show you how to connect to your ticketing tools in a moment here.",
        timestamp: 404,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            'But we do support many ticketing tools, both to reference tickets in the conversation based on the discussion, and then also give you the ability to create a ticket back into your board with one click.',
        timestamp: 408,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'So we listen in for those action items, those decisions.',
        timestamp: 420,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            'We will draft the ticket for you and then you can with one click create a ticket and you select the project of where it needs to go.',
        timestamp: 424,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'You assign it to whomever needs and update your ticket into your board.',
        timestamp: 431,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'I will also cover this wonderful feature called Ask Spinach.',
        timestamp: 436,
    },
    {
        speaker: 'Karin Sharon',
        statement: "So as I mentioned, there's so much data happening in your meetings, right?",
        timestamp: 440,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            "You want to make sure that you get the most out of the meeting after it happens, and then you're on your way.",
        timestamp: 444,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'So we give you the opportunity to ask any question you want against the meeting that happened.',
        timestamp: 449,
    },
    {
        speaker: 'Karin Sharon',
        statement: "Hey, what was ex client's feedback about the product?",
        timestamp: 453,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'For instance, you can generate reports.',
        timestamp: 457,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            'You can generate a follow up email that you can then copy out and then immediately paste it into your inbox, make edits and send your email on your way.',
        timestamp: 459,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'We provide the sentiment report, coaching report, and some sales reports and more coming soon.',
        timestamp: 469,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'So really this will speed up your workflows after the call happens.',
        timestamp: 476,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            'If you want to generate a timeline for a project, any estimates, deadlines, really, the world is your oyster.',
        timestamp: 480,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'With this you can pick your meetings with Ask Spinach.',
        timestamp: 488,
    },
    {
        speaker: 'Karin Sharon',
        statement: "Now I've shown you how spinach joins your meetings and what outcomes you get.",
        timestamp: 494,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'Now let me revisit some of the other settings to help customize your experience.',
        timestamp: 500,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            'So if you go into settings, you could then enable the ability to edit all of your summaries before they get sent out.',
        timestamp: 505,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            'I showed you how to customize that per meeting, but you can establish that for all of your meetings.',
        timestamp: 511,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            'We do support over 100 languages, so you can designate your speaking language as well as the output language that you would like.',
        timestamp: 515,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            "We can represent your brand in calls so you can swap the spinnish logo for your own so you're always representing your brand in the call.",
        timestamp: 526,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'As you saw, spinach joins as a participant in the call so that screen will show your brand.',
        timestamp: 533,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            'We also integrate with Slack to send a copy of your spinach summary to slack in a slack friendly thread, and then you can ask questions with ask Spinach in Slack as well as well as we can join your Slack huddles now.',
        timestamp: 540,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'Ticketing integrations I showed you how to create a ticket.',
        timestamp: 554,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            "We support Jira as on a linear ClickUp trello [money.com](http://money.com/) with more integrations coming soon, you can connect your tool, your project, and then you'll be able to create tickets.",
        timestamp: 556,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            'We also have a feature to record custom vocabulary for any misspellings or keywords or company jargon that you have.',
        timestamp: 567,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            'You can also save your spinach summaries automatically into your confluence notion or Google Drive.',
        timestamp: 576,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            'Then it sends privately to you, and then you can grant access to any stakeholders who need that visibility and have a safekeeping of spinach summaries to visit later.',
        timestamp: 582,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            'And then we also have CRM integrations which you can update your contacts and also move your spinach summary to the contact based on the meeting.',
        timestamp: 592,
    },
    {
        speaker: 'Karin Sharon',
        statement: "Let's say you met with a client and you want that record, you can pull that into your CRM.",
        timestamp: 602,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            'Lastly, we have a zapier integration that is part of that automation that Yoav mentioned in the beginning.',
        timestamp: 608,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            'We really want to make sure that you can be on your way with your workflows and work streams and connect to any tools that you need, any integrations that you use, any way that you want to use your spinach summary, you can get that data and connect it to your tools.',
        timestamp: 613,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            'So we also have this feature called off the record where if you want to be able to be in control of any confidential conversation or sensitive information at any point, you can type pause in the chat and spinach will completely pause that recording and not listen in at all in that conversation.',
        timestamp: 620,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            "It's completely off the record and then you type in resume and spinach will be back up and listening again.",
        timestamp: 636,
    },
    {
        speaker: 'Karin Sharon',
        statement:
            "So I just wanted to make sure that you're ready for success, ready to get started, ready to leverage spinach in your meetings to get the most out of them possible.",
        timestamp: 643,
    },
    {
        speaker: 'Karin Sharon',
        statement: 'Thank you so much for joining us today and listening.',
        timestamp: 652,
    },
    {
        speaker: 'Yoav',
        statement: 'Awesome, thanks so much.',
        timestamp: 656,
    },
    {
        speaker: 'Yoav',
        statement: 'We hope you get the most out of it.',
        timestamp: 657,
    },
    {
        speaker: 'Yoav',
        statement: 'If you have any questions, you can reach out to us at support@spinach.io',
        timestamp: 659,
    },
    {
        speaker: 'Yoav',
        statement: "We're happy to help.",
        timestamp: 663,
    },
    {
        speaker: 'Yoav',
        statement: "Or if you look on the bottom right of your screen, you'll see an intercom icon.",
        timestamp: 664,
    },
    {
        speaker: 'Yoav',
        statement:
            "We're always available, we can help you, we can hop on a call if you need, but super excited to hear how it goes and please share your feedback.",
        timestamp: 668,
    },
    {
        speaker: 'Yoav',
        statement: 'You go through it.',
        timestamp: 676,
    },
];

const demoMinimalTranscript = demoTranscriptLineData.map(
    (line) => `${line.speaker === 'Yoav' ? '0' : '1'}: ${line.statement}`
);

demoMinimalTranscript.unshift('Where 0 = Yoav, 1 = Karin Sharon');

export const getDemoData = ({ username }: { username: string }) => {
    username = username || 'You';
    return [
        {
            isTranscriptDisabled: false,
            transcriptLineData: demoTranscriptLineData,
        } as TranscriptData,
        {
            minimalTranscript: demoMinimalTranscript,
        },
        `
        <h3>Hey Spinach:</h3>
        <ul>
            <li>
                <b>Karin Sharon:</b> Create a ticket for Capture Action Item
                <ul>
                    <li>Ensure Spinach captures the specified action item during the call by saying “Hey Spinach create ticket for…” to avoid missing any critical tasks.</li>
                </ul>
            </li>
        </ul>
        <h3>Key Topics:</h3>
        <ul>
            <li>
                <b>Spinach Platform Introduction and Setup - 25%</b>
                <ul>
                    <li>Overview of Spinach's purpose, account creation process, and initial configuration with calendar integration.</li>
                </ul>
            </li>
            <li>
                <b>Meeting Participation and Summary Generation - 20%</b>
                <ul>
                    <li>Explanation of how Spinach joins meetings and generates comprehensive summaries with key topics, action items, and decisions.</li>
                </ul>
            </li>
            <li>
                <b>Customization and User Control Features - 20%</b>
                <ul>
                    <li>Details on customization options including Spinach Host role, summary review, recipient management, meeting templates, and language support.</li>
                </ul>
            </li>
            <li>
                <b>Integration and Workflow Automation - 20%</b>
                <ul>
                    <li>Overview of Spinach's integration capabilities with productivity tools and the &quot;Ask Spinach&quot; feature for post-meeting analysis and automation.</li>
                </ul>
            </li>
            <li>
                <b>Privacy and Data Management - 15%</b>
                <ul>
                    <li>Discussion of privacy features like &quot;Off the Record&quot; functionality, control over summary distribution, and data storage options.</li>
                </ul>
            </li>
        </ul>
        
        <h3>Action Items:</h3>
        <ul>
            <li>
                <b>${username}:</b> Add Spinach to upcoming meetings
                <ul>
                    <li>Review upcoming meetings and toggle Spinach on for relevant calls, or set up automations to add Spinach to specific meeting types.</li>
                </ul>
            </li>
            <li>
                <b>${username}:</b> Let Spinach AI in from the waiting room
                <ul>
                    <li>Watch for the waiting room when you join your meeting to admin Spinach AI in from the waiting room.</li>
                </ul>
            </li>
            <li>
                <b>${username}:</b> Customize Spinach settings
                <ul>
                    <li>Configure Spinach preferences, including language settings, branding options, and default summary distribution settings to tailor the experience to specific needs.</li>
                </ul>
            </li>
            <li>
                <b>${username}:</b> Integrate with productivity tools
                <ul>
                    <li>Connect Spinach with existing ticketing systems, CRM, and collaboration tools to maximize workflow automation and data synchronization.</li>
                </ul>
            </li>
            <li>
                <b>${username}:</b> Practice using &quot;Ask Spinach&quot; feature
                <ul>
                    <li>Familiarize herself with the &quot;Ask Spinach&quot; functionality by generating reports, follow-up emails, and querying past meeting data to fully leverage the platform's capabilities.</li>
                </ul>
            </li>
        </ul>
        
        <h3>Key Decisions:</h3>
        <ul>
            <li>
                <b>Intelligent Meeting Capture and Customization:</b>
                <ul>
                    <li>Spinach auto-joins meetings, offers host controls for settings and summaries, provides customizable templates, and supports multiple languages and branding options.</li>
                </ul>
            </li>
            <li>
                <b>Comprehensive Summary Generation and Analysis:</b>
                <ul>
                    <li>Generates detailed meeting summaries with key points and offers post-meeting data analysis through the &quot;Ask Spinach&quot; feature, with options for user review and editing.</li>
                </ul>
            </li>
            <li>
                <b>Seamless Integration with Productivity Tools:</b>
                <ul>
                    <li>Integrates with various work tools, enabling direct creation of tickets and workflow automation from meeting data, with options for automatic summary storage.</li>
                </ul>
            </li>
            <li>
                <b>Privacy and Control Features:</b>
                <ul>
                    <li>Allows users to pause recording, toggle Spinach on/off for meetings, and customize recipient lists, ensuring control over sensitive information.</li>
                </ul>
            </li>
        </ul>
        
        <h3>Celebrations:</h3>
        <ul>
            <li>
                <b>Yoav:</b> Excited to have you experience the magic of Spinach, a platform to help you get the most out of meetings and automate workflows.
            </li>
        </ul>`,
        {
            summaryJson: {
                attendees: ['Yoav - Co-founder & CTO', 'Karin Sharon, Head of Customer Success'],
                blockers: [],
                keyDecisions: [
                    {
                        content:
                            'Simplify the initial upload experience to generate a basic summary without requiring speaker names or other metadata',
                        context:
                            'The team decided to create a simplified upload flow where users can just upload a recording and receive a basic summary, without the need to provide speaker names or other metadata upfront. This will provide a more streamlined initial experience, while still allowing for a more detailed editing flow in the future.',
                    },
                    {
                        content:
                            'Explore a custom template for the summary generation to avoid potential issues with the current prompts',
                        context:
                            "The team discussed creating a custom template for the summary generation process, tailored specifically to the upload use case. This would help avoid potential issues with the current prompts, which may lead to inaccuracies or 'hallucinations' in the generated summary.",
                    },
                    {
                        content: 'Defer speaker name matching and other advanced features to a future iteration',
                        context:
                            'The team decided to defer more advanced features, such as speaker name matching, to a future iteration, in order to focus on delivering a simple and functional initial upload experience for users.',
                    },
                ],
                celebrations: [],
                agendaItems: [
                    {
                        content: 'Exploring database options for meeting transcript storage and analysis',
                        context:
                            'The team discussed the need for a more searchable and responsive database solution to store and analyze meeting transcripts, considering the use of a vector database for AI-readiness, but decided to start with a simpler solution and potentially migrate to a vector database later if needed.',
                        amount: '30',
                    },
                    {
                        content: 'Implementing audio/video upload and processing for meeting notes generation',
                        context:
                            'The team discussed adding the ability for users to upload audio or video recordings of meetings, which would then be processed to generate meeting notes. They explored the user experience, including options for naming the uploaded files, selecting templates, and differentiating between live meetings and uploaded recordings.',
                        amount: '30',
                    },
                    {
                        content: 'Refining the team account and user management functionality',
                        context:
                            'The team reviewed the current state of the team account and user management features, discussing details such as tracking active users, handling guest users, and the ability to add new team members, with the goal of simplifying the experience while still providing the necessary functionality.',
                        amount: '20',
                    },
                    {
                        content: 'Improving the web app dashboard and meeting history display',
                        context:
                            'The team discussed ways to reorganize and enhance the information displayed on the web app dashboard and meeting history pages, with the goal of making the data more easily accessible and digestible for users.',
                        amount: '20',
                    },
                ],
                actionItems: [
                    {
                        title: 'Add Spinach to upcoming meetings',
                        context:
                            'Review upcoming meetings and toggle Spinach on for relevant calls, or set up automations to add Spinach to specific meeting types.',
                        users: [{ username }],
                        speaker: username,
                        id: 'a59705ae-7033-439a-8f4a-c4c4f98732b4',
                    },
                    {
                        title: 'Let Spinach AI in from the waiting room',
                        context:
                            'Watch for the waiting room when you join your meeting to admin Spinach AI in from the waiting room.',
                        users: [{ username }],
                        speaker: username,
                        id: '44913dba-3c8f-4e2e-893a-630b3dc4d82c',
                    },
                    {
                        title: 'Customize Spinach settings',
                        context:
                            'Configure Spinach preferences, including language settings, branding options, and default summary distribution settings to tailor the experience to specific needs.',
                        users: [{ username }],
                        speaker: username,
                        id: 'c36a448d-135b-4e79-ade3-f9759909c103',
                    },
                    {
                        title: 'Integrate with productivity tools',
                        context:
                            'Connect Spinach with existing ticketing systems, CRM, and collaboration tools to maximize workflow automation and data synchronization.',
                        users: [{ username }],
                        speaker: username,
                        id: 'a48b52e4-f536-4096-80ad-b145c03d0a92',
                    },
                    {
                        title: 'Practice using "Ask Spinach" feature',
                        context:
                            'Familiarize herself with the "Ask Spinach" functionality by generating reports, follow-up emails, and querying past meeting data to fully leverage the platform\'s capabilities.',
                        users: [{ username }],
                        speaker: username,
                        id: '82d3fd74-a5eb-40fb-96c2-766b9d663f85',
                    },
                ],
                heySpinach: [],
                previousMeetingInsights: [],
                workstreamProjects: [],
                slackOutput: [],
                clips: [
                    {
                        title: 'Introduction to Spinach',
                        subt: "Spinach's Mission and Features",
                        startTime: '00:00',
                        endTime: '00:15',
                    },
                    {
                        title: 'Introduction to Spinach',
                        subt: 'Automating Meeting Tasks',
                        startTime: '00:39',
                        endTime: '00:54',
                    },
                    {
                        title: "Spinach's Functionality and Setup",
                        subt: 'Spinach Joining Meetings as a Participant',
                        startTime: '01:26',
                        endTime: '01:41',
                    },
                    {
                        title: "Spinach's Functionality and Setup",
                        subt: 'Customizing Spinach Settings and Preferences',
                        startTime: '02:18',
                        endTime: '02:33',
                    },
                    {
                        title: 'Advanced Spinach Features',
                        subt: 'Ask Spinach: Generating Reports and Follow-ups',
                        startTime: '06:31',
                        endTime: '06:46',
                    },
                    {
                        title: 'Language, Branding, and Integrations',
                        subt: 'Language Support',
                        startTime: '08:28',
                        endTime: '08:36',
                    },
                    {
                        title: 'Language, Branding, and Integrations',
                        subt: 'Branding Representation',
                        startTime: '08:36',
                        endTime: '08:43',
                    },
                    {
                        title: 'Language, Branding, and Integrations',
                        subt: 'Slack Integration',
                        startTime: '08:53',
                        endTime: '08:59',
                    },
                    {
                        title: 'Language, Branding, and Integrations',
                        subt: 'Ticketing Integrations',
                        startTime: '09:07',
                        endTime: '09:14',
                    },
                    {
                        title: 'Confidentiality and Feedback',
                        subt: 'Off the Record Feature',
                        startTime: '10:20',
                        endTime: '10:31',
                    },
                ],
                meetingChapters: [
                    {
                        title: 'Introduction and Platform Overview',
                        startTime: '00:00',
                        endTime: '01:25',
                        summary:
                            "Yoav introduces Spinach's platform, explaining its core mission to enhance meeting productivity and automate post-meeting tasks. He highlights the platform's ability to help users stay present during meetings while handling documentation and follow-up tasks automatically.",
                        subsections: [
                            {
                                title: 'Welcome and Initial Introduction',
                                startTime: '00:00',
                            },
                            {
                                title: 'Platform Mission and Benefits',
                                startTime: '00:26',
                            },
                            {
                                title: 'Transition to Demo',
                                startTime: '01:11',
                            },
                        ],
                    },
                    {
                        title: 'Platform Setup and Basic Features',
                        startTime: '01:26',
                        endTime: '05:25',
                        summary:
                            "Karin demonstrates the platform's setup process and core features, including calendar integration, meeting management, and host controls. She explains how Spinach joins meetings, customization options, and various meeting templates available to users.",
                        subsections: [
                            {
                                title: 'Getting Started and Setup',
                                startTime: '01:45',
                            },
                            {
                                title: 'Meeting Management Dashboard',
                                startTime: '02:43',
                            },
                            {
                                title: 'Host Controls and Templates',
                                startTime: '03:33',
                            },
                        ],
                    },
                    {
                        title: 'Advanced Features and Integration Capabilities',
                        startTime: '05:26',
                        endTime: '11:16',
                        summary:
                            'Karin details advanced features including meeting summaries, action items, and integrations with various tools. She covers the Ask Spinach functionality, ticketing system integration, and security features like Off the Record, concluding with support options.',
                        subsections: [
                            {
                                title: 'Meeting Summaries and Action Items',
                                startTime: '05:26',
                            },
                            {
                                title: 'Tool Integrations',
                                startTime: '08:31',
                            },
                            {
                                title: 'Security Features and Wrap-up',
                                startTime: '10:20',
                            },
                        ],
                    },
                ],
            },
            meetingTime: 'Thursday, October 17th',
            meetingTitle: 'Designup',
            usernameEmailMapping: {
                Yoav: { email: 'yoav@spinach.io', emailConfidence: 1 },
                'Karin Sharon': { email: 'karin@spinach.io', emailConfidence: 1 },
            },
            meetingType: 'generic',
            seriesId: demoSeriesId,
            attendees: [],
        } as SummaryJson,
        {
            recordingUrl: 'https://media.spinach.io/Onboard-Demo-Features2.mp4',
            participantsNames: ['Yoav - Co-founder & CTO', 'Karin Sharon - Head of Customer Success'] as string[],
        } as const,
    ] as const;
};
