import { Box, Modal, makeStyles } from '@material-ui/core';
import { Fullscreen, FullscreenExit, Pause, PlayArrow } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';

import { SummaryJson } from '@spinach-shared/types';

import { lightTheme } from '../../../../styles';

const useStyles = makeStyles((theme) => ({
    seekBar: {
        position: 'absolute',
        bottom: 24,
        left: '16px',
        right: '16px',
        width: 'auto',
        height: 50,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        zIndex: 2,
        transition: 'opacity 0.3s ease-in-out',
        [theme.breakpoints.down('sm')]: {
            bottom: 18,
            left: '12px',
            right: '12px',
            height: 40,
        },
    },
    seekBarGradient: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 120,
        background: 'linear-gradient(to top, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 100%)',
        pointerEvents: 'none',
        zIndex: 1,
        [theme.breakpoints.down('sm')]: {
            height: 100,
        },
    },
    seekBarTrack: {
        width: '100%',
        height: 4,
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        position: 'relative',
        borderRadius: 4,
        [theme.breakpoints.down('sm')]: {
            height: 6,
        },
    },
    progressBar: {
        position: 'absolute',
        left: 0,
        height: '100%',
        backgroundColor: lightTheme.primary.greenLight,
        borderRadius: 4,
    },
    currentPosition: {
        position: 'absolute',
        top: '50%',
        width: 16,
        height: 16,
        backgroundColor: lightTheme.primary.greenLight,
        borderRadius: '50%',
        zIndex: 2,
        transform: 'translate(-50%, -50%)',
        [theme.breakpoints.down('sm')]: {
            width: 12,
            height: 12,
        },
    },
    clipMarker: {
        position: 'absolute',
        top: '50%',
        width: 6,
        height: 6,
        backgroundColor: lightTheme.primary.greenLight,
        border: `1px solid white`,
        borderRadius: '50%',
        zIndex: 1,
        transform: 'translate(-50%, -50%)',
        [theme.breakpoints.down('sm')]: {
            width: 4,
            height: 4,
        },
    },
    titleContainer: {
        position: 'absolute',
        top: 10,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            top: 5,
        },
    },
    titleInner: {
        padding: '8px 16px',
        background: 'rgba(0, 0, 0, 0.6)',
        borderRadius: 4,
        maxWidth: '90%',
        [theme.breakpoints.down('sm')]: {
            padding: '4px 8px',
        },
    },
    titleText: {
        fontSize: '16px',
        lineHeight: '1.4',
        textAlign: 'center',
        whiteSpace: 'normal',
        overflow: 'visible',
        textOverflow: 'clip',
        wordWrap: 'break-word',
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '1.3',
        },
    },
    hoverCircle: {
        position: 'absolute',
        width: 16,
        height: 16,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        borderRadius: '50%',
        transform: 'translate(-50%, -50%)',
        pointerEvents: 'none',
        top: '50%',
        [theme.breakpoints.down('sm')]: {
            width: 12,
            height: 12,
        },
    },
    tooltip: {
        position: 'absolute',
        top: -35,
        padding: '4px 8px',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        borderRadius: 4,
        fontSize: '12px',
        transform: 'translateX(-50%)',
        whiteSpace: 'nowrap',
        pointerEvents: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize: '10px',
            padding: '2px 6px',
            top: -20,
        },
    },
    playPauseButton: {
        position: 'absolute',
        bottom: 5,
        left: 0,
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        zIndex: 3,
        color: 'white',
        transition: 'opacity 0.3s ease-in-out',
        '& svg': {
            fontSize: 28,
        },
        [theme.breakpoints.down('sm')]: {
            bottom: 4,
            left: '12px',
            width: 32,
            height: 32,
            '& svg': {
                fontSize: 22,
            },
        },
    },
    fullscreenButton: {
        position: 'absolute',
        bottom: 5,
        right: 0,
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        zIndex: 3,
        color: 'white',
        transition: 'opacity 0.3s ease-in-out',
        '& svg': {
            fontSize: 28,
        },
        [theme.breakpoints.down('sm')]: {
            bottom: 4,
            right: '12px',
            width: 32,
            height: 32,
            '& svg': {
                fontSize: 22,
            },
        },
    },
    theaterModeOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        zIndex: theme.zIndex.modal,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    theaterModeContent: {
        width: '90%',
        maxWidth: '1600px',
        position: 'relative',
        '& video': {
            width: '100%',
            height: 'auto',
            maxHeight: '80vh',
        },
    },
    timeDisplay: {
        position: 'absolute',
        bottom: 16,
        left: '40px',
        color: 'white',
        fontSize: '14px',
        zIndex: 3,
        fontFamily: 'monospace',
        transition: 'opacity 0.3s ease-in-out',
        [theme.breakpoints.down('sm')]: {
            bottom: 12,
            left: '48px',
            fontSize: '12px',
        },
    },
}));

export type Clip = {
    title: string;
    subt: string;
    startTime: string;
    endTime: string;
};

const timeToSeconds = (timeStr: string): number => {
    const parts = timeStr.split(':').map(Number);
    if (parts.length === 3) {
        // HH:MM:SS
        return parts[0] * 3600 + parts[1] * 60 + parts[2];
    }
    // MM:SS
    return parts[0] * 60 + parts[1];
};

// Added helper function to compute each clip's length
const getClipDuration = (clip: Clip) => {
    return timeToSeconds(clip.endTime) - timeToSeconds(clip.startTime);
};

// Added helper function to get total duration of all clips combined
const getTotalClipsDuration = (clips: Clip[]) => {
    return clips.reduce((acc, clip) => acc + getClipDuration(clip), 0);
};

export interface HighlightsPlayerProps {
    videoRef: React.RefObject<HTMLVideoElement>;
    onPlay: React.ReactEventHandler<HTMLVideoElement>;
    onTimeUpdate: React.ReactEventHandler<HTMLVideoElement>;
    recordingUrl: string;
    summaryJson: SummaryJson | null;
}

// Update the formatTime function to be smarter about format matching
const formatTime = (seconds: number, includeHours: boolean = false): string => {
    const hours = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    // Always show hours if includeHours is true or if we have hours to show
    if (includeHours || hours > 0) {
        return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs
            .toString()
            .padStart(2, '0')}`;
    }
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
};

// Add helper to determine if we should show hours based on clip times
const shouldShowHours = (clips: Clip[]): boolean => {
    return clips.some((clip) => clip.startTime.split(':').length === 3 || clip.endTime.split(':').length === 3);
};

// Add helper function to find clip and time at position
const findClipAtPosition = (position: number, clips: Clip[]): { clipIndex: number; timeInVideo: number } => {
    const totalClipsDuration = getTotalClipsDuration(clips);
    const virtualTime = (position / 100) * totalClipsDuration;

    let cumulative = 0;
    for (let i = 0; i < clips.length; i++) {
        const clipDur = getClipDuration(clips[i]);
        if (virtualTime <= cumulative + clipDur) {
            const offsetInClip = virtualTime - cumulative;
            const clipStartSeconds = timeToSeconds(clips[i].startTime);
            return {
                clipIndex: i,
                timeInVideo: clipStartSeconds + offsetInClip,
            };
        }
        cumulative += clipDur;
    }

    // If we're past all clips, return the last clip's end time
    const lastClip = clips[clips.length - 1];
    return {
        clipIndex: clips.length - 1,
        timeInVideo: timeToSeconds(lastClip.endTime),
    };
};

export const HighlightsPlayer = ({
    videoRef,
    onPlay,
    onTimeUpdate,
    recordingUrl,
    summaryJson,
}: HighlightsPlayerProps) => {
    const classes = useStyles();
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentClipIndex, setCurrentClipIndex] = useState(0);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState<number | null>(null);
    const [showHoverCircle, setShowHoverCircle] = useState(false);
    const [hoverPosition, setHoverPosition] = useState(0);
    const [hoverTime, setHoverTime] = useState<string>('');
    const [hoverRealTime, setHoverRealTime] = useState<string>('');
    const [useHoursFormat, setUseHoursFormat] = useState(false);
    const [hoverLeftPx, setHoverLeftPx] = useState(0);
    const [hoveredMarkerIndex, setHoveredMarkerIndex] = useState<number | null>(null);
    const [isTheaterMode, setIsTheaterMode] = useState(false);
    const [showControls, setShowControls] = useState(true);
    const playerContainerRef = React.useRef<HTMLDivElement>(null);
    const fadeTimeoutRef = React.useRef<NodeJS.Timeout>();

    const clips = summaryJson?.clips || [];

    // Initialize video to first clip position when component mounts
    useEffect(() => {
        if (videoRef.current && clips.length > 0) {
            const firstClipStartTime = timeToSeconds(clips[0].startTime);
            videoRef.current.currentTime = firstClipStartTime;
        }
    }, [clips, videoRef]);

    // Reset player state when recordingUrl changes (switching between meetings)
    useEffect(() => {
        if (videoRef.current && clips.length > 0) {
            // Reset to the first clip
            setCurrentClipIndex(0);
            setProgress(0);
            setIsPlaying(false);

            // Set video position to the start of the first clip
            const firstClipStartTime = timeToSeconds(clips[0].startTime);
            videoRef.current.currentTime = firstClipStartTime;

            // Pause the video to ensure it doesn't auto-play
            videoRef.current.pause();
        }
    }, [recordingUrl, clips]);

    const startFadeOutTimer = useCallback(() => {
        if (!isPlaying) return;

        // Clear any existing timeout
        if (fadeTimeoutRef.current) {
            clearTimeout(fadeTimeoutRef.current);
        }

        // Set new timeout
        fadeTimeoutRef.current = setTimeout(() => {
            setShowControls(false);
        }, 5000);
    }, [isPlaying]);

    // Handle mouse activity and control visibility
    useEffect(() => {
        const handleMouseMove = () => {
            setShowControls(true);
            startFadeOutTimer();
        };

        const handleMouseLeave = () => {
            if (isPlaying) {
                setShowControls(false);
                if (fadeTimeoutRef.current) {
                    clearTimeout(fadeTimeoutRef.current);
                }
            }
        };

        const container = playerContainerRef.current;
        if (container) {
            container.addEventListener('mousemove', handleMouseMove);
            container.addEventListener('mouseleave', handleMouseLeave);
        }

        return () => {
            if (container) {
                container.removeEventListener('mousemove', handleMouseMove);
                container.removeEventListener('mouseleave', handleMouseLeave);
            }
            if (fadeTimeoutRef.current) {
                clearTimeout(fadeTimeoutRef.current);
            }
        };
    }, [isPlaying, startFadeOutTimer]);

    // Show controls when paused
    useEffect(() => {
        if (!isPlaying) {
            setShowControls(true);
            if (fadeTimeoutRef.current) {
                clearTimeout(fadeTimeoutRef.current);
            }
        } else {
            startFadeOutTimer();
        }
    }, [isPlaying, startFadeOutTimer]);

    useEffect(() => {
        if (!videoRef.current || !isPlaying || !clips.length) return;

        const video = videoRef.current;
        const currentClip = clips[currentClipIndex];

        const totalClipsDuration = getTotalClipsDuration(clips);
        const clipStartInSeconds = timeToSeconds(currentClip.startTime);
        const clipEndInSeconds = timeToSeconds(currentClip.endTime);

        const previousClipsDuration = clips.slice(0, currentClipIndex).reduce((acc, c) => acc + getClipDuration(c), 0);
        const currentClipTime = video.currentTime - clipStartInSeconds;
        const virtualTime = previousClipsDuration + Math.max(0, currentClipTime);

        setProgress((virtualTime / totalClipsDuration) * 100);

        if (video.currentTime >= clipEndInSeconds) {
            if (currentClipIndex < clips.length - 1) {
                setCurrentClipIndex((prev) => prev + 1);
                video.currentTime = timeToSeconds(clips[currentClipIndex + 1].startTime);
            } else {
                // Reset everything to initial state when reaching the end
                setIsPlaying(false);
                setCurrentClipIndex(0);
                setProgress(0);
                video.pause();
                video.currentTime = timeToSeconds(clips[0].startTime);
            }
        }
    }, [videoRef.current?.currentTime, isPlaying, currentClipIndex, clips]);

    const handleLoadedMetadata = () => {
        if (videoRef.current) {
            setDuration(videoRef.current.duration);
        }
    };

    const handlePlayPause = () => {
        if (!videoRef.current || !clips.length) return;
        if (isPlaying) {
            videoRef.current.pause();
            setIsPlaying(false);
        } else {
            videoRef.current.play();
            setIsPlaying(true);
        }
    };

    // Updated getClipMarkers to place markers based on cumulative clip durations rather than the full video duration
    const getClipMarkers = () => {
        if (!clips.length) return [];
        const totalClipsDuration = getTotalClipsDuration(clips);
        let cumulative = 0;
        return clips.map((clip) => {
            const marker = {
                position: (cumulative / totalClipsDuration) * 100,
            };
            cumulative += getClipDuration(clip);
            return marker;
        });
    };

    // New handler to let users click the seek bar and jump to the clicked position
    const handleSeekBarClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!videoRef.current || !clips.length) return;
        const totalClipsDuration = getTotalClipsDuration(clips);

        if (hoveredMarkerIndex !== null) {
            const clipTime = timeToSeconds(clips[hoveredMarkerIndex].startTime);
            setCurrentClipIndex(hoveredMarkerIndex);
            videoRef.current.currentTime = clipTime;
            setProgress((getClipDuration(clips[hoveredMarkerIndex]) / totalClipsDuration) * 100);

            if (!isPlaying) {
                setIsPlaying(true);
                videoRef.current.play();
            }
            return;
        }

        const barRect = event.currentTarget.getBoundingClientRect();
        const clickX = event.clientX - barRect.left;
        const ratio = clickX / barRect.width;
        const newVirtualTime = ratio * totalClipsDuration;

        let cumulative = 0;
        let targetClipIndex = 0;
        for (let i = 0; i < clips.length; i++) {
            const clipDur = getClipDuration(clips[i]);
            if (newVirtualTime <= cumulative + clipDur) {
                targetClipIndex = i;
                break;
            }
            cumulative += clipDur;
        }

        const offsetInClip = newVirtualTime - cumulative;
        const clipStartSeconds = timeToSeconds(clips[targetClipIndex].startTime);
        const newRealTime = clipStartSeconds + offsetInClip;

        setCurrentClipIndex(targetClipIndex);
        videoRef.current.currentTime = Math.max(0, newRealTime);
        setProgress(ratio * 100);

        if (!isPlaying) {
            setIsPlaying(true);
            videoRef.current.play();
        }
    };

    // Add effect to determine time format on mount and when clips change
    useEffect(() => {
        setUseHoursFormat(shouldShowHours(clips));
    }, [clips]);

    const handleSeekBarHover = useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            if (!clips.length) return;
            const barRect = event.currentTarget.getBoundingClientRect();
            const rawLeftPx = event.clientX - barRect.left;

            const padding = 150;
            let clampedLeftPx = rawLeftPx;

            // Adjust clamping logic to account for tooltip width
            if (rawLeftPx < padding) {
                clampedLeftPx = padding;
            } else if (rawLeftPx > barRect.width - padding) {
                clampedLeftPx = barRect.width - padding;
            }
            setHoverLeftPx(clampedLeftPx);

            // Calculate percentage for the hover circle
            const position = (rawLeftPx / barRect.width) * 100;
            const clampedPosition = Math.max(0, Math.min(100, position));
            setHoverPosition(clampedPosition);

            // Calculate hover time (virtual time within clips)
            const totalClipsDuration = getTotalClipsDuration(clips);
            const virtualTime = (clampedPosition / 100) * totalClipsDuration;
            setHoverTime(formatTime(virtualTime, useHoursFormat));

            // Calculate actual video time and get clip info
            const { timeInVideo, clipIndex } = findClipAtPosition(clampedPosition, clips);
            setHoverRealTime(`${formatTime(timeInVideo, useHoursFormat)} - ${clips[clipIndex].subt}`);

            // Calculate marker positions and distances
            const markers = getClipMarkers();
            let markerDetected = false;

            // Find minimum distance between any two adjacent markers
            let minMarkerDistance = Infinity;
            for (let i = 0; i < markers.length - 1; i++) {
                const distance = ((markers[i + 1].position - markers[i].position) / 100) * barRect.width;
                minMarkerDistance = Math.min(minMarkerDistance, distance);
            }
            const snapThreshold = Math.min(70, minMarkerDistance / 2);

            for (let i = 0; i < markers.length; i++) {
                const markerPx = (markers[i].position / 100) * barRect.width;
                if (Math.abs(markerPx - rawLeftPx) < snapThreshold) {
                    setHoveredMarkerIndex(i);
                    markerDetected = true;
                    break;
                }
            }
            if (!markerDetected) {
                setHoveredMarkerIndex(null);
            }
        },
        [clips, useHoursFormat]
    );

    // Add click handler for the video element
    const handleVideoClick = (event: React.MouseEvent<HTMLVideoElement>) => {
        // Prevent click from propagating to other elements
        event.preventDefault();
        handlePlayPause();
    };

    // Calculate current virtual time based on clips
    const getCurrentVirtualTime = () => {
        if (!clips.length || !videoRef.current) return 0;

        const previousClipsDuration = clips
            .slice(0, currentClipIndex)
            .reduce((acc, clip) => acc + getClipDuration(clip), 0);

        const currentClip = clips[currentClipIndex];
        const currentClipStart = timeToSeconds(currentClip.startTime);
        const currentPosition = videoRef.current.currentTime - currentClipStart;

        return previousClipsDuration + Math.max(0, currentPosition);
    };

    // Get total playable duration
    const totalDuration = clips.reduce((acc, clip) => acc + getClipDuration(clip), 0);

    const handleTheaterModeToggle = () => {
        const wasPlaying = isPlaying;
        const currentTime = videoRef.current?.currentTime;
        const currentClip = clips[currentClipIndex];

        setIsTheaterMode(!isTheaterMode);

        // Use a small timeout to let the modal transition complete
        setTimeout(() => {
            if (videoRef.current && currentTime !== undefined) {
                // Always restore the exact time and clip position
                videoRef.current.currentTime = currentTime;
                setCurrentClipIndex(currentClipIndex);

                // Only resume playback if it was playing before
                if (wasPlaying) {
                    videoRef.current.play();
                }
            }
        }, 100);
    };

    const playerContent = (
        <div ref={playerContainerRef} style={{ position: 'relative', width: '100%' }}>
            <video
                ref={videoRef}
                onTimeUpdate={onTimeUpdate}
                onPlay={onPlay}
                onLoadedMetadata={handleLoadedMetadata}
                controlsList="nodownload"
                style={{ width: '100%', height: 'auto', cursor: 'pointer', backgroundColor: 'black' }}
                controls={false}
                src={recordingUrl}
                onContextMenu={(e) => e.preventDefault()}
                onClick={handleVideoClick}
            />
            <Box className={classes.titleContainer}>
                <Box className={classes.titleInner}>
                    <Box className={classes.titleText}>
                        <Box component="span" fontWeight={700}>
                            {clips[currentClipIndex].title}
                        </Box>
                        {' - '}
                        {clips[currentClipIndex].subt}
                    </Box>
                </Box>
            </Box>
            <Box
                className={classes.playPauseButton}
                onClick={handlePlayPause}
                style={{ opacity: showControls ? 1 : 0 }}
            >
                {isPlaying ? <Pause /> : <PlayArrow />}
            </Box>

            <Box
                className={classes.fullscreenButton}
                onClick={handleTheaterModeToggle}
                style={{ opacity: showControls ? 1 : 0 }}
            >
                {isTheaterMode ? <FullscreenExit /> : <Fullscreen />}
            </Box>

            <Box className={classes.timeDisplay} style={{ opacity: showControls ? 1 : 0 }}>
                {formatTime(getCurrentVirtualTime())}/{formatTime(totalDuration)}
            </Box>

            <Box className={classes.seekBarGradient} style={{ opacity: showControls ? 1 : 0 }} />
            <Box
                className={classes.seekBar}
                onClick={handleSeekBarClick}
                onMouseMove={handleSeekBarHover}
                onMouseEnter={() => setShowHoverCircle(true)}
                onMouseLeave={() => {
                    setShowHoverCircle(false);
                    setHoverTime('');
                    setHoverRealTime('');
                    setHoveredMarkerIndex(null);
                }}
                style={{ opacity: showControls ? 1 : 0 }}
            >
                <Box className={classes.seekBarTrack}>
                    <Box className={classes.progressBar} sx={{ width: `${progress}%` }} />
                    <Box className={classes.currentPosition} sx={{ left: `${progress}%` }} />
                    {getClipMarkers().map((marker, index) => {
                        const isHovered = hoveredMarkerIndex === index;
                        const scaleVal = isHovered ? 2 : 1;

                        return (
                            <Box
                                key={index}
                                className={classes.clipMarker}
                                style={{
                                    left: `${marker.position}%`,
                                    transformOrigin: 'center',
                                    transform: `translate(-50%, -50%) scale(${scaleVal})`,
                                    transition: 'transform 0.2s ease-out',
                                    zIndex: isHovered ? 3 : 1,
                                }}
                            />
                        );
                    })}
                    {showHoverCircle ? (
                        <>
                            <Box className={classes.hoverCircle} sx={{ left: `${hoverPosition}%` }} />
                            <Box className={classes.tooltip} sx={{ left: `${hoverLeftPx}px` }}>
                                {hoverRealTime}
                            </Box>
                        </>
                    ) : null}
                </Box>
            </Box>
        </div>
    );

    if (!clips.length) {
        return null;
    }

    return (
        <>
            {!isTheaterMode ? playerContent : null}
            <Modal
                open={isTheaterMode}
                onClose={handleTheaterModeToggle}
                BackdropProps={{
                    style: { backgroundColor: 'transparent' },
                }}
            >
                <Box className={classes.theaterModeOverlay}>
                    <Box className={classes.theaterModeContent}>{playerContent}</Box>
                </Box>
            </Modal>
        </>
    );
};
