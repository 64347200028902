import { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { SpinachWorkflowClientJSON, SpinachWorkflowTriggerClientJSON } from '@spinach-shared/types';

import { fetchSpinachWorkflows } from '../../apis/workflows/fetchSpinachWorkflows';
import { atomSpinachWorkflowTriggers, atomSpinachWorkflows } from '../../atoms/workflows/atomSpinachWorkflows';
import { useGlobalUser } from '../useGlobalUser';

export const useGlobalSpinachWorkflows = () => {
    return useRecoilState(atomSpinachWorkflows);
};

export const useGlobalSpinachWorkflowTriggers = () => {
    return useRecoilState(atomSpinachWorkflowTriggers);
};

export function useSpinachWorkflows(): {
    workflows: SpinachWorkflowClientJSON[];
    triggers: SpinachWorkflowTriggerClientJSON[];
    setWorkflows: (workflows: SpinachWorkflowClientJSON[]) => void;
    setTriggers: (triggers: SpinachWorkflowTriggerClientJSON[]) => void;
    isFetching: boolean;
    refetch: () => Promise<void>;
} {
    const [user] = useGlobalUser();
    const [globalSpinachWorkflows, setGlobalSpinachWorkflows] = useGlobalSpinachWorkflows();
    const [globalSpinachWorkflowTriggers, setGlobalSpinachWorkflowTriggers] = useGlobalSpinachWorkflowTriggers();
    const [isFetching, setIsFetching] = useState(false);
    const getSpinachWorkflows = useCallback(async () => {
        setIsFetching(true);
        const response = await fetchSpinachWorkflows();
        if (response?.workflows) {
            setGlobalSpinachWorkflows(response.workflows);
        }
        if (response?.triggers) {
            setGlobalSpinachWorkflowTriggers(response.triggers);
        }
        setIsFetching(false);
    }, [setGlobalSpinachWorkflows, setGlobalSpinachWorkflowTriggers]);

    useEffect(() => {
        if (user?.isEnabledForMorningBriefing || user?.isEnabledForSpinachWorkflows) {
            getSpinachWorkflows();
        }
    }, [user?.isEnabledForMorningBriefing, user?.isEnabledForSpinachWorkflows, getSpinachWorkflows]);

    return {
        workflows: globalSpinachWorkflows,
        triggers: globalSpinachWorkflowTriggers,
        setWorkflows: setGlobalSpinachWorkflows,
        setTriggers: setGlobalSpinachWorkflowTriggers,
        isFetching,
        refetch: getSpinachWorkflows,
    };
}
