import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

import { ENGLISH_LANGUAGE } from '@spinach-shared/constants';
import { ClientUser } from '@spinach-shared/models';
import {
    CalendarEvent,
    ClientEventType,
    EmailDistributionConfig,
    UserCreationSource,
    WebUrlQuery,
} from '@spinach-shared/types';
import { TimeUtils } from '@spinach-shared/utils';

import {
    ClientLogger,
    Direction,
    URLUtil,
    getUser,
    patchAllSeriesSettings,
    patchUser,
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalRouting,
} from '../../../../..';
import { postSlackDefaultUserChannel } from '../../../../apis/postSlackDefaultUserChannel';
import { useBulkUpdateScribeOnEvents, useCalendarEventSuggestions } from '../../../../hooks/useCalendarEvents';
import { useGlobalMeetingSettings } from '../../../../hooks/useGlobalMeetingSettings';
import { useStoredSeriesListFetcher } from '../../../../hooks/useGlobalStoredSeriesList';
import { TagManager } from '../../../../utils/TagManager';
import { ViewContainer } from '../../../common';
import { isScribeOnEvent } from '../../ScribeCalendarPage';
import { FinishedFlowReverseTrial } from '../FinishedFlowReverseTrialStep';
import { FinishedFlow } from '../FinishedFlowStep';
import { KnowledgeBaseSetupStep } from '../KnowledgeBaseSetupStep';
import { ManualInvite } from '../ManualInviteStep';
import { SlackDefaults } from '../SlackDefaultsStep';
import { SlackSetup } from '../SlackSetupStep';
import { TicketSetup } from '../TicketSetupStep';
import { OnboardingStep } from '../common';
import { AddToAllConfirmationStep } from './AddToAllConfirmationStep';
import { AddToAllCalendarPermissionStep } from './AddtoAllCalendarPermissionsStep';
import { SecurityCalendarPermissionWithStepTrackerStep } from './SecurityCalendarPermissionWithStepTrackerStep';
import { StepTrackerAboutStep } from './StepTrackerAboutStep';
import { StepTrackerAddToMeetingsStep } from './StepTrackerAddToMeetingsStep';

export function StepTrackerOnboardingFlowContainer(): JSX.Element {
    const STARTING_STEP = OnboardingStep.About;
    const [onboardingStep, setOnboardingStep] = useState<OnboardingStep>(STARTING_STEP);

    return (
        <ViewContainer>
            <StepTrackerOnboardingFlow
                onboardingStep={onboardingStep}
                setOnboardingStep={setOnboardingStep}
                startingStep={STARTING_STEP}
            />
        </ViewContainer>
    );
}

/** @NOTE - we don't want to show reverse trial in onboarding flow if theyre eligble but only have a few days left */
const MIN_DAYS_LEFT_TO_USE_TRIAL_FLOW = 5;

export function StepTrackerOnboardingFlow({
    isAgentOnboarding = false,
    onboardingStep,
    setOnboardingStep,
    startingStep,
}: {
    onboardingStep: OnboardingStep;
    setOnboardingStep: (step: OnboardingStep) => void;
    isAgentOnboarding?: boolean;
    startingStep: OnboardingStep;
}): JSX.Element {
    const { routeToAIDashboard } = useGlobalRouting();
    const [user, setUser] = useGlobalAuthedUser();

    const shouldUseReverseTrialFlow =
        user.isOnLiveReverseTrial &&
        (user.reverseTrialDaysLeft >= MIN_DAYS_LEFT_TO_USE_TRIAL_FLOW || user.isOnManuallyManagedTrial);

    const FINAL_STEP = shouldUseReverseTrialFlow ? OnboardingStep.ReverseTrialFinishFlow : OnboardingStep.Finished;
    const [direction, setSlidingDirection] = useState<Direction>(Direction.Forward);
    const [loadingMessage, setLoadingMessage] = useState('');
    const { setSubview, closeSettingsModal } = useGlobalMeetingSettings();
    const track = useExperienceTracking();
    const [previousSteps, setPreviousSteps] = useState<OnboardingStep[]>([]);

    const [firstName, setFirstName] = useState(user.metadata.firstName ?? '');
    const [lastName, setLastName] = useState(user.metadata.lastName ?? '');
    const [howDidYouHear, setHowDidYouHear] = useState('');
    const [howDidYouHearOther, setHowDidYouHearOther] = useState('');
    const [defaultMeetingLanguage, setDefaultMeetingLanguage] = useState(ENGLISH_LANGUAGE);
    const [numberOfPeopleInCompany, setNumberOfPeopleInCompany] = useState('');
    const [department, setDepartment] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [otherDepartment, setOtherDepartment] = useState('');

    const [hadErrorWhileAddingScribeToEvents, setHadErrorWhileAddingScribeToEvents] = useState(false);
    const [onboardingEventsToAddScribeTo, setOnboardingEventsToAddScribeTo] = useState<CalendarEvent[]>([]);
    const [hasFetched, setHasFetched] = useState(false);
    const [hasRefetched, setHasRefetched] = useState(false);
    const { data, error, queryKey, isFetching, refetch, isRefetching } = useCalendarEventSuggestions(
        {
            startISOString: moment.tz(TimeUtils.getTimezoneRegion()).startOf('day').toISOString(),
            endISOString: moment.tz(TimeUtils.getTimezoneRegion()).add(28, 'days').endOf('day').toISOString(),
        },
        !hasFetched
    );
    useEffect(() => {
        track(ClientEventType.UserStartedStepTrackerOnboardingFlow, { StartingStep: startingStep });
    }, [startingStep]);
    const refetchEvents = useCallback(() => {
        async function performRefetch() {
            await refetch();
            setHasRefetched(true);
        }
        performRefetch();
    }, [refetch]);
    useEffect(() => {
        if (hasRefetched) {
            setOnboardingEventsToAddScribeTo(
                !data
                    ? []
                    : data.events.filter((event) => !!onboardingEventsToAddScribeTo.find((e) => e.id === event.id))
            );
            setHasRefetched(false);
        }
    }, [hasRefetched, setHasRefetched, setOnboardingEventsToAddScribeTo, data, onboardingEventsToAddScribeTo]);
    useEffect(() => {
        if (!!error) {
            track(ClientEventType.FailedToGetMeetingSuggestionsInStepTrackerOnboarding);
        }
    }, [error]);
    useEffect(() => {
        if (data && !hasFetched && user.isAuthedForAnyCalendar) {
            setHasFetched(true);
            track(ClientEventType.MeetingSuggestionsReceived, {
                TimedOut: data.timedOut,
                SuggestionCount: data.suggestionIds.length,
                EventCount: data.events.length,
                ChosenScribeEmail: user.assignedScribeEmail,
            });

            if (data.suggestionsWithoutSpinach.length) {
                setOnboardingEventsToAddScribeTo(data.suggestionsWithoutSpinach);
            }
        }
    }, [data, hasFetched, setHasFetched, user.isAuthedForAnyCalendar]);

    const { mutateAsync: bulkUpdateAddScribeToEvents, error: bulkError } = useBulkUpdateScribeOnEvents(queryKey);
    const { fetch } = useStoredSeriesListFetcher({ recurringOnly: false });

    // if an error occurs from retrieving or updating events, kill the loading message
    useEffect(() => {
        if (!!error || !!bulkError) {
            setLoadingMessage('');
        }
    }, [error, bulkError]);

    useEffect(() => {
        TagManager.trackStartOnboarding(user);
    }, []);

    const preSelectedChannel =
        user.slackSettings?.defaultChannelId && user.slackSettings?.defaultChannelName
            ? { code: user.slackSettings?.defaultChannelId, label: user.slackSettings?.defaultChannelName }
            : null;

    const [selectedChannel, setSelectedChannel] = useState<{ code: string; label: string } | null>(preSelectedChannel);
    const [editSummariesBeforeTheyAreSent, setEditSummariesBeforeTheyAreSent] = useState(
        !!user.metadata.isEditingAiEmailsByDefault
    );

    const [isEmailingIcpOnly, setIsEmailingIcpOnly] = useState(false);

    const [emailDistributionConfig, setEmailDistributionConfig] = useState(EmailDistributionConfig.All);

    function progressTo(step: OnboardingStep, preventGoingBackTo = false) {
        track(ClientEventType.UserNavigatingToOnboardingStep, {
            CurrentStep: onboardingStep,
            DestinationStep: step,
            Direction: 'forward',
        });
        setSlidingDirection(Direction.Forward);

        if (!preventGoingBackTo) {
            setPreviousSteps([onboardingStep, ...previousSteps]);
        }

        setOnboardingStep(step);
    }

    function goBack() {
        const previousStep = previousSteps[0];

        track(ClientEventType.UserNavigatingToOnboardingStep, {
            CurrentStep: onboardingStep,
            DestinationStep: previousStep || startingStep,
            Direction: 'back',
        });

        setSlidingDirection(Direction.Backward);

        if (!previousStep) {
            setOnboardingStep(startingStep);
            return;
        }

        const updatedPreviousSteps = previousSteps.filter((pStep) => previousStep !== pStep);

        setOnboardingStep(previousStep);
        setPreviousSteps(updatedPreviousSteps);
    }

    let activeStep: JSX.Element | undefined;

    switch (onboardingStep) {
        case OnboardingStep.About:
            activeStep = (
                <StepTrackerAboutStep
                    department={department}
                    setDepartment={setDepartment}
                    companyName={companyName}
                    setCompanyName={setCompanyName}
                    otherDepartment={otherDepartment}
                    setOtherDepartment={setOtherDepartment}
                    isAgentOnboarding={isAgentOnboarding}
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    howDidYouHear={howDidYouHear}
                    setHowDidYouHear={setHowDidYouHear}
                    direction={direction}
                    howDidYouHearOther={howDidYouHearOther}
                    setHowDidYouHearOther={setHowDidYouHearOther}
                    defaultMeetingLanguage={defaultMeetingLanguage}
                    setDefaultMeetingLanguage={setDefaultMeetingLanguage}
                    numberOfPeopleInCompany={numberOfPeopleInCompany}
                    setNumberOfPeopleInCompany={setNumberOfPeopleInCompany}
                    onSubmit={async () => {
                        setLoadingMessage("Let's do this...");

                        track(ClientEventType.UserSubmittedAboutStep, {
                            HowDidYouHear: howDidYouHear,
                            HowDidYouHearOther: howDidYouHearOther,
                            Department: department,
                            OtherDepartment: otherDepartment,
                            IsEnabledForCalendarOnboardingStepV2: user.isEnabledForCalendarOnboardingStepV2,
                            NumberOfPeopleInCompany: numberOfPeopleInCompany,
                            CompanyName: companyName,
                        });

                        const updatedUser = await patchUser({
                            metadata: {
                                department,
                                otherDepartment,
                                howDidYouHear,
                                howDidYouHearOther,
                                firstName,
                                lastName,
                                preferredName: `${firstName} ${lastName}`,
                                defaultMeetingLanguage,
                                numberOfPeopleInCompany,
                                companyName,
                            },
                        });

                        if (updatedUser.user) {
                            setUser(updatedUser.user);
                        }

                        setLoadingMessage('');

                        if (user.isAuthedForAnyCalendar) {
                            if (user.isEnabledForAddToAllOnboarding) {
                                progressTo(OnboardingStep.AddToAllConfirmation);
                            } else {
                                progressTo(OnboardingStep.AddSpinachToMeetings);
                            }
                        } else {
                            if (user.isEnabledForAddToAllOnboarding) {
                                progressTo(OnboardingStep.AddToAllCalendarPermissions);
                            } else {
                                progressTo(OnboardingStep.CalendarPermissions);
                            }
                        }
                    }}
                    loadingMessage={loadingMessage}
                />
            );
            break;
        case OnboardingStep.AddSpinachToMeetings:
            activeStep = (
                <StepTrackerAddToMeetingsStep
                    isAgentOnboarding={isAgentOnboarding}
                    data={data}
                    isFetchingEvents={isFetching}
                    isRefetchingEvents={isRefetching}
                    refetchEvents={refetchEvents}
                    onboardingEventsToAddScribeTo={onboardingEventsToAddScribeTo}
                    hadErrorWhileAddingScribeToEvents={hadErrorWhileAddingScribeToEvents}
                    onEventClick={(event: CalendarEvent) => {
                        const isOrganizer = user.isUserTheOrganizer(event);
                        const isScribeOn = isScribeOnEvent(event);
                        if (isScribeOn) {
                            return;
                        }
                        track(ClientEventType.CalendarMeetingItemClick, {
                            ICalUid: event.iCalUID,
                            Action: !!onboardingEventsToAddScribeTo.find((e) => e.iCalUID === event.iCalUID)
                                ? 'remove'
                                : 'add',
                            MeetingTitle: event.summary,
                            IsCurrentUserTheOrganizer: isOrganizer,
                            IsOnboardingFlow: true,
                            AttendeeCount: event.attendees?.length,
                            IsSuggestion: event.iCalUID && data ? data.isICalUidSuggested(event.iCalUID) : false,
                            ChosenScribeEmail: user.assignedScribeEmail,
                            CalendarProvider: user.calendarProvider,
                        });
                        if (!event.iCalUID) {
                            return;
                        }
                        if (onboardingEventsToAddScribeTo.includes(event)) {
                            setOnboardingEventsToAddScribeTo(
                                onboardingEventsToAddScribeTo.filter(
                                    (eventToAdd) => eventToAdd.iCalUID !== event.iCalUID
                                )
                            );
                        } else {
                            setOnboardingEventsToAddScribeTo([...onboardingEventsToAddScribeTo, event]);
                        }
                    }}
                    hasError={!!error}
                    onContinue={async () => {
                        track(ClientEventType.UserSubmittedAddSpinachToMeetingStep, {
                            NumberOfEventsScribeWasAddedTo: onboardingEventsToAddScribeTo.length,
                            NumberOfOrganizerEventsScribeWasAddedTo: onboardingEventsToAddScribeTo.filter((e) =>
                                user.isUserTheOrganizer(e)
                            ).length,
                            TotalEventsAvailable: data?.events.length,
                            DidSuggestionsTimeOut: data?.timedOut,
                            TotalSuggestedMeetingsAdded: onboardingEventsToAddScribeTo.filter(
                                (event) => !!event.iCalUID && data?.suggestionIds.includes(event.iCalUID)
                            ).length,
                            ...(data?.getAcceptedSuggestionsByMeetingType(onboardingEventsToAddScribeTo) ?? {}),
                            ChosenScribeEmail: user.assignedScribeEmail,
                            IsEmailingIcpOnly: isEmailingIcpOnly,
                            EditSummariesBeforeTheyAreSent: editSummariesBeforeTheyAreSent,
                            IsEnabledForInternalOnlyEmailDistribution: user.isEnabledForInternalOnlyEmailDistribution,
                            EmailDistributionConfig: emailDistributionConfig,
                        });

                        setLoadingMessage('Saving...');

                        // have to add first to create the series
                        // don't retry if we've already errored out
                        if (!hadErrorWhileAddingScribeToEvents && !!onboardingEventsToAddScribeTo.length) {
                            try {
                                await bulkUpdateAddScribeToEvents(
                                    onboardingEventsToAddScribeTo.map((event) => ({
                                        iCalUid: event.iCalUID!,
                                        addToEvent: true,
                                    }))
                                );
                            } catch (e: any) {
                                track(ClientEventType.FailedToAddScribeToEventsInStepTrackerOnboarding, {
                                    NumberOfMeetings: onboardingEventsToAddScribeTo.length,
                                    ErrorMessage: e.message ?? '',
                                });
                                ClientLogger.error('Error adding scribe to events in step tracker onboarding', e);
                                setHadErrorWhileAddingScribeToEvents(true);
                                setLoadingMessage('');
                                return;
                            }
                        }

                        try {
                            await patchAllSeriesSettings({
                                isHostEditing: editSummariesBeforeTheyAreSent,
                                isEmailingIcpOnly,
                                emailDistributionConfig,
                            });
                        } catch (e) {
                            // if the user does not have any series, this will fail and that's ok
                        }

                        try {
                            await fetch();
                        } catch (e) {}

                        await patchUser({
                            metadata: {
                                isEditingAiEmailsByDefault: editSummariesBeforeTheyAreSent,
                                isEmailingIcpOnly,
                                emailDistributionConfig,
                            },
                        });

                        if (!!onboardingEventsToAddScribeTo.length) {
                            if (user.metadata.usedGoogleCodeFlow) {
                                TagManager.trackGoogleCodeScribeUserAddedSpinach(user);
                            } else if (
                                user.metadata.creationSource === UserCreationSource.MicrosoftSignInFromCompanyWebsite
                            ) {
                                TagManager.trackMicrosoftCompanyWebsiteScribeUserAddedSpinach(user);
                            }
                            TagManager.trackTypeOfMeetingAdded(user, onboardingEventsToAddScribeTo, 'v0');
                        }

                        const updatedUser = await getUser();

                        if (updatedUser.user) {
                            setUser(updatedUser.user);
                        }

                        setLoadingMessage('');
                        setOnboardingEventsToAddScribeTo([]);

                        if (user.isAuthedForSlack) {
                            progressTo(OnboardingStep.SlackDefaults);
                        } else {
                            progressTo(OnboardingStep.Slack);
                        }
                    }}
                    loadingMessage={loadingMessage}
                    editSummariesBeforeTheyAreSent={editSummariesBeforeTheyAreSent}
                    setEditSummariesBeforeTheyAreSent={setEditSummariesBeforeTheyAreSent}
                    isEmailingIcpOnly={isEmailingIcpOnly}
                    setIsEmailingIcpOnly={setIsEmailingIcpOnly}
                    emailDistributionConfig={emailDistributionConfig}
                    setEmailDistributionConfig={setEmailDistributionConfig}
                />
            );
            break;
        case OnboardingStep.CalendarPermissions:
            activeStep = (
                <SecurityCalendarPermissionWithStepTrackerStep
                    direction={direction}
                    onSubmit={async () => {
                        // not used
                    }}
                    loadingMessage={loadingMessage}
                    onBack={() => {
                        track(ClientEventType.UserWentBackFromCalendarPermissionsStep);
                        goBack();
                    }}
                    onScheduleOnboardingSession={() => {
                        track(ClientEventType.UserClickedSecheduleOnboardingSession);
                        URLUtil.openURL(user.scheduleOnboardingSessionUrl);
                    }}
                    onScheduleDemoSessionMidTrial={() => {
                        track(ClientEventType.UserClickedSecheduleDemoSessionMidTrial);
                        URLUtil.openURL(user.scheduleOnboardingSessionUrl);
                    }}
                    onProgressToAddEventToMeeting={(updatedUser: ClientUser) => {
                        if (updatedUser.isAuthedForGoogleCalendar || updatedUser.isAuthedForMicrosoftCalendar) {
                            track(ClientEventType.UserAutoProgressedCalendarPermissionsStep, {
                                IsAuthedForGoogleCalendar: updatedUser.isAuthedForGoogleCalendar,
                                IsAuthedForMicrosoftCalendar: updatedUser.isAuthedForMicrosoftCalendar,
                            });
                            progressTo(OnboardingStep.AddSpinachToMeetings, true);
                        } else if (
                            // user upgraded with google but did not grant calendar permissions
                            !user?.isAuthedForGoogle &&
                            !!updatedUser.isAuthedForGoogle &&
                            !updatedUser.isAuthedForGoogleCalendar
                        ) {
                            progressTo(OnboardingStep.ManualInvite);
                        }
                    }}
                    onInviteSpinachManuallyClick={() => {
                        track(ClientEventType.UserClickedCalendarPermissionsInviteSpinachManually);
                        progressTo(OnboardingStep.ManualInvite);
                    }}
                    isAgentOnboarding={isAgentOnboarding}
                />
            );
            break;
        case OnboardingStep.AddToAllConfirmation:
            activeStep = (
                <AddToAllConfirmationStep
                    direction={direction}
                    onSubmit={async () => {
                        // not used
                    }}
                    loadingMessage={loadingMessage}
                    onProgressToAddNextStep={async () => {
                        if (user.isAuthedForGoogleCalendar || user.isAuthedForMicrosoftCalendar) {
                            const onboardedUser = await patchUser({
                                metadata: {
                                    isOnboarded: true,
                                },
                            });

                            if (onboardedUser.user) {
                                setUser(onboardedUser.user);
                            }

                            track(ClientEventType.UserClickedGoToDashboardFromFinished);

                            setSubview(null);
                            closeSettingsModal();
                            const params = new URLSearchParams();
                            params.append(WebUrlQuery.FinishOnboardingWithAutoRecord, 'true');
                            routeToAIDashboard({ replace: true }, params);
                            return;
                        } else {
                            // should never happen
                            progressTo(OnboardingStep.AddToAllCalendarPermissions);
                        }
                    }}
                />
            );
            break;
        case OnboardingStep.AddToAllCalendarPermissions:
            activeStep = (
                <AddToAllCalendarPermissionStep
                    direction={direction}
                    onSubmit={async () => {
                        // not used
                    }}
                    loadingMessage={loadingMessage}
                    onScheduleOnboardingSession={() => {
                        track(ClientEventType.UserClickedSecheduleOnboardingSession);
                        URLUtil.openURL(user.scheduleOnboardingSessionUrl);
                    }}
                    onScheduleDemoSessionMidTrial={() => {
                        track(ClientEventType.UserClickedSecheduleDemoSessionMidTrial);
                        URLUtil.openURL(user.scheduleOnboardingSessionUrl);
                    }}
                    onProgressToAddNextStep={async (updatedUser: ClientUser) => {
                        if (updatedUser.isAuthedForGoogleCalendar || updatedUser.isAuthedForMicrosoftCalendar) {
                            track(ClientEventType.UserAutoProgressedCalendarPermissionsStep, {
                                IsAuthedForGoogleCalendar: updatedUser.isAuthedForGoogleCalendar,
                                IsAuthedForMicrosoftCalendar: updatedUser.isAuthedForMicrosoftCalendar,
                                IsEnabledForAddToAllOnboarding: updatedUser.isEnabledForAddToAllOnboarding,
                            });

                            const onboardedUser = await patchUser({
                                metadata: {
                                    isOnboarded: true,
                                },
                            });

                            if (onboardedUser.user) {
                                setUser(onboardedUser.user);
                            }

                            track(ClientEventType.UserClickedGoToDashboardFromFinished);

                            setSubview(null);
                            closeSettingsModal();
                            const params = new URLSearchParams();
                            params.append(WebUrlQuery.FinishOnboardingWithAutoRecord, 'true');
                            routeToAIDashboard({ replace: true }, params);
                            return;
                        }
                    }}
                    onInviteSpinachManuallyClick={() => {
                        track(ClientEventType.UserClickedCalendarPermissionsInviteSpinachManually);
                        progressTo(OnboardingStep.ManualInvite);
                    }}
                    isAgentOnboarding={isAgentOnboarding}
                />
            );
            break;
        case OnboardingStep.ManualInvite:
            activeStep = (
                <ManualInvite
                    direction={direction}
                    onSubmit={async () => {
                        // not used
                    }}
                    loadingMessage={loadingMessage}
                    onBack={() => {
                        track(ClientEventType.UserWentBackFromManualSpinachSetupStep);
                        goBack();
                    }}
                    onSkip={async () => {
                        track(ClientEventType.UserSkippedManualSpinachSetupStep);

                        if (!!user.isUsingShortenedOnobarding) {
                            setLoadingMessage("Let's do this...");

                            const updatedUser = await patchUser({
                                metadata: {
                                    isOnboarded: true,
                                },
                            });
                            // softly land on dash rather than instantly transitioning
                            await new Promise((resolve) => setTimeout(resolve, 1500));

                            if (updatedUser.user) {
                                setUser(updatedUser.user);
                            }

                            track(ClientEventType.UserClickedGoToDashboardFromFinished);

                            // clears any subviews / settings modals that may have been popped by integration rows
                            setSubview(null);
                            closeSettingsModal();

                            routeToAIDashboard({ replace: true });
                            return;
                        }

                        if (user.isAuthedForSlack) {
                            progressTo(OnboardingStep.SlackDefaults);
                        } else {
                            progressTo(OnboardingStep.Slack);
                        }
                    }}
                    onProgressToAddEventToMeeting={(updatedUser) => {
                        if (updatedUser.isAuthedForGoogleCalendar || updatedUser.isAuthedForMicrosoftCalendar) {
                            track(ClientEventType.UserAutoProgressedCalendarPermissionsStep, {
                                IsAuthedForGoogleCalendar: updatedUser.isAuthedForGoogleCalendar,
                                IsAuthedForMicrosoftCalendar: updatedUser.isAuthedForMicrosoftCalendar,
                            });
                            progressTo(OnboardingStep.AddSpinachToMeetings, true);
                        }
                    }}
                />
            );
            break;
        case OnboardingStep.Slack:
            activeStep = (
                <SlackSetup
                    loadingMessage={loadingMessage}
                    onBack={() => {
                        track(ClientEventType.UserWentBackFromConnectSlackStep);
                        goBack();
                    }}
                    direction={direction}
                    onSkip={(reason: string) => {
                        track(ClientEventType.UserSkippedConnectSlackStep, {
                            Reason: reason,
                        });
                        progressTo(OnboardingStep.Tickets);
                    }}
                    progressToSlackDefaults={() => {
                        track(ClientEventType.UserAutoProgressedConnectSlackStep);
                        progressTo(OnboardingStep.SlackDefaults, true);
                    }}
                />
            );
            break;
        case OnboardingStep.SlackDefaults:
            activeStep = (
                <SlackDefaults
                    loadingMessage={loadingMessage}
                    onBack={() => {
                        track(ClientEventType.UserWentBackFromSlackDefaultsStep);
                        goBack();
                    }}
                    selectedChannel={selectedChannel}
                    setSelectedChannel={setSelectedChannel}
                    direction={direction}
                    onSkip={() => {
                        track(ClientEventType.UserSkippedSlackDefaultsStep);
                        progressTo(OnboardingStep.Tickets);
                    }}
                    onSubmit={async () => {
                        if (!selectedChannel) {
                            return;
                        }

                        track(ClientEventType.UserSubmittedSlackDefaultsStep);
                        setLoadingMessage('Saving...');
                        const teamId = user.slackSettings?.teamId;
                        const teamType = user.slackSettings?.teamType;

                        if (!teamId || !teamType) {
                            return;
                        }

                        const result = await postSlackDefaultUserChannel(
                            teamId,
                            teamType,
                            selectedChannel.code,
                            selectedChannel.label.replaceAll('#', '')
                        );

                        if (result?.user) {
                            setUser(result.user);
                        }
                        setLoadingMessage('');
                        progressTo(OnboardingStep.Tickets);
                    }}
                />
            );
            break;
        case OnboardingStep.Tickets:
            activeStep = (
                <TicketSetup
                    loadingMessage={loadingMessage}
                    onBack={() => {
                        track(ClientEventType.UserWentBackFromConnectTicketsStep);
                        goBack();
                    }}
                    direction={direction}
                    onSkip={async () => {
                        track(ClientEventType.UserSkippedConnectTicketsStep);
                        setLoadingMessage('Here we go...');
                        await patchUser({ metadata: { isOnboarded: true } });
                        setLoadingMessage('');
                        progressTo(OnboardingStep.KnowledgeBase);
                    }}
                    onSubmit={async () => {
                        track(ClientEventType.UserSubmittedConnectTicketsStep);
                        setLoadingMessage('Preparing...');

                        await patchUser({ metadata: { isOnboarded: true } });
                        progressTo(OnboardingStep.KnowledgeBase);
                        setLoadingMessage('');
                    }}
                />
            );
            break;
        case OnboardingStep.KnowledgeBase:
            activeStep = (
                <KnowledgeBaseSetupStep
                    loadingMessage={loadingMessage}
                    onBack={() => {
                        track(ClientEventType.UserWentBackFromKnowledgeBaseStep);
                        goBack();
                    }}
                    direction={direction}
                    onSubmit={async () => {
                        track(ClientEventType.UserSubmittedKnowledgeBaseStep);
                        setLoadingMessage('Saving...');
                        await patchUser({ metadata: { isOnboarded: true } });
                        setLoadingMessage('');
                        progressTo(FINAL_STEP);
                    }}
                    onSkip={async () => {
                        track(ClientEventType.UserSkippedKnowledgeBaseStep);
                        setLoadingMessage('Moving on...');
                        await patchUser({ metadata: { isOnboarded: true } });
                        setLoadingMessage('');
                        progressTo(FINAL_STEP);
                    }}
                />
            );
            break;
        case OnboardingStep.ReverseTrialFinishFlow:
            activeStep = (
                <FinishedFlowReverseTrial
                    loadingMessage={loadingMessage}
                    onBack={() => {
                        track(ClientEventType.UserWentBackFromFinishOnboardingStep);
                        goBack();
                    }}
                    direction={direction}
                    onSubmit={async () => {
                        setLoadingMessage('Here we go...');
                        track(ClientEventType.UserClickedGoToDashboardFromFinished);
                        const updatedUser = await patchUser({
                            metadata: {
                                isOnboarded: true,
                            },
                        });
                        // softly land on dash rather than instantly transitioning
                        await new Promise((resolve) => setTimeout(resolve, 1500));

                        if (updatedUser.user) {
                            setUser(updatedUser.user);
                        }
                        setLoadingMessage('');

                        // clears any subviews / settings modals that may have been popped by integration rows
                        setSubview(null);
                        closeSettingsModal();

                        routeToAIDashboard();
                    }}
                />
            );
            break;
        case OnboardingStep.Finished:
            activeStep = (
                <FinishedFlow
                    loadingMessage={loadingMessage}
                    onBack={() => {
                        track(ClientEventType.UserWentBackFromFinishOnboardingStep);
                        goBack();
                    }}
                    direction={direction}
                    onSubmit={async () => {
                        setLoadingMessage('Here we go...');
                        track(ClientEventType.UserClickedGoToDashboardFromFinished);
                        const updatedUser = await patchUser({
                            metadata: {
                                isOnboarded: true,
                            },
                        });
                        // softly land on dash rather than instantly transitioning
                        await new Promise((resolve) => setTimeout(resolve, 1500));

                        if (updatedUser.user) {
                            setUser(updatedUser.user);
                        }
                        setLoadingMessage('');

                        // clears any subviews / settings modals that may have been popped by integration rows
                        setSubview(null);
                        closeSettingsModal();

                        routeToAIDashboard();
                    }}
                />
            );
            break;
    }

    return <>{activeStep}</>;
}
