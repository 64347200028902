import { IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import styled from 'styled-components';

import { isLocalStage } from '@spinach-shared/utils';

import SpinachLogoImage from '../../assets/logo-spinachio.svg';
import { useGlobalRouting } from '../../hooks';
import { lightTheme } from '../../styles';
import { isChromeExtensionPlatform, isZoomPlatform } from '../../utils';
import { Row } from '../common';

const SpinachLogo = () => (
    <div
        style={{
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
        }}
    >
        <img
            src={SpinachLogoImage}
            alt="SpinachAI"
            style={{
                height: '24px',
                verticalAlign: 'middle',
            }}
        />
    </div>
);

const StyledIconButton = styled(IconButton)`
    position: absolute !important;
    left: 0;
    color: #666 !important;
    padding: 8px !important;
    &:hover {
        color: #333 !important;
        background-color: rgba(0, 0, 0, 0.04) !important;
    }
`;

export function ControlHeader(): JSX.Element {
    const { routeToAIDashboard } = useGlobalRouting();
    const showHomeIcon = isChromeExtensionPlatform() || isZoomPlatform() || isLocalStage();

    return (
        <Row vCenter style={{ marginBottom: '20px' }}>
            {showHomeIcon ? (
                <>
                    <StyledIconButton
                        onClick={() => routeToAIDashboard()}
                        size="small"
                        aria-label="return to dashboard"
                    >
                        <ArrowBack style={{ fontSize: '24px' }} htmlColor={lightTheme.primary.greenDark} />
                    </StyledIconButton>
                    <div style={{ flexGrow: 1 }} />
                </>
            ) : (
                <div style={{ flexGrow: 1 }} />
            )}
            <SpinachLogo />
            <div style={{ flexGrow: 1 }} />
        </Row>
    );
}
