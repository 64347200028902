import { Info } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DashboardSection } from '@spinach-shared/constants';
import { WebUrlQuery } from '@spinach-shared/types';

import { useGlobalAuthedUser, useGlobalRouting, useWindowSize } from '../../../hooks';
import { useSlackHuddleConfiguration } from '../../../hooks/slack-huddles';
import { useCelloInitalization } from '../../../hooks/useCelloInitalization';
import { useGlobalAiDashboard } from '../../../hooks/useGlobalAiDashboard';
import { useGlobalAiMeetingSelection } from '../../../hooks/useGlobalAiMeetingSelection';
import { useGlobalIntegrationSettingsModalKind } from '../../../hooks/useGlobalIntegrationSettingsModalKind';
import { useGlobalStoredSeriesList } from '../../../hooks/useGlobalStoredSeriesList';
import { useSlackChannels } from '../../../hooks/useSlack';
import { useZapierAPIKey } from '../../../hooks/zapier/useGlobalZapierApiKey';
import { lightTheme } from '../../../styles';
import { FYI, FYIState, Row, ViewContainer } from '../../common';
import { Notification } from '../../stand-up';
import { ActiveSection } from './ActiveSection';
import { IntegrationSettingsModal } from './IntegrationSettingsModal';
import { GlobalSearch } from './globalSearch/GlobalSearch';
import { MobileDashboardNavBar } from './sidebar/NavBar';
import { Sidebar } from './sidebar/Sidebar';
import { IntegrationSettingsModalMetadata } from './types';
import { isSummariesAndTranscriptsSection } from './utils';

export function AIDashboard(): JSX.Element {
    const { width } = useWindowSize();
    useCelloInitalization();
    const [user] = useGlobalAuthedUser();
    useSlackChannels();
    useSlackHuddleConfiguration();
    useZapierAPIKey();

    const [modalKind, setModalKind] = useGlobalIntegrationSettingsModalKind();
    const [modalMetadata, setModalMetadata] = useState<IntegrationSettingsModalMetadata>({});
    const { routeToDashboard } = useGlobalRouting();

    const {
        storedSeriesListState: { storedSeriesList, lastFetched },
        setStoredSeriesList,
    } = useGlobalStoredSeriesList();
    const { openedMeetingsState, setIsAddingAdditionalMeetings } = useGlobalAiMeetingSelection();

    const { activeSection, setActiveSection, toastText, setToastText } = useGlobalAiDashboard();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const section = searchParams.get(WebUrlQuery.Section);

        if (!section) {
            setActiveSection(DashboardSection.Summaries);
            return;
        }

        if (user.isAnonymous) {
            setActiveSection(DashboardSection.Summaries);
            return;
        }

        const validOptions: string[] = Object.values(DashboardSection);
        if (validOptions.includes(section)) {
            if (!user.isActionItemTicketCreationEnabled && section === DashboardSection.Actions) {
                setActiveSection(DashboardSection.Summaries);
            } else {
                setActiveSection(section as DashboardSection);
            }
        }
    }, []);

    // ensure that "limited" users cannot access dashboard sections they shouldn't
    useEffect(() => {
        if (user.isNotSetupForFullDashboardFeatures && !isSummariesAndTranscriptsSection(activeSection)) {
            setActiveSection(DashboardSection.Summaries);
        }
    }, [activeSection, setActiveSection, user.isNotSetupForFullDashboardFeatures]);

    useEffect(() => {
        const editSummarySuccess = searchParams.get(WebUrlQuery.EditSuccess);
        if (editSummarySuccess === 'true') {
            setToastText('Summary sent successfully.');
            searchParams.delete(WebUrlQuery.EditSuccess);
            setSearchParams(searchParams, { replace: true });
        }
    }, []);

    useEffect(() => {
        if (user.isHidingAiDashboard) {
            routeToDashboard({ replace: true });
        }
    }, []);

    const { meetingOptions, isSearchVisible, setIsSearchVisible, setState } = useGlobalAiDashboard();

    if (!lastFetched) {
        return (
            <ViewContainer>
                <FYI state={FYIState.Loading} />
            </ViewContainer>
        );
    }

    const isDesktopView = width > 800;

    return (
        <ViewContainer>
            {isDesktopView ? null : <MobileDashboardNavBar />}
            <Row
                style={{
                    height: isDesktopView ? '100%' : 'calc(100% - 60px)',
                    width: isDesktopView ? '100%' : 'calc(100% - 40px)',
                    paddingLeft: isDesktopView ? '0px' : '20px',
                    paddingRight: isDesktopView ? '0px' : '20px',
                    flexDirection: isDesktopView ? 'row' : 'column',
                    zIndex: 100,
                }}
            >
                {isDesktopView ? <Sidebar shouldShowShowcaseGuide={true} /> : null}

                <ActiveSection
                    isAddingAdditionalMeetings={openedMeetingsState.isAddingAdditionalMeetings}
                    setIsAddingAdditionalMeetings={setIsAddingAdditionalMeetings}
                    storedSeriesList={storedSeriesList}
                    setModalMetadata={setModalMetadata}
                    setStoredSeriesList={setStoredSeriesList}
                    setModalKind={setModalKind}
                />
            </Row>

            {meetingOptions && isSearchVisible ? (
                <GlobalSearch
                    meetings={meetingOptions}
                    hideRequestCallback={() => {
                        setIsSearchVisible(false);
                    }}
                    onMeetingSelected={async (botId, section) => {
                        const selectedOption = meetingOptions.find((option) => option.botId === botId);
                        if (selectedOption) {
                            setState((state) => ({
                                ...state,
                                isSearchVisible: false,
                                activeSection: section,
                            }));
                            searchParams.set(WebUrlQuery.Section, section);
                            searchParams.set(WebUrlQuery.BotId, selectedOption.botId);
                            searchParams.set(WebUrlQuery.SeriesId, selectedOption.seriesId);
                            setSearchParams(searchParams, { replace: true });
                        }
                    }}
                />
            ) : undefined}

            <IntegrationSettingsModal
                modalMetadata={modalMetadata}
                setModalMetadata={setModalMetadata}
                modalKind={modalKind}
                setModalKind={setModalKind}
            />
            <Notification
                containerStyle={{
                    position: 'absolute',
                    top: '10px',
                    alignItems: 'start',
                    zIndex: 'auto',
                    maxWidth: '90%',
                }}
                isOpen={!!toastText}
                duration={10_000}
                onClose={() => setToastText('')}
                message={toastText}
                icon={<Info style={{ color: lightTheme.neutrals.white }} htmlColor={lightTheme.neutrals.white} />}
                contentStyle={{ width: 'unset', zIndex: 10000 }}
            />
        </ViewContainer>
    );
}
