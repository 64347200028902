export enum SlackTeamType {
    Team = 'team',
    Enterprise = 'enterprise',
}

export type MarkDown = string;

export type BaseSlackChannelsRequest = {
    teamId: string;
    teamType: SlackTeamType;
    noCache?: boolean;
    spinachUserId: string;
};

export type SlackChannelMetadata = {
    id: string;
    isPrivate?: boolean;
    name?: string;
};

export type SlackHuddleClientConfiguration = {
    slackDomain: string;
    autoJoinPublicHuddles: boolean;
    askToJoinPrivateHuddles: boolean;
    botName?: string;
    askToJoinMessage?: string;
    successfullySetup: boolean;
    hasReceivedInvite: boolean;
    filterHuddlesByUserEmails: string[] | null;
};

export type SlackChannelsRequest = BaseSlackChannelsRequest & {
    spinachUserId: string;
};
export type SlackMembersRequest = SlackChannelsRequest;

export type SlackSettings = {
    teamId: string;
    teamType: SlackTeamType;
    version?: string;
};

export type SlackUserSettings = SlackSettings & {
    slackUserId: string;
    spinachUserId: string;
    appId?: string;
    defaultChannelName?: string;
    defaultChannelId?: string;
    isDefaultChannelPrivate?: boolean;
    welcomeMessageSent?: boolean;
    isDeleted?: boolean;
};
