import { atom } from 'recoil';

import { SpinachWorkflowClientJSON, SpinachWorkflowTriggerClientJSON } from '@spinach-shared/types';

export const atomSpinachWorkflows = atom<SpinachWorkflowClientJSON[]>({
    key: 'spinachWorkflows',
    default: [],
});

export const atomSpinachWorkflowTriggers = atom<SpinachWorkflowTriggerClientJSON[]>({
    key: 'spinachWorkflowTriggers',
    default: [],
});
