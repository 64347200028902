import { TextField } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { ClientEventType, FeatureToggle, UTMCampaign, UTMSource, WebUrlQuery } from '@spinach-shared/types';

import { postAnonymousFeatureFlag } from '../../apis/postAnonymousFeatureFlag';
import { ReactComponent as LogoSpinachio } from '../../assets/logo-spinachio.svg';
import { useGlobalUser, useLandingAnalytic, useNoteViewerDetection, useSeriesReality } from '../../hooks';
import { BodyBig, BodyBigOnboard, HeaderThreeOnboard, lightTheme } from '../../styles';
import { SetValue } from '../../types';
import { AnonymousUserTracker, withContentMasking } from '../../utils';
import { Column, Row, Spacing, SpinachStationary, ViewContainer } from '../common';
import { useSpinachInputStyles } from '../input';
import { Notification, PrimaryButton } from '../stand-up';
import { LoginWithGoogle } from './LoginWithGoogle';
import { LoginWithMicrosoft } from './LoginWithMicrosoft';
import { LoginWithSSO } from './LoginWithSSO';

export type VerifyEmailRouteProps = {
    email: string;
    setEmail: SetValue<string>;
    notification: string;
    onEmailSubmit: (referralEmail?: string) => Promise<void>;
};

const HorizontalDivider = styled.hr`
    background: #dfe5e5;
    color: #dfe5e5;
    width: 31%;
    height: 1px;
    border-style: none;
`;

export function VerifyEmailRoute({ email, setEmail, onEmailSubmit, notification }: VerifyEmailRouteProps): JSX.Element {
    const [user] = useGlobalUser();
    const [hasFiredStartedTyping, setHasFiredStartedTyping] = useState(false);
    const classes = useSpinachInputStyles({ value: email });
    const [searchParams] = useSearchParams();
    const { isDemoSeries } = useSeriesReality();
    const isProductHuntLaunch = searchParams.get(WebUrlQuery.UTMSource) === UTMSource.ProductHunt;
    const spinachReferrer = searchParams.get(WebUrlQuery.Referrer);
    const isFromAIEmail =
        searchParams.get(WebUrlQuery.UTMSource) === UTMSource.AiEmail ||
        searchParams.get(WebUrlQuery.Source) === UTMSource.AiEmail;
    const isFromOnboardingSlackConnect =
        (searchParams.get(WebUrlQuery.UTMSource) === UTMSource.AiOnboardingLandingPage ||
            searchParams.get(WebUrlQuery.Source) === UTMSource.AiOnboardingLandingPage) &&
        searchParams.get(WebUrlQuery.UTMCampaign) === UTMCampaign.SlackConnect;
    const isSsoRequired = searchParams.get(WebUrlQuery.SSO) === 'true';
    const emailOnly = searchParams.get(WebUrlQuery.EmailOnly) === 'true';

    const isViewingNotes = useNoteViewerDetection();

    const isFromContinueWithEmail = searchParams.get(WebUrlQuery.Source) === UTMSource.ContinueWithEmail;

    const [microsoftEnabled, setMicrosoftEnabled] = useState(false);

    useLandingAnalytic(ClientEventType.LoginPageActivity, {
        Activity: isFromContinueWithEmail ? 'Landed on Log In with Email Page' : 'Landed on Sign In Page',
    });

    useEffect(() => {
        try {
            if (spinachReferrer) {
                const expires = new Date();
                expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000);
                document.cookie = `${
                    WebUrlQuery.Referrer
                }=${spinachReferrer};expires=${expires.toUTCString()};path=/;domain=.spinach.io`;
            }
        } catch (error) {
            console.log('failed to set spinach referrer');
        }
    }, [spinachReferrer]);

    useEffect(() => {
        async function getIsMicrosoftSignInEnabled() {
            const value = await postAnonymousFeatureFlag(FeatureToggle.MicrosoftSignInEnabled, true);
            setMicrosoftEnabled(value);
        }

        getIsMicrosoftSignInEnabled();
    }, []);
    useEffect(() => {
        const queryEmail = searchParams.get(WebUrlQuery.Email);

        if (queryEmail) {
            const decodedEmail = decodeURIComponent(queryEmail);
            setEmail(decodedEmail);
            onEmailSubmit(decodedEmail);
        }
    }, []);

    useEffect(() => {
        if (isFromAIEmail) {
            AnonymousUserTracker.trackEvent(ClientEventType.UserRedirectedFromAiEmail, {
                UTMSource: searchParams.get(WebUrlQuery.UTMSource),
                Source: searchParams.get(WebUrlQuery.Source),
                UTMMedium: searchParams.get(WebUrlQuery.UTMMedium),
                Meeting: searchParams.get(WebUrlQuery.Meeting),
                UTMCampaign: searchParams.get(WebUrlQuery.UTMCampaign),
            });
        }
        if (isFromOnboardingSlackConnect) {
            AnonymousUserTracker.trackEvent(ClientEventType.UserReferredFromOnboardingSlackConnect, {
                UTMSource: searchParams.get(WebUrlQuery.UTMSource),
                Source: searchParams.get(WebUrlQuery.Source),
                UTMCampaign: searchParams.get(WebUrlQuery.UTMCampaign),
            });
        }
    }, []);

    useEffect(() => {
        const queryEmail = searchParams.get(WebUrlQuery.Email);

        AnonymousUserTracker.trackEvent(ClientEventType.UserLandedOnEmailEntryView, {
            Email: queryEmail || email || '',
        });
    }, []);

    useEffect(() => {
        const queryEmail = searchParams.get(WebUrlQuery.Email);

        if (!hasFiredStartedTyping && !queryEmail && email) {
            setHasFiredStartedTyping(true);

            AnonymousUserTracker.trackEvent(ClientEventType.UserStartedTypingEmail);
        }
    }, [email]);

    let header = 'Welcome back!';

    if (isDemoSeries) {
        if (isProductHuntLaunch) {
            header = 'Set up your team';
        } else if (user?.shouldAuthBeforeDemo) {
            header = "Let's setup your practice round";
        } else {
            header = 'Try Spinach with your team';
        }
    }

    if (isFromAIEmail) {
        header = 'Spinach AI settings';
    }
    if (isFromOnboardingSlackConnect) {
        header = 'Start by setting up an account';
    }
    if (isFromContinueWithEmail) {
        header = "Let's get started!";
    }
    if (emailOnly) {
        header = 'Welcome!';
    }
    let subtitle = '';

    if (isDemoSeries) {
        if (isProductHuntLaunch) {
            subtitle = 'Free for 90 days, no credit card, no obligation';
        } else if (user?.isAnonymous && user?.shouldAuthBeforeDemo) {
            subtitle = 'Add your email to get started';
        }
    }

    if (isFromAIEmail || isFromOnboardingSlackConnect) {
        subtitle = 'To add Slack, continue by verifying your email';
    }

    if (isFromContinueWithEmail) {
        subtitle = 'To get setup, start by providing your work email address.';
    }

    if (isSsoRequired) {
        subtitle = 'To continue, use your SSO email to log in';
    }

    if (emailOnly) {
        subtitle = 'Verify your email';
    }

    if (isViewingNotes) {
        header = '';
        subtitle = 'Please verify your identity to access meeting notes';
    }

    return (
        <ViewContainer>
            <SpinachStationary
                style={{
                    minHeight: isFromContinueWithEmail || emailOnly ? '350px' : '580px',
                    borderRadius: '8px',
                    maxWidth: '450px',
                }}
            >
                <LogoSpinachio style={{ height: '35px' }} />

                {header ? (
                    <>
                        <Spacing />
                        <HeaderThreeOnboard>{header}</HeaderThreeOnboard>
                    </>
                ) : (
                    <Spacing factor={1 / 2} />
                )}

                {subtitle ? (
                    <Column>
                        <Spacing factor={1 / 2} />
                        <BodyBig style={{ textAlign: 'center' }}>{subtitle}</BodyBig>
                    </Column>
                ) : (
                    <></>
                )}

                <Spacing />

                {!isFromContinueWithEmail && !isSsoRequired && !emailOnly ? (
                    <>
                        {isViewingNotes ? (
                            <LoginWithGoogle
                                buttonStyle={{
                                    maxWidth: '320px',
                                    minWidth: '200px',
                                    width: '70vw',
                                    justifyContent: 'center',
                                }}
                                variant="orange"
                                label="Continue with Google"
                            />
                        ) : (
                            <LoginWithGoogle />
                        )}
                        <Spacing factor={1 / 2} />
                        {microsoftEnabled ? (
                            <>
                                {isViewingNotes ? (
                                    <LoginWithMicrosoft
                                        buttonStyle={{
                                            maxWidth: '320px',
                                            minWidth: '200px',
                                            width: '70vw',
                                            justifyContent: 'center',
                                        }}
                                        variant="orange"
                                        label="Continue with Microsoft"
                                    />
                                ) : (
                                    <LoginWithMicrosoft />
                                )}
                                <Spacing factor={1 / 2} />
                            </>
                        ) : null}

                        <LoginWithSSO />

                        <Spacing />
                        <Row>
                            <HorizontalDivider style={{ marginRight: '10px' }} />
                            <BodyBigOnboard style={{ color: '#DFE5E5', fontSize: '14px', fontWeight: 'bold' }}>
                                OR
                            </BodyBigOnboard>
                            <HorizontalDivider style={{ marginLeft: '10px' }} />
                        </Row>

                        <Spacing />
                    </>
                ) : null}

                <TextField
                    InputProps={{ classes: { root: classes.base } }}
                    {...withContentMasking(classes.root)}
                    style={{ width: '70%' }}
                    placeholder="Email"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value.replace(/ /g, ''));
                    }}
                    onKeyDown={async (e) => {
                        if (!!email && e.key === 'Enter') {
                            await onEmailSubmit();
                        }
                    }}
                    autoFocus={isFromContinueWithEmail || emailOnly}
                />
                {/* <SsoHint>Use the above field for company SSO</SsoHint> */}

                <Spacing />

                <PrimaryButton onClick={() => onEmailSubmit()} title="Sign in" disabled={!email} />

                <Spacing />

                <Notification
                    containerStyle={{ position: 'relative', bottom: 'unset' }}
                    isOpen={!!notification}
                    onClose={() => null}
                    message={notification}
                    icon={<Error style={{ color: lightTheme.neutrals.white }} htmlColor={lightTheme.neutrals.white} />}
                />
            </SpinachStationary>
        </ViewContainer>
    );
}
