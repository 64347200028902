import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { FeatureToggle } from '@spinach-shared/types';

import LeftDesign from '../../assets/abstract-bottom-left.svg';
import RightDesign from '../../assets/abstract-top-right-2.svg';
import BlurredNotes from '../../assets/blurred-notes.png';
import MountainBackground from '../../assets/demo/mountain_bg.jpg';
import { useGlobalUser, useNoteViewerDetection, useSeriesReality } from '../../hooks';
import { responsiveness } from '../../styles';
import { ClientPath } from '../common';

const BackgroundTerrain = styled.img`
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 0;
    min-width: 100vw;
    min-height: 100%;
`;

const BackgroundTerrainRight = styled.img`
    top: 0;
    right: 0;
    position: absolute;
    z-index: 0;
    height: 250px;

    @media ${responsiveness.thinnerThan1100} {
        height: 200px;
        min-height: unset;
    }

    @media ${responsiveness.thinnerThanMD} {
        height: 150px;
    }

    @media ${responsiveness.thinnerThanSM} {
        height: 0px;
    }
`;

const BackgroundTerrainLeft = styled.img`
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 0;
    height: 250px;

    @media ${responsiveness.thinnerThan1100} {
        height: 200px;
        min-height: unset;
    }

    @media ${responsiveness.thinnerThanMD} {
        height: 150px;
    }

    @media ${responsiveness.thinnerThanSM} {
        height: 0px;
    }
`;

export function AbstractBackground(): JSX.Element {
    const { isDemoSeries } = useSeriesReality();
    const location = useLocation();
    const [user] = useGlobalUser();
    const isViewingNotes = useNoteViewerDetection();

    if (isViewingNotes && location.pathname === ClientPath.Verify) {
        return (
            <BackgroundTerrain
                src={BlurredNotes}
                alt=""
                style={{
                    left: '50%',
                    transform: 'translateX(-50%)',
                    objectFit: 'contain',
                    right: 'unset',
                    height: '100%',
                    minWidth: 'unset',
                    minHeight: 'unset',
                    maxHeight: '100vh',
                    overflow: 'hidden',
                    position: 'fixed',
                }}
            />
        );
    }

    if (location.pathname === ClientPath.VideoAgent) {
        return <></>;
    }

    if (location.pathname.includes(ClientPath.Control)) {
        return <></>;
    }

    if (isDemoSeries) {
        return <BackgroundTerrain src={MountainBackground} alt="" />;
    }

    if (location.pathname.match(/\/facilitation\/?/)) {
        return <></>;
    }

    if (
        location.pathname.includes(ClientPath.AIHome) ||
        (location.pathname === ClientPath.Root && user?.featureToggles[FeatureToggle.NewStandupAppDashboard])
    ) {
        return <></>;
    }

    return (
        <>
            <BackgroundTerrainLeft id="background-left" src={LeftDesign} alt="" />
            <BackgroundTerrainRight id="background-right" src={RightDesign} alt="" />
        </>
    );
}

export function AbstractStatelessBackground(): JSX.Element {
    return (
        <>
            <BackgroundTerrainLeft id="background-left" src={LeftDesign} alt="" />
            <BackgroundTerrainRight id="background-right" src={RightDesign} alt="" />
        </>
    );
}
