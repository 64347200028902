import { Box } from '@material-ui/core';
import { useEffect, useState } from 'react';

import { DashboardSection } from '@spinach-shared/constants';
import {
    ClientEventType,
    CreateAgentPayload,
    SpinachAPIPath,
    SpinachWorkflowClientJSON,
    SpinachWorkflowTriggerClientJSON,
    UpdateAgentPayload,
} from '@spinach-shared/types';

import { postSpinachAPI, useExperienceTracking, useGlobalAiDashboard, useGlobalAuthedUser } from '../../../..';
import { patchSpinachAPI } from '../../../../apis/patchSpinachAPI';
import { useSpinachWorkflows } from '../../../../hooks/workflows/useSpinachWorkflows';
import { LoadingSquares } from '../../../common';
import { AgentConfigurationView } from './AgentConfigurationView';
import { AgentsDirectoryView, getWorkflowForAgent } from './AgentsDirectoryView';
import { useEnabledAgents } from './useEnabledAgents';

export function AgentsSection(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const { setActiveSection } = useGlobalAiDashboard();
    const track = useExperienceTracking();
    const [toastMessage, setToastMessage] = useState<string | null>(null);

    const [view, setView] = useState<'directory' | 'configuration' | 'loading'>('loading');
    const [agentId, setAgentId] = useState<string | null>(null);
    const enabledAgents = useEnabledAgents();
    const { workflows, isFetching, triggers, refetch } = useSpinachWorkflows();
    const [workflow, setWorkflow] = useState<SpinachWorkflowClientJSON | undefined>(undefined);
    const [trigger, setTrigger] = useState<SpinachWorkflowTriggerClientJSON | undefined>(undefined);
    useEffect(() => {
        if (workflow) {
            setTrigger(triggers.find((trigger) => trigger.workflowId === workflow.id));
        }
    }, [workflow, triggers]);
    const onConfigure = (agentId: string, workflow?: SpinachWorkflowClientJSON) => {
        setAgentId(agentId);
        setView('configuration');
        setWorkflow(workflow);
        setToastMessage(null);
    };

    async function onCreateAgent(payload: CreateAgentPayload) {
        await postSpinachAPI<{
            workflow: SpinachWorkflowClientJSON;
            trigger: SpinachWorkflowTriggerClientJSON;
        }>(SpinachAPIPath.CreateAgent, {
            workflow: payload.workflow,
        });

        // fetch again
        await refetch();

        // find the workflow
        if (!agentId) {
            setWorkflow(undefined);
            setTrigger(undefined);
            return;
        }

        const newWorkflow = getWorkflowForAgent(agentId, workflows);
        const newTrigger = triggers.find((trigger) => trigger.workflowId === newWorkflow?.id);
        if (newWorkflow && newTrigger) {
            setWorkflow(newWorkflow);
            setTrigger(newTrigger);
        }
        track(ClientEventType.AIDashboardActivity, {
            Activity: 'User Created Agent',
            AgentId: agentId,
            Section: 'Agents',
        });
        setToastMessage('Agent Enabled');
    }

    async function onUpdateAgent(payload: UpdateAgentPayload) {
        if (!payload.workflow.workflowId) {
            return;
        }

        const path = SpinachAPIPath.UpdateAgent.replace(':id', payload.workflow.workflowId);
        await patchSpinachAPI<{
            workflow: SpinachWorkflowClientJSON;
            trigger: SpinachWorkflowTriggerClientJSON;
        }>(path, {
            workflow: payload.workflow,
        });
        // fetch again
        await refetch();

        // find the workflow
        if (!agentId) {
            setWorkflow(undefined);
            setTrigger(undefined);
            return;
        }

        const updatedWorkflow = workflows.find((w) => w.id === payload.workflow.workflowId);
        const updatedTrigger = triggers.find((trigger) => trigger.workflowId === updatedWorkflow?.id);
        if (updatedWorkflow && updatedTrigger) {
            setWorkflow(updatedWorkflow);
            setTrigger(updatedTrigger);
        }
        track(ClientEventType.AIDashboardActivity, {
            Activity: 'User Updated Agent',
            AgentId: agentId,
            Section: 'Agents',
        });
        setToastMessage('Agent Updated');
    }
    useEffect(() => {
        if (!user.isEnabledForAgentsSection) {
            setActiveSection(DashboardSection.Summaries);
        }
    }, [user.isEnabledForAgentsSection, setActiveSection]);

    useEffect(() => {
        if (isFetching) {
            setView('loading');
        } else {
            setView('directory');
        }
    }, [isFetching]);

    if (!user.isEnabledForAgentsSection) {
        return <></>;
    }

    if (view === 'loading') {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <LoadingSquares />
            </Box>
        );
    }
    if (view === 'directory') {
        return (
            <AgentsDirectoryView
                enabledAgents={enabledAgents}
                workflows={workflows}
                onConfigure={onConfigure}
                toastMessage={toastMessage}
            />
        );
    }
    if (view === 'configuration' && agentId) {
        return (
            <AgentConfigurationView
                onBack={() => setView('directory')}
                workflow={workflow}
                agentConfig={enabledAgents[agentId]}
                agentId={agentId}
                onCreateAgent={onCreateAgent}
                onUpdateAgent={onUpdateAgent}
                trigger={trigger}
            />
        );
    }

    return <></>;
}
