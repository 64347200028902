import { ClientUser } from '@spinach-shared/models';
import { ClientEventType, IClientUser, UserCreationSource } from '@spinach-shared/types';
import { getWebAppHost } from '@spinach-shared/utils';

import { AnonymousUserTracker, useExperienceTracking, useGlobalModal } from '../..';
import { TagManager } from '../utils/TagManager';

export function useHandleSuccessfulMicrosoftLogin() {
    const track = useExperienceTracking();
    const [, setGlobalModal] = useGlobalModal();

    return async (user: IClientUser, isNewUser: boolean) => {
        const validatedUser = new ClientUser(user);
        AnonymousUserTracker.trackUser(user._id);
        AnonymousUserTracker.trackEvent(ClientEventType.UserMicrosoftTokensVerifySuccess);
        await track(ClientEventType.UserMicrosoftTokensVerifySuccessBackend, {
            ...validatedUser.toUserIdentityPayload(),
            IsNewUser: isNewUser,
        });
        await track(ClientEventType.UserSucceededToProceedFromCodeEntryView, {
            ...validatedUser.toUserIdentityPayload(),
        });
        if (isNewUser) {
            TagManager.trackSignUp(validatedUser);
            if (validatedUser.isScribeOnlyUser) {
                if (validatedUser.metadata.creationSource === UserCreationSource.MicrosoftSignInFromCompanyWebsite) {
                    TagManager.trackMicrosoftSiteSignup(validatedUser);
                } else {
                    TagManager.trackMicrosoftWebAppSignup(validatedUser);
                }
            }
        }
        setGlobalModal(null);
        /** @NOTE - we want to ensure that the TagManager tracking fires before redirecting */
        setTimeout(() => {
            window.location.href = `${getWebAppHost()}${window.location.search}`;
        }, 300);
    };
}
