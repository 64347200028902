import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AddScribeToAllMeetingKind, ClientEventType, WebUrlQuery } from '@spinach-shared/types';

import { useExperienceTracking } from '../..';
import { GlobalModal, patchUser, useGlobalAuthedUser } from '../..';
import { useGlobalModal } from '../..';
import { updateAutomatedScribeSettingsOnCalendarEvents } from '../apis/calendar';
import { useSchedulingAutomationInProgressState } from './useSchedulingAutomationInProgressState';

export function useSetupAutoRecordUponOnboarding() {
    const [searchParams, setSearchParams] = useSearchParams();
    const startTrialNewUser = searchParams.get(WebUrlQuery.FinishOnboardingWithAutoRecord);
    const track = useExperienceTracking();
    const [modal, setGlobalModal] = useGlobalModal();
    const { schedulingAutomationInProgressState, pollForStateUntilCompletion } =
        useSchedulingAutomationInProgressState();
    const [user, setUser] = useGlobalAuthedUser();

    useEffect(() => {
        async function setupAutoRecordUponOnboarding() {
            if (!startTrialNewUser) {
                return;
            }

            // check for calendar conditions
            if (!user.isUsingRecallV2 || !user.isAuthedForAnyCalendar) {
                return;
            }

            // start a2a if needed
            const schedulingAutomationConfig = user.metadata.automaticAddScribeConfiguration;
            const userAlreadyHasSomeSchedulingAutomation =
                schedulingAutomationConfig &&
                'kind' in schedulingAutomationConfig &&
                schedulingAutomationConfig.kind !== null;
            const addToAllInProgress = schedulingAutomationInProgressState.kind === AddScribeToAllMeetingKind.All;
            if (!userAlreadyHasSomeSchedulingAutomation && !addToAllInProgress) {
                // we don't want to wait for this to finish, this runs in the background
                updateAutomatedScribeSettingsOnCalendarEvents({ kind: AddScribeToAllMeetingKind.All })
                    .then(async () => {
                        const updatedUserResponse = await patchUser({
                            metadata: {
                                automaticAddScribeConfiguration: { kind: AddScribeToAllMeetingKind.All },
                            },
                        });
                        if (!!updatedUserResponse.user) {
                            setUser(updatedUserResponse.user);
                        }
                    })
                    .catch((error) => {
                        track(ClientEventType.AIDashboardUnhappyPath, {
                            Error: error.message,
                            Flow: 'Enable AutoRecord during new user trial start',
                        });
                    });
                // update config
                pollForStateUntilCompletion();
            }

            // remove the query param
            const updatedSearchParams = new URLSearchParams(searchParams);
            updatedSearchParams.delete(WebUrlQuery.FinishOnboardingWithAutoRecord);
            setSearchParams(updatedSearchParams);

            // show all set modal
            if (modal !== GlobalModal.FinishedActivatingTrial) {
                setGlobalModal(GlobalModal.FinishedActivatingTrial);
            }
        }

        setupAutoRecordUponOnboarding();
    }, [
        startTrialNewUser,
        user.isUsingRecallV2,
        pollForStateUntilCompletion,
        schedulingAutomationInProgressState.kind,
        setGlobalModal,
        user.metadata.automaticAddScribeConfiguration,
        user.isAuthedForAnyCalendar,
        setSearchParams,
        searchParams,
        user.isOnLiveReverseTrial,
        modal,
        setUser,
        track,
        user.isPayingUser,
        user.hasProFeatures,
    ]);
}
