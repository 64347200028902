export enum SpinachIntegration {
    Slack = 'slack',
    Jira = 'jira',
    Clickup = 'clickUp',
    Trello = 'trello',
    Confluence = 'confluence',
    MondayDotCom = 'mondayDotCom',
    Linear = 'linear',
    Asana = 'asana',
    Google = 'google',
    GoogleDrive = 'googleDrive',
    GoogleCalendar = 'googleCalendar',
    Notion = 'notion',
    Microsoft = 'microsoft',
    Zoom = 'zoom',
    Zapier = 'zapier',
    HubSpot = 'hubSpot',
    Attio = 'attio',
    Zoho = 'zoho',
    Salesforce = 'salesforce',
}

// Define our integration categories
export type CRMIntegrations =
    | SpinachIntegration.HubSpot
    | SpinachIntegration.Salesforce
    | SpinachIntegration.Zoho
    | SpinachIntegration.Attio;

export type MeetingIntegrations =
    | SpinachIntegration.GoogleCalendar
    | SpinachIntegration.Microsoft
    | SpinachIntegration.Zoom;

export type DocumentationIntegrations =
    | SpinachIntegration.Confluence
    | SpinachIntegration.Notion
    | SpinachIntegration.GoogleDrive;

export type CollaborationIntegrations =
    | SpinachIntegration.Slack
    | SpinachIntegration.Jira
    | SpinachIntegration.Asana
    | SpinachIntegration.Trello
    | SpinachIntegration.Clickup
    | SpinachIntegration.Linear;

// Special integrations that don't fit into the above categories
export type UtilityIntegrations =
    | SpinachIntegration.Zapier
    | SpinachIntegration.Google // Base Google integration
    | SpinachIntegration.MondayDotCom;

// Type that represents all categorized integrations
export type CategorizedIntegrations =
    | CRMIntegrations
    | MeetingIntegrations
    | DocumentationIntegrations
    | CollaborationIntegrations
    | UtilityIntegrations;

// This type will cause a compile error if any SpinachIntegration is not categorized
export enum SpinachIntegrationCategory {
    CRM = 'crm',
    Meeting = 'meeting',
    Documentation = 'documentation',
    Collaboration = 'collaboration',
    Utility = 'utility',
}

export type SpinachIntegrationConfiguration = {
    code: SpinachIntegration;
    category: SpinachIntegrationCategory;
    displayName: string;
};

export const SpinachIntegrations: Record<SpinachIntegration, SpinachIntegrationConfiguration> = {
    [SpinachIntegration.Attio]: {
        code: SpinachIntegration.Attio,
        category: SpinachIntegrationCategory.CRM,
        displayName: 'Attio',
    },
    [SpinachIntegration.Zoho]: {
        code: SpinachIntegration.Zoho,
        category: SpinachIntegrationCategory.CRM,
        displayName: 'Zoho',
    },
    [SpinachIntegration.Salesforce]: {
        code: SpinachIntegration.Salesforce,
        category: SpinachIntegrationCategory.CRM,
        displayName: 'Salesforce',
    },
    [SpinachIntegration.HubSpot]: {
        code: SpinachIntegration.HubSpot,
        category: SpinachIntegrationCategory.CRM,
        displayName: 'HubSpot',
    },
    [SpinachIntegration.Slack]: {
        code: SpinachIntegration.Slack,
        category: SpinachIntegrationCategory.Collaboration,
        displayName: 'Slack',
    },
    [SpinachIntegration.Zapier]: {
        code: SpinachIntegration.Zapier,
        category: SpinachIntegrationCategory.Utility,
        displayName: 'Zapier',
    },
    [SpinachIntegration.Jira]: {
        code: SpinachIntegration.Jira,
        category: SpinachIntegrationCategory.Collaboration,
        displayName: 'Jira',
    },
    [SpinachIntegration.Confluence]: {
        code: SpinachIntegration.Confluence,
        category: SpinachIntegrationCategory.Documentation,
        displayName: 'Confluence',
    },
    [SpinachIntegration.Clickup]: {
        code: SpinachIntegration.Clickup,
        category: SpinachIntegrationCategory.Collaboration,
        displayName: 'Clickup',
    },
    [SpinachIntegration.Trello]: {
        code: SpinachIntegration.Trello,
        category: SpinachIntegrationCategory.Collaboration,
        displayName: 'Trello',
    },
    [SpinachIntegration.Linear]: {
        code: SpinachIntegration.Linear,
        category: SpinachIntegrationCategory.Collaboration,
        displayName: 'Linear',
    },
    [SpinachIntegration.Asana]: {
        code: SpinachIntegration.Asana,
        category: SpinachIntegrationCategory.Collaboration,
        displayName: 'Asana',
    },
    [SpinachIntegration.Google]: {
        code: SpinachIntegration.Google,
        category: SpinachIntegrationCategory.Utility,
        displayName: 'Google',
    },
    [SpinachIntegration.GoogleDrive]: {
        code: SpinachIntegration.GoogleDrive,
        category: SpinachIntegrationCategory.Documentation,
        displayName: 'Google Drive',
    },
    [SpinachIntegration.GoogleCalendar]: {
        code: SpinachIntegration.GoogleCalendar,
        category: SpinachIntegrationCategory.Meeting,
        displayName: 'Google Calendar',
    },
    [SpinachIntegration.Notion]: {
        code: SpinachIntegration.Notion,
        category: SpinachIntegrationCategory.Documentation,
        displayName: 'Notion',
    },
    [SpinachIntegration.Microsoft]: {
        code: SpinachIntegration.Microsoft,
        category: SpinachIntegrationCategory.Meeting,
        displayName: 'Microsoft',
    },
    [SpinachIntegration.Zoom]: {
        code: SpinachIntegration.Zoom,
        category: SpinachIntegrationCategory.Utility,
        displayName: 'Zoom',
    },
    [SpinachIntegration.MondayDotCom]: {
        code: SpinachIntegration.MondayDotCom,
        category: SpinachIntegrationCategory.Collaboration,
        displayName: 'Monday.com',
    },
};

// Type guard to check if an integration is a CRM integration
export const isCRMIntegration = (integration: SpinachIntegration): integration is CRMIntegrations => {
    return [
        SpinachIntegration.HubSpot,
        SpinachIntegration.Salesforce,
        SpinachIntegration.Zoho,
        SpinachIntegration.Attio,
    ].includes(integration);
};

// Type guard to check if an integration is a meeting integration
export const isMeetingIntegration = (integration: SpinachIntegration): integration is MeetingIntegrations => {
    return [SpinachIntegration.GoogleCalendar, SpinachIntegration.Microsoft, SpinachIntegration.Zoom].includes(
        integration
    );
};

export const isDocumentationIntegration = (
    integration: SpinachIntegration
): integration is DocumentationIntegrations => {
    return [SpinachIntegration.Confluence, SpinachIntegration.Notion, SpinachIntegration.GoogleDrive].includes(
        integration
    );
};

export const isCollaborationIntegration = (
    integration: SpinachIntegration
): integration is CollaborationIntegrations => {
    return [
        SpinachIntegration.Slack,
        SpinachIntegration.Jira,
        SpinachIntegration.Asana,
        SpinachIntegration.Trello,
        SpinachIntegration.Clickup,
        SpinachIntegration.Linear,
    ].includes(integration);
};
