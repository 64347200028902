import { Box, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { CSSProperties, useEffect } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import { ClientEventType, CustomVocabulary } from '@spinach-shared/types';

import { getCustomVocabulary, insertCustomVocabulary } from '../../../../apis/customVocabulary';
import {
    useCustomVocabularySettingsPremiumOnly,
    useExperienceTracking,
    useGlobalAiDashboard,
    useProFeatures,
} from '../../../../hooks';
import { BodyRegularOnboard, HeaderThree, lightTheme, responsiveness } from '../../../../styles';
import { withContentMasking } from '../../../../utils/withContentMasking';
import { ProBadge, Row, Spacing, UpgradeLockIcon } from '../../../common';
import { RowOfMainAndMisspelling } from './RowOfMainAndMisspelling';

export const toastErrorMessage = 'Experiencing an unexpected error. Please refresh the page';

export const InlineTitle = styled.div`
    display: none;

    @media ${responsiveness.thinnerThanMD} {
        margin-top: 12px;
        font-size: 16px;
        display: block;
        font-weight: bold;
        margin-right: 10px;
    }
`;

export const MainWordContainer = styled.div`
    width: 200px;
    flex: 0 0 auto;
    margin-right: 20px;
    display: flex;
    alignitems: flex-start;

    @media ${responsiveness.thinnerThanMD} {
        width: auto;
        flex: 1;
    }
`;

export const WordRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 10px;
    background: ${lightTheme.neutrals.offWhite};

    @media ${responsiveness.thinnerThanMD} {
        flex-direction: column;
    }
`;

const RowHeader = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 20px;

    @media ${responsiveness.thinnerThanMD} {
        display: none;
    }
`;

export const CustomVocabularySection = () => {
    const track = useExperienceTracking();

    const { setToastText } = useGlobalAiDashboard();
    // we use it inside a catch which can be triggered after the component is remounted
    const setErrorRef = React.useRef<() => void>();
    setErrorRef.current = () => {
        setToastText(toastErrorMessage);
    };

    const inputRefMain = React.useRef<HTMLInputElement>(null);
    const [showingCreateNew, setShowingCreateNew] = React.useState(false);
    const [inputActiveMain, setInputActiveMain] = React.useState(false);
    const [pendingTextMain, setPendingTextMain] = React.useState('');

    const [isLoading, setIsLoading] = React.useState(true);
    const [vocabs, setVocabs] = React.useState<
        { id: string; text: string; misspellings: { id: string; text: string }[] }[]
    >([]);

    useEffect(() => {
        const fetch = async () => {
            try {
                const res: CustomVocabulary[] = await getCustomVocabulary();
                setIsLoading(false);
                setVocabs(
                    res
                        .map(({ _id, text, createdAt, misspellings }) => ({
                            id: _id,
                            text,
                            createdAt: new Date(createdAt).getTime(),
                            misspellings:
                                misspellings
                                    ?.map(({ id, text, createdAt }) => ({
                                        id,
                                        text,
                                        createdAt: new Date(createdAt).getTime(),
                                    }))
                                    .sort((a, b) => a.createdAt - b.createdAt) ?? [],
                        }))
                        .sort((a, b) => a.createdAt - b.createdAt)
                );
            } catch {
                setErrorRef.current?.();
            }
        };
        fetch();
    }, []);

    const save = () => {
        if (pendingTextMain.trim().length === 0) {
            setShowingCreateNew(true);
            setInputActiveMain(false);
            setPendingTextMain('');
            return;
        }
        const id = uuid();
        insertCustomVocabulary(id, pendingTextMain).catch(() => {
            setErrorRef.current?.();
        });
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Custom Vocab Add Word',
            text: pendingTextMain.trim(),
        });
        setVocabs((vocabs) => [...vocabs, { id, text: pendingTextMain.trim(), misspellings: [] }]);
        setPendingTextMain('');
        setInputActiveMain(false);
        setShowingCreateNew(false);
    };

    const onBlur = () => {
        save();
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (e.key === 'Tab') {
            e.preventDefault();
            save();
        }
        if (e.key === 'Enter') {
            e.preventDefault();
            save();
        }
    };

    return (
        <>
            <Row>
                <HeaderThree>Custom Vocabulary</HeaderThree>
            </Row>
            <Spacing factor={1 / 2} />
            <Row>
                <BodyRegularOnboard>
                    {"Add any custom names, words or phrases you'd like Spinach to learn."}
                </BodyRegularOnboard>
            </Row>

            <Box display="flex" flexDirection="column">
                <RowHeader>
                    <Box
                        pl="10px"
                        width="200px"
                        flex="0 0 auto"
                        marginRight="20px"
                        display="flex"
                        alignItems="flex-start"
                    >
                        Word, phrases, or name
                    </Box>
                    <Box>Misspellings</Box>
                </RowHeader>
                {isLoading ? (
                    <></>
                ) : (
                    vocabs.map(({ text, id, misspellings }) => (
                        <RowOfMainAndMisspelling
                            key={id}
                            id={id}
                            text={text}
                            misspellings={misspellings}
                            vocabs={vocabs}
                            setVocabs={setVocabs}
                        />
                    ))
                )}
                {!showingCreateNew ? (
                    <Box
                        display="flex"
                        flexDirection="row"
                        mt="10px"
                        py="10px"
                        mr="10px"
                        px="10px"
                        justifyContent="center"
                        style={{ background: lightTheme.neutrals.offWhite }}
                        onClick={() => {
                            setShowingCreateNew(true);
                            setInputActiveMain(true);
                            setTimeout(() => {
                                inputRefMain.current?.focus();
                            }, 300);
                        }}
                    >
                        <AddIcon style={{ color: lightTheme.secondary.midnight }} />
                    </Box>
                ) : null}
                {showingCreateNew ? (
                    <Box
                        display="flex"
                        flexDirection="row"
                        mt="10px"
                        mr="10px"
                        px="10px"
                        flexWrap="wrap"
                        style={{ background: lightTheme.neutrals.offWhite }}
                    >
                        <InlineTitle>Word, phrases, or name:</InlineTitle>
                        <NewTag
                            inputRef={inputRefMain}
                            onBlur={onBlur}
                            handleKeyDown={handleKeyDown}
                            pendingText={pendingTextMain}
                            setPendingText={setPendingTextMain}
                            inputActive={inputActiveMain}
                            placeholder="Add word or phrase"
                            editNew={() => {
                                setPendingTextMain('');
                                setInputActiveMain(true);
                                setTimeout(() => {
                                    inputRefMain.current?.focus();
                                }, 100);
                            }}
                        />
                    </Box>
                ) : null}
            </Box>
        </>
    );
};

export const NewTag = ({
    inputRef,
    onBlur,
    handleKeyDown,
    pendingText,
    setPendingText,
    inputActive,
    editNew,
    save,
    isDisabled,
    styleOverrides,
    placeholder,
}: {
    inputRef?: React.RefObject<HTMLInputElement>;
    isDisabled?: boolean;
    onBlur: () => void;
    handleKeyDown: React.KeyboardEventHandler<HTMLInputElement>;
    pendingText: string;
    setPendingText: (text: string) => void;
    inputActive: boolean;
    editNew: () => void;
    save?: () => void;
    styleOverrides?: CSSProperties;
    placeholder: string;
}) => (
    <Box
        display="flex"
        flexDirection="row"
        paddingLeft="5px"
        paddingRight="5px"
        paddingTop="2px"
        paddingBottom="2px"
        minWidth="20px"
        alignItems="center"
        width="auto"
        marginBottom="10px"
        marginTop="10px"
        color="green"
        style={{
            border: `1px solid ${lightTheme.tag.actionColor}`,
            ...styleOverrides,
        }}
        borderRadius="4px"
        marginRight="10px"
    >
        <input
            ref={inputRef}
            size={pendingText.length || 1}
            onKeyDown={handleKeyDown}
            style={{
                display: inputActive ? undefined : 'none',
                background: 'none',
                border: 'none',
                outline: 'none',
                minWidth: '20px',
                width: 'auto',
                flexGrow: 1,
            }}
            maxLength={100}
            type="text"
            disabled={isDisabled}
            onBlur={() => {
                if (!pendingText.trim()) {
                    editNew();
                    return;
                }
                onBlur();
            }}
            value={pendingText}
            onChange={(e) => setPendingText(e.target.value)}
            placeholder=""
        />
        {!inputActive ? (
            <Box
                color={lightTheme.tag.actionColor}
                style={{ cursor: isDisabled ? 'default' : 'pointer', paddingRight: '7px' }}
                onClick={() => {
                    if (isDisabled) {
                        return;
                    }
                    editNew();
                }}
            >
                {placeholder}
            </Box>
        ) : (
            <></>
        )}
        <Box
            style={{ cursor: 'pointer', paddingRight: '5px' }}
            onClick={() => {
                if (isDisabled) {
                    return;
                }
                if (inputActive) {
                    if (save) {
                        save();
                    }
                    // no action required since onBlur will handle it
                } else {
                    editNew();
                }
            }}
        >
            {inputActive ? '✓' : '+'}
        </Box>
    </Box>
);

interface DeletableTagProps {
    id: string;
    text: string;
    deleteItem: (id: string) => void;
    disabled?: boolean;
    contentMasking?: boolean;
}

export function DeletableTag({ id, text, deleteItem, disabled, contentMasking }: DeletableTagProps) {
    return (
        <Box
            display="flex"
            flexWrap="nowrap"
            flexDirection="row"
            paddingLeft="5px"
            paddingRight="5px"
            paddingTop="2px"
            paddingBottom="2px"
            alignItems="center"
            marginBottom="10px"
            marginTop="10px"
            style={{
                border: `1px solid ${lightTheme.tag.borderColor}`,
                backgroundColor: lightTheme.tag.background,
            }}
            borderRadius="4px"
            key={id}
            marginRight="10px"
        >
            <Box style={{ textOverflow: 'ellipsis', overflow: 'clip', color: lightTheme.tag.textColor }}>
                <Typography variant="body2" {...(contentMasking ? withContentMasking() : {})}>
                    {text}
                </Typography>
            </Box>
            <Box
                style={{
                    cursor: disabled ? 'default' : 'pointer',
                    paddingRight: '5px',
                    paddingLeft: '5px',
                    color: lightTheme.tag.actionColor,
                    opacity: disabled ? 0 : 1,
                }}
                onClick={() => {
                    if (disabled) {
                        return;
                    }
                    deleteItem(id);
                }}
            >
                {'×'}
            </Box>
        </Box>
    );
}
