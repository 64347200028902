import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ConflictErrorCode, FeatureToggle, WebUrlQuery } from '@spinach-shared/types';
import { CELLO_COOKIE_NAME, UTM_COOKIE_NAME } from '@spinach-shared/utils';

import { postAnonymousFeatureFlag, postHandleMicrosoftLoginWithCode } from '../../apis';
import { getAnonymousUserId, useGlobalRouting } from '../../hooks';
import { useHandleSuccessfulMicrosoftLogin } from '../../hooks/useHandleSuccessfulMicrosoftLogin';
import { FYI, FYIState } from '../common';

export function VerifyMicrosoftCode() {
    const { routeToVerify } = useGlobalRouting();
    const [inProgress, setInProgress] = useState(false);
    const [params] = useSearchParams();
    const onSuccessfulMicrosoftLogin = useHandleSuccessfulMicrosoftLogin();

    useEffect(() => {
        async function verifyCode() {
            if (inProgress) {
                return;
            }
            try {
                const code = params.get(WebUrlQuery.Code);
                let state = params.get(WebUrlQuery.State);
                if (!code) {
                    throw new Error('No code provided');
                }

                let activateTrialParam = '';

                if (state) {
                    try {
                        const decodedState = atob(state);
                        const qp = new URLSearchParams(decodedState);
                        activateTrialParam = qp.get(WebUrlQuery.StartTrial) ?? '';
                        const ucc = qp.get(WebUrlQuery.UCC) ?? undefined;
                        const utmSource = qp.get(UTM_COOKIE_NAME) ?? undefined;
                        const timezone = qp.get(WebUrlQuery.Timezone) ?? undefined;
                        if (ucc) {
                            const expires = new Date();
                            expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000);
                            document.cookie = `${CELLO_COOKIE_NAME}=${ucc};expires=${expires.toUTCString()};path=/;domain=.spinach.io`;
                        }
                        if (utmSource) {
                            const expires = new Date();
                            expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000);
                            const rawValue = JSON.parse(utmSource);
                            const utmSourceFromRaw = rawValue[UTM_COOKIE_NAME];
                            if (utmSourceFromRaw) {
                                document.cookie = `${UTM_COOKIE_NAME}=${JSON.stringify(
                                    utmSourceFromRaw
                                )};expires=${expires.toUTCString()};path=/;domain=.spinach.io`;
                            }
                        }

                        if (!timezone) {
                            const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                            qp.set(WebUrlQuery.Timezone, userTimezone);
                            state = btoa(qp.toString());
                        }
                    } catch (error) {}
                } else {
                    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    state = btoa(`?${WebUrlQuery.Timezone}=${userTimezone}`);
                }
                setInProgress(true);
                const [recallV2Auth, recallV2Onboarding] = await Promise.all([
                    postAnonymousFeatureFlag(FeatureToggle.RecallV2Auth, false, getAnonymousUserId()),
                    postAnonymousFeatureFlag(FeatureToggle.RecallV2Onboarding, false, getAnonymousUserId()),
                ]);

                const isRecallV2Enabled = recallV2Auth || recallV2Onboarding;

                const response = await postHandleMicrosoftLoginWithCode(code, state || undefined, isRecallV2Enabled);
                const newParams = new URLSearchParams();
                if (activateTrialParam?.trim() === 'true') {
                    newParams.set(WebUrlQuery.StartTrial, 'true');
                }
                if (response.isSsoRequired) {
                    routeToVerify(
                        undefined,
                        new URLSearchParams({
                            [WebUrlQuery.SSO]: 'true',
                        })
                    );
                } else if ('microsoftFlow' in response && response.microsoftFlow && response.user) {
                    await onSuccessfulMicrosoftLogin(response.user, !!response.isNewUser);
                } else if (response?.success) {
                    if (response.email) {
                        newParams.set(WebUrlQuery.CodeEntry, btoa(response.email));
                    }
                    routeToVerify({ replace: true }, newParams);
                } else {
                    if (response.errorCode === ConflictErrorCode.UserAlreadyLoggedInWithGoogle) {
                        newParams.set(WebUrlQuery.AuthErrorCode, ConflictErrorCode.UserAlreadyLoggedInWithGoogle);
                    }

                    routeToVerify({ replace: true }, newParams);
                }
            } catch (error) {
                routeToVerify();
            }
        }
        verifyCode();
    }, [params.get(WebUrlQuery.Code)]);

    return <FYI state={FYIState.Loading} />;
}
