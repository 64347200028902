import { ClientLogger } from '../../../../utils';

export interface LocalRecording {
    id: string;
    chunks: Blob[];
    duration: number;
    createdAt: Date;
    name?: string;
    mimeType: string;
}

export class RecordingsStorageService {
    private db: IDBDatabase | null = null;
    private readonly DB_NAME = 'SpinachRecordings';
    private readonly STORE_NAME = 'recordings';
    private readonly DB_VERSION = 1;

    async initDB(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.db) {
                resolve();
                return;
            }

            try {
                const request = indexedDB.open(this.DB_NAME, this.DB_VERSION);

                request.onerror = (event) => {
                    ClientLogger.error('IndexedDB error', { event });
                    reject(new Error('Failed to open IndexedDB'));
                };

                request.onsuccess = (event) => {
                    this.db = (event.target as IDBOpenDBRequest).result;
                    resolve();
                };

                request.onupgradeneeded = (event) => {
                    const db = (event.target as IDBOpenDBRequest).result;

                    // Create object store if it doesn't exist
                    if (!db.objectStoreNames.contains(this.STORE_NAME)) {
                        const store = db.createObjectStore(this.STORE_NAME, { keyPath: 'id' });
                        store.createIndex('createdAt', 'createdAt', { unique: false });
                    }
                };
            } catch (error) {
                ClientLogger.error('Exception when opening IndexedDB', { error, dbName: this.DB_NAME });
                reject(new Error('Failed to open IndexedDB: Browser may not support it or it may be disabled'));
            }
        });
    }

    async saveRecording(recording: LocalRecording): Promise<string> {
        if (!this.db) {
            await this.initDB();
        }

        return new Promise((resolve, reject) => {
            try {
                const transaction = this.db!.transaction([this.STORE_NAME], 'readwrite');
                const store = transaction.objectStore(this.STORE_NAME);

                const request = store.put(recording);

                request.onsuccess = () => {
                    resolve(recording.id);
                };

                request.onerror = (event) => {
                    const error = (event?.target as IDBRequest).error;
                    ClientLogger.error('Error saving recording', {
                        errorName: error?.name,
                        errorMessage: error?.message,
                        recordingId: recording.id,
                    });
                    reject(new Error('Failed to save recording'));
                };
            } catch (error) {
                reject(error);
            }
        });
    }

    async getRecording(id: string): Promise<LocalRecording | null> {
        if (!this.db) {
            await this.initDB();
        }

        return new Promise((resolve, reject) => {
            try {
                const transaction = this.db!.transaction([this.STORE_NAME], 'readonly');
                const store = transaction.objectStore(this.STORE_NAME);

                const request = store.get(id);

                request.onsuccess = () => {
                    const recording = request.result as LocalRecording | undefined;
                    resolve(recording || null);
                };

                request.onerror = (event) => {
                    ClientLogger.error('Error getting recording', { event, recordingId: id });
                    reject(new Error('Failed to get recording'));
                };
            } catch (error) {
                ClientLogger.error('Transaction error when getting recording', { error, recordingId: id });
                reject(error);
            }
        });
    }

    async getAllRecordings(): Promise<LocalRecording[]> {
        if (!this.db) {
            await this.initDB();
        }

        return new Promise((resolve, reject) => {
            try {
                const transaction = this.db!.transaction([this.STORE_NAME], 'readonly');
                const store = transaction.objectStore(this.STORE_NAME);

                const request = store.getAll();

                request.onsuccess = () => {
                    const recordings = request.result as LocalRecording[];
                    // Ensure createdAt is a Date object (IndexedDB stores it as a string)
                    const processedRecordings = recordings.map((recording) => ({
                        ...recording,
                        createdAt: new Date(recording.createdAt),
                    }));
                    resolve(processedRecordings);
                };

                request.onerror = (event) => {
                    ClientLogger.error('Error getting all recordings', { event });
                    reject(new Error('Failed to get all recordings'));
                };
            } catch (error) {
                ClientLogger.error('Transaction error when getting all recordings', { error });
                reject(error);
            }
        });
    }

    async deleteRecording(id: string): Promise<void> {
        if (!this.db) {
            await this.initDB();
        }

        return new Promise((resolve, reject) => {
            try {
                const transaction = this.db!.transaction([this.STORE_NAME], 'readwrite');
                const store = transaction.objectStore(this.STORE_NAME);

                const request = store.delete(id);

                request.onsuccess = () => {
                    resolve();
                };

                request.onerror = (event) => {
                    ClientLogger.error('Error deleting recording', { event, recordingId: id });
                    reject(new Error('Failed to delete recording'));
                };
            } catch (error) {
                ClientLogger.error('Transaction error when deleting recording', { error, recordingId: id });
                reject(error);
            }
        });
    }

    async updateRecording(recording: Partial<LocalRecording> & { id: string }): Promise<void> {
        if (!this.db) {
            await this.initDB();
        }

        return new Promise(async (resolve, reject) => {
            try {
                // First get the existing recording
                const existingRecording = await this.getRecording(recording.id);
                if (!existingRecording) {
                    reject(new Error('Recording not found'));
                    return;
                }

                // Merge the existing recording with the updates
                const updatedRecording = {
                    ...existingRecording,
                    ...recording,
                };

                const transaction = this.db!.transaction([this.STORE_NAME], 'readwrite');
                const store = transaction.objectStore(this.STORE_NAME);

                const request = store.put(updatedRecording);

                request.onsuccess = () => {
                    resolve();
                };

                request.onerror = (event) => {
                    ClientLogger.error('Error updating recording', { event, recordingId: recording.id });
                    reject(new Error('Failed to update recording'));
                };
            } catch (error) {
                ClientLogger.error('Transaction error when updating recording', { error, recordingId: recording.id });
                reject(error);
            }
        });
    }

    async dispose(): Promise<void> {
        if (this.db) {
            this.db.close();
            this.db = null;
            ClientLogger.info('IndexedDB connection closed', { dbName: this.DB_NAME });
        }
    }
}
