import { IconButton } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { ReactNode } from 'react';
import { CSSProperties } from 'styled-components';

import { ResponsiveModalContent } from '../series/common';
import { CloseButton } from './CloseButton';
import { Row } from './framing';

export const SpinachModalContent = ({
    onBack,
    onClose,
    children,
    style,
    className,
}: {
    onClose?: () => void;
    onBack?: () => void;
    children?: ReactNode;
    style?: CSSProperties;
    className?: string;
}) => {
    return (
        <ResponsiveModalContent style={style} className={className}>
            <Row style={{ alignItems: 'center', zIndex: 100 }}>
                {onClose ? (
                    <CloseButton
                        style={{
                            fontSize: '17px',
                            cursor: 'pointer',
                            marginRight: '5px',
                            left: '3px',
                            top: '9px',
                        }}
                        onClick={onClose}
                    />
                ) : null}
                {onBack ? (
                    <IconButton
                        aria-label="back to previous modal view"
                        size="small"
                        style={{ marginTop: '-5px', marginLeft: '-5px' }}
                        onClick={onBack}
                    >
                        <ChevronLeft fontSize="default" />
                    </IconButton>
                ) : null}
            </Row>
            {children}
        </ResponsiveModalContent>
    );
};
