import { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ClientEventType } from '@spinach-shared/types';

import { useExperienceTracking, useGlobalAiDashboard, useGlobalAuthedUser } from '../../../../..';
import { ClientLogger } from '../../../../utils';
import { LocalRecording } from './RecordingsStorageService';
import { generateUniqueId } from './audioUtils';
import { AudioDevice, RecordingData, RecordingSettings, RecordingState } from './types';
import { useRecordingsStorage } from './useRecordingsStorage';

export function useAudioRecording() {
    const track = useExperienceTracking();
    const [recordingState, setRecordingState] = useState<RecordingState>('inactive');
    const [availableDevices, setAvailableDevices] = useState<AudioDevice[]>([]);
    const [recordingTime, setRecordingTime] = useState<number>(0);
    const [amplitude, setAmplitude] = useState<number>(0);
    const [mimeType, setMimeType] = useState<string>('audio/webm');
    const { openUploadSectionWithAudioRecording, openUploadSectionWithRecordingError } = useGlobalAiDashboard();
    const mediaRecorder = useRef<MediaRecorder | null>(null);
    const recordingData = useRef<RecordingData | null>(null);
    const stream = useRef<MediaStream | null>(null);
    const timerInterval = useRef<number | null>(null);
    const recordingId = useRef<string | null>(null);
    const analyser = useRef<AnalyserNode | null>(null);
    const animationFrame = useRef<number | null>(null);
    const [permissionDenied, setPermissionDenied] = useState(false);
    const [user] = useGlobalAuthedUser();
    const audioContext = useRef<AudioContext | null>(null);
    const { saveRecording, getAllRecordings } = useRecordingsStorage();
    const [isRestarting, setIsRestarting] = useState(false);

    // Timer management
    const startTimer = useCallback(() => {
        if (timerInterval.current) {
            return;
        }

        const startTime = Date.now() - recordingTime * 1000; // Account for paused time
        timerInterval.current = window.setInterval(() => {
            setRecordingTime(Math.floor((Date.now() - startTime) / 1000));
        }, 1000);
    }, [recordingTime]);

    const stopTimer = useCallback(() => {
        if (timerInterval.current) {
            window.clearInterval(timerInterval.current);
            timerInterval.current = null;
        }
    }, []);

    const resetTimer = useCallback(() => {
        stopTimer();
        setRecordingTime(0);
    }, [stopTimer]);

    // Get available audio devices
    const refreshDevices = useCallback(async () => {
        try {
            // First request microphone permission
            await navigator.mediaDevices.getUserMedia({ audio: true });

            // Now enumerate devices - labels will be populated
            const devices = await navigator.mediaDevices.enumerateDevices();
            const audioDevices = devices
                .filter((device) => device.kind === 'audioinput' && !!device.label && !!device.deviceId)
                .map((device) => ({
                    deviceId: device.deviceId,
                    label: device.label,
                }));
            setAvailableDevices(audioDevices);
            setPermissionDenied(false);
        } catch (err) {
            if (err instanceof DOMException && err.name === 'NotAllowedError') {
                setPermissionDenied(true);
            } else {
                track(ClientEventType.AIDashboardUnhappyPath, {
                    Flow: 'Sidebar Recorder',
                    Error: err instanceof Error ? err.message : 'Unknown error',
                });
                openUploadSectionWithRecordingError(
                    'Looks like we ran into an issue. Refresh your browser, and try again.'
                );
            }
        }
    }, [setPermissionDenied, openUploadSectionWithRecordingError]);

    // Initialize recording with selected device
    const initializeRecording = useCallback(async (settings: RecordingSettings) => {
        try {
            if (stream.current) {
                stream.current.getTracks().forEach((track) => track.stop());
            }

            // Close existing audio context if it exists
            if (audioContext.current) {
                await audioContext.current.close();
            }

            const constraints: MediaStreamConstraints = {
                audio: settings.selectedDeviceId ? { deviceId: { exact: settings.selectedDeviceId } } : true,
            };

            const audioStream = await navigator.mediaDevices.getUserMedia(constraints);
            stream.current = audioStream;

            // Create and store new audio context
            const newAudioContext = new AudioContext();
            audioContext.current = newAudioContext;
            const source = newAudioContext.createMediaStreamSource(audioStream);
            const newAnalyser = newAudioContext.createAnalyser();
            newAnalyser.fftSize = 256;
            source.connect(newAnalyser);
            analyser.current = newAnalyser;

            const recorder = new MediaRecorder(audioStream, {
                mimeType: settings.mimeType,
            });

            recorder.ondataavailable = async (event) => {
                if (event.data.size > 0 && recordingData.current && recordingId.current) {
                    recordingData.current.chunks.push(event.data);
                }
            };

            mediaRecorder.current = recorder;
        } catch (err) {
            track(ClientEventType.AIDashboardUnhappyPath, {
                Flow: 'Sidebar Recorder',
                Error: err instanceof Error ? err.message : 'Unknown error',
            });
            openUploadSectionWithRecordingError(
                'Looks like we ran into an issue. Refresh your browser, and try again.'
            );
        }
    }, []);

    const updateAmplitude = useCallback(() => {
        if (analyser.current && mediaRecorder.current?.state === 'recording') {
            const dataArray = new Uint8Array(analyser.current.frequencyBinCount);
            analyser.current.getByteTimeDomainData(dataArray);

            // Calculate average amplitude
            let sum = 0;
            for (let i = 0; i < dataArray.length; i++) {
                sum += Math.abs(dataArray[i] - 128);
            }
            const avg = sum / dataArray.length;
            // Normalize to 0-1 range and add base scale
            const newAmplitude = (avg / 128) * 0.3; // Scale factor of 0.3 can be adjusted
            setAmplitude(newAmplitude); // Scale factor of 0.3 can be adjusted

            animationFrame.current = requestAnimationFrame(updateAmplitude);
        }
    }, []);

    // Start recording
    const startRecording = useCallback(
        async (settings: Omit<RecordingSettings, 'mimeType'>) => {
            try {
                // Use the mimeType from state
                const recordingSettings: RecordingSettings = {
                    ...settings,
                    mimeType,
                };

                await initializeRecording(recordingSettings);

                if (mediaRecorder.current && stream.current) {
                    recordingId.current = uuidv4();
                    recordingData.current = {
                        chunks: [],
                        startTime: Date.now(),
                        deviceId: settings.selectedDeviceId || 'default',
                    };

                    mediaRecorder.current.start(1000); // Save chunk every second
                    setRecordingState('recording');
                    startTimer();

                    // Start amplitude monitoring
                    updateAmplitude();
                }
            } catch (err) {
                track(ClientEventType.AIDashboardUnhappyPath, {
                    Flow: 'Sidebar Recorder',
                    Error: err instanceof Error ? err.message : 'Unknown error',
                });
                openUploadSectionWithRecordingError(
                    'Looks like we ran into an issue. Refresh your browser, and try again.'
                );
            }
        },
        [initializeRecording, startTimer, updateAmplitude, openUploadSectionWithRecordingError, mimeType]
    );

    // Stop recording
    const stopRecording = useCallback(async () => {
        if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
            mediaRecorder.current.stop();
            stream.current?.getTracks().forEach((track) => track.stop());
            setRecordingState('inactive');
            const totalRecordingTime = recordingTime;
            stopTimer();
            resetTimer();

            // Create and download the recording file
            if (totalRecordingTime < 5) {
                //user.minRecordingLengthInSeconds) {
                track(ClientEventType.AIDashboardUnhappyPath, {
                    Flow: 'Sidebar Recorder',
                    Error: 'Recording is too short',
                });
                openUploadSectionWithRecordingError(
                    `Your recording is too short. Please record at least ${user.minRecordingLengthInSeconds} seconds for the best results.`
                );
            } else if (recordingData.current?.chunks.length) {
                const blob = new Blob(recordingData.current.chunks, { type: mimeType });

                // Save to IndexedDB
                const localRecordingId = generateUniqueId();
                const localRecording: LocalRecording = {
                    id: localRecordingId,
                    chunks: recordingData.current.chunks,
                    duration: totalRecordingTime,
                    createdAt: new Date(),
                    name: `Recording ${new Date().toLocaleString()}`,
                    mimeType: mimeType,
                };

                try {
                    await saveRecording(localRecording);
                } catch (error) {
                    ClientLogger.error('Failed to save recording to IndexedDB', {
                        error,
                        recordingId: localRecordingId,
                        spinachUserId: user?._id,
                    });
                }

                openUploadSectionWithAudioRecording(blob, mimeType);
            }

            recordingId.current = null;
        }
    }, [
        stopTimer,
        resetTimer,
        user.minRecordingLengthInSeconds,
        openUploadSectionWithAudioRecording,
        openUploadSectionWithRecordingError,
        recordingData.current?.chunks.length,
        recordingTime,
        saveRecording,
        user?._id,
        mimeType,
    ]);

    // Pause recording
    const pauseRecording = useCallback(() => {
        if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
            mediaRecorder.current.pause();
            setRecordingState('paused');
            stopTimer();
        }
    }, [stopTimer]);

    // Resume recording
    const resumeRecording = useCallback(() => {
        if (mediaRecorder.current && mediaRecorder.current.state === 'paused') {
            mediaRecorder.current.resume();
            setRecordingState('recording');
            startTimer();
            updateAmplitude();
        }
    }, [startTimer, updateAmplitude]);

    const restartRecording = useCallback(async () => {
        if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive' && recordingData.current) {
            mediaRecorder.current.stop();

            stopTimer();
            resetTimer();

            setIsRestarting(true);
        }
    }, [resetTimer, stopTimer]);

    useEffect(() => {
        if (isRestarting && recordingTime === 0) {
            setIsRestarting(false);
            startRecording({ selectedDeviceId: recordingData.current?.deviceId || 'default' });
        }
    }, [isRestarting, startRecording, recordingTime]);

    const deleteRecording = useCallback(async () => {
        if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
            mediaRecorder.current.stop();
            setRecordingState('inactive');
            resetTimer();
            recordingId.current = null;
        }
    }, [resetTimer]);

    // Get recording data
    const getRecordingData = useCallback(() => {
        return recordingData.current;
    }, []);

    // Get all local recordings
    const getAllLocalRecordings = useCallback(async () => {
        return await getAllRecordings();
    }, [getAllRecordings]);

    // Determine the supported MIME type based on browser capabilities
    const determineSupportedMimeType = useCallback(() => {
        // Default to audio/webm
        let supportedMimeType = 'audio/webm';

        // Safari only supports audio/mp4
        if (!MediaRecorder.isTypeSupported('audio/webm') && MediaRecorder.isTypeSupported('audio/mp4')) {
            supportedMimeType = 'audio/mp4';
        }

        setMimeType(supportedMimeType);
        return supportedMimeType;
    }, []);

    // Call determineSupportedMimeType during initialization
    useEffect(() => {
        determineSupportedMimeType();
    }, [determineSupportedMimeType]);

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            if (stream.current) {
                stream.current.getTracks().forEach((track) => track.stop());
            }
            if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
                mediaRecorder.current.stop();
            }
            if (animationFrame.current) {
                cancelAnimationFrame(animationFrame.current);
            }
            if (audioContext.current) {
                audioContext.current.close();
            }
            stopTimer();
        };
    }, [stopTimer]);

    return {
        recordingState,
        recordingTime,
        availableDevices,
        refreshDevices,
        startRecording,
        stopRecording,
        pauseRecording,
        resumeRecording,
        getRecordingData,
        restartRecording,
        deleteRecording,
        amplitude,
        permissionDenied,
        getAllLocalRecordings,
    };
}
