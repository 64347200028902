import { Box } from '@material-ui/core';
import styled from 'styled-components';

import { Row, useGlobalVideoAgent } from '../../../..';
import { PoweredBySpinachSection } from './NoAgendaContent';

const EmphasizedCommand = styled.span`
    color: #00ff00;
    font-weight: 700;
`;

export function AgentAgendaNextTopic(): JSX.Element {
    const { session } = useGlobalVideoAgent();

    if (session?.isConclusionPhase) {
        return <></>;
    }

    const next = session?.followingTopic ? session.followingTopic.title : 'Meeting End';
    let Command = session.settings.isVoicePrimaryCommandKind ? (
        <>
            Say <EmphasizedCommand>&quot;Spinach Next&quot;</EmphasizedCommand> when done
        </>
    ) : (
        <>
            Type <EmphasizedCommand>&quot;Next&quot;</EmphasizedCommand> when done
        </>
    );

    if (session?.isLobbyPhase) {
        Command = session.settings.isVoicePrimaryCommandKind ? (
            <>
                Say{' '}
                <EmphasizedCommand>
                    &quot;Spinach Add {'<'}TOPIC{'>'}&quot;
                </EmphasizedCommand>
            </>
        ) : (
            <>
                Type:{' '}
                <EmphasizedCommand>
                    &quot;Add {'<'}TOPIC{'>'}&quot;
                </EmphasizedCommand>
            </>
        );
    }

    if (session?.isPaused) {
        Command = (
            <>
                Type <EmphasizedCommand>&quot;resume&quot;</EmphasizedCommand> to continue recording
            </>
        );
    }

    const NextUp =
        session.agenda.topics.length > 0 ? (
            <>
                {session?.isAgendaPhase ? (
                    <Box style={{ opacity: next ? 1 : 0 }}>
                        Next: <EmphasizedCommand>{next}</EmphasizedCommand>
                    </Box>
                ) : null}
                {session?.isLobbyPhase && session.followingTopic ? (
                    <Box style={{ opacity: next ? 1 : 0 }}>
                        First up: <EmphasizedCommand>{next}</EmphasizedCommand>
                    </Box>
                ) : null}
            </>
        ) : null;

    return (
        <Row
            style={{
                marginBottom: '10px',
                textAlign: 'left',
                fontSize: '40px',
                color: 'white',
            }}
        >
            {NextUp}

            <div style={{ flexGrow: 1 }} />

            {session?.shouldShowFrontAndCenterSpinachLogo && !session.agenda.topics.length ? (
                <Box height={34} />
            ) : (
                <PoweredBySpinachSection />
            )}
        </Row>
    );
}
